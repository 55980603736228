<template>
    <div style="margin-left: 1.5%;">
      <q-select
          :name="inputName"
          :disable="!!disable"
          ref="inputRef"
          :options="items"
          behavior="menu"
          outlined
          :label="label"
          :rules="rules"
          @update:model-value="update($event)"
          :model-value="modelValue"
          transition-duration="100"
          dropdown-icon="fal fa-angle-down"
          style="font-size: 1em"
      />
    </div>
</template>

<script>

import {
  QSelect
} from "quasar";

import {defineComponent, ref} from "vue";

export default defineComponent({
  name: "FtrSelectOrder",
  components: {
    QSelect
  },
  props: {
    model: ref(),
    label: ref(),
    rules: ref(),
    inputName: ref(),
    options: ref(),
    initValue: ref(),
    disable: ref(),
  },
  setup(props, context) {
    const inputRef = ref(null);
    const items = ref([]);
    const modelValue = ref();
    let temp;

    function update(event) {
      console.log(event);
      modelValue.value = event;
      context.emit('update:modelValue', event);
    }

    if (props.options) {
      temp = JSON.stringify(props.options).replaceAll('"', '');
      temp = temp.replace('[', '');
      temp = temp.replace(']', '');
      temp = temp.replace(' ', '');
      items.value = temp.split(',');

      console.log(items.value);
    }
    update(props.initValue);
    return {
      modelValue,
      inputRef,
      items,
      update
    }
  }
})

</script>
<style scoped>
label {
  width: 100%;
  margin-left: 2%;
  margin-right: 2%;
  margin-bottom: 10px;
}

</style>