<template>
  <div style="width: 44%;margin-left: 3%;float: left">
    <q-input
        style="font-size: 1.1em;"
        :label="label"
        :disable="disable"
        ref="inputRef"
        :model-value="modelValue"
        type="text"
        :value="value"
        name="item"
        :name="inputName"
        :rules="rules"
        lazy-rules
        @update:model-value="update($event)"
    />
  </div>
</template>

<script>
import {defineComponent, ref} from "vue";
import {QInput} from "quasar";

export default defineComponent({
  name: "FtrTextInput",
  components: {
    QInput
  },
  props: {
    label: ref(),
    rules: ref(),
    modelValue: ref(),
    value: ref(),
    disable: ref(),
    inputName: ref(),
    initValue: ref(),
  },
  setup(props, context) {
    const inputRef = ref(null)

    function update(value) {
      context.emit("update:modelValue", value);
    }

    update(props.initValue);

    return {
      update,
      inputRef,
    }
  }
})

</script>
<style scoped>
.q-input .q-field {
  line-height: 24px;
  padding-top: 24px;
  padding-bottom: 0 !important;
}

label {
  width: 100%;
  margin-left: 2%;
  margin-right: 2%;
  margin-bottom: 10px;
}

</style>