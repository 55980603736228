<script setup>

import {ref} from "vue";

import {
  QSkeleton,
  QRadio,
  QItem,
  QItemLabel,
  QItemSection,
  useQuasar,
} from "quasar";

import {
  IonItem,
  IonList,
  IonCard,
  IonCardContent,
  IonLabel,
  IonCheckbox,
  IonListHeader
} from '@ionic/vue'

import {useRoute} from "vue-router";

import {useGlobalStore} from "@/store/global.js";

const store = useGlobalStore();
const content = ref();
const route = useRoute();

const selected = ref([]);
const group = ref([]);
const loader = ref();

const $q = useQuasar();

const props = defineProps({
  items: Array,
  title: String,
  propName: String,
  radio: Boolean,
  model: {required: true},
});

// function unique(array, propertyName) {
//   return array.filter((e, i) => array.findIndex(a => a[propertyName] === e[propertyName]) === i);
// }
//
// function modelExists(arr, modelID) {
//   return arr.some(function (el) {
//     return el.modelID === modelID;
//   });
// }

const capitalize = (value) => {
  if (!value) {
    return '';
  }
  value = value.toString();
  return value.charAt(0).toUpperCase() + value.slice(1)
}

const update = async (propName, item) => {
  await store.filterModels(propName, item.id);
  if (store.models.length === 0) {
    $q.notify({
      color: 'red-5',
      position: 'center',
      textColor: 'white',
      icon: 'fas fa-triangle-exclamation',
      message: 'Helaas, deze zoekopdracht geeft geen resultaten'
    });
    // setTimeout(() => {
    //   if (store.state.models.length === 0) {
    //     store.state.models = store.state.initialisedModels;
    //     store.methods.filter();
    //   }
    // }, 6000)
  }
}
</script>

<template>
  <ion-item lines="none" style="flex: 1 0 100%;--inner-padding-end: 0;
    --inner-padding-start: 0;
    --padding-start:0;
    --padding-end: 0" class="ion-no-margin ion-no-border">
    <ion-card v-if="!radio && propName !== 'priceLevel'" ref="content" style="width:100%">
      <ion-card-content>

        <template v-if="!store.filterLoadingState">
          <ion-list :style="store.filterLoadingState?'z-index:0;opacity:0':'z-index:2;opacity:1'"
                    ref="loader" style="transition: opacity 0.5s">
            <ion-list-header style="opacity:0.95;">
              <q-skeleton type="rect" animation="fade" height="26px" width="80px"/>
            </ion-list-header>
            <template v-for="i in 4" :key="i">
              <ion-item style="opacity:0.95;" :detail="false" lines="inset">
                <q-skeleton animation="fade" style="margin: 12px;" size="26px" type="rect"/>
                <q-skeleton animation="fade" style="margin-left: 2px;" width="70px" type="QBadge"/>
              </ion-item>
              <ion-item style="opacity:0.95;" v-if="i < 4" :detail="false" lines="inset">
                <q-skeleton animation="fade" style="margin: 12px;" size="26px" type="rect"/>
                <q-skeleton animation="fade" style="margin-left: 2px;" width="50px" type="QBadge"/>
              </ion-item>
            </template>
          </ion-list>
        </template>

        <template v-else>
          <ion-list style="transition: opacity 0.5s"
                    :style="store.filterLoadingState ? 'z-index:2;opacity:1':'z-index:0;opacity:0'">
            <ion-list-header>
              <ion-label>{{ title }}</ion-label>
            </ion-list-header>
            <template v-if="propName !== 'brand'">
              <ion-item v-for="item in items" :key="item?.id || item" :detail="false" lines="inset">
                <ion-label>{{
                    capitalize(item.item)
                  }}
                </ion-label>
                <ion-checkbox checked="false" slot="start" color="secondary" size="large"
                              :val="item.id"
                              @update:modelValue="update(propName, item)">
                </ion-checkbox>
              </ion-item>
            </template>
            <template v-else-if="route.path.includes('osb') && propName === 'brand'">
              <ion-item v-for="item in items" :key="item?.id || item" :detail="false" lines="inset">
                <ion-label>{{
                    capitalize(item.label)
                  }}
                </ion-label>
                <ion-checkbox checked="false" slot="start" color="secondary" size="large"
                              :val="item.id"
                              @update:modelValue="update(propName, item)">
                </ion-checkbox>
              </ion-item>
            </template>
          </ion-list>
        </template>

      </ion-card-content>
    </ion-card>


    <template
        v-if="route.path.includes('osa') && propName === 'priceLevel' && (items[0].length > 0 || items[1].length > 0 || items[2].length > 0)">
      <ion-card ref="content" style="width:100%">
        <ion-card-content>
          <ion-list :inset="true" v-if="!store.filterLoadingState" lines="inset" style="width:100%">
            <ion-list-header style="opacity:0.95">
              <q-skeleton type="rect" animation="fade" height="26px" width="80px"/>
            </ion-list-header>
            <template v-for="i in 4" :key="i">
              <ion-item style="opacity:0.95" :detail="false" lines="inset">
                <q-skeleton animation="fade" style="margin: 12px" size="26px" type="rect"/>
                <q-skeleton animation="fade" style="margin-left: 2px" width="70px" type="QBadge"/>
              </ion-item>
              <ion-item style="opacity:0.95" v-if="i < 4" :detail="false" lines="inset">
                <q-skeleton animation="fade" style="margin: 12px" size="26px" type="rect"/>
                <q-skeleton animation="fade" style="margin-left: 2px" width="50px" type="QBadge"/>
              </ion-item>
            </template>
          </ion-list>

          <ion-list v-else :inset="true" style="width:100%">
            <ion-list-header v-if="items[0].length > 0">
              <ion-label>Prijsniveau</ion-label>
            </ion-list-header>
            <template v-for="item in items[0]" :key="item.id">
              <ion-item style="--padding-start: 10px;width:100%" lines="none" :detail="false">
                <q-item style="width: 100%;padding: 0;margin: 0" tag="label">
                  <q-item-section style="margin-right: 0;padding-right:0" avatar>
                    <q-radio style="margin-right: 0" slot="start" size="lg" v-model="model"
                             :val="item.id"
                             @update:modelValue="update(propName, item)"
                             :model-value="model"/>
                  </q-item-section>
                  <q-item-section>
                    <q-item-label>
                      <ion-item style="--padding-start: 0;--background: transparent" v-if="items[0].length > 0"
                                :detail="false">
                        <ion-label> {{
                            capitalize(item.item)
                          }}
                        </ion-label>
                      </ion-item>
                    </q-item-label>
                  </q-item-section>
                </q-item>
              </ion-item>
            </template>
          </ion-list>

          <ion-list :inset="true" v-if="!store.filterLoadingState" style="width:100%">
            <ion-list-header style="opacity:0.95">
              <q-skeleton type="rect" animation="fade" height="26px" width="80px"/>
            </ion-list-header>
            <template v-for="i in 4" :key="i">
              <ion-item style="opacity:0.95" :detail="false" lines="inset">
                <q-skeleton animation="fade" style="margin: 12px" size="26px" type="rect"/>
                <q-skeleton animation="fade" style="margin-left: 2px" width="70px" type="QBadge"/>
              </ion-item>
              <ion-item style="opacity:0.95" v-if="i < 4" :detail="false" lines="inset">
                <q-skeleton animation="fade" style="margin: 12px" size="26px" type="rect"/>
                <q-skeleton animation="fade" style="margin-left: 2px" width="50px" type="QBadge"/>
              </ion-item>
            </template>
          </ion-list>
          <ion-list :inset="true" v-else style="width:100%">
            <ion-list-header v-if="items[1].length > 0">
              <ion-label>Zorgverzekeraars</ion-label>
            </ion-list-header>
            <template v-for="item in items[1]" :key="item.id">
              <ion-item style="--padding-start: 10px;width:100%" lines="none" :detail="false">
                <q-item style="width: 100%;padding: 0;margin: 0" tag="label">
                  <q-item-section style="margin-right: 0;padding-right:0" avatar>
                    <q-radio style="margin-right: 0" slot="start" size="lg" v-model="model"
                             :val="item.id"
                             @update:modelValue="update(propName, item)"
                             :model-value="model"/>
                  </q-item-section>
                  <q-item-section>
                    <q-item-label>
                      <ion-item style="--padding-start: 0;--background: transparent" v-if="items[1].length > 0"
                                :detail="false">
                        <ion-label> {{
                            capitalize(item.item)
                          }}
                        </ion-label>
                      </ion-item>
                    </q-item-label>
                  </q-item-section>
                </q-item>
              </ion-item>
            </template>
          </ion-list>

          <ion-list :inset="true" v-if="!store.filterLoadingState" style="width:100%">
            <ion-list-header style="opacity:0.95">
              <q-skeleton type="rect" animation="fade" height="26px" width="80px"/>
            </ion-list-header>
            <template v-for="i in 4" :key="i">
              <ion-item style="opacity:0.95" :detail="false" lines="inset">
                <q-skeleton animation="fade" style="margin: 12px" size="26px" type="rect"/>
                <q-skeleton animation="fade" style="margin-left: 2px" width="70px" type="QBadge"/>
              </ion-item>
              <ion-item style="opacity:0.95" v-if="i < 4" :detail="false" lines="inset">
                <q-skeleton animation="fade" style="margin: 12px" size="26px" type="rect"/>
                <q-skeleton animation="fade" style="margin-left: 2px" width="50px" type="QBadge"/>
              </ion-item>
            </template>
          </ion-list>
          <ion-list :inset="true" v-else style="width:100%">
            <ion-list-header v-if="items[2].length > 0">
              <ion-label>Leveranciers</ion-label>
            </ion-list-header>
            <template v-for="item in items[2]" :key="item.id">
              <ion-item style="--padding-start: 10px;width:100%" lines="none" :detail="false">
                <q-item style="width: 100%;padding: 0;margin: 0" tag="label">
                  <q-item-section style="margin-right: 0;padding-right:0" avatar>
                    <q-radio style="margin-right: 0" slot="start" size="lg" v-model="model"
                             :val="item.id"
                             @update:modelValue="update(propName, item)"
                             :model-value="model"/>
                  </q-item-section>
                  <q-item-section>
                    <q-item-label>
                      <ion-item style="--padding-start: 0;--background: transparent" v-if="items[2].length > 0"
                                :detail="false">
                        <ion-label> {{
                            capitalize(item.item)
                          }}
                        </ion-label>
                      </ion-item>
                    </q-item-label>
                  </q-item-section>
                </q-item>
              </ion-item>

            </template>
          </ion-list>
        </ion-card-content>
      </ion-card>
    </template>
  </ion-item>
</template>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 1s;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>