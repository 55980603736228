<template>

  <template v-if="positioning !== 'column'">
    <q-field ref="inputRef"
             style="font-size: 1.1rem"
             :tappable="true"
             :rules="rules"
             :model-value="modelValue"
             @update:model-value="update($event)"
    >
      <template v-slot:control>
        <ion-card-subtitle style="width: 100%;font-size: 1.1rem">
          {{label}}
        </ion-card-subtitle>
        <template v-for="item in items" :key="item.id" :id="item.id">
          <q-radio :tappable="true" size="lg" :model-value="modelValue" :val="item.id"
                   @update:model-value="update($event)"/>
          <q-item-label :tappable="true" style="min-width:50px;align-self:center">{{ item.item ? item.item : '' }}
          </q-item-label>
        </template>
      </template>
    </q-field>
  </template>

  <template v-if="positioning === 'column'">
    <q-field ref="inputRef" style="width:100%"
             :model-value="modelValue"
             :label="label"
             @update:model-value="update($event)"
    >
      <template v-slot:control>
        <q-list style="width:100%">
          <template v-for="item in items" :key="item.id" :id="item.id">
            <q-item dense style="margin:0;width:100%" tag="label">
              <q-radio size="lg" :model-value="modelValue" :val="item.id"
                       @update:model-value="update($event)"/>
              <q-item-label style="align-self:center">{{ item.item ? item.item : item }}</q-item-label>
            </q-item>
            <q-separator style="width: 100%"/>
          </template>
        </q-list>
      </template>
    </q-field>
  </template>
</template>
<script>

import {
  QItem,
  QItemLabel,
  QItemSection,
  QList,
  QRadio,
  QField,
  QSeparator
} from "quasar";
import {IonCardSubtitle} from "@ionic/vue";
import {
  defineComponent,
  onMounted,
  onUpdated,
  ref,
  watch
} from "vue";

export default defineComponent({
  name: "FtrRadio",
  components: {
    QItem, QItemSection, QRadio, QItemLabel, QList, QField, QSeparator,IonCardSubtitle
  },
  props: {
    options: {required: false},
    component: {required: false},
    modelValue: {required: false},
    model: {required: false},
    class: {required: false},
    uuid: {required: false},
    label: {required: false},
    watchValue: {required: false},
    option: ref(),
    positioning: {required: false},
    rules: ref(),
    menuType: ref(),
    condition: {required: false},
    selected: ref(),
    required: {
      type: Boolean,
      default: false,
    },
    disableNoSelection: {type: Boolean, default: false},
    validations: {
      type: Object,
      default: (r) => {
        console.log("validation: " + r)
      },
    },
  },
  setup(props, context) {
    const modelValue = ref({});
    const language = ref();
    const inputRef = ref(null);

    async function getNormalizedOptions(tempOptions) {
      const itemList = [];

      tempOptions.forEach(option => {
        // let tempOption = JSON.stringify(option);
        const tempOption = option;
        let normalizedOption;
        if (tempOption.id) {
          normalizedOption = {
            id: tempOption.id,
            item: tempOption[language.value],
          }
        } else if (tempOption.value) {
          normalizedOption = {
            id: tempOption.value,
            item: tempOption.value
          }
        } else {
          normalizedOption = {
            id: tempOption,
            item: tempOption
          }
        }
        itemList.push(normalizedOption);
      })
      console.log(itemList);
      return itemList;
    }

    function update(value) {
      modelValue.value = value;
      context.emit("update:modelValue", value);
    }

    const items = ref([]);
    const watcher = props.watchValue;
    (async () => {
      if (props.options !== []) {
        const tempOptions = await props.options;
        console.log(tempOptions);
        // items.value = await getNormalizedOptions(tempOptions)
        items.value = tempOptions;
      }
      if (props.selected) {
        update(props.selected)
      }
    })()

    if (watcher === "heelreinforcement_selector_left") {
      watch(modelValue, async (newValue) => {
        update(modelValue);
      })
    } else if (watcher === "heelreinforcement_selector_right") {
      watch(modelValue, async (newValue) => {
        update(modelValue);
      })
    }
    else if (watcher === "rolling_selector_left") {
      watch(modelValue, async (newValue) => {
        update(modelValue);
      })
    }

    onMounted(async () => {
      if (inputRef.value && props.rules) {
        inputRef.value.validate();
      }
    })

    onUpdated(() => {
      if (inputRef.value && props.rules) {
        inputRef.value.validate();
      }
    })

    return {
      items,
      inputRef,
      update,
      modelValue
    }
  }
})

</script>
<style scoped>
label {
  width: 100%;
  margin-left: 2%;
  margin-right: 2%;
  margin-bottom: 10px;
}

</style>