<script setup>
// This starter template is using Vue 3 <script setup> SFCs
// Check out https://v3.vuejs.org/api/sfc-script-setup.html#sfc-script-setup
import {
	IonAvatar,
	IonButtons,
	IonBadge,
	IonCard,
	IonCardHeader,
	IonCardSubtitle,
	IonCardTitle,
	IonCol,
	IonContent,
	IonFooter,
	IonGrid,
	IonHeader,
	IonLabel,
	IonMenuToggle,
	IonPage,
	IonRow,
	IonSegment,
	IonSegmentButton,
	IonSpinner,
	IonThumbnail,
	IonToolbar,
	IonButton,
	onIonViewDidEnter
} from "@ionic/vue";
import {
	useQuasar,
	QInnerLoading
} from "quasar";
import {
	DataStore,
	SortDirection,
	syncExpression
} from "aws-amplify";
import {
	DevModelCollection,
	DevFootcareModel,
	DevFittrModel,
} from "@/models";
import {useRouter} from 'vue-router';
import {ref} from "vue";
import {useGlobalStore} from "@/store/global";

const store = useGlobalStore();
const $q = useQuasar();
const category = ref();
const router = useRouter();
const visible = ref(true);
const osa = ref();
const measure = ref();
const lasttypes = ref();
const osb = ref();

import {insert} from "@/models-input";

const segmentChanged = (ev) => {
	category.value = ev.detail.value;
	store.category = ev.detail.value;
}

const navigateOSA = async () => {
	if (category.value) {
		osa.value.$el.classList.add('loading');
		const urlModels = [];
		const temp = await DataStore.query(DevFootcareModel, c =>
						c.category("contains", category.value), {
					sort: s => s['modelnumber'](SortDirection.ASCENDING)['colornumber'](SortDirection.ASCENDING)
				}
		);
		for (let i = 0; i < temp.length; i++) {
			let model = temp[i];
			const modelObj = {
				modelID: model.id,
			}
			urlModels.push(modelObj);
		}
		// const collection = await DataStore.save(
		// 		new DevModelCollection({
		// 			models: JSON.stringify(urlModels)
		// 		})
		// );
		const params = {
			category: category.value,
			// collectionId: collection.id,
		};
		store.models = [];
		store.initialisedModels = [];
    store.modelCollection = [];
    store.modelCollection = urlModels;
		store.footData = undefined;
		store.lastProposals.footData = undefined;
    store.imageLoadingState = false;
    store.filterLoadingState = false;
		await router.push('/osa/models/' + JSON.stringify(params));
		setTimeout(() => {
			osa.value.$el.classList.remove("loading");
		}, 500);
	} else {
		showAlert();
	}
}

const navigateOSB = async () => {
	if (category.value) {
		osb.value.$el.classList.add("loading");
		const urlModels = [];
		const temp = await DataStore.query(DevFittrModel, c =>
						c.category("contains", category.value), {
					sort: s => s['brand'](SortDirection.ASCENDING)['modelnumber'](SortDirection.ASCENDING)['colornumber'](SortDirection.ASCENDING)
				}
		);
		for (let i = 0; i < temp.length; i++) {
			let model = temp[i];
			const modelObj = {
				modelID: model.id,
			}
			urlModels.push(modelObj);
		}

		// const collection = await DataStore.save(
		// 		new DevModelCollection({
		// 			models: JSON.stringify(urlModels)
		// 		})
		// );
		const params = {
			category: category.value,
			// collectionId: collection.id,
		};

		store.models = [];
		store.initialisedModels = [];
    store.modelCollection = [];
    store.modelCollection = urlModels;
		store.footData = undefined;
		store.lastProposals.footData = undefined;

    store.imageLoadingState = false;
    store.filterLoadingState = false;
		await router.push('/osb/models/' + JSON.stringify(params));

		setTimeout(() => {
			osb.value.$el.classList.remove("loading");
		}, 500)
	} else {
		showAlert();
	}
}

const navigateMeasurements = () => {
	if (category.value) {
		measure.value.$el.classList.add("loading");
		router.push('/osb/foot-dimensions/' + category.value);
		setTimeout(() => {
			measure.value.$el.classList.remove("loading");
		}, 500)
	} else {
		showAlert();
	}
}

const navigateLasttypes = () => {
	if (category.value) {
		lasttypes.value.$el.classList.add("loading");
		router.push('/osb/alternative-lasts/' + category.value);
		setTimeout(() => {
			lasttypes.value.$el.classList.remove("loading");
		}, 500);
	} else {
		showAlert();
	}
};

const showAlert = () => {
	$q.notify({
		color: 'red-5',
		position: 'center',
		textColor: 'white',
		icon: 'fas fa-triangle-exclamation',
		message: 'Om verder te gaan moet er eerst een doelgroep worden geselecteerd!'
	})
};

onIonViewDidEnter(async () => {
	store.initAppGlobals();
	// DataStore.configure({
	// 	syncExpressions: [
	// 		await syncExpression(DevModelCollection, () => {
	// 			return order => order.isLink('eq', 'TRUE');
	// 		}),
	// 	]
	// });
  DataStore.configure({
    syncExpressions: [
      await syncExpression(DevModelCollection, () => {
        return order => order.isLink('ne', null);
      }),
    ]
  });
});

</script>

<template>

	<ion-page>

		<ion-header class="ion-no-border">
			<ion-toolbar color="none">
				<ion-buttons slot="start">
					<img class="header-logo" alt="no-image" src="../assets/fittr_logo.png">
				</ion-buttons>
				<ion-buttons slot="end" style="color: white;margin-right: 10px;">
					<ion-button style="color:white">
						<ion-menu-toggle style="z-index:200;position:absolute;width:100px;height: 100%">
						</ion-menu-toggle>
						<i style="font-size: 24px;" class="fa-regular fa-bars"></i>
						<ion-badge v-if="!store.logged_in_user" color="secondary"
						           style="z-index:100;position: fixed;right: 0;top:0"><i
								class="fa-regular fa-user-xmark"></i></ion-badge>
					</ion-button>
				</ion-buttons>
			</ion-toolbar>
		</ion-header>

		<ion-content fullscreen class="background-image">
			<ion-grid slot="fixed" class="homepage-overlay">

				<!--        <div class="background-image"></div>-->
				<!--        <div class="homepage-overlay"></div>-->

				<ion-row class="ion-align-items-end">

					<ion-col size-xs="6" size-sm="6" size-md="3" size-lg="3" size-xl="3">
						<ion-card style="--background: var(--ion-color-secondary);" ref="osa" id="osa" button
						          @click="navigateOSA()">
							<div class="card-overlay-osa">
							</div>
							<ion-card-header>
								<ion-thumbnail>
									<ion-avatar>
										<img alt="no image" src="../assets/img/book.jpg">
									</ion-avatar>
								</ion-thumbnail>
								<ion-card-subtitle style="border-bottom: 1px rgba(175, 175, 183, 1) solid;">OS<span
										class="os-type"> A</span></ion-card-subtitle>
								<ion-card-title>Catalogus</ion-card-title>
							</ion-card-header>
							<q-inner-loading :showing="visible">
								<ion-spinner></ion-spinner>
							</q-inner-loading>
						</ion-card>
					</ion-col>

					<ion-col size-xs="6" size-sm="6" size-md="3" size-lg="3" size-xl="3">
						<ion-card style="--background: var(--ion-color-secondary);" button ref="measure" id="measure"
						          @click="navigateMeasurements()">
							<div class="card-overlay">
							</div>
							<ion-card-header>
								<ion-thumbnail>
									<ion-avatar>
										<img alt="no image" src="../assets/img/measurements.jpg">
									</ion-avatar>
								</ion-thumbnail>
								<ion-card-subtitle style="border-bottom: 1px rgba(175, 175, 183, 1) solid;">OS<span class="os-type"> B</span></ion-card-subtitle>
								<ion-card-title>Voetmaten</ion-card-title>
							</ion-card-header>
							<q-inner-loading :showing="visible">
								<ion-spinner></ion-spinner>
							</q-inner-loading>
						</ion-card>
					</ion-col>

					<ion-col size-xs="6" size-sm="6" size-md="3" size-lg="3" size-xl="3">
						<ion-card style="--background: var(--ion-color-secondary);" button ref="lasttypes" id="lasttypes"
						          @click="navigateLasttypes()">
							<div class="card-overlay">
							</div>
							<ion-card-header>
								<ion-thumbnail>
									<ion-avatar>
										<img alt="no image" src="../assets/img/lasts.jpg">
									</ion-avatar>
								</ion-thumbnail>
								<ion-card-subtitle style="border-bottom: 1px rgba(175, 175, 183, 1) solid;">OS<span class="os-type"> B</span></ion-card-subtitle>
								<ion-card-title>Leesttypen</ion-card-title>
							</ion-card-header>
							<q-inner-loading :showing="visible">
								<ion-spinner></ion-spinner>
							</q-inner-loading>
						</ion-card>
					</ion-col>

					<ion-col size-xs="6" size-sm="6" size-md="3" size-lg="3" size-xl="3" @click="navigateOSB()">
						<ion-card style="--background: var(--ion-color-secondary);z-index: 0;" button ref="osb" id="osb">
							<div class="card-overlay">
							</div>
							<ion-card-header>
								<ion-thumbnail>
									<ion-avatar>
										<img alt="no image" src="../assets/img/osb.jpg">
									</ion-avatar>
								</ion-thumbnail>
								<ion-card-subtitle style="border-bottom: 1px rgba(175, 175, 183, 1) solid;">OS<span class="os-type"> B</span></ion-card-subtitle>
								<ion-card-title>Catalogus</ion-card-title>
							</ion-card-header>
							<q-inner-loading :showing="visible">
								<ion-spinner></ion-spinner>
							</q-inner-loading>
						</ion-card>
					</ion-col>

				</ion-row>
			</ion-grid>
		</ion-content>

		<ion-footer>
			<ion-toolbar style="display: flex;" color="light">
				<ion-segment @ionChange="segmentChanged($event)" color="tertiary" style="color:var(--ion-color-tertiary)">
					<ion-segment-button value="f">
						<i class="fa-thin fa-female footer-icon" style="color:var(--ion-color-tertiary)"></i>
						<ion-label style="color:var(--ion-color-tertiary);margin-top: 0;margin-bottom: 0">Dames</ion-label>
					</ion-segment-button>
					<ion-segment-button value="m">
						<i class="fa-thin fa-male footer-icon" style="color:var(--ion-color-tertiary)"></i>
						<ion-label style="color:var(--ion-color-tertiary);margin-top: 0;margin-bottom: 0">Heren</ion-label>
					</ion-segment-button>
					<ion-segment-button value="k">
						<i class="fa-thin fa-child footer-icon" style="color:var(--ion-color-tertiary)"></i>
						<ion-label style="color:var(--ion-color-tertiary);margin-top: 0;margin-bottom: 0">Kids</ion-label>
					</ion-segment-button>
					<ion-segment-button value="w">
						<i class="fa-thin fa-user-helmet-safety footer-icon" style="color:var(--ion-color-tertiary)"></i>
						<ion-label style="color:var(--ion-color-tertiary);margin-top: 0;margin-bottom: 0">Safety</ion-label>
					</ion-segment-button>
				</ion-segment>
			</ion-toolbar>
		</ion-footer>
	</ion-page>
</template>

<style scoped>

@media (min-width: 350px) {
	ion-grid {
		height: 100%;
		width: 100%;
	}

	ion-row {
		width: 100%;
		height: 100%;
		padding-bottom: calc(80px + var(--ion-safe-area-bottom));
		align-content: flex-end;
	}

	.q-inner-loading {
		background: rgba(175, 175, 193, 0.5);
		visibility: hidden;
	}

	.loading > .q-inner-loading {
		visibility: visible;
	}

	.os-type {
		color: rgba(255, 255, 255, 0.75);
		font-size: 1.6rem;
	}

	ion-content {
		--background: rgba(75, 75, 93, 0.7);
	}

	.background-image {
		position: absolute;
		height: 67vh;
		width: 100%;
		background-image: url(../assets/img/homepage.jpg);
		background-position: top;
		background-size: cover;
		top: 0;
		left: 0;
		background-repeat: no-repeat;
	}

	.homepage-overlay {
		height: 100vh;
		width: 100%;
		top: 0;
		left: 0;
		padding-left: 5px;
		padding-right: 5px;
		position: absolute;
		background: rgba(75, 75, 93, 0.15);
		/*background: hsla(0, 0%, 84.3%, .7);;*/
	}

	.card-overlay {
		height: 100%;
		width: 100%;
		top: 0;
		left: 0;
		position: absolute;
		background: rgba(75, 75, 93, 0.5);
	}

	.card-overlay-osa {
		height: 100%;
		width: 100%;
		top: 0;
		left: 0;
		position: absolute;
		background: rgba(75, 75, 93, 0.5);
	}

	ion-segment {
		width: 90%;
		margin-bottom: 10px;
		margin-top: 10px;
	}

	.md ion-segment {
		left: 5%;
	}

	ion-segment-button {
		padding-top: 5px;
		width: 70px;
	}

	.footer-icon {
		font-size: 1.5em;
	}

	ion-avatar {
		width: 55px;
		height: 55px;
	}

	ion-card {
		--background: var(--ion-color-secondary);
		--padding-inline-start: 5px;
		--padding-top: 5px;
		border-radius: 15px;
		margin: 10px;
	}

	ion-card-subtitle {
		margin: 10px 0 5px;
		border-bottom: 1px rgba(75, 75, 83, 1) solid;
		color: rgba(255, 255, 255, 0.5);
		padding-bottom: 5px;
		font-size: 1rem;
		text-align: right;
	}

	ion-card-title {
		color: rgba(255, 255, 255, 0.9);
		font-size: 1.1rem;
		text-align: center;
	}

	ion-toolbar {
		background-image: none !important;
	}

}

/*###############################################################*/
@media (min-width: 576px) {
	ion-row {
		width: 100%;
		height: 100%;
		padding-bottom: calc(130px + var(--ion-safe-area-bottom));
		align-content: flex-end;
	}

	ion-card {
		--background: var(--ion-color-secondary-shade);
		--padding-inline-start: 5px;
		--padding-top: 5px;
		border-radius: 15px;
		margin: 30px;
	}

	.homepage-overlay {
		padding-left: 20px;
		padding-right: 20px;
	}

	ion-card-subtitle {
		margin: 20px 0 10px;
		border-bottom: 1px rgba(75, 75, 83, 1) solid;
		color: rgba(255, 255, 255, 0.5);
		padding-bottom: 5px;
		font-size: 1.3rem;
		text-align: right;
	}

	ion-card-title {
		color: rgba(255, 255, 255, 0.9);
		font-size: 1.4rem;
		text-align: center;
	}

	ion-segment {
		width: 60%;
		margin-bottom: 10px;
		margin-top: 10px;
	}

	.md ion-segment {
		left: 20%;
	}
}

/*###############################################################*/
@media (min-width: 768px) {
	ion-segment-button {
		width: 100px;
	}
}

/*###############################################################*/
@media (min-width: 992px) {
	ion-segment-button {
		width: 125px;
	}
}

/*###############################################################*/
@media (min-width: 1024px) {

}

/*###############################################################*/
@media (min-width: 1150px) {

}
</style>
