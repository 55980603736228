<template>
  <div style="margin-left: 1.5%;">
    <q-select
        :name="inputName"
        :disable="!!disable"
        ref="inputRef"
        :options="items"
        behavior="dialog"
        outlined
        :label="label"
        :rules="rules"
        @update:model-value="update($event)"
        :model-value="modelValue"
        transition-duration="100"
        dropdown-icon="fal fa-angle-down"
        style="font-size: 1em"
    >
      <template v-slot:option="{ itemProps, opt, selected, toggleOption }">
        <q-item v-bind="itemProps">
          <q-item-section v-if="selected" avatar>
            <ion-checkbox checked></ion-checkbox>
          </q-item-section>
          <q-item-section v-else avatar>
            <ion-checkbox></ion-checkbox>
          </q-item-section>
          <q-item-section>
              <q-img style="max-height: 120px;max-width: 300px" no-transition
                     placeholder-src="../assets/img/blank.png"
                     no-spinner
                     :ratio="1"
                     fit="contain"
                     class="rounded-borders"
                     :draggable="false" ref="imgRef"
                     :src="'../../img/' + opt.img"
                     alt="No Image"></q-img>
          </q-item-section>
            <!--            <q-item-label>{{ scope.opt.item }}</q-item-label>-->
          <q-item-section>
            <q-item-label class="text-h5">
              {{ opt.item }}
            </q-item-label>
          </q-item-section>
        </q-item>
        <q-separator style="width: 100%"></q-separator>
      </template>
      <template v-slot:selected-item="scope">
        <q-item-section style="color:var(--ion-color-dark)">
          <!--        {{ scope.opt[selectedValue] ? scope.opt[selectedValue] : null }}-->
          {{ scope.opt.item }}
        </q-item-section>
      </template>
      <!--      <template v-slot:option="scope">-->
      <!--        <q-item clickable tag="label" v-ripple>-->
      <!--          <q-item-section avatar style="width: 30%">-->
      <!--              <ion-item>-->
      <!--                <ion-label>-->
      <!--                  Blah-->
      <!--                </ion-label>-->
      <!--              </ion-item>-->
      <!--          </q-item-section>-->
      <!--          <q-item-section>-->
      <!--            &lt;!&ndash;                          <q-item-label>&ndash;&gt;-->
      <!--            &lt;!&ndash;                            <img style="height:120px;width:auto;" :src="'../assets/' + opt.img"/>&ndash;&gt;-->
      <!--            &lt;!&ndash;                          </q-item-label>&ndash;&gt;-->
      <!--            <q-item-label>-->
      <!--              &lt;!&ndash;                <img style="float:left;height:130px;width:auto;" :src="'../assets/' + scope.opt.img"/>&ndash;&gt;-->
      <!--            </q-item-label>-->
      <!--          </q-item-section>-->
      <!--          &lt;!&ndash;                          <q-item-section>&ndash;&gt;-->
      <!--          &lt;!&ndash;                            <q-item-label>{{ scope.opt.item }}</q-item-label>&ndash;&gt;-->
      <!--          &lt;!&ndash;                          </q-item-section>&ndash;&gt;-->
      <!--        </q-item>-->
      <!--      </template>-->
    </q-select>
  </div>
</template>

<script>

import {
  QSelect, QList, QItem, QItemSection, QItemLabel, QSeparator, QRadio, QImg
} from "quasar";
import {IonItem, IonLabel,IonCheckbox} from "@ionic/vue";
import {defineComponent, ref} from "vue";

export default defineComponent({
  name: "FtrSelectDialog",
  components: {
    QSelect, QList, QItem, QItemSection, QItemLabel, QSeparator, IonItem, IonLabel, QRadio, QImg,IonCheckbox
  },
  props: {
    model: ref(),
    label: ref(),
    rules: ref(),
    inputName: ref(),
    options: ref([]),
    initValue: ref(),
    disable: ref(),
  },
  setup(props, context) {
    const inputRef = ref(null);
    const items = ref([]);
    const modelValue = ref();

    function update(event) {
      modelValue.value = event;
      context.emit('update:modelValue', event);
    }
    update(props.initValue);

    // if (props.options) {
    //   temp = JSON.stringify(props.options).replaceAll('"', '');
    //   temp = temp.replace('[', '');
    //   temp = temp.replace(']', '');
    //   temp = temp.replace(' ', '');
    //   items.value = temp.split(',');
    //
    //   console.log(items.value);
    // }
    items.value = props.options;
    return {
      modelValue,
      inputRef,
      update,
      items
    }
  }
})

</script>
<style scoped>
label {
  width: 100%;
  margin-left: 2%;
  margin-right: 2%;
  margin-bottom: 10px;
}

</style>