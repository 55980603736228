import {createApp} from 'vue'
import App from './App.vue'
import router from './router/index.js'

import {
    IonicVue,
} from '@ionic/vue';

import Amplify, {Auth, DataStore, syncExpression} from 'aws-amplify';

import awsmobile from './aws-exports.js';

Amplify.configure(awsmobile);
Amplify.configure({
    Auth: {
        // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
        identityPoolId: 'eu-west-1:6b946439-f55a-4d6d-b69f-4a4e48472e66', // REQUIRED - Amazon Cognito Region
        region: 'eu-west-1', // OPTIONAL - Amazon Cognito Federated Identity Pool Region
        // Required only if it's different from Amazon Cognito Region
        identityPoolRegion: 'eu-west-1', // OPTIONAL - Amazon Cognito User Pool ID
        userPoolId: 'eu-west-1_70bmL721z', // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
        userPoolWebClientId: '3061lm42b3p4ijfkoc4n0lmlra', // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
        mandatorySignIn: false, // OPTIONAL - Configuration for cookie storage
        // Note: if the secure flag is set to true, then the cookie transmission requires a secure protocol
        // OPTIONAL - Hosted UI configuration
        oauth: {}
    },
    Analytics: {
        disabled: true,
    },
});
// You can get the current config object
Auth.configure();

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';

/* Theme variables - copied from existing project */
import './theme/variables.css';

import './theme/quasar.prod.css';

import '@fortawesome/fontawesome-pro/css/fontawesome.min.css';
import '@fortawesome/fontawesome-pro/css/light.min.css';
// import '@fortawesome/fontawesome-pro/css/thin.min.css';
import '@fortawesome/fontawesome-pro/css/solid.min.css';
import '@fortawesome/fontawesome-pro/css/regular.min.css';

import ClosePopup from 'quasar/src/directives/ClosePopup.js';import LocalStorage from 'quasar/src/plugins/LocalStorage.js';import Notify from 'quasar/src/plugins/Notify.js';import Quasar from 'quasar/src/vue-plugin.js';import TouchPan from 'quasar/src/directives/TouchPan.js';
import iconSet from 'quasar/icon-set/fontawesome-v6-pro'
// import quasarUserOptions from './quasar-user-options'
import {defineCustomElements} from '@ionic/pwa-elements/loader';

defineCustomElements(window).then();
import { plugin, defaultConfig } from '@formkit/vue'
import VueVirtualScroller from 'vue-virtual-scroller'
import 'vue-virtual-scroller/dist/vue-virtual-scroller.css'

import './registerServiceWorker';

import {createPinia} from 'pinia'
import i18n from './i18n'
import VueZoomer from 'vue-zoomer'

const app = createApp(App)
    .use(i18n)
    .use(createPinia())
    .use(Quasar, {
        config: {
            brand: {
                primary: '#f39600',
                secondary: '#00518b',
                accent: '#feebd0',
                dark: '#1d1d1d',
                positive: '#21BA45',
                negative: '#C10015',
                info: '#31CCEC',
                warning: '#ff9966'
            },
            notify: { /* look at QuasarConfOptions from the API card */},
        },
        directives: {
            TouchPan,
            ClosePopup
        },
        plugins: {
            Notify,
            LocalStorage,
        },
        framework: {cssAddon: true},
        iconSet: iconSet,
        // Fastclick:Fastclick
    })
    .use(IonicVue)
    .use(router)
    .use(VueZoomer)
    .use(VueVirtualScroller)
    .use(plugin, defaultConfig);

router.isReady().then(() => {
    app.mount('#app');
});