<template>
      <ion-card-subtitle  v-if="title" style="position: relative;width: 100%;margin-bottom: 20px;">
    <ion-label style="color:var(--ion-color-tertiary)">{{ title }}</ion-label>
      </ion-card-subtitle>
  <ion-item v-if="subtitle" mode="ios" style="left:-16px;position: relative;width: 100%;margin-bottom: 20px;">
    <ion-card-title style="width: 100%">
      <ion-label style="font-size:24px;color:var(--ion-color-tertiary)">{{ subtitle }}</ion-label>
    </ion-card-title>
  </ion-item>
</template>

<script>
import {IonItem, IonLabel, IonCardTitle, IonCardSubtitle} from "@ionic/vue";
import {defineComponent, ref} from "vue";

export default defineComponent({
  name: "FtrSubFormTitle",
  components: {
    IonItem, IonLabel, IonCardTitle, IonCardSubtitle
  },
  props: {
    title: ref(),
    tagNumber: ref(),
    subtitle: ref(),
    component: {required: false},
    modelValue: {required: false},
    model: {required: false},
    class: {required: false},
    uuid: {required: false},
  }
})
</script>

<style scoped>

ion-card {
  padding-top: 0;
  padding-bottom: 0;
}
</style>