<script setup>
// This starter template is using Vue 3 <script setup> SFCs
// Check out https://v3.vuejs.org/api/sfc-script-setup.html#sfc-script-setup
import {
	IonBackButton,
	IonButtons,
	IonButton,
	IonCard,
	IonCardContent,
	IonCardTitle,
	IonCol,
	IonContent,
	IonGrid,
	IonHeader,
	IonItem,
	IonLabel,
	IonList,
	IonMenuToggle,
	IonPage,
	IonRow,
	IonSegmentButton,
	IonToolbar,
	onIonViewDidEnter, useIonRouter
} from '@ionic/vue';

import {
  useQuasar,
  QBtn,
  QLayout,
  QPage,
  QForm,
  QRadio,
  QStepper,
  QStep,
  QBanner,
  QSeparator
} from 'quasar'

import {useGlobalStore} from "@/store/global";

import '@fortawesome/fontawesome-pro/css/fontawesome.min.css';
import '@fortawesome/fontawesome-pro/css/light.min.css';
import '@fortawesome/fontawesome-pro/css/thin.min.css';
import '@fortawesome/fontawesome-pro/css/solid.min.css';

import {ref} from "vue";
import {useRoute, useRouter} from "vue-router";

import {
  DataStore,
  SortDirection
} from "aws-amplify";

import {DevFittrLastV1} from "@/models";

const $q = useQuasar();

const selectedFilters = ref();
const selectedBrand = ref('Selecteer een leverancier');
const selectedLasttype = ref('Selecteer een leesttype');
const selectedLasttypeRight = ref('Selecteer een leesttype');
const selectedSize = ref('Selecteer een maat');
const selectedSizeRight = ref('Selecteer een maat');
const onpaar = ref('Onpaar of verder naar overzicht?');
const onpaarRight = ref('Bevestig de selectie om verder te gaan.');
const onpaarElem = ref('next');
const selectedLasttypes = ref();
const selectedLasttypesRight = ref();
const selectedSizes = ref();
const selectedSizesRight = ref();

const category = ref();
const footData = ref();
const router = useRouter();
const route = useRoute();
const ionRouter = useIonRouter();

const lastsLeft = ref();
const lastsRight = ref([]);
const myForm = ref(null);
const submitting = ref(false);
const result = ref();
const brands = ref();

const store = useGlobalStore();

const targetgroup = route.params.category;

const optionsForKidLasts = ref();
const optionsForKidLastsRight = ref();

const step = ref(route?.params?.category?.includes('k') ? 'Lastoption' : 'Leverancier');
const stepRight = ref(route?.params?.category?.includes('k') ? 'LastoptionRight' : 'LeesttypeRight');

let options = [
  {
    label: 'Normaal-Reval',
    value: 'Normaal-Reval'
  },
  {
    label: 'Korset-Stabiel',
    value: 'Korset-Stabiel'
  },
  {
    label: 'Orthese',
    value: 'Orthese'
  },
  {
    label: 'Antivarus',
    value: 'Antivarus'
  }
]

optionsForKidLasts.value = options;
optionsForKidLastsRight.value = options;

const kidsOptionSelectedLeft = ref('Kies eerst hieronder een contrefort/leest uitvoering zodat we een accurater\n' +
    '                                  leest-voorstel kunnen presenteren');
const kidsOptionSelectedRight = ref('Kies eerst hieronder een contrefort/leest uitvoering zodat we een accurater\n' +
    '                                  leest-voorstel kunnen presenteren');

function update(value) {
  console.log(value);
  step.value = 'Leverancier';
  lasttypeOptionsKids.value = value;
}

function updateRight(value) {
  console.log(value);
  stepRight.value = 'LeesttypeRight';
  lasttypeOptionsKidsRight.value = value;
  setLasttypesOnpaar();
}

onIonViewDidEnter(() => {
  console.log("lasttypes Did Enter")
  DataStore.observeQuery(DevFittrLastV1, c =>
      c.targetgroup("contains", route.params.category), {
    sort: s => s.brand(SortDirection.ASCENDING)
  }).subscribe(snapshot => {
    const {items, isSynced} = snapshot;
    result.value = items;
    if (result.value.length > 0) {
      const arr = result.value.map(p => p['brand']);
      const flat = arr.flat(Infinity);
      const s = new Set(flat);
      const unique = [...s];
      brands.value = unique;
      selectedFilters.value = unique;
    }
    console.log(`[Snapshot] item count: ${items.length}, isSynced: ${isSynced}`);
  });
})

async function bestMatch() {


  let incrementing_testvalue_footlength = parseInt(footData.value["footlength_incl_oversize_" + side.value]);
  let decrementing_testvalue_footlength = parseInt(footData.value["footlength_incl_oversize_" + side.value]);
  incrementing_testvalue_footlength = parseInt(footData.value["footlength_incl_oversize_" + side.value]);
  decrementing_testvalue_footlength = parseInt(footData.value["footlength_incl_oversize_" + side.value]);
  let ballsize_incl_insole = parseInt(footData.value["ballsize_incl_insole_" + side.value]);

  let overlimit_footlength = 0;
  overlimit_footlength = parseInt(footData.value["footlength_incl_oversize_" + side.value]) + 3;
  let underlimit_footlength = 0;
  underlimit_footlength = parseInt(footData.value["footlength_incl_oversize_" + side.value]) - 1;

  let overlimit_ballsize = 0;
  overlimit_ballsize = parseInt(footData.value["ballsize_incl_insole_" + side.value]) + 2;
  let underlimit_ballsize = 0;
  underlimit_ballsize = parseInt(footData.value["ballsize_incl_insole_" + side.value]) - 4;

  let incrementing_testvalue_ballsize = parseInt(ballsize_incl_insole);
  let decrementing_testvalue_ballsize = parseInt(ballsize_incl_insole);

  return new Promise(async (resolved) => {

    let all_bestmatches = [];
    let lastgroups = "";
    let iterations = 0;

    async function queryDatabase() {
      let lasts;
      if (route?.params?.category?.includes('k')) {
        let lastoption = 'kids_lastoption_' + side.value;
        // if (side.value === 'right' && footData.value?.kids_lastoption_right !== undefined) {
        //   lastoption = 'kids_lastoption_right';
        // } else {
        //   lastoption = 'kids_lastoption_left';
        // }
        let type_substring_1;
        let type_substring_2;
        if (footData?.value[lastoption].includes('-')) {
          type_substring_1 = footData?.value[lastoption].substr(1, (footData?.value[lastoption].indexOf('-') - 1));
          type_substring_2 = footData?.value[lastoption].substr((footData?.value[lastoption].indexOf('-') + 2), (footData?.value[lastoption].length - (footData.value[lastoption].indexOf('-') + 1)))
          console.log(type_substring_1);
          console.log(type_substring_2);
          let lasts1 = await DataStore.query(DevFittrLastV1, c =>
                  c.targetgroup("eq", route?.params?.category).lasttype("contains", type_substring_1).lastlength("between", [decrementing_testvalue_footlength, incrementing_testvalue_footlength]).ballsize("between", [decrementing_testvalue_ballsize, incrementing_testvalue_ballsize]),
              {sort: s => s.lastgroup(SortDirection.DESCENDING)})
          let lasts2 = await DataStore.query(DevFittrLastV1, c =>
                  c.targetgroup("eq", route?.params?.category).lasttype("contains", type_substring_2).lastlength("between", [decrementing_testvalue_footlength, incrementing_testvalue_footlength]).ballsize("between", [decrementing_testvalue_ballsize, incrementing_testvalue_ballsize]),
              {sort: s => s.lastgroup(SortDirection.DESCENDING)})
          lasts = [...lasts1, ...lasts2];
          if ((type_substring_1.includes('ormaa') || type_substring_1.includes('eval') || type_substring_1.includes('orse') || type_substring_1.includes('tabi')) && selectedBrand.value === 'Nimco') {
            let lasts3 = await DataStore.query(DevFittrLastV1, c =>
                    c.targetgroup("eq", route?.params?.category).lasttype("notContains", type_substring_1).lasttype("notContains", type_substring_2).lastlength("between", [decrementing_testvalue_footlength, incrementing_testvalue_footlength]).ballsize("between", [decrementing_testvalue_ballsize, incrementing_testvalue_ballsize]),
                {sort: s => s.lastgroup(SortDirection.DESCENDING)})
            let lasts4 = await DataStore.query(DevFittrLastV1, c =>
                    c.targetgroup("eq", route?.params?.category).lasttype("notContains", type_substring_1).lasttype("notContains", type_substring_2).lastlength("between", [decrementing_testvalue_footlength, incrementing_testvalue_footlength]).ballsize("between", [decrementing_testvalue_ballsize, incrementing_testvalue_ballsize]),
                {sort: s => s.lastgroup(SortDirection.DESCENDING)})
            lasts = [...lasts3, ...lasts4];
          }
        } else {
          type_substring_1 = footData?.value[lastoption].substr(1, (footData?.value[lastoption].length - 2));
          console.log(type_substring_1);
          let lasts1 = await DataStore.query(DevFittrLastV1, c =>
                  c.targetgroup("eq", route?.params?.category).lasttype("contains", type_substring_1).ballsize("between", [decrementing_testvalue_ballsize, incrementing_testvalue_ballsize]).lastlength("between", [decrementing_testvalue_footlength, incrementing_testvalue_footlength]),
              {sort: s => s.lastgroup(SortDirection.DESCENDING)})
          lasts = [...lasts1];
          if ((type_substring_1.includes('ormaa') || type_substring_1.includes('eval') || type_substring_1.includes('orse') || type_substring_1.includes('tabi')) && selectedBrand.value === 'Nimco') {
            let lasts3 = await DataStore.query(DevFittrLastV1, c =>
                    c.targetgroup("eq", route?.params?.category).lasttype("notContains", type_substring_1).lastlength("between", [decrementing_testvalue_footlength, incrementing_testvalue_footlength]).ballsize("between", [decrementing_testvalue_ballsize, incrementing_testvalue_ballsize]),
                {sort: s => s.lastgroup(SortDirection.DESCENDING)})
            lasts = [...lasts3];
          }
        }
      } else {
        lasts = await DataStore.query(DevFittrLastV1, c =>
                c.targetgroup("eq", route.params.category).ballsize("between", [decrementing_testvalue_ballsize, incrementing_testvalue_ballsize]).lastlength("between", [decrementing_testvalue_footlength, incrementing_testvalue_footlength]),
            {sort: s => s.lastgroup(SortDirection.DESCENDING)})

      }
      for (let i = 0; i < lasts.length; i++) {
        if (!lastgroups.includes(lasts[i].lastgroup)) {
          all_bestmatches.push(lasts[i]);
          lastgroups = lastgroups + lasts[i].lastgroup;
        }
      }

      iterations++;
      if (all_bestmatches.length < 5 && iterations < 25) {
        if (incrementing_testvalue_footlength < overlimit_footlength) {
          incrementing_testvalue_footlength++;
        }
        if (decrementing_testvalue_footlength > underlimit_footlength) {
          decrementing_testvalue_footlength--;
        }
        if (incrementing_testvalue_ballsize < overlimit_ballsize) {
          incrementing_testvalue_ballsize++;
        }
        if (decrementing_testvalue_ballsize > underlimit_ballsize) {
          decrementing_testvalue_ballsize--;
        }
        await queryDatabase();
      } else {
        resolved(all_bestmatches);
      }
    }

    await queryDatabase();
  });
}


const side = ref();

async function verder(L, R) {
  submitting.value = true;
  footData.value = {
		measurements: 'last',
    footlength_left: L.lastlength,
    insole_left: 0,
    insole_right: 0,
    footlength_right: R.lastlength,
    ballsize_left: L.ballsize,
    ballsize_right: R.ballsize,
    footlength_incl_oversize_left: L.lastlength,
    footlength_incl_oversize_right: R.lastlength,
    ballwidth_left: L.ballwidth,
    ballwidth_right: R.ballwidth,
    oversize_left: 0,
    oversize_right: 0,
    lastlength_left: L.lastlength,
    lastlength_right: R.lastlength,
    ballsize_last_left: L.ballsize,
    ballsize_last_right: R.ballsize,
    ballwidth_last_left: L.ballwidth,
    ballwidth_last_right: R.ballwidth,
    ballsize_incl_insole_left: L.ballsize,
    ballsize_incl_insole_right: R.ballsize,
  };
  footData.value.kids_lastoption_left = kidsOptionSelectedLeft.value || undefined;
  footData.value.kids_lastoption_right = kidsOptionSelectedRight.value || undefined;
  footData.value.overlimit_footlength_left = parseInt(L.lastlength) + 3;
  footData.value.underlimit_footlength_left = parseInt(L.lastlength) - 3;
  footData.value.overlimit_footlength_right = parseInt(R.lastlength) + 3;
  footData.value.underlimit_footlength_right = parseInt(R.lastlength) - 3;

  store.lastProposals.footData = footData.value;

  side.value = 'left';
  let initialLastsLeft = await bestMatch();
  console.log(initialLastsLeft);

  const lastgroupsLeft = new Set();
  initialLastsLeft.forEach(last => {
    lastgroupsLeft.add(last.lastgroup);
  });

  side.value = 'right';
  let initialLastsRight = await bestMatch();
  console.log(initialLastsRight)
  lastsRight.value = [];
  initialLastsRight.forEach(last => {
    if (lastgroupsLeft.has(last.lastgroup)) {
      lastsRight.value.push(last);
    }
  });

  const lastgroupsRight = new Set();
  lastsRight.value.forEach(last => {
    lastgroupsRight.add(last.lastgroup);
  });

  lastsLeft.value = [];
  initialLastsLeft.forEach(last => {
    if (lastgroupsRight.has(last.lastgroup)) {
      lastsLeft.value.push(last);
    }
  });
  lastsLeft.value.sort();
  lastsRight.value.sort();
  // for (let i = 0; i < initialLastsLeft.length; i++) {
  //   let last = initialLastsLeft[i];
  //   lastsLeft.value.push(last);
  // }
  console.log(footData.value)
  if (lastsLeft.value.length > 0 && lastsRight.value.length > 0) {

    store.lastProposals = {
      lastsLeft: lastsLeft.value,
      lastsRight: lastsRight.value,
      footData: footData.value,
      category: route.params.category
    };

    await router.push('/osb/alternative-lasts/last-proposals/' + route.params.category);

  } else {
    $q.notify({
      color: 'red-5',
      textColor: 'white',
      icon: 'fas fa-triangle-exclamation',
      message: 'Er zijn geen passende leesten gevonden!'
    })
  }
  submitting.value = false;
}

const lasttypeOptionsKids = ref('Selecteer eerst een leest/contrefort uitvoering');
const lasttypeOptionsKidsRight = ref('Selecteer eerst een leest/contrefort uitvoering');

const lastgroups = ref();
const lasttypes = ref();
const lasttypesOnpaar = ref();
const sizes = ref();
const sizesRight = ref();

async function setLasttypes(brand) {

  if (route?.params?.category?.includes('k')) {
    let lastoption = 'kids_lastoption_left';
    let type_substring_1;
    let type_substring_2;
    if (lasttypeOptionsKids.value.includes('-')) {
      type_substring_1 = lasttypeOptionsKids.value?.substr(1, (lasttypeOptionsKids.value?.indexOf('-') - 1));
      type_substring_2 = lasttypeOptionsKids.value?.substr((lasttypeOptionsKids.value?.indexOf('-') + 2), (lasttypeOptionsKids.value?.length - (lasttypeOptionsKids.value?.indexOf('-') + 1)))
      let lasts1 = await DataStore.query(DevFittrLastV1, c =>
              c.targetgroup("eq", targetgroup).brand("eq", brand).lasttype("contains", type_substring_1),
          {
            sort: s => s.lastgroup(SortDirection.ASCENDING).lasttype(SortDirection.ASCENDING)
          });
      let lasts2 = await DataStore.query(DevFittrLastV1, c =>
              c.targetgroup("eq", targetgroup).brand("eq", brand).lasttype("contains", type_substring_2),
          {
            sort: s => s.lastgroup(SortDirection.ASCENDING).lasttype(SortDirection.ASCENDING)
          });
      lastgroups.value = [...lasts1, ...lasts2];
      if (lastgroups.value.length === 0 && brand === 'Nimco') {
        let lasts3 = await DataStore.query(DevFittrLastV1, c =>
                c.targetgroup("eq", targetgroup).brand("eq", brand).lasttype("notContains", 'ntivaru').lasttype("notContains", 'rthes'),
            {
              sort: s => s.lastgroup(SortDirection.ASCENDING).lasttype(SortDirection.ASCENDING)
            });
        let lasts4 = await DataStore.query(DevFittrLastV1, c =>
                c.targetgroup("eq", targetgroup).brand("eq", brand).lasttype("notContains", 'rthes').lasttype("notContains", 'ntivaru'),
            {
              sort: s => s.lastgroup(SortDirection.ASCENDING).lasttype(SortDirection.ASCENDING)
            });
        lastgroups.value = [...lasts3, ...lasts4];
      }
    } else {
      type_substring_1 = lasttypeOptionsKids.value?.substr(1, (lasttypeOptionsKids.value?.length - 2));
      let lasts1 = await DataStore.query(DevFittrLastV1, c =>
              c.targetgroup("eq", targetgroup).brand("eq", brand).lasttype("contains", type_substring_1),
          {
            sort: s => s.lastgroup(SortDirection.ASCENDING).lasttype(SortDirection.ASCENDING)
          });
      lastgroups.value = [...lasts1];
    }
  } else {
    lastgroups.value = await DataStore.query(DevFittrLastV1, c =>
            c.targetgroup("eq", targetgroup).brand("eq", brand),
        {
          sort: s => s.lastgroup(SortDirection.ASCENDING).lasttype(SortDirection.ASCENDING)
        });
  }

  lasttypes.value = [
    ...new Map(lastgroups.value.map((item) => [item["lasttype"], item])).values(),
  ];

  setTimeout(() => {
    step.value = 'Leesttype';
    selectedBrand.value = selectedFilters.value;
  }, 250);
}

async function setLasttypesOnpaar() {
  setTimeout(async () => {
    const lasts = await DataStore.query(DevFittrLastV1, c =>
            c.targetgroup("eq", targetgroup).lasttype("eq", selectedLasttype.value),
        {
          sort: s => s.lastgroup(SortDirection.ASCENDING).lasttype(SortDirection.ASCENDING)
        });
    console.log(kidsOptionSelectedRight.value)
    if (route?.params?.category?.includes('k')) {
      let type_substring_1;
      let type_substring_2;

      if (kidsOptionSelectedRight.value.includes('-')) {
        type_substring_1 = kidsOptionSelectedRight.value?.substr(1, (kidsOptionSelectedRight.value?.indexOf('-') - 1));
        type_substring_2 = kidsOptionSelectedRight.value?.substr((kidsOptionSelectedRight.value?.indexOf('-') + 2), (kidsOptionSelectedRight.value?.length - (kidsOptionSelectedRight.value?.indexOf('-') + 1)))

        const lastgroup = lasts[0].lastgroup;
        const brand = lasts[0].brand;

        const lasts1 = await DataStore.query(DevFittrLastV1, c =>
                c.targetgroup("eq", targetgroup).lastgroup("eq", lastgroup).lasttype("contains", type_substring_1),
            {
              sort: s => s.lastgroup(SortDirection.ASCENDING).lasttype(SortDirection.ASCENDING)
            });
        const lasts2 = await DataStore.query(DevFittrLastV1, c =>
                c.targetgroup("eq", targetgroup).lastgroup("eq", lastgroup).lasttype("contains", type_substring_2),
            {
              sort: s => s.lastgroup(SortDirection.ASCENDING).lasttype(SortDirection.ASCENDING)
            });

        let lastsByLastgroup = [...lasts1, ...lasts2];

        if ((type_substring_1.includes('ormaa') || type_substring_1.includes('eval') || type_substring_1.includes('orse') || type_substring_1.includes('tabi')) && brand === 'Nimco') {
          const lasts3 = await DataStore.query(DevFittrLastV1, c =>
                  c.targetgroup("eq", targetgroup).lastgroup("eq", lastgroup).lasttype("notContains", 'ntivaru').lasttype("notContains", 'rthes'),
              {
                sort: s => s.lastgroup(SortDirection.ASCENDING).lasttype(SortDirection.ASCENDING)
              });
          const lasts4 = await DataStore.query(DevFittrLastV1, c =>
                  c.targetgroup("eq", targetgroup).lastgroup("eq", lastgroup).lasttype("notContains", 'ntivaru').lasttype("notContains", 'rthes'),
              {
                sort: s => s.lastgroup(SortDirection.ASCENDING).lasttype(SortDirection.ASCENDING)
              });
          lastsByLastgroup = [...lasts3, ...lasts4];
        }

        const lasttypes = new Set();

        for (let i = 0; i < lastsByLastgroup.length; i++) {
          const last = lastsByLastgroup[i];
          lasttypes.add(last.lasttype);
        }
        lasttypesOnpaar.value = [...lasttypes];
      } else {
        type_substring_1 = kidsOptionSelectedRight.value?.substr(1, (kidsOptionSelectedRight.value?.length - 2));
        const lastgroup = lasts[0].lastgroup;
        const lasts1 = await DataStore.query(DevFittrLastV1, c =>
                c.targetgroup("eq", targetgroup).lastgroup("eq", lastgroup).lasttype("contains", type_substring_1),
            {
              sort: s => s.lastgroup(SortDirection.ASCENDING).lasttype(SortDirection.ASCENDING)
            });
        const lastsByLastgroup = [...lasts1];
        const lasttypes = new Set();

        for (let i = 0; i < lastsByLastgroup.length; i++) {
          const last = lastsByLastgroup[i];
          lasttypes.add(last.lasttype);
        }
        lasttypesOnpaar.value = [...lasttypes];
      }
    } else {
      const lastgroup = lasts[0].lastgroup;

      const lastsByLastgroup = await DataStore.query(DevFittrLastV1, c =>
              c.targetgroup("eq", targetgroup).lastgroup("eq", lastgroup),
          {
            sort: s => s.lastgroup(SortDirection.ASCENDING).lasttype(SortDirection.ASCENDING)
          });

      const lasttypes = new Set();

      for (let i = 0; i < lastsByLastgroup.length; i++) {
        const last = lastsByLastgroup[i];
        lasttypes.add(last.lasttype);
      }
      lasttypesOnpaar.value = [...lasttypes];
      route?.params?.category?.includes('k') ? stepRight.value = 'LastoptionRight' : stepRight.value = 'LeesttypeRight';
    }
  }, 250);
}

async function setSizes(lasttype) {
  const lastsByTargetGroupSizes = await DataStore.query(DevFittrLastV1, c =>
      c.targetgroup("eq", targetgroup).lasttype("eq", lasttype.lasttype), {
    sort: s => s['shoesize'](SortDirection.ASCENDING)
  });
  sizes.value = [
    ...new Map(lastsByTargetGroupSizes.map((item) => [item["shoesize"], item])).values(),
  ];
  sizes.value.sort(function (a, b) {
    return a.shoesize - b.shoesize;
  });
  setTimeout(() => {
    step.value = 'Maat';
    selectedLasttype.value = selectedLasttypes.value
  }, 250);
}

async function setSizesRight(lasttype) {
  console.log(lasttype)
  selectedLasttypeRight.value = lasttype
  const lastsByTargetGroupSizes = await DataStore.query(DevFittrLastV1, c =>
      c.targetgroup("eq", targetgroup).lasttype("eq", lasttype), {
    sort: s => s['shoesize'](SortDirection.ASCENDING)
  });
  sizesRight.value = [
    ...new Map(lastsByTargetGroupSizes.map((item) => [item["shoesize"], item])).values(),
  ];
  sizesRight.value.sort(function (a, b) {
    return a.shoesize - b.shoesize;
  });
  setTimeout(() => {
    stepRight.value = 'MaatRight';
    selectedLasttypeRight.value = selectedLasttypesRight.value
  }, 250);
}

const lastL = ref();
const lastR = ref();

async function setLastByLasttypeLength(action) {
  if (action === 'next') {
    if (route?.params?.category?.includes('k')) {
      kidsOptionSelectedRight.value = kidsOptionSelectedLeft.value;
    }
    lastL.value = await DataStore.query(DevFittrLastV1, c =>
        c.shoesize("eq", selectedSize.value).lasttype("eq", selectedLasttype.value).brand("eq", selectedBrand.value));
    lastR.value = lastL.value;
    await verder(lastL.value[0], lastR.value[0]);
  } else if (action === 'nextOnpaar') {
    lastL.value = await DataStore.query(DevFittrLastV1, c =>
        c.shoesize("eq", selectedSize.value).lasttype("eq", selectedLasttype.value).brand("eq", selectedBrand.value));

    lastR.value = await DataStore.query(DevFittrLastV1, c =>
        c.shoesize("eq", selectedSizeRight.value).lasttype("eq", selectedLasttypeRight.value).brand("eq", selectedBrand.value));
    await verder(lastL.value[0], lastR.value[0]);
  } else if (action === 'onpaar') {
    onpaarElem.value = 'onpaar';
    if (route?.params?.category?.includes('k')) {
      console.log(kidsOptionSelectedRight.value);
    } else {
      await setLasttypesOnpaar();
    }
    step.value = 'done';
  }
  // step.value = 'done';
}

async function setOnpaar() {
  setTimeout(() => {
    step.value = 'Onpaar';
    selectedSize.value = selectedSizes.value
  }, 250);
}

function capitalize(value) {
  if (!value) return ''
  value = value.toString()
  return value.charAt(0).toUpperCase() + value.slice(1)
}

function validateForm(evt) {
  myForm.value.validate().then(success => {
    if (success) {
      submitting.value = true
      // yay, models are correct
      onSubmit(evt)
    } else {
      // oh no, user has filled in
      // at least one invalid value
      $q.notify({
        color: 'red-5',
        textColor: 'white',
        icon: 'fas fa-triangle-exclamation',
        message: 'Er zijn nog velden met geen of foutieve waarden!'
      })
    }
  }, error => {
    console.log(error)
  });
}

async function compareLasts() {

}
</script>

<template>

  <IonPage>

	  <ion-header :translucent="true">
		  <ion-toolbar color="primary">
			  <ion-buttons slot="start">
				  <ion-back-button color="tertiary" default-href="/home">
				  </ion-back-button>
				  <img @click="() => ionRouter.navigate('/home', 'back', 'push')" class="header-logo" alt="no-image" src="../assets/fittr_logo.png">
			  </ion-buttons>

        <ion-buttons slot="end">
          <ion-button color="tertiary">
            <i style="font-size: 24px;" class="fa-regular fa-bars"></i>
            <ion-menu-toggle style="position:absolute;width:100%;height: 100%">
            </ion-menu-toggle>
          </ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>

    <IonContent :fullscreen="true" style="position: absolute;width: 100vw;height: 100vh;" class="hide-scrollbar">
      <QLayout style="width: 100vw;height: 100vh">
        <QPage style="width: 100vw;height: 100vh">
          <q-form greedy ref="myForm" @submit="validateForm($event)">
            <ion-grid fixed
                      style="height:100%;padding-left: 0;padding-right: 0;margin-left: 0;margin-right: 0; width: 100%">
              <ion-row style="padding-top: calc(66px + var(--ion-safe-area-top, 0));">
                <ion-col size-xs="12" :size-sm="onpaarElem.toString() === 'onpaar' ? 6 : 8">
                  <ion-card class="custom-card" style="padding-left: 10px;padding-bottom: 20px">
                    <ion-card-content>
                      <ion-item style="position: relative;left: -16px">
                        <ion-card-title>
                          <ion-label style="font-size: 24px">Links</ion-label>
                        </ion-card-title>
                      </ion-item>
                      <q-stepper
                          ref="stepper"
                          v-model="step"
                          vertical
                          flat
                          animated
                      >
                        <template v-if="route?.params?.category?.includes('k')">
                          <q-step
                              :prefix="0"
                              icon="fal fa-list-tree"
                              :name="'Lastoption'"
                              :title="lasttypeOptionsKids.toString()"
                              :done="step === 'Leverancier'"
                          >
                            <q-separator style="width: 100%;margin-bottom:0"></q-separator>
                            <q-field ref="inputRef" style="padding:5px;width:96%;margin-left:4%"
                                     outlined
                                     v-model="kidsOptionSelectedLeft"
                                     name="kids_lastoption_left"
                                     :rules="[val => !!val || '* Dit veld mag niet leeg blijven']"
                                     :model-value="kidsOptionSelectedLeft"
                                     @update:model-value="update($event)"
                            >
                              <template v-slot:control>
                                <q-toolbar>Kies eerst hieronder een contrefort/leest uitvoering zodat we een accurater
                                  leest-voorstel kunnen presenteren
                                </q-toolbar>
                                <q-separator style="width:98%;margin:1%"/>
                                <q-option-group name="kids_lastoption_left"
                                                v-model="kidsOptionSelectedLeft"
                                                clearable
                                                :model-value="kidsOptionSelectedLeft"
                                                :options="optionsForKidLasts"
                                                type="radio"
                                                @update:model-value="update($event)"
                                                style="display:flex;flex-direction: row;justify-content:flex-start;flex-wrap:wrap;flex-grow:1"
                                                inline
                                                color="primary"
                                />
                              </template>
                            </q-field>
                          </q-step>
                        </template>

                        <q-step
                            :prefix="1"
                            icon="fal fa-list-tree"
                            :name="'Leverancier'"
                            :title="selectedBrand.toString()"
                            :done="step === 'Leverancier'"
                        >
                          <q-banner v-if="step === 'Leverancier'"
                                    inline-actions
                                    style="background:white;margin:0;min-height: 0;line-height: unset;padding: 0 0 0 0"
                                    rounded>
                            <template v-slot:action>
                              <q-btn v-if="step === 'Leverancier'" flat
                                     @click="$refs.stepper.previous(); kidsOptionSelectedLeft = 'Kies eerst hieronder een contrefort/leest uitvoering'; lasttypeOptionsKids = 'Selecteer eerst een leest/contrefort uitvoering'">
                                <!--                                  <i class="fas fa-diagram-previous" style="color:var(&#45;&#45;ion-color-danger);margin-right: 5px"></i>-->
                                <ion-label>Terug</ion-label>
                              </q-btn>
                            </template>
                          </q-banner>
                          <q-separator style="width: 100%;margin-bottom:0"></q-separator>
                          <IonList style="display: flex;flex-wrap: wrap">
                            <template v-for="brand in brands" :key="brand.id">
                              <IonItem style="width: 50%;padding:0">
                                <QRadio toggle-order="ft" style="width: 100%" v-model="selectedFilters"
                                        :val="brand"
                                        @click="setLasttypes(brand)"
                                        :label="capitalize(brand)"
                                        model-value="selectedFilters"/>
                                <!--                              </QItem>-->
                              </IonItem>
                            </template>
                          </IonList>
                        </q-step>

                        <q-step
                            :prefix="2"
                            icon="fal fa-list-tree"
                            :name="'Leesttype'"
                            :title="selectedLasttype.toString()"
                            :done="step === 'Leesttype'"
                        >
                          <template v-slot:default>
                            <q-banner v-if="step === 'Leesttype'"
                                      inline-actions
                                      style="background:white;margin:0;min-height: 0;line-height: unset;padding: 0 0 0 0"
                                      rounded>
                              <template v-slot:action>
                                <q-btn v-if="step === 'Leesttype'" flat
                                       @click="$refs.stepper.previous(); selectedBrand = 'Selecteer een leverancier'; selectedFilters = undefined">
                                  <!--                                  <i class="fas fa-diagram-previous" style="color:var(&#45;&#45;ion-color-danger);margin-right: 5px"></i>-->
                                  <ion-label>Terug</ion-label>
                                </q-btn>
                              </template>
                            </q-banner>
                            <q-separator style="width: 100%;margin-bottom:0"></q-separator>
                            <IonList style="display: flex;flex-wrap: wrap">
                              <template v-for="lasttype in lasttypes" :key="lasttype.id">
                                <IonItem style="width: 50%;padding:0">
                                  <QRadio toggle-order="ft" style="width: 100%" v-model="selectedLasttypes"
                                          :val="lasttype.lasttype"
                                          :label="lasttype.lasttype"
                                          @click="setSizes(lasttype)"
                                          model-value="selectedLasttypes"/>
                                  <!--                              </QItem>-->
                                </IonItem>
                              </template>
                            </IonList>
                          </template>
                        </q-step>

                        <q-step
                            prefix="3"
                            icon="fal fa-list-tree"
                            :name="'Maat'"
                            :done="step === 'Maat'"
                            :title="selectedSize.toString()"
                        >
                          <template v-slot:default>
                            <q-banner v-if="step === 'Maat'"
                                      inline-actions
                                      style="background:white;margin-top:0;min-height: 0;line-height: unset;padding: 0 0 0 0"
                                      rounded>
                              <template v-slot:action>
                                <q-btn v-if="step === 'Maat'" flat
                                       @click="$refs.stepper.previous(); selectedLasttype = 'Selecteer een leesttype'; selectedLasttypes = undefined">
                                  <!--                                  <i class="fas fa-diagram-previous" style="color:var(&#45;&#45;ion-color-danger);margin-right: 5px"></i>-->
                                  <ion-label>Terug</ion-label>
                                </q-btn>
                              </template>
                            </q-banner>
                            <q-separator style="width: 100%;"></q-separator>
                            <IonList style="display: flex;flex-wrap: wrap">
                              <template v-for="size in sizes" :key="size.id">
                                <IonItem style="width: 50%;padding:0">
                                  <QRadio toggle-order="ft" style="width: 100%;margin:0" v-model="selectedSizes"
                                          :model-value="selectedSizes"
                                          :val="size.shoesize"
                                          :label="size.shoesize"
                                          @click="setOnpaar(size)"/>
                                  <!--                              </QItem>-->
                                </IonItem>
                              </template>
                            </IonList>
                          </template>
                        </q-step>


                        <q-step
                            prefix="4"
                            :name="'Onpaar'"
                            icon="fal fa-list-tree"
                            :done="step === 'Onpaar'"
                            :title="onpaar">

                          <template v-slot:default>
                            <q-separator style="width: 100%;margin-bottom:0"></q-separator>
                            <q-banner v-if="step === 'Onpaar'"
                                      inline-actions
                                      style="background:white;margin-top:0;min-height: 0;line-height: unset;padding: 20px 0 0 0"
                                      rounded>
                              <ion-button v-if="step === 'Onpaar'" color="light"
                                          @click="setLastByLasttypeLength('onpaar')"><i
                                  class="fas fa-code-compare" style="margin-right: 5px"></i>
                                <ion-label>Onpaar</ion-label>
                              </ion-button>
                              <ion-button v-if="step === 'Onpaar'" color="tertiary" style="margin-left:15px;"
                                          @click="setLastByLasttypeLength('next')">
                                <ion-label>VERDER</ion-label>
                                <i
                                    class="fal fa-step-forward" style="margin-left: 5px"></i>
                              </ion-button>
                              <template v-slot:action>
                                <q-btn v-if="step === 'Onpaar'" flat style="color:var(--q-negative)"
                                       @click="$refs.stepper.previous(); selectedSize = 'Selecteer een maat'; selectedSizes = undefined">
                                  <!--                                  <i class="fas fa-diagram-previous" style="margin-right: 5px"></i>-->
                                  <ion-label>Terug</ion-label>
                                </q-btn>
                              </template>
                            </q-banner>
                          </template>
                        </q-step>
                      </q-stepper>
                    </ion-card-content>
                  </ion-card>
                </ion-col>

                <ion-col v-if="onpaarElem.toString() === 'onpaar'" size-xs="12"
                         :size-sm="onpaarElem.toString() === 'onpaar' ? 6 : 8">
                  <ion-card class="custom-card" style="padding-left: 10px;padding-bottom: 20px">
                    <ion-card-content>
                      <ion-item style="position: relative;left: -16px">
                        <ion-card-title>
                          <ion-label style="font-size: 24px">Rechts</ion-label>
                        </ion-card-title>
                      </ion-item>
                      <q-stepper
                          ref="stepperRight"
                          v-model="stepRight"
                          vertical
                          flat
                          animated
                      >

                        <template v-if="route?.params?.category?.includes('k')">
                          <q-step
                              :prefix="0"
                              icon="fal fa-list-tree"
                              :name="'LastoptionRight'"
                              :title="lasttypeOptionsKidsRight"
                              :done="step === 'LeesttypeRight'"
                          >
                            <template v-slot:default>

                              <q-separator style="width: 100%;margin-bottom:0"></q-separator>
                              <q-banner inline-actions
                                        style="background:white;margin:0;min-height: 0;line-height: unset;padding: 0 0 0 0"
                                        rounded>
                                <template v-slot:action>
                                  <q-btn flat @click="step = 'Onpaar'; stepRight = 'Kies eerst hieronder een contrefort/leest uitvoering'; lasttypeOptionsKidsRight = 'Kies eerst hieronder een contrefort/leest uitvoering zodat we een accurater\n' +
    '                                  leest-voorstel kunnen presenteren'">
                                    <ion-label>Terug</ion-label>
                                  </q-btn>
                                </template>
                              </q-banner>

                              <q-field ref="inputRef" style="padding:5px;width:96%;margin-left:4%"
                                       outlined
                                       v-model="kidsOptionSelectedRight"
                                       name="kids_lastoption_right"
                                       :rules="[val => !!val || '* Dit veld mag niet leeg blijven']"
                                       :model-value="kidsOptionSelectedRight"
                                       @update:model-value="updateRight($event)"
                              >
                                <template v-slot:control>
                                  <q-toolbar>Kies eerst hieronder een contrefort/leest uitvoering zodat we een accurater
                                    leest-voorstel kunnen presenteren
                                  </q-toolbar>
                                  <q-separator style="width:98%;margin:1%"/>
                                  <q-option-group name="kids_lastoption_right"
                                                  v-model="kidsOptionSelectedRight"
                                                  clearable
                                                  :model-value="kidsOptionSelectedRight"
                                                  :options="optionsForKidLastsRight"
                                                  type="radio"
                                                  @update:model-value="updateRight($event)"
                                                  style="display:flex;flex-direction: row;justify-content:flex-start;flex-wrap:wrap;flex-grow:1"
                                                  inline
                                                  color="primary"
                                  />
                                </template>
                              </q-field>
                            </template>
                          </q-step>
                        </template>

                        <q-step
                            :prefix="1"
                            icon="fal fa-list-tree"
                            :name="'LeverancierRight'"
                            :title="selectedBrand"
                            :done="stepRight === 'LeverancierRight'"
                        >
                          <IonList>
                            <template v-for="brand in brands" :key="brand.id">
                              <IonItem style="width: 50%;padding:0">
                                <QRadio toggle-order="ft" style="width: 100%" v-model="selectedFilters"
                                        :val="brand"
                                        :label="capitalize(brand)"
                                        model-value="selectedFilters"/>
                                <!--                              </QItem>-->
                              </IonItem>
                            </template>
                          </IonList>
                        </q-step>


                        <q-step
                            prefix="2"
                            icon="fal fa-list-tree"
                            :name="'LeesttypeRight'"
                            :title="selectedLasttypeRight"
                            :done="selectedLasttypesRight === 'SizeRight'"
                        >
                          <template v-slot:default>
                            <q-banner v-if="stepRight === 'LeesttypeRight'"
                                      inline-actions
                                      style="background:white;margin:0;min-height: 0;line-height: unset;padding: 0 0 0 0"
                                      rounded>
                              <template v-slot:action>
                                <q-btn v-if="route?.params?.category?.includes('k')" flat
                                       @click="stepRight = 'LastoptionRight'; kidsOptionSelectedRight = 'Selecteer eerst een contrefort/leest uitvoering'; lasttypeOptionsKidsRight = 'Selecteer eerst een contrefort/leest uitvoering'">
                                  <ion-label>Terug</ion-label>
                                </q-btn>
                                <q-btn v-else flat @click="step = 'Onpaar'">
                                  <ion-label>Terug</ion-label>
                                </q-btn>
                              </template>
                            </q-banner>
                            <q-separator style="width: 100%;margin-bottom:0"></q-separator>
                            <IonList style="display: flex;flex-wrap: wrap">
                              <template v-for="lasttype in lasttypesOnpaar" :key="lasttype">
                                <IonItem style="width: 50%;padding:0">
                                  <QRadio toggle-order="ft" style="width: 100%" v-model="selectedLasttypesRight"
                                          :val="lasttype"
                                          :label="lasttype"
                                          @click="setSizesRight(lasttype)"
                                          :model-value="selectedLasttypesRight"/>
                                </IonItem>
                              </template>
                            </IonList>
                          </template>
                        </q-step>

                        <q-step
                            prefix="3"
                            icon="fal fa-list-tree"
                            :name="'MaatRight'"
                            :done="stepRight === 'MaatRight'"
                            :title="selectedSizeRight"
                        >
                          <template v-slot:default>
                            <q-banner v-if="stepRight === 'MaatRight'"
                                      inline-actions
                                      style="background:white;margin-top:0;min-height: 0;line-height: unset;padding: 0 0 0 0"
                                      rounded>
                              <template v-slot:action>
                                <q-btn v-if="stepRight === 'MaatRight'" flat @click="$refs.stepperRight.previous()">
                                  <!--                                  <i class="fas fa-diagram-previous" style="color:var(&#45;&#45;ion-color-danger);margin-right: 5px"></i>-->
                                  <ion-label>Terug</ion-label>
                                </q-btn>
                              </template>
                            </q-banner>
                            <q-separator style="width: 100%;"></q-separator>
                            <IonList style="display: flex;flex-wrap: wrap">
                              <template v-for="size in sizesRight" :key="size.id">
                                <IonItem style="width: 50%;padding:0">
                                  <QRadio toggle-order="ft" style="width: 100%;margin:0" v-model="selectedSizesRight"
                                          :model-value="selectedSizesRight"
                                          :val="size.shoesize"
                                          :label="size.shoesize"
                                          @click="selectedSizeRight = size.shoesize; stepRight = 'OnpaarRight'"/>
                                  <!--                              </QItem>-->
                                </IonItem>
                              </template>
                            </IonList>
                          </template>
                        </q-step>


                        <q-step
                            prefix="4"
                            :name="'OnpaarRight'"
                            icon="fal fa-list-tree"
                            :done="stepRight === 'OnpaarRight'"
                            :title="onpaarRight">

                          <template v-slot:default>
                            <q-separator style="width: 100%;margin-bottom:0"></q-separator>
                            <q-banner inline-actions
                                      style="background:white;margin-top:0;min-height: 0;line-height: unset;padding: 20px 0 0 0"
                                      rounded>
                              <template v-slot:action>
                                <q-btn v-if="stepRight === 'OnpaarRight'" flat @click="$refs.stepperRight.previous()">
                                  <!--                                  <i class="fas fa-diagram-previous" style="color:var(&#45;&#45;ion-color-danger);margin-right: 5px"></i>-->
                                  <ion-label>Terug</ion-label>
                                </q-btn>
                                <ion-button v-if="stepRight === 'OnpaarRight'" color="tertiary"
                                            @click="setLastByLasttypeLength('nextOnpaar')">
	                                <i
                                    class="fas fa-chevron-right" style="margin-right: 5px"></i>
                                  <ion-label>Verder</ion-label>
                                </ion-button>
                              </template>
                            </q-banner>
                          </template>
                        </q-step>
                      </q-stepper>
                    </ion-card-content>
                  </ion-card>
                </ion-col>
              </ion-row>
            </ion-grid>
          </q-form>
        </QPage>
      </QLayout>
    </IonContent>
  </IonPage>
</template>

<style scoped>
.q-stepper__step-inner {
  padding: 10px;
}

.q-slider .q-slider__track-container--h {
  opacity: 0;
}

.input-icon {
  height: 70px;
  padding: 2px;
  margin-left: 3px;
  margin-bottom: 15px;
}

IonPage {
  height: 100vh;
  /*overflow: hidden;*/
}

IonContent {
  /*--background: rgba(75, 75, 93, 0.7);*/
}

::-webkit-scrollbar {
  display: none;
}


@media (min-width: 350px) {

}

/*###############################################################*/
@media (min-width: 576px) {

}

/*###############################################################*/
@media (min-width: 768px) {
  ion-segment-button {
    width: 100px;
  }
}

/*###############################################################*/
@media (min-width: 992px) {
  ion-segment-button {
    width: 125px;
  }
}

/*###############################################################*/
@media (min-width: 1024px) {

}

/*###############################################################*/
@media (min-width: 1150px) {

}
</style>
