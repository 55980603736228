<template>
  <ion-card>
    <!--    <ion-header v-if="title" key="title">-->
    <!--      <ion-toolbar>-->
    <!--        {{ t(title) }}-->
    <!--      </ion-toolbar>-->
    <!--    </ion-header>-->
    <ion-card-content style="width:100%;">
      <component
          :is="behaveLikeParentSchema ? 'form' : 'div'"
          v-bind="formBinds"
      >
        <slot
            v-if="behaveLikeParentSchema"
            name="beforeForm"
            v-bind="slotBinds"
        />

        <SchemaRow
            v-for="(row, index) in parsedSchema"
            :key="index"
            :preventModelCleanupOnSchemaChange="preventModelCleanupOnSchemaChange"
            :row="row"
            :schemaRowClasses="schemaRowClasses"
            :sharedConfig="sharedConfig"
        />

        <slot
            v-if="behaveLikeParentSchema"
            name="afterForm"
            v-bind="slotBinds"
        />
      </component>
    </ion-card-content>
  </ion-card>
</template>

<script>
import useParsedSchema from '../../node_modules/formvuelate/src/features/ParsedSchema'
import SchemaRow from '../../node_modules/formvuelate/src/SchemaRow'

import {computed} from 'vue'

import useParentSchema from '../../node_modules/formvuelate/src/features/ParentSchema'
import useInjectedSchema from '../../node_modules/formvuelate/src/features/InjectedSchema'
import useFormModel from '../../node_modules/formvuelate/src/features/FormModel'

import {
  IonCard,
  IonCardContent,
} from "@ionic/vue";

export default {
  name: "FtrFormCardWrappedSchemaForm",
  components: {
    IonCard,
    IonCardContent,
    SchemaRow
  },
  props: {
    schema: {
      type: [Object, Array],
      required: true,
      validator(schema) {
        if (!Array.isArray(schema)) return true

        return schema.filter(field => !Array.isArray(field) && (!field.model && !field.schema)).length === 0
      }
    },
    sharedConfig: {
      type: Object,
      default: () => ({})
    },
    preventModelCleanupOnSchemaChange: {
      type: Boolean,
      default: false
    },
    nestedSchemaModel: {
      type: String,
      default: ''
    },
    schemaRowClasses: {
      type: [String, Object, Array],
      default: null
    }
  },
  emits: ['submit', 'update:modelValue'],
  setup(props, {emit, attrs}) {
    const {behaveLikeParentSchema, hasParentSchema} = useParentSchema()
    const {schema} = useInjectedSchema(props, behaveLikeParentSchema)
    const {parsedSchema} = useParsedSchema(schema, attrs.model)

    useFormModel(props, parsedSchema)

    const formBinds = computed(() => {
      if (hasParentSchema) return {}

      return {
        onSubmit: event => {
          event.preventDefault()
          emit('submit', event)
        }
      }
    })

    const slotBinds = computed(() => {
      return {}
    })

    return {
      behaveLikeParentSchema,
      parsedSchema,
      hasParentSchema,
      formBinds,
      slotBinds
    }
  }
}
</script>
