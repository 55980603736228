<template>
  <div>
    <q-form :formData="formData" ref="myForm" greedy>
      <SchemaForm :preventModelCleanupOnSchemaChange="true" :schema="schema"/>
    </q-form>

    <ion-card ref="orderCheck" id="orderCheck">
      <ion-header>
        <ion-toolbar>
          <ion-title slot="start">
            <ion-text>
              <ion-label style="font-size:1.3rem; font-weight: 400;color:var(--ion-color-primary-shade)">
                {{ submitTitle }}
              </ion-label>
            </ion-text>
          </ion-title>
        </ion-toolbar>
      </ion-header>
      <ion-card-content class="ion-padding" style="width:100%;color:var(--ion-color-primary)">
        <div style="width: 100%;display: flex; justify-content: flex-end">
          <ion-button v-if="route?.params?.category?.includes('edit')" id="archived" color="danger" fill="solid"
                      style="--color:white;position: relative;--padding-start:15px;--padding-end:15px"
                      @click.prevent.stop="formSubmit($event, 'archived')">
            <ion-label style="color:white"><i class="fal fa-archive" style="margin-right: 7px"></i>
              Verstuur
            </ion-label>
          </ion-button>
          <ion-button v-else id="archived" color="danger" fill="solid"
                      style="--color:white;position: relative;--padding-start:15px;--padding-end:15px"
                      @click.prevent.stop="formSubmit($event, 'archived')">
            <ion-label style="color:white"><i class="fal fa-archive" style="margin-right: 7px"></i>
              Archiveer
            </ion-label>
          </ion-button>

          <ion-button v-if="route?.params?.category?.includes('edit')" id="submit" color="tertiary"
                      fill="solid"
                      style="--color:white;position: relative;margin-left:30px;--padding-start:15px;--padding-end:15px;--color:white"
                      @click.prevent.stop="formSubmit($event, 'submit')">
            <ion-label style="color:white">
              <i class="fal fa-clipboard-list-check"
                 style="margin-right: 7px"></i>
              Opslaan
            </ion-label>
          </ion-button>
          <ion-button v-else id="submit" color="tertiary"
                      fill="solid"
                      style="--color:white;position: relative;margin-left:30px;--padding-start:15px;--padding-end:15px;--color:white"
                      @click.prevent.stop="formSubmit($event, 'submit')">
            <ion-label style="color:white">
              <i class="fal fa-clipboard-list-check"
                 style="margin-right: 7px"></i>
              Verzenden
            </ion-label>
          </ion-button>
        </div>
      </ion-card-content>
      <ion-footer class="ion-padding">
        <ion-label>{{ submitText }}</ion-label>
        <ul v-if="errors.length > 0">
          <li style="color:black" v-for="error in errors" :key="error.name">
            {{ error }}
          </li>
        </ul>
<!--        				                <pre>{{ formData }}</pre>-->
      </ion-footer>
    </ion-card>
  </div>
</template>
<script>

import {QForm, useQuasar} from "quasar"
import {defineComponent, markRaw, ref} from "vue"
import {
  IonButton,
  IonCard,
  IonText,
  IonCardContent,
  IonCardSubtitle,
  IonHeader,
  IonTitle,
  loadingController,
  IonLabel,
  IonToolbar,
  IonFooter, IonAlert, alertController
} from '@ionic/vue';

import FtrFormCardWrappedSchemaForm from "@/components/FtrFormCardWrappedSchemaForm";
import FtrSubFormTitle from "@/components/FtrSubFormTitle";
import FtrTextInput from "@/components/FtrTextInput";
import FtrTextFieldOrder from "@/components/FtrTextFieldOrder";
import FtrRadio from "@/components/FtrRadio";

import {API, Auth, DataStore} from "aws-amplify";
import {SchemaForm, useSchemaForm} from "formvuelate";

import {
  DevFittrModel,
  DevFittrOrder
} from "@/models";

import {useRoute} from "vue-router";
import {useGlobalStore} from "@/store/global";
import FtrSelectOrder from "@/components/FtrSelectOrder";
import FtrSelectDialog from "@/components/FtrSelectDialog";

export let formData = ref({});

markRaw(FtrFormCardWrappedSchemaForm);
markRaw(FtrSelectOrder);
markRaw(FtrSelectDialog);
markRaw(FtrSubFormTitle);
markRaw(SchemaForm);
markRaw(FtrTextInput);
markRaw(FtrTextFieldOrder);
markRaw(FtrRadio);

export default defineComponent({
  components: {
    FtrSelectOrder,
    IonButton,
    IonCard,
    IonCardSubtitle,
    IonHeader,
    IonCardContent,
    IonTitle,
    SchemaForm,
    QForm,
    IonLabel,
    IonToolbar,
    IonFooter,
    IonText,
    IonAlert
  },
  props: {
    style: {required: false},
    model: ref(),
  },
  setup(props) {
    const store = useGlobalStore();
    const route = useRoute();
    const schema = ref();
    const errors = ref([]);
    const user = ref();
    const isOpenRef = ref(false);
    const isOpenRefAlert = ref(false);
    const orderCheck = ref(null);
    const setOpen = (state) => isOpenRef.value = state.value;
    const $q = useQuasar();
    const brand = ref('');
    const supplier = ref('');
    const size_left = ref('');
    const size_right = ref('');
    const orderIdOne = ref('');
    const orderIdTwo = ref('');
    const lasttype_left = ref('');
    const lasttype_right = ref('');
    const modelnumber = ref('');
    const modelname = ref('');
    const colornumber = ref('');
    const colorname = ref('');
    const filename = ref('');
    const lasttypes = ref([]);
    const sizes = ref([]);
    const remarks = ref('');
    const specs = ref();
    const model = ref();
    const form = ref();
    const price = ref('na');
    const orderType = ref('');
    orderType.value = route.params.category || 'new';
    model.value = props.model;
    const orderID = ref('');

    if (orderType?.value?.includes('edit') || orderType?.value?.includes('archive') || orderType?.value?.includes('reorder')) {
      let temp = orderType.value;
      orderID.value = temp.replace('edit_', '').replace('archived_', '').replace('reorder_', '');
      form.value = store.activeOrder;
      formData.value.modelnumber = form.value.formData?.modelnumber;
      formData.value.brand = form.value.formData?.brand;
      formData.value.supplier = form.value.formData?.supplier;
      formData.value.modelnumber = form.value.formData?.modelnumber;
      formData.value.modelname = form.value.formData?.modelname;
      formData.value.colornumber = form.value.formData?.colornumber;
      formData.value.colorname = form.value.formData?.colorname;

      store.footData = form.value.formData?.footData;
      formData.value.footData = form.value.formData?.footData;
      brand.value = form.value.formData?.brand;
      supplier.value = form.value.formData?.supplier;
      modelnumber.value = form.value.formData?.modelnumber;
      modelname.value = form.value.formData?.modelname;
      colornumber.value = form.value.formData?.colornumber;
      colorname.value = form.value.formData?.colorname;
      filename.value = form.value.formData?.filename;
      price.value = form.value.formData?.price;
      // specs.value = order.formData?.attributes?.specs;

      lasttypes.value = model.value?.lasttypes;

      let min = parseFloat(model.value?.sizerangeMin);
      let max = parseFloat(model.value?.sizerangeMax);
      sizes.value.push(min.toString());
      while (min < max) {
        min = min + 0.5;
        sizes.value.push(min.toString());
      }

      formData.value.size_left = form.value.formData?.size_left;
      formData.value.size_right = form.value.formData?.size_right;
      formData.value.lasttype_left = form.value.formData?.lasttype_left;
      formData.value.lasttype_right = form.value.formData?.lasttype_right;
      size_left.value = form.value.formData?.size_left;
      size_right.value = form.value.formData?.size_right;
      lasttype_left.value = form.value.formData?.lasttype_left;
      lasttype_right.value = form.value.formData?.lasttype_right;
      orderIdTwo.value = form.value.formData?.orderIdTwo;
      orderIdOne.value = form.value.formData?.orderIdOne;
      specs.value = model?.value.attributes?.specs;
    } else {
      console.log(model.value.modelID);
      orderID.value = model.value.modelID;
      brand.value = model.value?.brand;
      supplier.value = model?.value.attributes?.supplier;
      modelnumber.value = model.value?.modelnumber;
      modelname.value = model?.value.attributes?.modelname;
      colornumber.value = model?.value?.colornumber;
      colorname.value = model?.value.attributes?.colorname;
      filename.value = model.value?.filename;
      store.footData = store.lastProposals?.footData;
      specs.value = model?.value.attributes?.specs;
      price.value = model.value?.price;
      if (model.value?.size_left) {
        size_left.value = model.value?.size_left;
        size_right.value = model.value?.size_right;
        lasttype_left.value = model.value?.lasttype_left;
        lasttype_right.value = model.value?.lasttype_right;
      } else {
        lasttypes.value = model.value?.lasttypes;
        let min = parseFloat(model.value?.sizerangeMin);
        let max = parseFloat(model.value?.sizerangeMax);
        sizes.value.push(min.toString());
        while (min < max) {
          console.log(min)
          min = min + 0.5;
          sizes.value.push(min.toString());
        }
        console.log(sizes.value);

      }
    }

    useSchemaForm(formData);

    async function fetchData() {

      formData.value = {};
      formData.value.footData = store?.footData || form.value?.formData?.footData;

      // formData.value = form.value;
      // #################   LOADING SELECT OPTION LISTS     ######################################################
      function getHeights(height, shaft) {
        const leg_heights = ref([]);
        for (let i = 80; i < height; i = i + 5) {
          let item = {id: i + " mm", item: i + " mm"}
          leg_heights.value.push(item);
        }
        const first = [{
          // id: t('low_shoe'),
          // item: t('low_shoe'),
          // item_dutch: t('low_shoe'),
          // item_german: t('low_shoe'),
          // item_english: t('low_shoe'),
          // item_chinese: t('low_shoe'),
          // item_portugese: t('low_shoe'),
          // item_lithuanian: t('low_shoe')
        }];
        if (shaft) {
          return first.concat(leg_heights.value);
        } else {
          return leg_heights;
        }
      }

      function getThicknesses(max) {
        const thicknesses = ref([]);
        thicknesses.value.push('-');
        for (let i = 0; i < max; i = i + 1) {
          let item = i + " mm";
          thicknesses.value.push(item);
        }
        return thicknesses
      }

      schema.value = [];
      // #################   SCHEMA START     ######################################################
      schema.value = [
        [{
          component: FtrFormCardWrappedSchemaForm,
          style: "top:0;padding-top:16px;",
          schema: [[{
            component: SchemaForm,
            model: "",
            label: "",
            schema: [[{
              component: FtrSubFormTitle,
              title: "",
              subtitle: 'Model informatie'
            }],
              [{
                component: FtrTextInput,
                style: "width: 44%;",
                name: "brand",
                model: 'brand',
                disable: true,
                initValue: brand,
                label: "Merk",
                rules: [modelValue => !!modelValue || 'verplicht'],
              },
                {
                  component: FtrTextInput,
                  style: "width: 44%;",
                  name: "supplier",
                  model: 'supplier',
                  disable: true,
                  initValue: supplier,
                  label: "Leverancier",
                  rules: [modelValue => !!modelValue || 'verplicht'],
                }],
              [{
                component: FtrTextInput,
                style: "width: 44%;",
                name: "modelnumber",
                model: "modelnumber",
                disable: true,
                initValue: modelnumber.value,
                label: "Modelnummer",
                rules: [modelValue => !!modelValue || 'verplicht'],
              },
                {
                  component: FtrTextInput,
                  style: "width: 44%;",
                  name: "colornumber",
                  disable: true,
                  model: "colornumber",
                  initValue: colornumber.value,
                  label: "Kleurnummer",
                  rules: [modelValue => !!modelValue || 'verplicht'],
                  condition: () => colornumber.value !== 'na' && colornumber?.value,
                }],
              [{
                component: FtrTextInput,
                style: "width: 44%;",
                name: "modelname",
                disable: true,
                model: "modelname",
                initValue: modelname.value,
                label: "Modelnaam",
                rules: [modelValue => !!modelValue || 'verplicht'],
                condition: () => modelname.value !== 'na' && modelname?.value,
              },
                {
                  component: FtrTextInput,
                  style: "width: 44%;",
                  name: "colorname",
                  disable: true,
                  model: "colorname",
                  initValue: colorname.value,
                  label: "Kleurnaam",
                  rules: [modelValue => !!modelValue || 'verplicht'],
                  condition: () => colorname.value !== 'na' && colorname?.value,
                }],
              [{
                component: FtrTextInput,
                style: "width: 44%;",
                name: "price",
                disable: true,
                model: "price",
                initValue: '€' + price.value,
                label: "Inkoopsprijs",
                rules: [modelValue => !!modelValue || 'verplicht'],
                condition: () => price.value !== undefined && price.value !== 'na',
              }]]
          }]]
        }],
        [{
          component: FtrFormCardWrappedSchemaForm,
          style: "top:0;padding-top:16px;",
          schema: [[{
            component: SchemaForm,
            model: "",
            label: "",
            schema: [[{
              component: FtrSubFormTitle,
              title: "",
              subtitle: 'Maat/Leest gegevens'
            }],
              [{
                component: FtrSelectOrder,
                style: "width: 44%;",
                name: "size_left",
                model: "size_left",
                disable: sizes.value?.length > 0 ? false : true,
                initValue: size_left?.value,
                options: sizes.value,
                label: "Lengtemaat",
                rules: [modelValue => !!modelValue || 'verplicht'],
              },
                {
                  component: FtrSelectOrder,
                  style: "width: 44%;",
                  name: "size_right",
                  model: "size_right",
                  disable: sizes.value?.length > 0 ? false : true,
                  initValue: size_right?.value,
                  options: sizes.value,
                  label: "Lengtemaat",
                  rules: [modelValue => !!modelValue || 'verplicht'],
                }],
              [{
                component: FtrSelectOrder,
                style: "width: 44%;",
                name: "lasttype_left",
                model: "lasttype_left",
                disable: lasttypes?.value?.length > 0 ? false : true,
                initValue: lasttype_left?.value,
                label: "Leesttype",
                options: lasttypes?.value,
                rules: [modelValue => !!modelValue || 'verplicht'],
              },
                {
                  component: FtrSelectOrder,
                  style: "width: 44%;",
                  name: "lasttype_right",
                  model: "lasttype_right",
                  disable: lasttypes?.value?.length > 0 ? false : true,
                  initValue: lasttype_right?.value,
                  label: "Leesttype",
                  options: lasttypes?.value,
                  rules: [modelValue => !!modelValue || 'verplicht'],
                }],
              [{
                component: FtrTextInput,
                style: "width: 44%;",
                name: "kids_lastoption_left",
                model: "kids_lastoption_left",
                disable: !!store?.lastProposals?.footData?.kids_lastoption_left,
                initValue: store?.lastProposals?.footData !== undefined ? store?.lastProposals?.footData?.kids_lastoption_left : form.value?.formData?.kids_lastoption_left,
                label: "Contrefort/Uitvoering",
                // rules: [modelValue => !!modelValue || 'verplicht'],
                condition: () => model.value.category?.includes('k'),
              },
                {
                  component: FtrTextInput,
                  style: "width: 44%;",
                  name: "kids_lastoption_right",
                  model: "kids_lastoption_right",
                  disable: !!store?.lastProposals?.footData?.kids_lastoption_left,
                  initValue: store?.lastProposals?.footData !== undefined ? store?.lastProposals?.footData?.kids_lastoption_right : form.value?.formData?.kids_lastoption_right,
                  label: "Contrefort/Uitvoering",
                  // rules: [modelValue => !!modelValue || 'verplicht'],
                  condition: () => model.value.category?.includes('k'),
                }]]
          }]]
        }],
        [{
          component: FtrFormCardWrappedSchemaForm,
          condition: () => specs?.value !== [],
          schema: [[{
            component: SchemaForm,
            schema: [[{
              component: FtrSubFormTitle,
              title: "",
              subtitle: 'Bijzonderheden',
            }],
              [{
                component: FtrRadio,
                model: "velcro_optional",
                selected: form?.value?.formData?.velcro_optional === 'true' ? 'true' : 'false',
                label: 'Klittenband',
                options: (async () => {
                  return [{
                    id: "true",
                    item: 'Ja',
                    value: "true"
                  },
                    {
                      id: "false",
                      item: 'Nee',
                      value: "false"
                    }]
                })(),
                condition: () => specs?.value?.indexOf('6849475f-b7f0-46b5-a9ec-6e5b8f790fe6') !== -1,
              }],
              [{
                component: FtrRadio,
                model: "stretch_leather_optional",
                selected: form?.value?.formData?.stretch_leather_optional === 'true' ? 'true' : 'false',
                label: 'Stretchleder',
                options: (async () => {
                  return [{
                    id: "true",
                    item: 'Ja',
                    value: "true"
                  },
                    {
                      id: "false",
                      item: 'Nee',
                      value: "false"
                    }]
                })(),
                condition: () => specs?.value?.indexOf('38b996ba-d46c-40ba-8099-8140c501a3ec') !== -1,
              }],
              [{
                component: FtrRadio,
                model: "lining_diabetic",
                selected: form?.value?.formData?.lining_diabetic === 'true' ? 'true' : 'false',
                label: 'Diabetische voering',
                options: (async () => {
                  return [{
                    id: "true",
                    item: 'Ja',
                    value: "true"
                  },
                    {
                      id: "false",
                      item: 'Nee',
                      value: "false"
                    }]
                })(),
                condition: () => specs?.value?.indexOf('6b5f1b8c-07de-4eaa-ae80-c2478bdd3f01') !== -1,
              }]],
            condition: () => specs?.value?.indexOf('6b5f1b8c-07de-4eaa-ae80-c2478bdd3f01') !== -1 || specs?.value?.indexOf('38b996ba-d46c-40ba-8099-8140c501a3ec') !== -1 || specs?.value?.indexOf('6849475f-b7f0-46b5-a9ec-6e5b8f790fe6') !== -1
          }]],
        }],
        [{
          component: FtrFormCardWrappedSchemaForm,
          condition: () => specs?.value !== [],
          schema: [[{
            component: SchemaForm,
            schema: [[{
              component: FtrSubFormTitle,
              title: "",
              subtitle: 'Modificaties (uitgevoerd door leverancier)',
            }],
              [{
                component: FtrRadio,
                model: "lastmodifications_visible",
                selected: form.value?.formData?.lastmodifications_visible === 'true' ? 'true' : "false",
                label: 'Leest',
                options: (async () => {
                  return [{
                    id: "true",
                    item: 'Ja',
                    value: "true"
                  },
                    {
                      id: "false",
                      item: 'Nee',
                      value: "false"
                    }]
                })(),
                condition: () => specs?.value?.indexOf('464156af-b72d-4888-91ba-e4d0e2c4f88d') !== -1 || brand?.value === 'TOM',
              }],
              [{
                component: SchemaForm,
                schema: [[{
                  component: FtrSubFormTitle,
                  title: "Links",
                  subtitle: '',
                }],
                  [{
                    style: "width: 94%;",
                    component: FtrSelectOrder,
                    model: "last_mpt1_left",
                    label: 'Binnenbal',
                    initValue: form.value?.formData?.last_mpt1_left || undefined,
                    visible: true,
                    menuType: 'menu',
                    options: getThicknesses(11),
                  }],
                  [{
                    style: "width: 94%;",
                    component: FtrSelectOrder,
                    model: "last_mtp5_left",
                    initValue: form.value?.formData?.last_mtp5_left || undefined,
                    label: 'Buitenbal',
                    visible: true,
                    menuType: 'menu',
                    options: getThicknesses(11),
                  }],
                  [{
                    style: "width: 94%;",
                    component: FtrSelectOrder,
                    model: "last_pip_left",
                    initValue: form.value?.formData?.last_pip_left || undefined,
                    label: 'Teenruimte',
                    visible: true,
                    menuType: 'menu',
                    options: getThicknesses(11),
                  }],
                  [{
                    style: "width: 94%;",
                    component: FtrSelectOrder,
                    model: "last_general_left",
                    initValue: form.value?.formData?.last_general_left || undefined,
                    label: 'Generaal',
                    visible: true,
                    menuType: 'menu',
                    options: getThicknesses(11),
                  }]],
                condition: () => formData?.value?.lastmodifications_visible === "true"
              },
                {
                  component: SchemaForm,
                  schema: [[{
                    component: FtrSubFormTitle,
                    title: "Rechts",
                    subtitle: '',
                  }],
                    [{
                      style: "width: 94%;",
                      component: FtrSelectOrder,
                      model: "last_mpt1_right",
                      label: 'Binnenbal',
                      initValue: form.value?.formData?.last_mpt1_right || undefined,
                      visible: true,
                      menuType: 'menu',
                      options: getThicknesses(11),
                    }],
                    [{
                      style: "width: 94%;",
                      component: FtrSelectOrder,
                      model: "last_mtp5_right",
                      initValue: form.value?.formData?.last_mtp5_right || undefined,
                      label: 'Buitenbal',
                      visible: true,
                      menuType: 'menu',
                      options: getThicknesses(11),
                    }],
                    [{
                      style: "width: 94%;",
                      component: FtrSelectOrder,
                      model: "last_pip_right",
                      initValue: form.value?.formData?.last_pip_right || undefined,
                      label: 'Teenruimte',
                      visible: true,
                      menuType: 'menu',
                      options: getThicknesses(11),
                    }],
                    [{
                      style: "width: 94%;",
                      component: FtrSelectOrder,
                      model: "last_general_right",
                      initValue: form.value?.formData?.last_general_right || undefined,
                      label: 'Generaal',
                      visible: true,
                      menuType: 'menu',
                      options: getThicknesses(11),
                    }]],
                  condition: () => formData?.value?.lastmodifications_visible === "true"
                }],]
          }],
            [{
              component: SchemaForm,
              schema: [[{
                component: FtrSubFormTitle,
              }],
                [{
                  component: FtrRadio,
                  model: "rolling_visible",
                  selected: form.value?.formData?.rolling_visible === 'true' ? 'true' : 'false',
                  label: 'Afwikkeling / Verhoging',
                  options: (async () => {
                    return [{
                      id: "true",
                      item: 'Ja',
                      value: "true"
                    },
                      {
                        id: "false",
                        item: 'Nee',
                        value: "false"
                      }]
                  })(),
                  condition: () => specs?.value?.indexOf('38b996ba-d46c-40ba-8099-8140c501a3ec') !== -1,
                }]]
            }],
            [{
              component: SchemaForm,
              schema: [[{
                component: FtrSubFormTitle,
                title: "Links",
                subtitle: '',
              }],
                [{
                  style: "width: 94%;",
                  component: FtrSelectDialog,
                  model: "rolling_type_left",
                  initValue: form.value?.formData?.rolling_type_left || undefined,
                  label: 'Type',
                  visible: true,
                  // rules: [modelValue => !!modelValue || 'verplicht'],
                  menuType: 'dialog',
                  options: [
                    {id: "Normaal", item: "Normaal", value: "Normaal", img: "rolling_normaal.png"},
                    {id: "Vervroegd", item: "Vervroegd", value: "Vervroegd", img: "rolling_vervroegd.png"},
                    {id: "Vertraagd", item: "Vertraagd", value: "Vertraagd", img: "rolling_vertraagd.png"},
                    {id: "2-Fase", item: "2-Fase", value: "2-Fase", img: "rolling_2-fase.png"},
                  ],
                }],
                [{
                  style: "width: 94%;",
                  component: FtrSelectOrder,
                  model: "rolling_toe_left",
                  initValue: form.value?.formData?.rolling_toe_left || undefined,
                  label: 'Tenen',
                  visible: true,
                  // rules: [modelValue => !!modelValue || 'verplicht'],
                  menuType: 'menu',
                  options: getThicknesses(71),
                },
                  {
                    style: "width: 94%;",
                    component: FtrSelectOrder,
                    model: "rolling_ball_left",
                    initValue: form.value?.formData?.rolling_ball_left || undefined,
                    label: 'Bal',
                    visible: true,
                    // rules: [modelValue => !!modelValue || 'verplicht'],
                    menuType: 'menu',
                    options: getThicknesses(71),
                  },
                  {
                    style: "width: 94%;",
                    component: FtrSelectOrder,
                    model: "rolling_heel_left",
                    initValue: form.value?.formData?.rolling_heel_left || undefined,
                    label: 'Hiel',
                    visible: true,
                    // rules: [modelValue => !!modelValue || 'verplicht'],
                    menuType: 'menu',
                    options: getThicknesses(71),
                  }]
              ],
              condition: () => formData.value?.rolling_visible === "true"
            },
              {
                component: SchemaForm,
                schema: [[{
                  component: FtrSubFormTitle,
                  title: "Rechts",
                  subtitle: '',
                }],
                  [{
                    style: "width: 94%;",
                    component: FtrSelectDialog,
                    model: "rolling_type_right",
                    initValue: form.value?.formData?.rolling_type_right || undefined,
                    label: 'Type',
                    visible: true,
                    // rules: [modelValue => !!modelValue || 'verplicht'],
                    menuType: 'dialog',
                    options: [
                      {id: "Normaal", item: "Normaal", value: "Normaal", img: "rolling_normaal.png"},
                      {id: "Vervroegd", item: "Vervroegd", value: "Vervroegd", img: "rolling_vervroegd.png"},
                      {id: "Vertraagd", item: "Vertraagd", value: "Vertraagd", img: "rolling_vertraagd.png"},
                      {id: "2-Fase", item: "2-Fase", value: "2-Fase", img: "rolling_2-fase.png"},
                    ],
                  }],
                  [{
                    style: "width: 94%;",
                    component: FtrSelectOrder,
                    model: "rolling_toe_right",
                    initValue: form.value?.formData?.rolling_toe_right || undefined,
                    label: 'Tenen',
                    visible: true,
                    // rules: [modelValue => !!modelValue || 'verplicht'],
                    menuType: 'menu',
                    options: getThicknesses(71),
                  },
                    {
                      style: "width: 94%;",
                      component: FtrSelectOrder,
                      model: "rolling_ball_right",
                      initValue: form.value?.formData?.rolling_ball_right || undefined,
                      label: 'Bal',
                      visible: true,
                      // rules: [modelValue => !!modelValue || 'verplicht'],
                      menuType: 'menu',
                      options: getThicknesses(71),
                    },
                    {
                      style: "width: 94%;",
                      component: FtrSelectOrder,
                      model: "rolling_heel_right",
                      initValue: form.value?.formData?.rolling_heel_right || undefined,
                      label: 'Hiel',
                      visible: true,
                      // rules: [modelValue => !!modelValue || 'verplicht'],
                      menuType: 'menu',
                      options: getThicknesses(71),
                    }]],
                condition: () => formData.value?.rolling_visible === "true"
              }],
            [{
              component: SchemaForm,
              schema: [[{
                component: FtrSubFormTitle,
              }],
                [{
                  component: SchemaForm,
                  model: "heeladjustments",
                  label: "",
                  schema: [[{
                    component: FtrSubFormTitle,
                    title: "",
                    subtitle: '',
                  }],
                    [{
                      component: FtrRadio,
                      model: "heelrolling_visible",
                      selected: form?.value?.formData?.heeladjustments?.heelrolling_visible === 'true' ? 'true' : 'false',
                      label: 'Hak aanpassingen',
                      options: (async () => {
                        return [{
                          id: "true",
                          item: 'Ja',
                          value: "true"
                        },
                          {
                            id: "false",
                            item: 'Nee',
                            value: "false"
                          }]
                      })(),
                      condition: () => specs?.value?.indexOf('38b996ba-d46c-40ba-8099-8140c501a3ec') !== -1,
                    }],
                    [{
                      component: SchemaForm,
                      schema: [[{
                        component: FtrSubFormTitle,
                        title: "",
                        subtitle: '',
                      }],
                        [{
                          component: FtrSubFormTitle,
                          title: "",
                          subtitle: 'Hakafronding',
                        }],
                        [{
                          style: "width:50%",
                          component: FtrSelectOrder,
                          model: "heelrolling",
                          label: 'mm',
                          menuType: 'menu',
                          watchValue: 'heelrolling_selector',
                          visible: false,
                          initValue: form.value?.formData?.heeladjustments?.heelrolling,
                          options: getThicknesses(16),
                        }],
                        [{
                          component: FtrSubFormTitle,
                          title: "",
                          subtitle: 'Hakbuffer',
                        }],
                        [{
                          component: FtrRadio,
                          model: "heelbuffer",
                          selected: form.value?.formData?.heeladjustments?.heelbuffer,
                          label: '',
                          options: (async () => {
                            return [{
                              id: "yes",
                              item: 'Ja'
                            },
                              {
                                id: "no",
                                item: 'Nee'
                              }]
                          })(),
                        }],
                        [{
                          component: FtrSubFormTitle,
                          title: "",
                          subtitle: 'Schoring',
                        }],
                        [{
                          title: 'left_class',
                          style: "width:50%",
                          component: FtrSelectOrder,
                          model: "heel_expansion_left_medial",
                          label: 'mediaal',
                          initValue: form.value?.formData?.heeladjustments?.heel_expansion_left_medial,
                          menuType: 'menu',
                          watchValue: 'heelexpansion_selector',
                          options: ["-", "3 mm", "6 mm", "9 mm", "12 mm", "-3 mm"],
                        },
                          {
                            title: 'left_class',
                            style: "width:50%",
                            component: FtrSelectOrder,
                            model: "heel_expansion_left_lateral",
                            initValue: form.value?.formData?.heeladjustments?.heel_expansion_left_lateral,
                            label: 'lateraal',
                            menuType: 'menu',
                            watchValue: 'heelexpansion_selector',
                            options: ["-", "3 mm", "6 mm", "9 mm", "12 mm", "-3 mm"],
                          }],
                        [{
                          title: 'right_class',
                          style: "width:50%",
                          component: FtrSelectOrder,
                          model: "heel_expansion_right_medial",
                          initValue: form.value?.formData?.heeladjustments?.heel_expansion_right_medial,
                          label: 'mediaal',
                          menuType: 'menu',
                          watchValue: 'heelexpansion_selector',
                          options: ["-", "3 mm", "6 mm", "9 mm", "12 mm", "-3 mm"],
                        },
                          {
                            title: 'right_class',
                            style: "width:50%",
                            component: FtrSelectOrder,
                            model: "heel_expansion_right_lateral",
                            initValue: form.value?.formData?.heeladjustments?.heel_expansion_right_lateral,
                            label: 'lateraal',
                            menuType: 'menu',
                            watchValue: 'heelexpansion_selector',
                            options: ["-", "3 mm", "6 mm", "9 mm", "12 mm", "-3 mm"],
                          }]],
                      condition: () => formData.value?.heeladjustments?.heelrolling_visible === "true"
                    }]],
                }]],
            }],
            [{
              component: SchemaForm,
              schema:
                  [[{
                    component: FtrSubFormTitle,
                    title: "Bijzonderheden",
                    subtitle: '',
                  }],
                    [{
                      component: FtrTextFieldOrder,
                      style: "width: 100%;",
                      name: "remarks",
                      model: "remarks",
                      selected: form?.value?.formData?.remarks,
                      label: ""
                    }]]
            }]],
        }],
        [{
          component: FtrFormCardWrappedSchemaForm,
          style: "top:0;padding-top:16px;",
          schema: [[{
            component: SchemaForm,
            model: "",
            label: "",
            schema: [[{
              component: FtrSubFormTitle,
              title: "",
              subtitle: 'Order gegevens'
            }],
              [{
                component: FtrTextInput,
                style: "width: 44%;",
                name: "orderIdOne",
                model: "orderIdOne",
                initValue: orderIdOne?.value,
                label: "Kenmerk één",
                rules: [modelValue => !!modelValue || 'verplicht'],
              },
                {
                  component: FtrTextInput,
                  style: "width: 44%;",
                  name: "orderIdTwo",
                  model: "orderIdTwo",
                  initValue: orderIdTwo?.value,
                  label: "Kenmerk twee",
                  rules: [modelValue => !!modelValue || 'verplicht'],
                }]]
          }]]
        }]]
      // #################    INITIAL VALUES FOR BRIGANTE TEMPLATEMODEL     ######################################################

    }

    // #################   SCHEMA END     ######################################################

    // #################   INIT VARIABLES AND GET FUNCTIONS     ######################################################
    try {
      user.value = Auth.currentAuthenticatedUser();
    } catch (error) {
      console.log('user is not logged in', error);
    }
    const myDialog = ref(null);
    const submitResult = ref([]);
    const dialog = ref(false)
    const position = ref('bottom')
    const submitTitle = ref('Archiveer of verzend uw order!');
    const submitText = ref();
    const myForm = ref(null);

    const date = new Date(Date.now());
    const convertedDate = ((date.getDate() > 9) ? date.getDate() : ("0" + date.getDate())) + "-" + ((date.getMonth() > 8) ? (date.getMonth() + 1) : ("0" + (date.getMonth() + 1))) + "-" + date.getFullYear() + " " + ((date.getHours() > 9) ? date.getHours() : ("0" + date.getHours())) + ":" + ((date.getMinutes() > 9) ? date.getMinutes() : ("0" + date.getMinutes()));

    // if (route.path?.includes('reorder')) {
    //   setTimeout(async function () {
    //     const order = await DataStore.query(DevFittrOrder, orderID);
    //     const initValue = JSON.parse(order.formData);
    //     formData.value = null;
    //     formData.value = initValue._value;
    //     myForm.value.resetValidation();
    //   }, 1000);
    // }

    async function openSubmitDialog() {
      dialog.value = true
    }

    async function closeSubmitDialog() {
      dialog.value = false
    }

    const formSubmit = async (event, action) => {
      event.preventDefault();
      event.stopImmediatePropagation();
      console.log(event);
      if (action === 'archived') {
        if (dialog.value === false) {
          // submitTitle.value = 'Success';
          // submitText.value = 'Gelukt hoor, geen zorgen!';
          // await openSubmitDialog();
          async function presentAlertRadio() {
            if (orderType.value?.includes('edit')) {
              const suppliers = [
                {name: 'Nimco Made 4 You BV', brands:['Nimco'], email: 'salesnm4y@nimco.nl'},
                {name: 'Piedro', brands:['Piedro'], email: 'klantenservice.nl@piedro.nl'},
                {name: 'SGI Footcare', brands:['DrComfort'], email: 'info@sgifootcare.nl'},
                {name: 'Van Drunen Schoenfabriek', brands:['Durea', 'Gijs'], email: 'sales@vandrunenschoenfabriek.nl'},
                {name: 'TOM Schoenen', brands:['TOM'],  email: 'info@tomschoenen.nl'},
                {name: 'Schulte BV', brands:['MyGeneration', 'LuCro'], email: 'info@schultebv.nl'},
                {name: 'Orthotec', brands:['Orthotec'], email: 'planning@footcare.nl'},
                {name: 'Neskrid', brands:['Neskrid'], email: 'wpheerlen@footcare.nl'},
                {name: 'Emma', brands:['Emma'], email: 'wpheerlen@footcare.nl'},
              ];
              const supplier = suppliers.filter(item => item.name === formData?.value.supplier)[0];
              console.log(supplier);
              const alert = await alertController
                  .create({
                    cssClass: 'my-custom-class',
                    header: 'Weet je zeker dat je de wijzigingen wilt opslaan en de opdracht verzenden naar:  ' + supplier.name + '?',
                    // subHeader: 'selecteer een bestemming voor verwerking van de order',
                    message: 'De order wordt verzonden naar: ' + supplier.email,
                    translucent: true,
                    buttons: [
                      {
                        text: 'Afbreken',
                        role: 'cancel',
                        cssClass: 'secondary',
                        handler: () => {
                          console.log('Confirm Cancel')
                        },
                      },
                      {
                        text: 'Verzenden',
                        handler: async () => {
                          console.log('Confirm Ok');
                          const loading = await loadingController
                              .create({
                                cssClass: 'my-custom-class',
                                message: 'Een moment alstublieft, de order wordt verzonden...',
                              })
                          await loading.present();
                          const original = await DataStore.query(DevFittrOrder, orderID.value);
                          console.log(formData.value);
                          let search = formData?.value?.orderIdOne?.toLowerCase() + formData?.value?.orderIdOne?.toLowerCase() + convertedDate + user['custom:organisation'] + user?.email;
                          let temp = await DataStore.save(
                              DevFittrOrder.copyOf(original, updated => {
                                updated.orderIdOne = formData.value.orderIdOne;
                                updated.orderIdTwo = formData.value.orderIdTwo;
                                updated.status = 'processed';
                                updated.exportedAt = convertedDate;
                                updated.search = search;
                                updated.submittedAt = convertedDate;
                                updated.formData = JSON.stringify(formData.value);
                                updated.editor = ['object creation: ' + convertedDate];
                              })
                          );
                          let orderToSent = JSON.parse(JSON.stringify(temp))
                          console.log(orderToSent);
                          // orderToSent.order_destination = supplier.email;
                          orderToSent.order_destination = 'allard@cachet-it.nl';
                          orderToSent.userName = null;
                          const apiName = "sentConfirmationMail"; // replace this with your api name.
                          const path = "/confirmation"; //replace this with the path you have configured on your API
                          const myInit = {
                            body: {
                              order: orderToSent
                            }, // replace this with attributes you need
                          };
                          await API.post(apiName, path, myInit)
                              .then(response => {
                                console.log(response);
                              })
                              .catch(error => {
                                console.log(error.response);
                              });
                          await loading.dismiss();
                          setTimeout(async function () {
                            $q.notify({
                              color: 'green-5',
                              position: 'center',
                              textColor: 'white',
                              icon: 'fal fa-otter',
                              message: 'Chillen maar, je order is verzonden!'
                            })
                          }, 3000);
                          setTimeout(async function () {
                            // await store.methods.getArchivedOrders();
                            // await store.methods.getSubmittedOrders();
                            window.location.href = window.location.href = 'https://test-app.footcaremaatschoenen.info/admin/orders'
                          }, 3000);
                        }
                      },
                    ],
                  });
              return alert.present();
            } else {
              const alert = await alertController
                  .create({
                    cssClass: 'my-custom-class',
                    header: 'Weet je zeker dat je de order wilt archiveren?',
                    // subHeader: 'selecteer een bestemming voor verwerking van de order',
                    translucent: true,
                    buttons: [
                      {
                        text: 'Afbreken',
                        role: 'cancel',
                        cssClass: 'secondary',
                        handler: () => {
                          console.log('Confirm Cancel')
                        },
                      },
                      {
                        text: 'Archiveren',
                        handler: async () => {
                          console.log('Confirm Ok');
                          const loading = await loadingController
                              .create({
                                cssClass: 'my-custom-class',
                                message: 'Een moment alstublieft, de order wordt opgeslagen...',
                              })
                          await loading.present();

                          const user = await Auth.currentAuthenticatedUser();

                          let search = formData?.value?.orderIdOne?.toLowerCase() + formData?.value?.orderIdOne?.toLowerCase() + convertedDate + user['custom:organisation'] + user?.email;

                          search = search.replaceAll(" ", "");
                          if (orderType.value?.includes('edit') || orderType.value?.includes('archive')) {
                            const original = await DataStore.query(DevFittrOrder, orderID.value);
                            console.log(formData.value);
                            if (formData.value.orderIdOne !== undefined) {
                              await DataStore.save(
                                  DevFittrOrder.copyOf(original, updated => {
                                    updated.orderIdOne = formData.value.orderIdOne;
                                    updated.orderIdTwo = formData.value.orderIdTwo;
                                    updated.status = 'archived';
                                    updated.search = search;
                                    updated.submittedAt = convertedDate;
                                    updated.formData = JSON.stringify(formData.value);
                                    updated.editor = ['object creation: ' + convertedDate];
                                  })
                              );
                              setTimeout(async function () {
                                // await store.methods.getArchivedOrders();
                                // await store.methods.getSubmittedOrders();
                                $q.notify({
                                  color: 'green-5',
                                  position: 'center',
                                  textColor: 'white',
                                  icon: 'fal fa-face-smile-relaxed',
                                  message: 'Nice, je order is opgeslagen!'
                                })
                                await loading.dismiss();
                                setTimeout(async function () {
                                  // await store.methods.getArchivedOrders();
                                  // await store.methods.getSubmittedOrders();
                                  window.location.href = window.location.origin;
                                }, 3000);
                              }, 3000);
                            } else {
                              await loading.dismiss();
                              const alertAr = await alertController
                                  .create({
                                    cssClass: 'my-custom-class',
                                    header: 'Vul minimaal een eerste kenmerk in!',
                                    subHeader: "Tenminste één kenmerk onder het kopje 'Order gegevens' is noodzakelijk om de order later terug te vinden.",
                                    translucent: true,
                                    buttons: [
                                      {
                                        text: 'OK',
                                        role: 'cancel',
                                        cssClass: 'secondary',
                                        handler: () => {
                                          console.log('Confirm Cancel')
                                        }
                                      }
                                    ]
                                  });
                              await alertAr.present();
                            }
                          } else {
                            const newOrder = JSON.parse(JSON.stringify({
                              createdAt: convertedDate,
                              modelnumber: formData.value.modelnumber,
                              clientID: user.attributes['custom:organisation'],
                              organisation: user.attributes['custom:organisation'],
                              userName: user.attributes.email,
                              orderIdOne: formData.value.orderIdOne,
                              orderIdTwo: formData.value.orderIdTwo,
                              filename: filename.value,
                              status: 'archived',
                              order_destination: formData.value.order_destination,
                              search: search,
                              submittedAt: convertedDate,
                              formData: formData.value,
                              editors: ['object creation: ' + convertedDate]
                            }));
                            if (newOrder.orderIdOne !== undefined) {
                              console.log(newOrder);
                              const result = await DataStore.save(
                                  new DevFittrOrder(newOrder)
                              );
                              console.log(result)
                              setTimeout(async function () {
                                // await store.methods.getArchivedOrders();
                                // await store.methods.getSubmittedOrders();
                                $q.notify({
                                  color: 'green-5',
                                  position: 'center',
                                  textColor: 'white',
                                  icon: 'fal fa-fae-smile-relaxed',
                                  message: 'Nice, je order is opgeslagen!'
                                })
                                await loading.dismiss();
                                setTimeout(async function () {
                                  // await store.methods.getArchivedOrders();
                                  // await store.methods.getSubmittedOrders();
                                  window.location.href = window.location.origin;
                                }, 3000);
                              }, 3000);
                            } else {
                              await loading.dismiss();
                              const alertAr = await alertController
                                  .create({
                                    cssClass: 'my-custom-class',
                                    header: 'Vul minimaal een eerste kenmerk in!',
                                    subHeader: "Tenminste één kenmerk onder het kopje 'Order gegevens' is noodzakelijk om de order later terug te vinden.",
                                    translucent: true,
                                    buttons: [
                                      {
                                        text: 'OK',
                                        role: 'cancel',
                                        cssClass: 'secondary',
                                        handler: () => {
                                          console.log('Confirm Cancel')
                                        }
                                      }
                                    ]
                                  });
                              await alertAr.present();
                            }
                          }
                        }
                      },
                    ],
                  });
              return alert.present();
            }
          }
          await presentAlertRadio();
        } else {

          const loading = await loadingController
              .create({
                cssClass: 'my-custom-class',
                message: 'Please wait...',
                duration: 10000,
              });

          await loading.present();

          const user = await Auth.currentAuthenticatedUser();

          let search = formData.value.orderIdOne.toLowerCase() + formData.value.orderIdOne.toLowerCase() + convertedDate + user['custom:organisation'] + user.email;

          search = search.replaceAll(" ", "");

          if (orderType.value?.includes('edit') || orderType.value?.includes('archive')) {
            const original = await DataStore.query(DevFittrOrder, orderID.value);
            console.log(formData.value);
            await DataStore.save(
                DevFittrOrder.copyOf(original, updated => {
                  updated.orderIdOne = formData.value.orderIdOne;
                  updated.orderIdTwo = formData.value.orderIdTwo;
                  updated.status = 'archived';
                  updated.search = search;
                  updated.submittedAt = convertedDate;
                  updated.formData = JSON.stringify(formData.value);
                  updated.editor = ['object creation: ' + convertedDate];
                })
            );
          } else {
            const newOrder = JSON.parse(JSON.stringify({
              createdAt: convertedDate,
              modelnumber: formData.value.modelnumber,
              clientID: user.attributes['custom:organisation'],
              organisation: user.attributes['custom:organisation'],
              userName: user.attributes.email,
              orderIdOne: formData.value.orderIdOne,
              orderIdTwo: formData.value.orderIdTwo,
              filename: filename.value,
              status: 'archived',
              order_destination: formData.value.order_destination,
              search: search,
              submittedAt: convertedDate,
              formData: formData.value,
              editors: ['object creation: ' + convertedDate]
            }));
            console.log(newOrder);
            const result = await DataStore.save(
                new DevFittrOrder(newOrder)
            );
            console.log(result)
          }

          $q.notify({
            color: 'green-5',
            position: 'center',
            textColor: 'white',
            icon: 'fal fa-otter',
            message: 'Chillen maar, je order is verzonden!'
          })
          setTimeout(async function () {
            // await store.methods.getArchivedOrders();
            // await store.methods.getSubmittedOrders();
            window.location.href = window.location.origin;
          }, 3000);
        }
      } else if (action === 'submit') {
        myForm.value.validate().then(async (success) => {
              if (success) {
                if (orderType.value?.includes('edit')) {
                  const alert = await alertController
                      .create({
                        cssClass: 'my-custom-class',
                        header: 'De order is compleet!',
                        // subHeader: 'selecteer een bestemming voor verwerking van de order',
                        message: 'Wilt u de wijzigingen opslaan?',
                        translucent: true,
                        buttons: [
                          {
                            text: 'Afbreken',
                            role: 'cancel',
                            cssClass: 'secondary',
                            handler: () => {
                              console.log('Confirm Cancel')
                            },
                          },
                          {
                            text: 'Opslaan',
                            handler: async () => {
                              console.log('Confirm Ok');
                              const loading = await loadingController
                                  .create({
                                    cssClass: 'my-custom-class',
                                    message: 'Een moment alstublieft, de wijzigingen worden opgeslagen...',
                                  })
                              await loading.present();
                              const user = await Auth.currentAuthenticatedUser();
                              let search = formData.value.orderIdOne.toLowerCase() + formData.value.orderIdOne.toLowerCase() + convertedDate + user['custom:organisation'] + user.attributes.email;
                              search = search.replaceAll(" ", "");
                              const original = await DataStore.query(DevFittrOrder, orderID.value);
                              const result = await DataStore.save(
                                  DevFittrOrder.copyOf(original, updated => {
                                    updated.orderIdOne = formData.value.orderIdOne;
                                    updated.orderIdTwo = formData.value.orderIdTwo;
                                    updated.submittedAt = form?.value?.submittedAt;
                                    updated.search = search;
                                    updated.formData = JSON.stringify(formData.value);
                                    updated.editor = ['object creation: ' + convertedDate];
                                  })
                              );
                              console.log(result);

                              setTimeout(async function () {
                                // await store.methods.getArchivedOrders();
                                // await store.methods.getSubmittedOrders();
                                $q.notify({
                                  color: 'green-5',
                                  position: 'center',
                                  textColor: 'white',
                                  icon: 'fal fa-otter',
                                  message: 'Geen paniek! Alles is netjes opgeslagen!'
                                })
                                await loading.dismiss();
                                setTimeout(async function () {
                                  // await store.methods.getArchivedOrders();
                                  // await store.methods.getSubmittedOrders();
                                  window.location.href = window.location.href = 'https://test-app.footcaremaatschoenen.info/admin/orders'
                                }, 3000);
                              }, 3000);
                            }
                          },
                        ],
                      });
                  return alert.present();
                } else {
                  if (dialog.value === false) {
                    // submitTitle.value = 'Success';
                    // submitText.value = 'Gelukt hoor, geen zorgen!';
                    // await openSubmitDialog();
                    async function presentAlertRadio() {
                      const alert = await alertController
                          .create({
                            cssClass: 'my-custom-class',
                            header: 'De order is compleet!',
                            // subHeader: 'selecteer een bestemming voor verwerking van de order',
                            message: 'Selecteer een bestemming voor verwerking van de order.',
                            translucent: true,
                            inputs: [
                              {
                                type: 'radio',
                                label: 'wpheerlen@footcare.nl',
                                value: 'wpheerlen@footcare.nl',
                                handler: () => {
                                  console.log('Radio 2 selected');
                                  formData.value.order_destination = 'wpheerlen@footcare.nl';
                                }
                              },
                              {
                                type: 'radio',
                                label: 'wparnhem@footcare.nl',
                                value: 'wparnhem@footcare.nl',
                                handler: () => {
                                  console.log('Radio 3 selected');
                                  formData.value.order_destination = 'wparnhem@footcare.nl';
                                }
                              },
                              {
                                type: 'radio',
                                label: 'wpleiden@footcare.nl',
                                value: 'wpleiden@footcare.nl',
                                handler: () => {
                                  console.log('Radio 4 selected');
                                  formData.value.order_destination = 'wpleiden@footcare.nl';
                                }
                              },
                              {
                                type: 'radio',
                                label: 'wpamersfoort@footcare.nl',
                                value: 'wpamersfoort@footcare.nl',
                                handler: () => {
                                  console.log('Radio 5 selected');
                                  formData.value.order_destination = 'wpamersfoort@footcare.nl';
                                }
                              },
                              {
                                type: 'radio',
                                label: 'wphaarlem@footcare.nl',
                                value: 'wphaarlem@footcare.nl',
                                handler: () => {
                                  console.log('Radio 6 selected');
                                  formData.value.order_destination = 'wphaarlem@footcare.nl';
                                }
                              },
                              {
                                type: 'radio',
                                label: 'TEST ONTVANGER (selecteer deze optie voor test orders)',
                                value: 'allard@cachet-it.nl',
                                handler: () => {
                                  console.log('Radio 1 selected');
                                  formData.value.order_destination = 'allard@cachet-it.nl';
                                },
                              },
                            ],
                            buttons: [
                              {
                                text: 'Afbreken',
                                role: 'cancel',
                                cssClass: 'secondary',
                                handler: () => {
                                  console.log('Confirm Cancel')
                                },
                              },
                              {
                                text: 'Verzenden',
                                handler: async (inputs) => {
                                  console.log(inputs);
                                  const loading = await loadingController
                                      .create({
                                        cssClass: 'my-custom-class',
                                        message: 'Een moment alstublieft, de order wordt verzonden...',
                                      })
                                  await loading.present();

                                  const user = await Auth.currentAuthenticatedUser();

                                  let search = formData.value.orderIdOne.toLowerCase() + formData.value.orderIdOne.toLowerCase() + convertedDate + user['custom:organisation'] + user.attributes.email;

                                  search = search.replaceAll(" ", "");
                                  const newOrder = ref();

                                  if (orderType.value?.includes('archive')) {
                                    const original = await DataStore.query(DevFittrOrder, orderID.value);
                                    console.log(original);
                                    const result = await DataStore.save(
                                        DevFittrOrder.copyOf(original, updated => {
                                          updated.orderIdOne = formData.value.orderIdOne;
                                          updated.orderIdTwo = formData.value.orderIdTwo;
                                          updated.status = 'submitted';
                                          updated.search = search;
                                          updated.order_destination = inputs;
                                          updated.submittedAt = convertedDate;
                                          updated.formData = JSON.stringify(formData.value);
                                          updated.editor = ['object creation: ' + convertedDate];
                                        })
                                    );
                                    newOrder.value = result;
                                  } else {
                                    const nwo = JSON.parse(JSON.stringify({
                                      createdAt: convertedDate,
                                      modelnumber: formData.value.modelnumber,
                                      clientID: user.attributes['custom:organisation'],
                                      organisation: user.attributes['custom:organisation'],
                                      userName: user.attributes.email,
                                      orderIdOne: formData.value.orderIdOne,
                                      orderIdTwo: formData.value.orderIdTwo,
                                      filename: filename.value,
                                      status: 'submitted',
                                      order_destination: inputs,
                                      search: search,
                                      submittedAt: convertedDate,
                                      formData: formData.value,
                                      editors: ['object creation: ' + convertedDate]
                                    }));
                                    const result = await DataStore.save(
                                        new DevFittrOrder(nwo)
                                    );
                                    newOrder.value = result;
                                  }
                                  console.log(newOrder.value)
                                  const apiName = "sentConfirmationMail"; // replace this with your api name.
                                  const path = "/confirmation"; //replace this with the path you have configured on your API
                                  const myInit = {
                                    body: {
                                      order: newOrder.value
                                    }, // replace this with attributes you need
                                  };
                                  await API.post(apiName, path, myInit)
                                      .then(response => {
                                        console.log(response);
                                        setTimeout(async function () {
                                          $q.notify({
                                            color: 'green-5',
                                            position: 'center',
                                            textColor: 'white',
                                            icon: 'fal fa-otter',
                                            message: 'Chillen maar, je order is verzonden!'
                                          })
                                          await loading.dismiss();
                                          setTimeout(async function () {
                                            window.location.href = window.location.origin;
                                          }, 2000);
                                        }, 3000);
                                      })
                                      .catch(error => {
                                        console.log(error.response);
                                      });
                                }
                              },
                            ],
                          });
                      return alert.present();
                    }

                    await presentAlertRadio();
                  } else {

                    const loading = await loadingController
                        .create({
                          cssClass: 'my-custom-class',
                          message: 'Please wait...',
                          duration: 10000,
                        });

                    await loading.present();

                    const user = await Auth.currentAuthenticatedUser();

                    let search = formData.value.orderIdOne.toLowerCase() + formData.value.orderIdOne.toLowerCase() + convertedDate + user['custom:organisation'] + user.email;

                    search = search.replaceAll(" ", "");
                    const newOrder = ref();
                    if (orderType.value?.includes('archive')) {
                      const original = await DataStore.query(DevFittrOrder, orderID.value);
                      console.log(formData.value);
                      newOrder.value = await DataStore.save(
                          DevFittrOrder.copyOf(original, updated => {
                            updated.orderIdOne = formData.value.orderIdOne;
                            updated.orderIdTwo = formData.value.orderIdTwo;
                            updated.status = 'submitted';
                            updated.search = search;
                            updated.order_destination = formData.value.order_destination;
                            updated.submittedAt = convertedDate;
                            updated.formData = JSON.stringify(formData.value);
                            updated.editor = ['object creation: ' + convertedDate];
                          })
                      );
                    } else {
                      const nwo = JSON.parse(JSON.stringify({
                        createdAt: convertedDate,
                        modelnumber: formData.value.modelnumber,
                        clientID: user.attributes['custom:organisation'],
                        organisation: user.attributes['custom:organisation'],
                        userName: user.attributes.email,
                        orderIdOne: formData.value.orderIdOne,
                        orderIdTwo: formData.value.orderIdTwo,
                        filename: filename.value,
                        status: 'submitted',
                        order_destination: formData.value.order_destination,
                        search: search,
                        submittedAt: convertedDate,
                        formData: formData.value,
                        editors: ['object creation: ' + convertedDate]
                      }));
                      const result = await DataStore.save(
                          new DevFittrOrder(nwo)
                      );
                      newOrder.value = result;
                      console.log(result)
                    }
                    const apiName = "sentConfirmationMail"; // replace this with your api name.
                    const path = "/confirmation"; //replace this with the path you have configured on your API
                    const myInit = {
                      body: {
                        order: newOrder.value
                      }, // replace this with attributes you need
                    };
                    await API.post(apiName, path, myInit)
                        .then(response => {
                          console.log(response);
                          setTimeout(async function () {
                            $q.notify({
                              color: 'green-5',
                              position: 'center',
                              textColor: 'white',
                              icon: 'fal fa-otter',
                              message: 'Chillen maar, je order is verzonden!'
                            })
                            await loading.dismiss();
                            setTimeout(async function () {
                              window.location.href = window.location.origin;
                            }, 2000);
                          }, 3000);
                        })
                        .catch(error => {
                          console.log(error.response);
                        });
                  }
                }

              } else {
                // newOrder.filename = files;
                const vals = myForm.value.getValidationComponents();
                const errors = [];
                for (let i = 0; i < vals.length; i++) {
                  let ord = vals[i]
                  if (ord.hasError === true) {
                    errors.push(ord.$attrs.id ? ord.$attrs.id : ord.$attrs);
                  }
                }
                if (dialog.value === false) {
                  submitTitle.value = 'Mislukt';
                  submitText.value = 'Niet Gelukt hoor, zorgen!';
                } else {
                  submitTitle.value = 'Mislukt';
                  submitText.value = 'Niet Gelukt hoor, zorgen!';
                  // myDialog.value.shake();
                }
                setTimeout(async function () {
                  // closeSubmitDialog();
                }, 3000)
              }
            }
        )
      }

    }

    fetchData();

    return {
      store,
      orderCheck,
      formData,
      route,
      setOpen,
      isOpenRef,
      isOpenRefAlert,
      schema,
      formSubmit,
      submitResult,
      myForm,
      errors,
      dialog,
      position,
      openSubmitDialog,
      closeSubmitDialog,
      submitTitle,
      submitText,
      myDialog,
      user,
      onReset() {
        // name.value = null
        // age.value = null
        // accept.value = false
      },
      reset() {
        myForm.value.resetValidation()
      }
    }
  }
})

</script>

<style>
.my-custom-class .alert-wrapper {
  --min-width: 40%;
  --max-width: 50%;
}

.my-custom-class h2 {
  line-height: unset;
}

.my-custom-class .alert-radio-button {
  padding: 5px 20px 5px 10px;
  height: 50px;
}

.my-custom-class .alert-button-inner {
  flex-flow: row-reverse nowrap;
  justify-content: center;
}

/*.my-custom-class [aria-checked=true] .alert-radio-icon {*/
/*  background: var(--ion-color-primary);*/
/*}*/

.my-custom-class .alert-radio-inner {
  left: 12px;
  top: 12px;
  width: 10px;
  height: 20px;
}

.my-custom-class .alert-radio-icon {
  border: 1px solid grey;
  border-radius: 50px;
  height: 30px;
  width: 30px;
}

/*.my-custom-class .alert-radio-inner {*/
/*  position: relative;*/
/*  width: 12px;*/
/*  height: 20px;*/
/*  padding-right: 10px;*/
/*  left: unset;*/
/*  top: unset;*/
/*}*/

.my-custom-class .alert-radio-label {
  margin: 5px;
  border-bottom: 0.5px solid grey;
}

/*.my-style {*/
/*  --background: var(--ion-card-background, var(--ion-item-background, var(--ion-background-color, #fff)));*/
/*  --color: var(--ion-card-color, var(--ion-item-color, var(--ion-color-step-600, #666666)));*/
/*  padding-top: 15px;*/
/*  padding-bottom: 15px;*/
/*  font-size: 14px;*/
/*  border-radius: 10px;*/
/*  box-shadow: rgb(0 0 0 / 31%) 0 10px 70px;*/
/*}*/

.selected {
  /*--background: rgba(39, 52, 139, 0.05);*/
  --color: rgba(39, 52, 139, 1);
}

.schema-row {
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
}

.schema-col {
  width: 100%;
}

SchemaForm .schema-row {
  padding-bottom: 5px;
  padding-top: 5px;
}

@media (min-width: 768px) {
  .schema-row {
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
}

</style>