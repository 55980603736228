<script setup>
// This starter template is using Vue 3 <script setup> SFCs
// Check out https://v3.vuejs.org/api/sfc-script-setup.html#sfc-script-setup
import {
	useQuasar,
	QBtn,
	QPage,
	QSkeleton,
	QLayout,
	QForm,
	QPageSticky,
	QToolbar,
	QField,
	QOptionGroup,
	QSeparator
} from 'quasar'
import {
	IonButton,
	IonBackButton,
	IonButtons,
	IonCard,
	IonCardContent,
	IonCardTitle,
	IonCheckbox,
	IonCol,
	IonContent,
	IonGrid,
	IonHeader,
	IonItem,
	IonLabel,
	IonList,
	IonListHeader,
	IonMenuToggle,
	IonPage,
	IonRow,
	IonToolbar,
	onIonViewDidEnter,
	useIonRouter,
} from '@ionic/vue';
import FtrFootLengthInput from "@/components/FtrFootLengthInput";
import FtrInput from "@/components/FtrInput";
import FtrSelect from "@/components/FtrSelect";
import {ref} from "vue";
import {useRoute,
	useRouter} from "vue-router";
import {
	DataStore,
	SortDirection
} from "aws-amplify";
import {DevFittrLastV1} from "@/models";
import {useGlobalStore} from "@/store/global";

const store = useGlobalStore();
const selectedFilters = ref([]);
const footData = ref(new Map([]));
const router = useRouter();
const route = useRoute();
const ionRouter = useIonRouter();
const fabPos = ref();
const $q = useQuasar();
const inputName = ref(null);
const lastsLeft = ref([]);
const lastsRight = ref([]);
const myForm = ref(null);
const disable = ref(true);
const submitting = ref(false);
const result = ref([]);
const brands = ref([]);
const selectedBrands = ref();
const brandsLoadingState = ref(false);
const draggingFab = ref(false);
const MinMaxLastLength = ref('');
const MinMaxBallSize = ref('');
const optionsForKidLasts = ref();
const side = ref('');
const kidsOptionSelectedLeft = ref();
const kidsOptionSelectedRight = ref();

const foot = {}
const options = [
	{
		label: 'Normaal-Reval',
		value: 'Normaal-Reval'
	},
	{
		label: 'Korset-Stabiel',
		value: 'Korset-Stabiel'
	},
	{
		label: 'Orthese',
		value: 'Orthese'
	},
	{
		label: 'Antivarus',
		value: 'Antivarus'
	}
];

function update(value) {
	selectedBrands.value.has(value) ? selectedBrands.value.delete(value) : selectedBrands.value.add(value);
	console.log(selectedBrands.value)
}

optionsForKidLasts.value = options;


function findMinMax(arr, param) {
	let min = arr[0][param], max = arr[0][param];
	for (let i = 1, len = arr.length; i < len; i++) {
		let v = arr[i][param];
		min = (v < min) ? v : min;
		max = (v > max) ? v : max;
	}
	return [min, max];
}

function reset() {
	myForm.value.resetValidation()
}

onIonViewDidEnter(() => {
	DataStore.observeQuery(DevFittrLastV1, c =>
			c.targetgroup("contains", route.params.category), {
		sort: s => s.brand(SortDirection.ASCENDING)
	}).subscribe(snapshot => {
		const {items, isSynced} = snapshot;
		result.value = items;
		if (result.value.length > 0) {
			fetchData();
		}
		console.log(`[Snapshot] item count: ${items.length}, isSynced: ${isSynced}`);
	});
})

const brandsList = ref(true);

async function fetchData() {
	MinMaxLastLength.value = findMinMax(result.value, 'lastlength');
	MinMaxBallSize.value = findMinMax(result.value, 'ballsize');
	const arr = result.value.map(p => p['brand']);
	const flat = arr.flat(Infinity);
	const s = new Set(flat);
	const unique = [...s];
	brands.value = unique;
	selectedBrands.value = new Set(brands.value);
	selectedFilters.value = unique;
	brandsList.value === true ? store.category = route.params.category : store.category = 'o';
	setTimeout(() => {
		disable.value = false;
		brandsLoadingState.value = true;
	}, 250);
}

async function bestMatch() {
	let copySide = side.value;
	if (side.value === 'right' && isNaN(footData.value["footlength_" + side.value])) {
		copySide = 'left'
	} else {
		copySide = side.value;
	}

	let incrementing_testvalue_footlength = parseInt(footData.value["footlength_incl_oversize_" + copySide]);
	let decrementing_testvalue_footlength = parseInt(footData.value["footlength_incl_oversize_" + copySide]);
	incrementing_testvalue_footlength = parseInt(footData.value["footlength_incl_oversize_" + copySide]);
	decrementing_testvalue_footlength = parseInt(footData.value["footlength_incl_oversize_" + copySide]);

	let ballsize_incl_insole = parseInt(footData.value["ballsize_incl_insole_" + copySide]);

	let overlimit_footlength = 0;
	overlimit_footlength = parseInt(footData.value["footlength_incl_oversize_" + copySide]) + 4;
	let underlimit_footlength = 0;
	underlimit_footlength = parseInt(footData.value["footlength_incl_oversize_" + copySide]) - 3;

	let overlimit_ballsize = 0;
	overlimit_ballsize = parseInt(footData.value["ballsize_incl_insole_" + copySide]) + 4;
	let underlimit_ballsize = 0;
	underlimit_ballsize = parseInt(footData.value["ballsize_incl_insole_" + copySide]) - 3;

	let incrementing_testvalue_ballsize = parseInt(ballsize_incl_insole);
	let decrementing_testvalue_ballsize = parseInt(ballsize_incl_insole);

	let ballwidth = parseInt(footData.value["ballwidth_" + copySide]);

	let overlimit_ballwidth = 0;
	overlimit_ballwidth = ballwidth + 4;
	let underlimit_ballwidth = 0;
	underlimit_ballwidth = ballwidth - 14;

	let incrementing_testvalue_ballwidth = 0;
	incrementing_testvalue_ballwidth = ballwidth;
	let decrementing_testvalue_ballwidth = 0;
	decrementing_testvalue_ballwidth = ballwidth;

	return new Promise(async (resolved) => {

		let all_bestmatches = [];
		let lastgroups = "";
		let iterations = 0;

		async function queryDatabase() {
			let lasts;
			if (footData?.value?.kids_lastoption_left) {
				let lastoption;
				if (side.value === 'right' && footData?.value?.kids_lastoption_right !== undefined) {
					lastoption = 'kids_lastoption_right';
				} else {
					lastoption = 'kids_lastoption_left';
				}
				let type_substring_1;
				let type_substring_2;
				if (footData?.value[lastoption]?.includes('-')) {
					type_substring_1 = footData?.value[lastoption]?.substr(1, (footData?.value[lastoption]?.indexOf('-') - 1));
					type_substring_2 = footData?.value[lastoption]?.substr((footData?.value[lastoption]?.indexOf('-') + 2), (footData?.value[lastoption]?.length - (footData.value[lastoption]?.indexOf('-') + 1)))
					console.log(type_substring_1);
					console.log(type_substring_2);
					let lasts1 = await DataStore.query(DevFittrLastV1, c =>
									c.targetgroup("eq", store?.category).lasttype("contains", type_substring_1).lastlength("between", [decrementing_testvalue_footlength, incrementing_testvalue_footlength]).ballsize("between", [decrementing_testvalue_ballsize, incrementing_testvalue_ballsize]),
							{sort: s => s.lastgroup(SortDirection.DESCENDING)})
					let lasts2 = await DataStore.query(DevFittrLastV1, c =>
									c.targetgroup("eq", store?.category).lasttype("contains", type_substring_2).lastlength("between", [decrementing_testvalue_footlength, incrementing_testvalue_footlength]).ballsize("between", [decrementing_testvalue_ballsize, incrementing_testvalue_ballsize]),
							{sort: s => s.lastgroup(SortDirection.DESCENDING)})
					lasts = [...lasts1, ...lasts2];
				} else {
					type_substring_1 = footData?.value[lastoption]?.substr(1, (footData?.value[lastoption]?.length - 2));
					console.log(type_substring_1);
					let lasts1 = await DataStore.query(DevFittrLastV1, c =>
									c.targetgroup("eq", store?.category).lasttype("contains", type_substring_1).ballsize("between", [decrementing_testvalue_ballsize, incrementing_testvalue_ballsize]).lastlength("between", [decrementing_testvalue_footlength, incrementing_testvalue_footlength]),
							{sort: s => s.lastgroup(SortDirection.DESCENDING)})
					lasts = [...lasts1];
				}
			} else {
				lasts = await DataStore.query(DevFittrLastV1, c =>
								c.targetgroup("eq", store?.category).ballsize("between", [decrementing_testvalue_ballsize, incrementing_testvalue_ballsize]).lastlength("between", [decrementing_testvalue_footlength, incrementing_testvalue_footlength]),
						{sort: s => s.lastgroup(SortDirection.DESCENDING)})

			}
			for (let i = 0; i < lasts.length; i++) {
				if (!lastgroups.includes(lasts[i].lastgroup)) {
					all_bestmatches.push(lasts[i]);
					lastgroups = lastgroups + lasts[i].lastgroup;
				}
			}

			iterations++;
			if (all_bestmatches.length < 12 && iterations < 50) {
				if (incrementing_testvalue_footlength < overlimit_footlength) {
					incrementing_testvalue_footlength++;
				}
				if (decrementing_testvalue_footlength > underlimit_footlength) {
					decrementing_testvalue_footlength--;
				}
				if (incrementing_testvalue_ballsize < overlimit_ballsize) {
					incrementing_testvalue_ballsize++;
				}
				if (decrementing_testvalue_ballsize > underlimit_ballsize) {
					decrementing_testvalue_ballsize--;
				}
				await queryDatabase();
			} else {
				resolved(all_bestmatches);
			}
		}

		async function queryDatabaseBallwidth() {
			let lasts;
			if (footData?.value?.kids_lastoption_left) {
				let lastoption;
				if (side.value === 'right' && footData?.value?.kids_lastoption_right !== undefined) {
					lastoption = 'kids_lastoption_right';
				} else {
					lastoption = 'kids_lastoption_left';
				}
				let type_substring_1;
				let type_substring_2;
				if (footData?.value[lastoption]?.includes('-')) {
					type_substring_1 = footData?.value[lastoption]?.substr(1, (footData?.value[lastoption]?.indexOf('-') - 1));
					type_substring_2 = footData?.value[lastoption]?.substr((footData?.value[lastoption]?.indexOf('-') + 2), (footData?.value[lastoption]?.length - (footData.value[lastoption]?.indexOf('-') + 1)))
					console.log(type_substring_1);
					console.log(type_substring_2);
					let lasts1 = await DataStore.query(DevFittrLastV1, c =>
									c.targetgroup("eq", store?.category).lasttype("contains", type_substring_1).lastlength("between", [decrementing_testvalue_footlength, incrementing_testvalue_footlength]).ballsize("between", [decrementing_testvalue_ballsize, incrementing_testvalue_ballsize]),
							{sort: s => s.lastgroup(SortDirection.DESCENDING)})
					let lasts2 = await DataStore.query(DevFittrLastV1, c =>
									c.targetgroup("eq", store?.category).lasttype("contains", type_substring_2).lastlength("between", [decrementing_testvalue_footlength, incrementing_testvalue_footlength]).ballsize("between", [decrementing_testvalue_ballsize, incrementing_testvalue_ballsize]),
							{sort: s => s.lastgroup(SortDirection.DESCENDING)})
					lasts = [...lasts1, ...lasts2];
				} else {
					type_substring_1 = footData?.value[lastoption]?.substr(1, (footData?.value[lastoption]?.length - 2));
					console.log(type_substring_1);
					let lasts1 = await DataStore.query(DevFittrLastV1, c =>
									c.targetgroup("eq", store?.category).lasttype("contains", type_substring_1).ballsize("between", [decrementing_testvalue_ballsize, incrementing_testvalue_ballsize]).lastlength("between", [decrementing_testvalue_footlength, incrementing_testvalue_footlength]),
							{sort: s => s.lastgroup(SortDirection.DESCENDING)})
					lasts = [...lasts1];
				}
			} else {
				lasts = await DataStore.query(DevFittrLastV1, c =>
								c.targetgroup("eq", store?.category).ballsize("between", [decrementing_testvalue_ballsize, incrementing_testvalue_ballsize]).lastlength("between", [decrementing_testvalue_footlength, incrementing_testvalue_footlength]),
						{sort: s => s.lastgroup(SortDirection.DESCENDING)})

			}

			for (let i = 0; i < lasts.length; i++) {
				if (!lastgroups.includes(lasts[i].lastgroup)) {
					all_bestmatches.push(lasts[i]);
					lastgroups = lastgroups + lasts[i].lastgroup;
				}
			}

			iterations++;
			if (all_bestmatches.length < 12 && iterations < 50) {
				if (incrementing_testvalue_footlength < overlimit_footlength) {
					incrementing_testvalue_footlength++;
				}
				if (decrementing_testvalue_footlength > underlimit_footlength) {
					decrementing_testvalue_footlength--;
				}
				if (incrementing_testvalue_ballsize < overlimit_ballsize) {
					incrementing_testvalue_ballsize++;
				}
				if (decrementing_testvalue_ballsize > underlimit_ballsize) {
					decrementing_testvalue_ballsize--;
				}
				await queryDatabaseBallwidth();
			} else {
				resolved(all_bestmatches);
			}
		}

		if (side.value === 'right' && isNaN(footData.value["footlength_" + side.value])) {
			copySide = 'left'
		} else {
			copySide = side.value;
		}
		if (footData.value.ballwidth_left) {
			ballwidth = footData.value["ballwidth_" + copySide];
			overlimit_ballwidth = ballwidth + 14;
			underlimit_ballwidth = ballwidth - 32;
			incrementing_testvalue_ballwidth = ballwidth;
			decrementing_testvalue_ballwidth = ballwidth;
			await queryDatabaseBallwidth();
		} else {
			await queryDatabase();
		}
	});
}

if ("fabPosMeasurementsPage" in localStorage) {
	fabPos.value = JSON.parse(localStorage.getItem('fabPosMeasurementsPage'));
} else {
	fabPos.value = [38, 38]
}

function moveFab(ev) {
	draggingFab.value = ev.isFirst !== true && ev.isFinal !== true
	fabPos.value = [
		fabPos.value[0] - ev.delta.x,
		fabPos.value[1] - ev.delta.y
	]
	localStorage.setItem("fabPosMeasurementsPage", JSON.stringify(fabPos.value))
	console.log(footDimensions);
}

const footDimensions = () => ref(new FormData(footData))

function validate(evt) {
	{
		myForm.value.validate().then(success => {
			if (success) {
				submitting.value = true
				// yay, models are correct
				onSubmit(evt)
			} else {
				// oh no, user has filled in
				// at least one invalid value
				$q.notify({
					color: 'red-5',
					textColor: 'white',
					icon: 'fas fa-triangle-exclamation',
					message: 'Er zijn nog velden met geen of foutieve waarden!'
				})
			}
		}, error => {
			console.log(error)
		});
	}
}

async function onSubmit(evt) {
	submitting.value = true;
	side.value = '';
	const formData = new FormData(evt.target)
	let data = {};

	for (const [nameValue, value] of formData.entries()) {
		data[nameValue] = parseInt(value);
	}
	footData.value = data;

	footData.value = {
		footlength_left: data.footlength_left,
		insole_left: data.insole_left,
		insole_right: data.insole_right,
		footlength_right: data?.footlength_right || data.footlength_left,
		ballsize_left: data.ballsize_left,
		ballsize_right: data?.ballsize_right || data.ballsize_left,
		ballwidth_left: data.ballwidth_left,
		ballwidth_right: data?.ballwidth_right || data.ballwidth_left,
		oversize_left: data.oversize_left,
		oversize_right: data?.oversize_right || data.oversize_left,
		lastlength_left: 0,
		lastlength_right: 0,
		ballsize_last_left: 0,
		ballsize_last_right: 0,
		ballwidth_last_left: 0,
		ballwidth_last_right: 0,

	};
	footData.value.footlength_incl_oversize_left = parseInt(data.footlength_left) + parseInt(data.oversize_left);
	footData.value.footlength_incl_oversize_right = (parseInt(data.footlength_right) + parseInt(data.oversize_right)) || (parseInt(data.footlength_left) + parseInt(data.oversize_left));
	footData.value.ballsize_incl_insole_left = parseInt(data.ballsize_left) + (parseInt(data.insole_left) * 2);
	footData.value.ballsize_incl_insole_right = (parseInt(data.ballsize_right) + (parseInt(data.insole_right) * 2)) || parseInt(data.ballsize_left) + (parseInt(data.insole_left) * 2);
	footData.value.kids_lastoption_left = kidsOptionSelectedLeft.value || undefined;
	footData.value.kids_lastoption_right = kidsOptionSelectedLeft.value ? kidsOptionSelectedRight.value : kidsOptionSelectedLeft.value;
	footData.value.overlimit_footlength_left = (footData.value.footlength_incl_oversize_left + data.oversize_left) + 2;
	footData.value.underlimit_footlength_left = (footData.value.overlimit_footlength_left - 3);
	footData.value.overlimit_footlength_right = (footData.value.footlength_incl_oversize_right + data.oversize_right) + 2;
	footData.value.underlimit_footlength_right = (footData.value.overlimit_footlength_right - 3);

	console.log(footData.value);
	side.value = 'left';
	let initialLastsLeft = await bestMatch();

	const lastgroupsLeft = new Set();
	initialLastsLeft.forEach(last => {
		if (selectedBrands.value.has(last.brand)) {
			lastgroupsLeft.add(last.lastgroup);
		}
	});

	side.value = 'right';
	let initialLastsRight = await bestMatch();

	lastsRight.value = [];
	initialLastsRight.forEach(last => {
		if (lastgroupsLeft.has(last.lastgroup) && selectedBrands.value.has(last.brand)) {
			lastsRight.value.push(last);
		}
	});

	const lastgroupsRight = new Set();
	lastsRight.value.forEach(last => {
		if (selectedBrands.value.has(last.brand)) {
			lastgroupsRight.add(last.lastgroup);
		}
	});

	lastsLeft.value = [];
	initialLastsLeft.forEach(last => {
		if (lastgroupsRight.has(last.lastgroup) && selectedBrands.value.has(last.brand)) {
			lastsLeft.value.push(last);
		}
	});
	lastsLeft.value.sort();
	lastsRight.value.sort();

	// for (let i = 0; i < initialLastsLeft.length; i++) {
	//   let last = initialLastsLeft[i];
	//   lastsLeft.value.push(last);
	// }

	if (lastsLeft.value.length > 0 && lastsRight.value.length > 0) {
		store.lastProposals = {
			lastsLeft: lastsLeft.value,
			lastsRight: lastsRight.value,
			footData: footData.value,
			category: store?.category
		};

		await router.push('/osb/foot-dimensions/last-proposals/' + store?.category);

	} else {
		$q.notify({
			color: 'red-5',
			textColor: 'white',
			icon: 'fas fa-triangle-exclamation',
			message: 'Er zijn geen passende leesten gevonden!'
		})
	}
	submitting.value = false;
}

const lasttypeOptionsKids = ref('');

function capitalize(value) {
	if (!value) return ''
	value = value.toString()
	return value.charAt(0).toUpperCase() + value.slice(1)
}

</script>

<template>

	<ion-page>

		<ion-header :translucent="true">
			<ion-toolbar color="primary">
				<ion-buttons slot="start">
					<ion-back-button style="color:white" default-href="/home">
					</ion-back-button>
					<img @click="() => ionRouter.navigate('/home', 'back', 'push')" class="header-logo" alt="no-image"
					     src="../assets/fittr_logo.png">
				</ion-buttons>

				<ion-buttons slot="end">
					<ion-button style="color:white">
						<i style="font-size: 24px;" class="fa-regular fa-bars"></i>
						<ion-menu-toggle style="position:absolute;width:100%;height: 100%">
						</ion-menu-toggle>
					</ion-button>
				</ion-buttons>
			</ion-toolbar>
		</ion-header>

		<ion-content fullscreen>
			<q-layout style="width: 100%;height: 100%">
				<q-page>
					<q-form greedy ref="myForm" @submit="onSubmit">
						<ion-grid
								style="height:100%;padding-left: 0;padding-right: 0;margin-left: 0;margin-right: 0; width: 100%">
							<ion-row style="overflow-y:auto;padding-top: calc(10px + var(--ion-safe-area-top, 0));">
								<ion-col size-xs="12" size-sm="3">
									<ion-card ref="content" class="custom-card">
										<ion-card-content>
											<template v-if="!brandsLoadingState">
												<ion-list>
													<ion-list-header style="opacity:0.95">
														<q-skeleton type="rect" animation="fade" height="26px" width="80px"/>
													</ion-list-header>
													<template v-for="i in 4" :key="i">
														<ion-item style="opacity:0.95" :detail="false" lines="inset">
															<q-skeleton animation="fade" style="margin: 12px" size="26px" type="rect"/>
															<q-skeleton animation="fade" style="margin-left: 2px" width="70px" type="QBadge"/>
														</ion-item>
														<ion-item style="opacity:0.95" v-if="i < 4" :detail="false" lines="inset">
															<q-skeleton animation="fade" style="margin: 12px" size="26px" type="rect"/>
															<q-skeleton animation="fade" style="margin-left: 2px" width="50px" type="QBadge"/>
														</ion-item>
													</template>
												</ion-list>
											</template>
											<template v-else>
												<ion-item style="position: relative;left: 0">
													<ion-card-title>
														<ion-label color="tertiary" style="font-size: 24px">Merken</ion-label>
													</ion-card-title>
												</ion-item>
												<ion-list
														:style="[brandsList === false ? 'pointer-events:none;opacity:0.25' : 'pointer-events:all;opacity:1']">
													<ion-item v-for="item in brands" :key="item" lines="inset" :detail="false">
														<ion-label>{{
																item
															}}
														</ion-label>
														<ion-checkbox :ref="item" checked="true" @update:modelValue="update(item)" slot="start"
														              color="primary" size="large"
														              :value="item">
														</ion-checkbox>
													</ion-item>
												</ion-list>
												<ion-list>
													<ion-item lines="inset" :detail="false">
														<ion-label>Orthotec
														</ion-label>
														<ion-checkbox :checked="false" slot="start"
														              @click="() => { selectedBrands.has('Orthotec') ? ( brandsList = true, brands.forEach(brand => update(brand)), selectedBrands.delete('Orthotec'), store.category = route.params.category ) : ( brandsList = false, brands.forEach(brand => selectedBrands.delete(brand)), update('Orthotec'), store.category = 'o' ) }"
														              color="primary" size="large"
														              :value="'Orthotec'">
														</ion-checkbox>
													</ion-item>
												</ion-list>
											</template>
										</ion-card-content>
									</ion-card>
								</ion-col>

								<ion-col size-xs="12" size-sm="4.5">
									<ion-card class="custom-card" style="padding-left: 0;padding-bottom: 20px">
										<ion-card-content style="padding-left: 6px">
											<ion-item style="position: relative;left: 0">
												<ion-card-title>
													<ion-label color="tertiary" style="font-size: 24px">Links</ion-label>
												</ion-card-title>
											</ion-item>
											<template v-if="route?.params?.category?.includes('k')">
												<q-field ref="inputRef" style="padding:5px;width:96%;margin-left:4%"
												         outlined
												         v-model="kidsOptionSelectedLeft"
												         name="kids_lastoption_left"
												         :rules="[val => !!val || '* Dit veld mag niet leeg blijven']"
												         :model-value="kidsOptionSelectedLeft"
												         @update:model-value="update($event)"
												>
													<template v-slot:control>
														<q-toolbar>Kies eerst hieronder een contrefort/leest uitvoering zodat we een accurater
															leest-voorstel kunnen presenteren
														</q-toolbar>
														<q-separator style="width:98%;margin:1%"/>
														<q-option-group name="kids_lastoption_left"
														                v-model="kidsOptionSelectedLeft"
														                clearable
														                :model-value="kidsOptionSelectedLeft"
														                :options="optionsForKidLasts"
														                type="radio"
														                @update:model-value="update($event)"
														                style="display:flex;flex-direction: row;justify-content:flex-start;flex-wrap:wrap;flex-grow:1"
														                inline
														                color="primary"
														/>
													</template>
												</q-field>
											</template>
											<div style="display: flex; align-items: flex-end">
												<img class="input-icon" alt="no-image" src="../assets/img/length-icon.jpg">
												<FtrFootLengthInput label="Voetlengte*"
												                    name="footlength_left"
												                    name2="oversize_left"
												                    mask="###"
												                    :rules="[
          val => !!val || '* Dit veld mag niet leeg blijven',
          val => val.length > 2 || '* Gebruik minimaal 3 cijfers!',
          val => val > MinMaxLastLength[0] || '*Oeps, zo klein zijn er geen leesten! minimale lengte: ' + MinMaxLastLength[0] + ' mm!',
           val => val < MinMaxLastLength[1] || '*Oeps, zulke grote hebben zelfs wij niet! maximale lengte: ' + MinMaxLastLength[1] + ' mm!',
        ]"/>
											</div>
											<div style="display: flex;align-items: flex-end">
												<img class="input-icon" alt="no-image" src="../assets/img/ball-icon.jpg">
												<FtrInput mask="###"
												          min-characters="3"
												          label="Balomvang*"
												          name="ballsize_left"
												          :rules="[
          val => !!val || '* Dit veld mag niet leeg blijven',
          val => val.length > 2 || '* Gebruik minimaal 3 cijfers!',
          val => val > MinMaxBallSize[0] || '*Oeps, krapper dan ' + MinMaxBallSize[0] + ' mm hebben we ze niet! ',
           val => val < MinMaxBallSize[1] || '*Oeps, alles ruimer dan ' + MinMaxBallSize[1] + ' mm is zelfs voor ons te ruim!',
        ]"/>
											</div>
											<div style="display: flex;align-items: flex-end">
												<img class="input-icon" alt="no-image" src="../assets/img/width.jpg">
												<FtrInput mask="###"
												          min-characters="2"
												          label="Balbreedte"
												          name="ballwidth_left"
												          :rules="[
           val => val.length > 0 ? val.length > 1 || '* Gebruik minimaal 2 cijfers!' : true,
        ]"/>
											</div>
											<div style="display: flex;align-items: flex-end">
												<img class="input-icon" alt="no-image" src="../assets/img/heel.jpg">
												<FtrInput mask="###"
												          min-characters="3"
												          label="Hielwreef"
												          name="heelsize_left"
												          :rules="[
           val => val.length > 0 ? val.length > 2 || '* Gebruik minimaal 3 cijfers!' : true,
        ]"/>
											</div>
											<FtrSelect label="Voetbeddikte"
											           name="insole_left"
											/>
										</ion-card-content>
									</ion-card>
								</ion-col>

								<ion-col size-xs="12" size-sm="4.5">
									<ion-card class="custom-card" style="padding-left: 10px;padding-bottom: 20px">
										<ion-card-content style="padding-left: 6px">
											<ion-item style="position: relative;left: 0">
												<ion-card-title>
													<ion-label color="tertiary" style="font-size: 24px">Rechts</ion-label>
												</ion-card-title>
											</ion-item>
											<template v-if="route?.params?.category?.includes('k')">
												<q-field ref="inputRef" style="padding:5px;width:96%;margin-left:4%"
												         outlined
												         name="kids_lastoption_right"
												         :model-value="kidsOptionSelectedRight"
												         v-model="kidsOptionSelectedRight"
												         @update:model-value="update($event)"
												>
													<template v-slot:control>
														<q-toolbar>Kies eerst hieronder een contrefort/leest uitvoering zodat we een accurater
															leest-voorstel kunnen presenteren
														</q-toolbar>
														<q-separator style="width:98%;margin:1%"/>
														<q-option-group name="kids_lastoption_right"
														                v-model="kidsOptionSelectedRight"
														                :model-value="kidsOptionSelectedRight"
														                clearable
														                :options="optionsForKidLasts"
														                type="radio"
														                style="display:flex;flex-direction: row;justify-content:flex-start;flex-wrap:wrap;flex-grow:1"
														                inline
														                color="primary"
														/>
													</template>
												</q-field>
											</template>
											<div style="display: flex; align-items: flex-end">
												<img class="input-icon" alt="no-image" src="../assets/img/length-icon_right.jpg">
												<FtrFootLengthInput label="Voetlengte"
												                    name="footlength_right"
												                    :disable="disable"
												                    name2="oversize_right"
												                    mask="###"
												                    :rules="[
         val => val.length > 0 ? val.length > 2 || '* Gebruik minimaal 3 cijfers!' : true,
        ]"/>
											</div>
											<div style="display: flex;align-items: flex-end">
												<img class="input-icon" alt="no-image" src="../assets/img/ball-icon_right.jpg">
												<FtrInput mask="###"
												          min-characters="3"
												          label="Balomvang"
												          name="ballsize_right"
												          :rules="[
          val => val.length > 0 ? val.length > 2 || '* Gebruik minimaal 3 cijfers!' : true,
        ]"/>
											</div>
											<div style="display: flex;align-items: flex-end">
												<img class="input-icon" alt="no-image" src="../assets/img/width_right.jpg">
												<FtrInput mask="###"
												          min-characters="2"
												          label="Balbreedte"
												          name="ballwidth_right"
												          :rules="[
           val => val.length > 0 ? val.length > 1 || '* Gebruik minimaal 2 cijfers!' : true,
        ]"/>
											</div>
											<div style="display: flex;align-items: flex-end">
												<img class="input-icon" alt="no-image" src="../assets/img/heel_right.jpg">
												<FtrInput mask="###"
												          min-characters="3"
												          label="Hielwreef"
												          name="heelsize_right"
												          :rules="[
          val => val.length > 0 ? val.length > 2 || '* Gebruik minimaal 3 cijfers!' : true,
        ]"/>
											</div>
											<FtrSelect label="Voetbeddikte"
											           name="insole_right"
											/>
										</ion-card-content>
									</ion-card>
								</ion-col>
							</ion-row>
						</ion-grid>
						<q-page-sticky class="fixed-bottom-right"
						               :offset="fabPos">
							<q-btn type="submit"
							       style="padding-left:30px;padding-right:20px;"
							       :loading="submitting" fab
							       :disabled="disable"
							       icon-right="fal fa-chevron-right"
							       label="Leesten"
							       color="secondary"
							       :disable="draggingFab"
							       v-touch-pan.prevent.mouse="moveFab"
							>
							</q-btn>
						</q-page-sticky>
					</q-form>
				</q-page>
			</q-layout>
		</ion-content>
		<!-- fab placed in the center of the content with a list on each side -->
	</ion-page>
</template>

<style>
.q-btn__content {
	flex-wrap: nowrap;
}
</style>

<style scoped>

.custom-card {
	/*margin: calc(12px + var(--ion-grid-column-padding-lg, var(--ion-grid-column-padding, 5px))) 12px calc(24px + var(--ion-grid-column-padding-lg, var(--ion-grid-column-padding, 5px)));*/
}


.q-slider .q-slider__track-container--h {
	opacity: 0;
}

ion-row {
	height: 100%;
	margin-left: 4px;
	margin-right: 4px;
}

.input-icon {
	height: 70px;
	padding: 2px;
	margin-left: 3px;
	margin-bottom: 15px;
}

@media (min-width: 350px) {

}

/*###############################################################*/
@media (min-width: 576px) {
	ion-row {
		height: 100%;
		margin-left: 8px;
		margin-right: 8px;
	}

}

/*###############################################################*/
@media (min-width: 768px) {

}

/*###############################################################*/
@media (min-width: 992px) {

}

/*###############################################################*/
@media (min-width: 1024px) {

}

/*###############################################################*/
@media (min-width: 1150px) {

}
</style>
