// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';



const { FootcareWelt, FootcareApplicatie, FootcareOutsole, FootcareUpperleather, FootcareBadslipper, FootcareComfortslipper, FootcareMaatpantoffel, FootcareSandaal, FootcareUitwasbareschoen, FootcareClosingItem, DevModelCollection, DevFittrOrder, DevFittrModel, DevFittrLastgroup, DevFittrClosing, DevFittrColor, DevFittrStyle, DevFittrHeight, DevFittrSpec, DevFittrLastV1, DevFittrPurchasePrice, DevFittrPriceLimit, DevFootcareModel, DevFootcarePriceLevel, DevFootcareSupplier, DevFootcareHealthcareProvider, DevFootcareStyle, DevFootcareClosing, DevFootcareInstep, DevFootcareHeight } = initSchema(schema);

export {
  FootcareWelt,
  FootcareApplicatie,
  FootcareOutsole,
  FootcareUpperleather,
  FootcareBadslipper,
  FootcareComfortslipper,
  FootcareMaatpantoffel,
  FootcareSandaal,
  FootcareUitwasbareschoen,
  FootcareClosingItem,
  DevModelCollection,
  DevFittrOrder,
  DevFittrModel,
  DevFittrLastgroup,
  DevFittrClosing,
  DevFittrColor,
  DevFittrStyle,
  DevFittrHeight,
  DevFittrSpec,
  DevFittrLastV1,
  DevFittrPurchasePrice,
  DevFittrPriceLimit,
  DevFootcareModel,
  DevFootcarePriceLevel,
  DevFootcareSupplier,
  DevFootcareHealthcareProvider,
  DevFootcareStyle,
  DevFootcareClosing,
  DevFootcareInstep,
  DevFootcareHeight
};