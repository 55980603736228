<template>
  <ion-page>

    <!--    <ion-menu v-if="router.options.history.state.back" style="&#45;&#45;width:28em;" side="start" content-id="men" menu-id="men"-->
    <!--              class="my-custom-menu">-->

    <!--      <ion-header :translucent="true">-->
    <!--        <ion-toolbar color="primary">-->
    <!--          <ion-buttons slot="end">-->
    <!--            <ion-button @click="menuController.close('men')">Close</ion-button>-->
    <!--          </ion-buttons>-->
    <!--        </ion-toolbar>-->
    <!--      </ion-header>-->

    <!--      <ion-content style="width:28em;background: #f7f7f7">-->

    <!--        <div style="width: 100%;padding:12px">-->

    <!--          <ModelPageFilter v-if="route.path.includes('osa')" propName="priceLevel" :model="selectedPriceLevel"-->
    <!--                           title="Prijs niveau" :items="[priceLevels,healthcareProviders,suppliers]"/>-->

    <!--          <ion-card style="padding:16px">-->
    <!--            <ion-list v-if="route.path.includes('osb')">-->
    <!--              <ion-list-header>-->
    <!--                <ion-label>Technische filters</ion-label>-->
    <!--              </ion-list-header>-->
    <!--              <ion-item :detail="false" lines="inset">-->
    <!--                <ion-label>Klittenband optioneel</ion-label>-->
    <!--                <ion-toggle-->
    <!--                    @ionChange="filterSpecs('6849475f-b7f0-46b5-a9ec-6e5b8f790fe6')"-->
    <!--                    value="6849475f-b7f0-46b5-a9ec-6e5b8f790fe6" :checked="false">-->
    <!--                </ion-toggle>-->
    <!--              </ion-item>-->
    <!--              <ion-item :detail="false" lines="inset">-->
    <!--                <ion-label>Stretchleder optioneel</ion-label>-->
    <!--                <ion-toggle-->
    <!--                    @ionChange="filterSpecs('38b996ba-d46c-40ba-8099-8140c501a3ec')"-->
    <!--                    value="38b996ba-d46c-40ba-8099-8140c501a3ec" :checked="false">-->
    <!--                </ion-toggle>-->
    <!--              </ion-item>-->
    <!--              <ion-item :detail="false" lines="inset">-->
    <!--                <ion-label>Diabetische voering optioneel</ion-label>-->
    <!--                <ion-toggle-->
    <!--                    @ionChange="filterSpecs('6b5f1b8c-07de-4eaa-ae80-c2478bdd3f01')"-->
    <!--                    value="6b5f1b8c-07de-4eaa-ae80-c2478bdd3f01" :checked="false">-->
    <!--                </ion-toggle>-->
    <!--              </ion-item>-->
    <!--              <ion-item :detail="false" lines="inset">-->
    <!--                <ion-label>Leestmodificaties optioneel</ion-label>-->
    <!--                <ion-toggle-->
    <!--                    @ionChange="filterSpecs('464156af-b72d-4888-91ba-e4d0e2c4f88d')"-->
    <!--                    value="464156af-b72d-4888-91ba-e4d0e2c4f88d" :checked="false">-->
    <!--                </ion-toggle>-->
    <!--              </ion-item>-->
    <!--              <ion-item :detail="false" lines="inset">-->
    <!--                <ion-label>Schacht/onderwerk aanpassingen optioneel</ion-label>-->
    <!--                <ion-toggle-->
    <!--                    @ionChange="filterSpecs('38b996ba-d46c-40ba-8099-8140c501a3ec')"-->
    <!--                    value="38b996ba-d46c-40ba-8099-8140c501a3ec" :checked="false">-->
    <!--                </ion-toggle>-->
    <!--              </ion-item>-->
    <!--            </ion-list>-->

    <!--            <ion-list v-if="route.path.includes('osb')">-->
    <!--              <ion-list-header style="opacity:0.95">-->
    <!--                <ion-label>Merken</ion-label>-->
    <!--              </ion-list-header>-->
    <!--              <ion-item v-for="item in brands" :key="item?.value || item" :detail="false" lines="inset">-->
    <!--                <ion-label>{{-->
    <!--                    item.label-->
    <!--                  }}-->
    <!--                </ion-label>-->
    <!--                <ion-checkbox @update:modelValue="store.filterModels('brand', item.value)" checked="false" slot="start"-->
    <!--                              color="secondary" size="large"-->
    <!--                              :value="item.value">-->
    <!--                </ion-checkbox>-->
    <!--              </ion-item>-->
    <!--            </ion-list>-->

    <!--            <ion-list v-if="route.path.includes('osb')">-->
    <!--              <ion-item :detail="false" lines="inset">-->
    <!--                <ion-label>Prijsfilter</ion-label>-->
    <!--                <ion-toggle-->
    <!--                    @ionChange="setPricefilter"-->
    <!--                    :value="store.priceFilter" :checked="store.priceFilter">-->
    <!--                </ion-toggle>-->
    <!--              </ion-item>-->
    <!--            </ion-list>-->
    <!--          </ion-card>-->
    <!--        </div>-->
    <!--      </ion-content>-->
    <!--    </ion-menu>-->

    <ion-header condense="fade" :translucent="true">
      <ion-toolbar color="primary">

        <ion-buttons slot="start">
          <ion-back-button v-if="router.options.history.state.back" default-href="/home" style="color:white">
          </ion-back-button>
          <img tappable
               @click="() => router.options.history.state.back ? ionRouter.navigate('/home', 'back', 'push') : null"
               class="header-logo"
               alt="no-image" src="../assets/fittr_logo.png">
        </ion-buttons>

        <ion-title v-if="innerWidth > 450">
          <ion-label>

          </ion-label>
        </ion-title>

        <ion-buttons slot="end" style="align-self: center">

          <ion-searchbar v-if="innerWidth > 450" style="margin-right:20px;padding:0;align-self: center;"
                         inputmode="search"
                         @ionChange="searchModels($event)"/>

          <ion-button @click="showFavorites()" style="color:white">
            <div slot="icon-only">
              <i style="font-size: 24px;" class="fa-regular fa-heart"></i>
              <ion-badge style="position:absolute;top:0;right:0;margin-right:-3px" v-if="favorites.length > 0"
                         color="secondary">
                {{ favorites.length }}
              </ion-badge>
            </div>
          </ion-button>
          <ion-button v-if="router.options.history.state.back" style="color:white">
            <ion-menu-toggle menu="men" style="position:absolute;width:100%;height: 100%">
            </ion-menu-toggle>
            <i style="font-size: 24px;" class="fa-regular fa-cog"></i>
          </ion-button>
          <ion-button v-if="router.options.history.state.back" style="color:white">
            <div slot="icon-only">
              <i style="font-size: 24px;" class="fa-regular fa-bars"></i>
              <ion-menu-toggle style="position:absolute;width:100%;height: 100%">
              </ion-menu-toggle>
            </div>
          </ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>

    <ion-content :fullscreen="true">

      <div class="row" style="width: 100%;height: 100%">

        <q-scroll-area class="col-3" style="height: 100%;margin:0" :visible="false">
          <ModelPageFilter propName="styles"
                           title="Styles"
                           :items="styles"
                           :model="selectedStyles"/>

          <ModelPageFilter propName="height"
                           title="Schoen hoogte"
                           :items="heights"
                           :model="selectedHeight"/>

          <ModelPageFilter propName="closings"
                           title="Sluiting"
                           :items="closings"
                           :model="selectedClosings"/>

          <ModelPageFilter v-if="route.path.includes('osb')" propName="colors" title="Kleurtinten"
                           :model="selectedColors"
                           :items="colors"/>
        </q-scroll-area>

        <q-scroll-area class="col-9" style="height: 100%;margin:0" :visible="false">

          <div v-if="!store.imageLoadingState"
               class="row"
               style="width:100%;height:100%;">

            <ModelCardSkeleton v-for="i in 20" :key="'skel' + i"/>

          </div>

          <q-infinite-scroll @load="onLoad" v-else
                             class="row"
                             :style="store.imageLoadingState ? 'position:absolute;':'position:relative;'"
                             style="top:0;height:100%;width:100%;">

            <ModelCard v-for="item in store.models" :key="'card' + item?.modelID"
                       :item="item"
                       :setFavorite="setFavorite"
                       :isOpenRef="isOpenRef"
                       :setOpen="setOpen"
                       :dbModel="dbModel"/>

            <template v-slot:loading>
              <div class="col-12">
                <div class="row">
                  <ModelCardSkeleton/>
                  <ModelCardSkeleton/>
                  <ModelCardSkeleton/>
                </div>
              </div>
              <!--              <div class="col-12">-->
              <!--              <div class="row justify-center q-my-md">-->
              <!--                <q-spinner-dots color="primary" size="50px" />-->
              <!--              </div>-->
              <!--              </div>-->
            </template>
          </q-infinite-scroll>

        </q-scroll-area>

      </div>


      <!--    <ion-content :fullscreen="true" id="men" style="width: 100%;height: 100%">-->

      <!--      <ion-grid slot="fixed" style="height:100%;width:100%">-->

      <!--        <ion-row style="height:100%">-->
      <!--          &lt;!&ndash;  the side menu  &ndash;&gt;-->
      <!--          <ion-col style="height:100%" class="ion-hide-sm-down" size-sm="3" size-lg="3">-->

      <!--            <ion-content style="&#45;&#45;padding-start: 0;&#45;&#45;padding-end: 0">-->
      <!--              <ion-grid style="height:100%;width:100%">-->
      <!--                <ion-row style="height: 100%;">-->
      <!--                  <ion-col size="12">-->

      <!--                    <ModelPageFilter propName="styles"-->
      <!--                                     title="Styles"-->
      <!--                                     :items="styles"-->
      <!--                                     :model="selectedStyles"/>-->

      <!--                    <ModelPageFilter propName="height"-->
      <!--                                     title="Schoen hoogte"-->
      <!--                                     :items="heights"-->
      <!--                                     :model="selectedHeight"/>-->

      <!--                    <ModelPageFilter propName="closings"-->
      <!--                                     title="Sluiting"-->
      <!--                                     :items="closings"-->
      <!--                                     :model="selectedClosings"/>-->

      <!--                    <ModelPageFilter v-if="route.path.includes('osb')" propName="colors" title="Kleurtinten"-->
      <!--                                     :model="selectedColors"-->
      <!--                                     :items="colors"/>-->

      <!--                  </ion-col>-->
      <!--                </ion-row>-->
      <!--              </ion-grid>-->
      <!--            </ion-content>-->
      <!--          </ion-col>-->

      <!--          <ion-col size-xs="12"-->
      <!--                   size-sm="9"-->
      <!--                   size-lg="9"-->
      <!--                   style="height:100%;">-->
      <!--            <ion-content style="&#45;&#45;padding-start: 0;&#45;&#45;padding-end: 0;height: 100%;width: 100%">-->

      <!--              <ion-grid style="&#45;&#45;ion-grid-width:100%;height:auto;margin-bottom:200px">-->

      <!--                <ion-row :style="store.imageLoadingState ? 'z-index:0;opacity:0' : 'z-index:2;opacity:1'"-->
      <!--                         style="width:100%;height:100%;transition: opacity 0.5s;">-->

      <!--                  <ion-col v-for="i in 20" :key="'skel' + i"-->
      <!--                           size-xs="12"-->
      <!--                           size-sm="6"-->
      <!--                           size-md="6"-->
      <!--                           size-lg="4"-->
      <!--                           size-xl="4">-->

      <!--                    <ModelCardSkeleton/>-->

      <!--                  </ion-col>-->

      <!--                </ion-row>-->

      <!--                <ion-row-->
      <!--                    :style="store.imageLoadingState ? 'position:absolute;z-index:2;opacity:1':'position:relative;z-index:0;opacity:0'"-->
      <!--                    style="transition: opacity 0.5s;top:0;height:100%;width:100%;">-->

      <!--                  <ModelCard v-for="item in store.models" :key="'card' + item?.modelID"-->
      <!--                             :item="item"-->
      <!--                             :setFavorite="setFavorite"-->
      <!--                             :isOpenRef="isOpenRef"-->
      <!--                             :setOpen="setOpen"-->
      <!--                             :dbModel="dbModel"/>-->

      <!--                </ion-row>-->

      <!--              </ion-grid>-->
      <!--              <ion-infinite-scroll-->
      <!--                  position="bottom"-->
      <!--                  @ionInfinite="loadData($event)"-->
      <!--                  id="infinite-scroll"-->
      <!--                  :disabled="isDisabled"-->
      <!--                  style="height: 100px;z-index: 5000;width:100%"-->
      <!--              >-->
      <!--                <ion-infinite-scroll-content-->
      <!--                    loading-text="Loading more data...">-->
      <!--                </ion-infinite-scroll-content>-->
      <!--              </ion-infinite-scroll>-->
      <!--            </ion-content>-->

      <!--          </ion-col>-->

      <!--        </ion-row>-->

      <!--        <ModelModal v-if="store.isOpen"-->
      <!--                    :innerWidth="innerWidth"-->
      <!--                    :isOpenRef="isOpenRef"-->
      <!--                    :setOpen="setOpen"-->
      <!--                    :setFavorite="setFavorite"/>-->

      <!--      </ion-grid>-->
      <ModelModal v-if="store.isOpen"
                  :innerWidth="innerWidth"
                  :isOpenRef="isOpenRef"
                  :setOpen="setOpen"
                  :setFavorite="setFavorite"/>
      <!--    </ion-content>-->
    </ion-content>
  </ion-page>
</template>

<script setup>
// This starter template is using Vue 3 <script setup> SFCs
// Check out https://v3.vuejs.org/api/sfc-script-setup.html#sfc-script-setup
import {
  IonBackButton,
  IonBadge,
  IonButton,
  IonButtons,
  IonCheckbox,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonMenu,
  IonMenuToggle,
  IonPage,
  IonRow,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonSearchbar,
  IonTitle,
  IonCard,
  IonToggle,
  IonToolbar,
  menuController,
  onIonViewDidEnter,
  onIonViewWillEnter,
  useIonRouter,
} from '@ionic/vue';

import {useQuasar} from 'quasar';

import ModelPageFilter from "@/components/ModelpageFilter";
import ModelCard from "@/components/ModelCard";
import ModelCardSkeleton from "@/components/ModelCardSkeleton";
import ModelModal from "@/components/ModelModal";

import {ref} from "vue";

import {useRoute, useRouter} from "vue-router";

import {API, DataStore, Predicates, SortDirection} from "aws-amplify";

import {useGlobalStore} from "@/store/global";

import {
  DevFittrClosing,
  DevFittrColor,
  DevFittrHeight,
  DevFittrModel,
  DevFittrPriceLimit,
  DevFittrStyle,
  DevFootcareClosing,
  DevFootcareHealthcareProvider,
  DevFootcareModel,
  DevFootcarePriceLevel,
  DevFootcareStyle,
  DevFootcareSupplier,
  DevModelCollection,
} from "@/models";

import {getDevModelCollection} from "@/graphql/queries";

const models = ref([]);
const route = useRoute();
const $q = useQuasar();
const router = useRouter();
const ionRouter = useIonRouter();
const store = useGlobalStore();

// const dbModel = ref();
const favorites = ref([]);

const styles = ref([]);
const closings = ref([]);
const colors = ref([]);
const heights = ref([]);
const insteps = ref([]);
const priceLevels = ref([]);
const healthcareProviders = ref([]);
const suppliers = ref([]);
const brands = ref([]);

const isOpenRef = ref(false);

const searchRef = ref('');
const search = ref('');

const tempList = ref([]);

const innerWidth = ref(0);

const selectedSpecs = ref([]);
const selectedStyles = ref([]);
const selectedHeight = ref([]);
const selectedClosings = ref([]);
const selectedColors = ref([]);
const selectedPriceLevel = ref([]);

let dbModel;
let category;
let filter1;
let filter2;

store.initAppGlobals();

const setOpen = (state) => {
  isOpenRef.value = state;
  if (state === false) {
    store.favoritesOpen = false
  }
}

const fetchData = async () => {
  //
  // store.imageLoadingState = false;
  // store.filterLoadingState = false;

  const params = JSON.parse(route.params.collectionId);

  let list = [];
  if (router.options.history.state.back) {
    if (router.options.history.state.back !== '/home') {
      tempList.value = await DataStore.query(DevModelCollection, params.collectionId);
      list = JSON.parse(tempList.value.models);
      console.log('local data loaded')
    } else {
      list = store.modelCollection;
      console.log('store data loaded')
    }
  } else {
    const temp = await API.graphql({query: getDevModelCollection, variables: {id: params.collectionId}});
    tempList.value = temp.data.getDevModelCollection;
    list = JSON.parse(tempList.value.models);
    console.log('online data loaded')
  }

  // list = JSON.parse(list);
  if (route.path.includes("osb")) {
    for (let i = 0; i < list.length; i++) {
      let model = list[i];
      let mod = await DataStore.query(DevFittrModel, model.modelID);
      // console.log(mod);
      let prices;
      let price;
      prices = mod?.prices;
      if (prices.length > 0) {
        if (!Array.isArray(prices[0])) {
          try {
            let temp = JSON.parse(prices[0]);
            price = temp[0]?.price;
          } catch (e) {
            try {
              let temp = prices[0];
              price = temp[0]?.price;
            } catch (e) {
              console.log(prices[0])
            }
          }
        }
      }

      // if (!Array.isArray(mod?.prices)) {
      //   prices = JSON.parse(mod?.prices);
      //   if (!Array.isArray(prices)) {
      //     let temp = JSON.parse(prices);
      //     console.log(temp[0]);
      //     price = temp[0]?.price;
      //   } else if (Array.isArray(prices)) {
      //     console.log(prices[0]);
      //     price = prices[0]?.price;
      //   }
      //   console.log(price)
      // } else {
      //   prices = mod?.prices;
      //   if (!Array.isArray(prices)) {
      //     let temp = JSON.parse(prices);
      //     console.log(temp[0]);
      //     price = temp[0]?.price;
      //   } else if (Array.isArray(prices)) {
      //     let temp = JSON.parse(prices[0]);
      //     price = temp.price;
      //   }
      //   console.log(price)
      // }
      model.category = mod.category;
      model.modelnumber = mod.modelnumber;
      model.modelname = mod?.modelname;
      model.colorname = mod?.colorname;
      model.filename = mod.filename;
      model.brand = mod.brand;
      model.attributes = mod?.attributes;
      model.colornumber = mod?.colornumber;
      model.lasttypes = mod.lasttypes;
      model.sizerangeMin = mod.sizerangeMin;
      model.sizerangeMax = mod.sizerangeMax;
      model.price = price;
    }

    store.priceFilter = true;
    store.expensiveModels = list;

    if (router.options.history.state.back) {
      const priceLimitObj = await DataStore.query(DevFittrPriceLimit, 'cb29f55a-2cc9-4766-9042-c505c2cfdeeb');
      store.priceLimit = priceLimitObj.amount;
    } else {
      store.priceLimit = 500;
    }

    store.cheapModels = list.filter(model => parseInt(model.price) < parseInt(store.priceLimit) || !model.price);
    store.initialisedModels = store.priceFilter === true ? store.cheapModels : store.expensiveModels;

    // store.models = store.priceFilter === true ? store.cheapModels : store.expensiveModels;

    await loadFilters();

    store.imageLoadingState = true;
    store.filterLoadingState = true;
  } else {

    for (let i = 0; i < list.length; i++) {
      let model = list[i];
      let mod = await DataStore.query(DevFootcareModel, model.modelID);
      model.modelnumber = mod.modelnumber;
      model.filename = mod?.filename;
      model.attributes = mod.attributes;
      model.colornumber = mod.colornumber;
    }

    store.initialisedModels = list;
    // store.models = list;

    await loadFilters();

    store.imageLoadingState = true;
    store.filterLoadingState = true;

  }
}

const searchModels = async (value) => {

  store.imageLoadingState = false;

  const input = value.detail.value.toLowerCase();
  if (input !== "" && input !== null) {

    const modelsBySearch = new Set();

    store.initialisedModels.forEach(model => {
      let search = model?.modelnumber?.toString() + model?.colornumber?.toString() + model?.colorname?.toString() + model?.modelname?.toString()
      search = search.toLowerCase();
      if (search.includes(input)) {
        modelsBySearch.add(model);
      }
    });

    const searchResult = [...modelsBySearch];
    if (searchResult.length > 0) {
      store.models = searchResult.sort((firstEl, secondEl) => {
        return firstEl.modelnumber - secondEl.modelnumber
      }).sort((firstEl, secondEl) => {
        return firstEl.colornumber - secondEl.colornumber
      });
      items.value = store.models;
    } else {
      $q.notify({
        color: 'red-5',
        position: 'center',
        textColor: 'white',
        icon: 'fas fa-triangle-exclamation',
        message: 'Helaas, deze zoekopdracht geeft geen resultaten'
      })
      store.models = [];
      items.value = [];
      setTimeout(() => {
        if (store.models.length === 0) {
          value.target.value = '';
        }
      }, 6000)
    }

  } else {
    const searchResult = [...store.initialisedModels];
    store.models = searchResult.sort((firstEl, secondEl) => {
      return firstEl.modelnumber - secondEl.modelnumber
    }).sort((firstEl, secondEl) => {
      return firstEl.colornumber - secondEl.colornumber
    });
    items.value = store.models;
  }
  setTimeout(() => {
    store.imageLoadingState = true;
  }, 250)
}

const getFilterItems = async (table, param) => {
  const result = [];
  let uniques = store.findUniqueModelValue(param);
  for (let i = 0; i < uniques.length; i++) {
    let tempItem = await DataStore.query(table, uniques[i]);
    if (tempItem !== undefined && param !== 'instep_type') {
      let item = {id: tempItem['id'], item: tempItem.item};
      result.push(item);
    } else if (param === 'instep_type') {
      console.log(uniques[i])
    }
  }
  return result;
}

const loadFilters = async () => {

  if (route.path.includes("osa") && route.path.includes("models")) {

    styles.value = await getFilterItems(DevFootcareStyle, 'styles');
    heights.value = [{id: "laag", item: "laag", value: "laag"}, {id: "hoog", item: "hoog", value: "hoog"}];
    closings.value = await getFilterItems(DevFootcareClosing, 'closings');

    priceLevels.value = await DataStore.query(DevFootcarePriceLevel, Predicates.ALL, {
      sort: s => s.item(SortDirection.ASCENDING)
    });

    suppliers.value = await DataStore.query(DevFootcareSupplier, Predicates.ALL, {
      sort: s => s.item(SortDirection.ASCENDING)
    });

    healthcareProviders.value = await DataStore.query(DevFootcareHealthcareProvider, Predicates.ALL, {
      sort: s => s.item(SortDirection.ASCENDING)
    });

  } else if (route.path.includes("osb") && route.path.includes("models")) {

    colors.value = await getFilterItems(DevFittrColor, 'colors')
    styles.value = await getFilterItems(DevFittrStyle, 'styles')
    heights.value = await getFilterItems(DevFittrHeight, 'height')
    closings.value = await getFilterItems(DevFittrClosing, 'closings')

    brands.value = store.findUniqueModelValue('brand');
    const tempBrands = brands.value;
    brands.value = [];

    for (let i = 0; i < tempBrands.length; i++) {
      let brand = tempBrands[i];
      let obj = {
        value: brand,
        label: brand,
      }
      brands.value.push(obj);
    }

  }
}

const setActiveModel = (model) => {
  store.activeModel = model;
  store.model = model;
}

const setFavorite = (event, id) => {
  if (event.currentTarget.firstChild.className === 'fal fa-heart') {
    event.currentTarget.firstChild.className = 'fas fa-heart'
    favorites.value.push(id);
  } else {
    event.currentTarget.firstChild.className = 'fal fa-heart'
    for (let index = 0; index < favorites.value.length; index++) {
      const favoId = favorites.value[index];
      if (favoId === id) {
        favorites.value.splice(index, 1);
      }
    }

  }
  if (store.favoritesOpen === true) {
    showFavorites();
  }
}

function showFavorites() {
  if (favorites.value.length > 0) {
    store.isOpen = true;
    store.activeModels = [];
    for (let i = 0; i < favorites.value.length; i++) {
      const favoID = favorites.value[i];
      let temp = store.initialisedModels.find(model => model.modelID === favoID);
      store.activeModels.push(temp);
    }
    setActiveModel(store.activeModels[0]);
    setOpen(true);
    store.favoritesOpen = true;
  } else {
    $q.notify({
      color: 'red-5',
      position: 'center',
      textColor: 'white',
      icon: 'fas fa-triangle-exclamation',
      message: 'Er zijn nog geen favorieten geselecteerd!'
    })
    setOpen(false);
  }
}

function filterSpecs(spec) {
  let models;
  let newModels;
  if (selectedSpecs.value.indexOf(spec) === -1) {
    selectedSpecs.value.push(spec);
    models = store.models;
    newModels = models.filter(model => {
      const {includes} = model.attributes.specs;
      return includes(spec);
    })
    store.models = newModels;
  } else {
    selectedSpecs.value.splice(selectedSpecs.value.indexOf(spec), 1);
    store.models = store.initialisedModels;
  }
}

const setPricefilter = () => {
  if (store.priceFilter === true) {
    store.priceFilter = false
    store.initialisedModels = store.expensiveModels
    store.models = store.expensiveModels
  } else {
    store.priceFilter = true
    store.initialisedModels = store.cheapModels
    store.models = store.cheapModels
  }
}

if (route.path.includes("osa") && route.path.includes("models")) {
  dbModel = DevFootcareModel;
  filter1 = "modelnumber";
  filter2 = "colornumber";
} else if (route.path.includes("osb") && route.path.includes("models")) {
  dbModel = DevFittrModel;
  filter1 = "brand";
  filter2 = "modelnumber";
}

innerWidth.value = window.innerWidth;

onIonViewDidEnter(async () => {
  await fetchData();
  pushData();
});

const min = ref(0);
const max = ref(15);
const items = ref([]);

function pushData() {
  if (max.value < parseInt(store.initialisedModels.length - 1)) {
    max.value = max.value + 15;
  }
  if (max.value > parseInt(store.initialisedModels.length - 1)) {
    max.value = parseInt(store.initialisedModels.length - 1);
  }
  for (min.value; min.value < max.value; min.value++) {
    const model = store.initialisedModels[min.value];
    items.value.push(model);
  }
  store.models = items.value
}

//
// const loadData = (ev) => {
//   setTimeout(() => {
//     pushData();
//     console.log('Loaded data');
//     ev.target.complete();
//     // App logic to determine if all data is loaded
//     // and disable the infinite scroll
//     if (items.value.length === 1000) {
//       ev.target.disabled = true;
//     }
//   }, 500);
// }

const onLoad = (index, done) => {
  console.log('more items loaded')
  pushData();
  // done();
  console.log(store.initialisedModels)
  setTimeout(() => {
    done();
    // if (items.value.length === store.initialisedModels.length) {
    //   ev.target.disabled = true;
    // }
  }, 500)
}
</script>
<style>
.q-infinite-scroll__loading {
  width: 100%
}
</style>
<style scoped>

.searchbar-input {
  padding-top: unset;
  padding-bottom: unset;
}

ion-content {
  --padding-start: 0;
  --padding-end: 0;
}

ion-searchbar {
  min-width: 200px;
}

ion-col {
  margin: 0;
  padding: 0;
}

ion-card-title {
  font-size: 22px;
}

/*@media (min-width: 350px) {*/

/*}*/

/*!*###############################################################*!*/
/*@media (min-width: 576px) {*/

/*}*/

/*!*###############################################################*!*/
/*@media (min-width: 768px) {*/

/*}*/

/*!*###############################################################*!*/
/*@media (min-width: 992px) {*/

/*}*/

/*!*###############################################################*!*/
/*@media (min-width: 1024px) {*/

/*}*/

/*!*###############################################################*!*/
/*@media (min-width: 1150px) {*/

/*}*/

/*!*###############################################################*!*/
/*@media (min-width: 1281px) {*/

/*}*/

</style>
