<script setup>

import {
	IonButton,
	IonButtons,
	IonCard,
	IonCardContent,
	IonCardSubtitle,
	IonCardTitle,
	IonItem,
	IonToolbar,
	IonSpinner,
	IonCol,
} from '@ionic/vue'

import {QSpace, QTooltip, QImg} from "quasar";

import {useGlobalStore} from "@/store/global";

import {useRoute} from "vue-router";
import {DataStore} from "aws-amplify";

const store = useGlobalStore();

const props = defineProps({
	item: Object,
	dbModel: Function,
	setFavorite: Function,
	setOpen: Function
})

const route = useRoute();

const loadError = (event, filename) => {
	if (!event?.target?.src?.includes('modelImagesFittr')) {
		event.target.src = "https://d1mxpim4y30517.cloudfront.net/modelImagesFittr/" + filename
	}
}

const setImageLoadingState = () => {
	store.imageLoadingState = true;
}

const initModalModels = async (id) => {

	store.isOpen = true;

	store.activeModels = [];

	const model = store.initialisedModels.find(model => model.modelID === id);
	store.activeModel = model;
	store.model = model;
  console.log(model);
	store.activeModels.push(model);

	const modelFamily = await DataStore.query(props.dbModel, c => c.modelnumber('eq', model.modelnumber).colornumber('ne', model.colornumber));

	for (let i = 0; i < modelFamily.length; i++) {
		const modelFamilyItem = modelFamily[i];
		let temp = store.initialisedModels.find(model => model.modelID === modelFamilyItem.id);
		if (temp) store.activeModels.push(temp);
	}

	props.setOpen(true);

}

</script>

<template>
<!--	<ion-col-->
<!--			size-xs="12"-->
<!--			size-sm="6"-->
<!--			size-md="6"-->
<!--			size-lg="4"-->
<!--			size-xl="4">-->
  <div class="col-4">
		<ion-card button>
			<ion-toolbar style="--background:transparent">
				<ion-buttons style="padding-left: 8px;padding-right: 8px">
					<ion-button :id="'favo_' + item?.modelID" slot="start" shape="round"
					            @click="props.setFavorite($event, item?.modelID)">
						<i slot="icon-only" style="opacity:0.75;color:var(--ion-color-secondary);font-size: 26px"
						   class="fal fa-heart"></i>
					</ion-button>
					<q-space></q-space>
					<ion-button slot="end"
					            v-if="props?.item?.attributes?.specs?.indexOf('6849475f-b7f0-46b5-a9ec-6e5b8f790fe6') !== -1 && route?.path?.includes('osb')"
					            shape="round">
						<img slot="icon-only" alt="loading"
						     style="border-radius:75px;opacity:0.75;height: 26px;width:26px"
						     src="../assets/velcro.png"/>
						<q-tooltip max-width="50%" style="font-size: 16px">
							Dit model is ook te verkrijgen in klittenband uitvoering. Desgewenst, kunt u dit kenbaar
							maken in het order formulier.
						</q-tooltip>
					</ion-button>
				</ion-buttons>
			</ion-toolbar>

			<ion-card-content tappable style="padding-top: 0" @click="initModalModels(props?.item?.modelID)">

				<ion-item style="--inner-padding-end: 0;--padding-start: 0;z-index: 0">

										<q-img placeholder-src="../assets/img/blank.png"
										       style="margin-bottom:10px;"
										       :ratio="1"
                           no-spinner
										       loading="eager"
										       fit="contain"
										       class="rounded-borders"
										       @load="setImageLoadingState()"
										       :draggable="false"
										       :src="store.imagePath + 'medium_' + props?.item?.filename"
										       @error="loadError($event, props?.item?.filename)"
										       :alt="props?.item?.brand + ' ' + props?.item?.modelnumber + ' ' + props?.item?.colornumber || ''"/>
<!--											<template v-slot:loading>-->
<!--												<ion-spinner>-->
<!--												</ion-spinner>-->
<!--											</template>-->
<!--										</q-img>-->
				</ion-item>
				<!--			</q-intersection>-->

				<ion-card-title style="font-size:1.2em;color:var(--ion-color-primary);padding-left:10px;margin-top:5px">
                          <span>Model:
                        {{ props?.item?.modelnumber }}
                         </span>
				</ion-card-title>

				<ion-card-subtitle style="padding-left: 12px">
                          <span :style="[props?.item?.colornumber !== 'na' ? 'opacity:1' : 'opacity:0']">
                            {{ 'Kleur: ' + props?.item?.colornumber }}
                        </span>
				</ion-card-subtitle>
				<!--                        <ion-card-subtitle style="padding-left: 12px">-->
				<!--                          <span v-if="item.price"> Price:-->
				<!--                            {{ item.price }}-->
				<!--                        </span>-->
				<!--                        </ion-card-subtitle>-->

			</ion-card-content>
		</ion-card>
  </div>
<!--	</ion-col>-->
</template>

<style scoped>
ion-col {
	margin: 0;
	padding: 0;
}
</style>