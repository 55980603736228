<template>
	<div style="margin-left: 10%;width:80%">
		<q-input outlined
		         type="textarea"
		         :disabled="false"
		         :label="label"
		         v-model="selection"
		         :model-value="selection"
		         :name="name"
		         @update:model-value="update($event)"
		/>

		<div style="justify-content: flex-end;display: flex;flex-wrap: wrap">

			<div style="width: 100%;display:flex;justify-content: space-around">
				<div v-for="button in buttons">
					<ion-button color="secondary" :disabled="false" @click.self="(event) => {
			selection = button + '\n' + selection;
			event.target.disabled = true;
      update(selection)
		}">
						{{ button }}
					</ion-button>
					<ion-badge v-if="button !== 'Extra paar veters!'" color="danger" @click="presentAlertConfirm(button)">
						<i class="fal fa-times"></i>
					</ion-badge>
				</div>
			</div>

			<!--		<ion-fab>-->
			<ion-button style="margin-top:20px" color="success" @click="presentAlertPrompt">
				<i slot="icon-only" class="fal fa-plus"></i>
			</ion-button>
			<!--		</ion-fab>-->
		</div>
	</div>
	<!--  <p v-show="validation.errorMessage" v-text="validation.errorMessage"></p>-->
</template>

<script>
import {QInput, useQuasar} from "quasar"
import {defineComponent, ref} from "vue";
import {IonButton, IonLabel, alertController, IonBadge} from '@ionic/vue';

export default defineComponent({
	name: "FtrTextFieldOrder",
	components: {
		IonBadge,
		QInput,
		IonButton,
		IonLabel
	},
	props: {
		name: ref(),
		rules: ref(),
		options: ref([]),
		label: ref(),
		selected: ref([]),
	},
	setup(props, context) {
		const modelValue = ref();
		const selection = ref('');
		const $q = useQuasar();
		const buttons = ref();
		buttons.value = $q.localStorage.getItem('buttons');
		if (buttons.value === null) {
			buttons.value = ['Extra paar veters!'];
			$q.localStorage.set('buttons', buttons.value)
		}

		function update(value) {
			if (!modelValue.value?.includes(value)) {
				modelValue.value = value;
				context.emit("update:modelValue", value);
			}
		}

		function disableButton(event) {
			console.log(event);
		}

		(async () => {
			if (props.selected) {
				selection.value = props.selected;
				update(props.selected)
			}
		})();

		async function presentAlertPrompt() {
			const alert = await alertController
					.create({
						cssClass: 'my-custom-class',
						header: 'Welke text kan er op de button?',
						inputs: [
							{
								name: 'input',
								id: 'input',
								value: '',
								placeholder: 'Hou het zo kort mogelijk!',
							}
						],
						buttons: [
							{
								text: 'Cancel',
								role: 'cancel',
								cssClass: 'secondary',
								handler: () => {
									console.log('Confirm Cancel')
								},
							},
							{
								text: 'Ok',
								handler: (inputs) => {
									buttons.value.push(inputs.input);
									$q.localStorage.set('buttons', buttons.value)
								},
							},
						],
					});
			return alert.present();
		}
		async function presentAlertConfirm(button) {
			const alert = await alertController
					.create({
						cssClass: 'my-custom-class',
						header: 'Wil je deze button echt verwijderen?',
						buttons: [
							{
								text: 'Cancel',
								role: 'cancel',
								cssClass: 'secondary',
								id: 'cancel-button',
								handler: blah => {
									console.log('Confirm Cancel:', blah)
								},
							},
							{
								text: 'Okay',
								id: 'confirm-button',
								handler: () => {
									console.log(button)
									const temp = [];
									for (let i = 0; i < buttons.value.length; i++) {
										const item = buttons.value[i];
										if (item !== button) {
											temp.push(item)
										}
									}
									buttons.value = temp;
									$q.localStorage.set('buttons', buttons.value);
								},
							},
						],
					});
			return alert.present();
		};
		return {
			modelValue,
			selection,
			update,
			buttons,
			disableButton,
			presentAlertPrompt,
			presentAlertConfirm
		}
	}
});

</script>
<style scoped>
label {
	margin-left: 15px;
	margin-right: 15px;
	margin-bottom: 20px;
}

q-input label input {
	margin-bottom: 0;
}

</style>