import {defineStore} from 'pinia'
import {Network} from "@capacitor/network";
import {
    Auth,
    DataStore,
    SortDirection
} from "aws-amplify";
import {DevFittrOrder} from "@/models";
import {Capacitor} from '@capacitor/core';

export const useGlobalStore = defineStore('global', {
    state: () => {
        return {
            logged_in_user: undefined,
            priceFilter: true,
            category: '',
            signedIn: false,
            priceLimit: 0,
            brands: [],
            imageLoadingState: false,
            filterLoadingState: false,
            initialisedModels: [],
            models: [],
            initialisedModels: [],
            expensiveModels: [],
            submittedOrders: [],
            archivedOrders: [],
            cheapModels: [],
            activeModel: {},
            activeOrder: {},
            footData: {},
            lastProposals: {},
            filters: {
                styles: [],
                height: [],
                closings: [],
                colors: [],
                priceLevel: '',
                healthcareProviders: [],
                suppliers: [],
                brand: [],
            },
            selectedFilters: [],
            // shareUrl: 'https://dla6u8nfrb8y7.cloudfront.net/osb/models/',
            // shareUrl: 'https://fittrapp.com/osb/models/',
            shareUrl: 'https://test-app.footcaremaatschoenen.info/osb/models/',
            imagePath: '',
            isOpen: false,
            favoritesOpen: false,
        }
    },
    actions: {
        findUniqueModelValue(column) {
            const length = this.initialisedModels.length;
            const result = new Set();
            for (let i = 0; i < length; i++) {
                let model = this.initialisedModels[i];
                if (column === 'brand') {
                    result.add(model[column]);
                } else {
                    for (let j = 0; j < model.attributes?.[column]?.length; j++) {
                        result.add(model.attributes?.[column][j]);
                    }
                }
            }
            return [...result];
        },
        async filterModels(propName, it) {
            this.imageLoadingState = false;

            let val = this.filters[propName].indexOf(it);
            if (propName === 'priceLevel') {
                this.filters.priceLevel = [it];
            } else if (val === -1) {
                this.filters[propName].push(it);
            } else {
                this.filters[propName].splice(val, 1);
            }

            let filteredModels = [...this.initialisedModels];

            let modelsByStyle = new Set();
            if (this.filters?.styles?.length > 0) {
                this.filters.styles.forEach(filter => {
                    let result = filteredModels.filter(model => model.attributes['styles'].includes(filter))
                    result.forEach(model => modelsByStyle.add(model));
                })
                filteredModels = [...modelsByStyle];
            }

            let modelsByHeight = new Set();
            if (this.filters?.height?.length > 0) {
                this.filters.height.forEach(filter => {
                    let result = filteredModels.filter(model => model.attributes['height'].includes(filter))
                    result.forEach(model => modelsByHeight.add(model));
                })
                filteredModels = [...modelsByHeight];
            }

            let modelsByClosings = new Set();
            if (this.filters?.closings?.length > 0) {
                this.filters.closings.forEach(filter => {
                    let result = filteredModels.filter(model => model.attributes['closings'].includes(filter))
                    result.forEach(model => modelsByClosings.add(model));
                })
                filteredModels = [...modelsByClosings];
            }

            let modelsByColor = new Set();
            if (this.filters?.colors?.length > 0) {
                this.filters.colors.forEach(filter => {
                    let result = filteredModels.filter(model => model.attributes['colors'].includes(filter))
                    result.forEach(model => modelsByColor.add(model));
                })
                filteredModels = [...modelsByColor];
            }

            let modelsByBrand = new Set();
            if (this.filters?.brand?.length > 0) {
                this.filters?.brand?.forEach(filter => {
                    let result = filteredModels?.filter(model => model['brand']?.includes(filter))
                    result.forEach(model => modelsByBrand.add(model));
                })
                filteredModels = [...modelsByBrand];
            }

            let modelsByPriceLevel = new Set();
            if (this.filters?.priceLevel?.length > 0) {
                this.filters?.priceLevel?.forEach(filter => {
                    let result = filteredModels?.filter(model => model.attributes['priceLevel']?.includes(filter))
                    result.forEach(model => modelsByPriceLevel.add(model));
                })
                filteredModels = [...modelsByPriceLevel];
            }

            if (filteredModels?.length > 0) {
                filteredModels.sort((firstEl, secondEl) => {
                    return firstEl.modelnumber - secondEl.modelnumber
                }).sort((firstEl, secondEl) => {
                    return firstEl.colornumber - secondEl.colornumber
                })
                this.models = filteredModels;
                setTimeout(() => {
                    this.imageLoadingState = true;
                    return this.models.length
                },250);
                return this.models.length
            } else {
                this.models = [];
                this.imageLoadingState = true;
            }
        },
        async getSubmittedOrders() {
            this.submittedOrders = await DataStore.query(DevFittrOrder, c => c.status('contains', 'submitted'), {
                sort: s => s.updatedAt(SortDirection.DESCENDING)
            })
            return this.submittedOrders
        },
        async getArchivedOrders() {
            const user = await Auth.currentAuthenticatedUser();
            this.archivedOrders = await DataStore.query(DevFittrOrder, c => c.status('contains', 'archived').userName('contains', user.attributes.email), {
                sort: s => s.updatedAt(SortDirection.DESCENDING)
            });
            return this.archivedOrders
        },
        async searchSubmittedOrders(value) {
            if (value === "") {
                await this.getSubmittedOrders();
            } else {
                const lowerCased = value.replaceAll(" ", "").toLowerCase();
                this.submittedOrders = await DataStore.query(DevFittrOrder, c => c.search("contains", lowerCased).status('contains', 'submitted'), {
                    sort: s => s.updatedAt(SortDirection.DESCENDING)
                });
            }
        },
        async searchArchivedOrders(value) {
            const user = await Auth.currentAuthenticatedUser();
            console.log(value);
            if (value === "") {
                await this.getArchivedOrders();
            } else {
                const lowerCased = value.replaceAll(" ", "").toLowerCase();
                this.archivedOrders = await DataStore.query(DevFittrOrder, c => c.search("contains", lowerCased).status('contains', 'archived').userName('contains', user.attributes.email), {
                    sort: s => s.updatedAt(SortDirection.DESCENDING)
                });
            }
        },
        async initAppGlobals() {
            Network.addListener('networkStatusChange', async status => {
                console.log('Network status changed', status);
                const result = await Network.getStatus();
                this.imagePath = result.connected ? "https://d1mxpim4y30517.cloudfront.net/modelImagesFittr/" : Capacitor.convertFileSrc(window.location.origin + "/img/content/")
            });
            const result = await Network.getStatus();
            this.imagePath = result.connected ? "https://d1mxpim4y30517.cloudfront.net/modelImagesFittr/" : Capacitor.convertFileSrc(window.location.origin + "/img/content/")
        },
    },
})