<template>
  <div style="width: 100%;display: flex;margin-top: 30px;">
    <div style="width: 40%;margin-top: 6px;margin-left: 4%;">
      <!--      <div style="margin-top:-40px;margin-bottom: 10px;width:100%;display: flex;flex-direction: row;position: absolute">-->
      <!--        <q-badge color="warning">Maatinvoer in millimeters!</q-badge>-->
      <!--      </div>-->
      <q-input
          input-class="text-right"
          inputmode="numeric"
          :label="label"
          ref="inputRef"
          v-model="model"
          type="text"
          :name="name"
          input-align
          :mask="mask"
          suffix="mm"
          :rules="rules"
          lazy-rules/>
    </div>
    <div style="width: 45%;margin-left: 6%;margin-right: 2%;margin-top: -7px">
      <div style="position: absolute;height: 40px;width: calc(45% - 38px);border-radius: 5px">
        <img alt=""
             src="../assets/img/tape6.png"
             style="z-index:0;position:absolute;width: 100%;height: 56px;margin-top:5px;border-radius: 5px">
      </div>
      <div style="width: 100%;z-index: 200">
        <q-field
            style="padding:0;margin: 0"
            borderless
            bottom-slots
            :model-value="value"
            v-model="value"
        >
          <template v-slot:control>
            <ion-range mode="ios" v-model="value" :name="name2" :model-value="value" :pin="true"
                       :pin-formatter="customFormatter" snaps="true" :ticks="false" :value="12" :min="0" :max="20"
                       step="1"
                       style="left:1px; padding:0; margin:10px 0 0 0; position:absolute; right:-4px">
            </ion-range>
          </template>
          <template v-slot:hint>
            <div>{{ 'overmaat' }}</div>
            <!--&lt;!&ndash;            <div class="thumb"&ndash;&gt;-->
            <!--&lt;!&ndash;                 style="margin-left:-2px;width: 100%;top: 10px;display: flex;align-items: center;justify-content: left">&ndash;&gt;-->
            <!--              &lt;!&ndash;              <i class="fal fa-arrow-left"></i>&ndash;&gt;-->
            <!--              &lt;!&ndash;              <i style="margin: 5px" class="fa-solid fa-fingerprint"></i>&ndash;&gt;-->
            <!--              &lt;!&ndash;              <i class="fal fa-arrow-right"></i>&ndash;&gt;-->
            <!--              Overmaat-->
            <!--            </div>-->
          </template>
        </q-field>
      </div>
    </div>
  </div>
</template>

<script>

import {
  IonRange
} from '@ionic/vue'

import {
  QInput,
  QSlider,
  QBtn,
  QField
} from "quasar";

import {defineComponent, ref} from "vue";

export default defineComponent({
  name: "FtrFootLengthInput",
  components: {
    QInput,
    QSlider,
    QBtn,
    QField,
    IonRange
  },
  props: {
    label: ref(),
    mask: ref(),
    rules: ref(),
    name: ref(),
    name2: ref(),
  },
  // methods: {
  //   update(event) {
  //     console.log(event)
  //     this.$emit("update:modelValue", event);
  //   }
  // },
  setup() {
    const value = ref(12);
    const model = ref('');
    const rangeLabel = ref('');
    const customFormatter = () => value.value + " mm";
    return {
      value,
      model,
      rangeLabel,
      customFormatter
    }
  }
})

</script>
<style>
ion-range::part(knob) {
  background-image: url("../assets/img/fingerprintje.png");
  background-size: 30px;
  background-repeat: no-repeat;
  background-position: 50%;
  width: 30px;
  box-shadow: 0 8px 5px rgba(0, 0, 0, 0.1), 0 4px 8px rgba(0, 0, 0, 0.13), 0 0 0 1px rgba(0, 0, 0, 0.02);
  height: 30px;
  padding: 0;
}

ion-range::part(bar) {
  opacity: 0;
}

ion-range::part(bar-active) {
  opacity: 0;
}

ion-range::part(pin) {
  transform: translate3d(0px, 0px, 0px) scale(1);
  padding: 8px;
  display: inline-block;
  position: relative;
  top: -30px;
  min-width: 28px;
  transition: unset;
  background: transparent;
  color: var(--ion-text-color, #000);
  font-size: 12px;
  text-align: center;
}

.q-field--standard .q-field__control {
  /*border-bottom: dashed 1px;*/
}

.q-slider .q-slider__track-container--h {
  opacity: 0;
}
</style>
<style scoped>
.q-field--standard .q-field__control {
  /*border-bottom: dashed 1px;*/
}

/*.q-field--standard.q-field--highlighted .q-field__control:after {
  transform: scale3d(1, -1, 1);
}*/

.custom-colored-range--zoom .q-slider__track-container--h {
  background: linear-gradient(to right, rgba(0, 0, 0, 0.26) 0%, rgba(0, 0, 0, 0.26) 100%), var(--track-bg), var(--tick-bg);
  background-repeat: no-repeat;
  background-position: 0 9px, 0 9px, var(--tick-bg-pos);
  background-size: 100% 2px, 100% 2px, var(--tick-bg-size);
  background-blend-mode: soft-light;
  margin-top: -7px;
  height: 20px;
  overflow: visible !important;
}

.custom-colored-range--zoom .q-slider__track--h {
  top: 9px;
  bottom: 9px;
  border: 1px solid currentColor;
  border-radius: 10px;
  background: transparent;
  transition: width 0.28s, left 0.28s, right 0.28s, top 0.28s, bottom 0.28s, border-width 0.28s;
}

.custom-colored-range--zoom.q-slider--active .q-slider__track--h {
  top: 5px;
  bottom: 5px;
  border-width: 3px;
  transition: top 0.28s, bottom 0.28s, border-width 0.28s;
}

.q-slider .q-slider__track-container--h {
  opacity: 0;
}

/*.q-slider .q-slider__thumb-container {
  opacity: 1;
  background-image: url(../assets/img/thumb-indicator.png);
  background-size: 100%;
  background-repeat: no-repeat;
  height: 20px;
  width: 50px;
  position: absolute;
}*/
.thumb {
  width: 100%;
  opacity: 1;
  background-image: url(../assets/img/fingerprint-indi2.tiff);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 91% 50%;
  padding-left: 5px;
  padding-right: 5px;
  height: 15px;
  margin-left: 50px;
  margin-top: 5px;
  position: absolute;
}

.q-slider .q-field__control {
  height: 46px;
}

label {
  width: 100%;
  margin-left: 2%;
  margin-right: 2%;
  margin-bottom: 10px;
}

</style>