import {DataStore} from "aws-amplify";
import {DevFittrModel} from "@/models";

const models = [
    {
        "modelnumber": "4601",
        "colornumber": "na",
        "brand": "LuCro",
        "lastgroup": "lucro-heren-urbanic",
        "filename": "",
        "category": "m",
        "modelname": "Jannes",
        "attributes": {
            "closings": [
                "06cfde34-87b0-428d-8e4b-1b2834194552"
            ],
            "colorname": "zwart",
            "height": [
                "574c17d0-682d-40a5-a982-17ffae7b3015"
            ],
            "supplier": "Schulte BV - Schein Orthopädie Service KG",
            "modelname": "Jannes"
        }
    },
    {
        "modelnumber": "4603",
        "colornumber": "na",
        "brand": "LuCro",
        "lastgroup": "lucro-heren-urbanic",
        "filename": "",
        "category": "m",
        "modelname": "Jannes",
        "attributes": {
            "closings": [
                "06cfde34-87b0-428d-8e4b-1b2834194552"
            ],
            "colorname": "bruin",
            "height": [
                "574c17d0-682d-40a5-a982-17ffae7b3015"
            ],
            "supplier": "Schulte BV - Schein Orthopädie Service KG",
            "modelname": "Jannes"
        }
    },
    {
        "modelnumber": "4608",
        "colornumber": "na",
        "brand": "LuCro",
        "lastgroup": "lucro-heren-urbanic",
        "filename": "",
        "category": "m",
        "modelname": "Jannes",
        "attributes": {
            "closings": [
                "06cfde34-87b0-428d-8e4b-1b2834194552"
            ],
            "colorname": "blauw",
            "height": [
                "574c17d0-682d-40a5-a982-17ffae7b3015"
            ],
            "supplier": "Schulte BV - Schein Orthopädie Service KG",
            "modelname": "Jannes"
        }
    },
    {
        "modelnumber": "4617",
        "colornumber": "na",
        "brand": "LuCro",
        "lastgroup": "lucro-heren-urbanic",
        "filename": "",
        "category": "m",
        "modelname": "Marcel",
        "attributes": {
            "closings": [
                "72589120-2085-4a19-bd1e-899c5a098a5f"
            ],
            "colorname": "zwart",
            "height": [
                "574c17d0-682d-40a5-a982-17ffae7b3015"
            ],
            "supplier": "Schulte BV - Schein Orthopädie Service KG",
            "modelname": "Marcel"
        }
    },
    {
        "modelnumber": "4618",
        "colornumber": "na",
        "brand": "LuCro",
        "lastgroup": "lucro-heren-urbanic",
        "filename": "",
        "category": "m",
        "modelname": "Marcel",
        "attributes": {
            "closings": [
                "72589120-2085-4a19-bd1e-899c5a098a5f"
            ],
            "colorname": "blauw",
            "height": [
                "574c17d0-682d-40a5-a982-17ffae7b3015"
            ],
            "supplier": "Schulte BV - Schein Orthopädie Service KG",
            "modelname": "Marcel"
        }
    },
    {
        "modelnumber": "4619",
        "colornumber": "na",
        "brand": "LuCro",
        "lastgroup": "lucro-heren-urbanic",
        "filename": "",
        "category": "m",
        "modelname": "Marcel",
        "attributes": {
            "closings": [
                "72589120-2085-4a19-bd1e-899c5a098a5f"
            ],
            "colorname": "grijs",
            "height": [
                "574c17d0-682d-40a5-a982-17ffae7b3015"
            ],
            "supplier": "Schulte BV - Schein Orthopädie Service KG",
            "modelname": "Marcel"
        }
    },
    {
        "modelnumber": "4620",
        "colornumber": "na",
        "brand": "LuCro",
        "lastgroup": "lucro-heren-urbanic",
        "filename": "",
        "category": "m",
        "modelname": "Elias",
        "attributes": {
            "closings": [
                "06cfde34-87b0-428d-8e4b-1b2834194552"
            ],
            "colorname": "zwart",
            "height": [
                "574c17d0-682d-40a5-a982-17ffae7b3015"
            ],
            "supplier": "Schulte BV - Schein Orthopädie Service KG",
            "modelname": "Elias"
        }
    },
    {
        "modelnumber": "4622",
        "colornumber": "na",
        "brand": "LuCro",
        "lastgroup": "lucro-heren-urbanic",
        "filename": "",
        "category": "m",
        "modelname": "Elias",
        "attributes": {
            "closings": [
                "06cfde34-87b0-428d-8e4b-1b2834194552"
            ],
            "colorname": "grijs",
            "height": [
                "574c17d0-682d-40a5-a982-17ffae7b3015"
            ],
            "supplier": "Schulte BV - Schein Orthopädie Service KG",
            "modelname": "Elias"
        }
    },
    {
        "modelnumber": "4628",
        "colornumber": "na",
        "brand": "LuCro",
        "lastgroup": "lucro-heren-urbanic",
        "filename": "",
        "category": "m",
        "modelname": "Elias",
        "attributes": {
            "closings": [
                "06cfde34-87b0-428d-8e4b-1b2834194552"
            ],
            "colorname": "blauw",
            "height": [
                "574c17d0-682d-40a5-a982-17ffae7b3015"
            ],
            "supplier": "Schulte BV - Schein Orthopädie Service KG",
            "modelname": "Elias"
        }
    },
    {
        "modelnumber": "4629",
        "colornumber": "na",
        "brand": "LuCro",
        "lastgroup": "lucro-heren-urbanic",
        "filename": "",
        "category": "m",
        "modelname": "Elias",
        "attributes": {
            "closings": [
                "06cfde34-87b0-428d-8e4b-1b2834194552"
            ],
            "colorname": "grijs",
            "height": [
                "574c17d0-682d-40a5-a982-17ffae7b3015"
            ],
            "supplier": "Schulte BV - Schein Orthopädie Service KG",
            "modelname": "Elias"
        }
    },
    {
        "modelnumber": "4632",
        "colornumber": "na",
        "brand": "LuCro",
        "lastgroup": "lucro-heren-urbanic",
        "filename": "",
        "category": "m",
        "modelname": "Rocco",
        "attributes": {
            "closings": [
                "72589120-2085-4a19-bd1e-899c5a098a5f"
            ],
            "colorname": "blauw",
            "height": [
                "574c17d0-682d-40a5-a982-17ffae7b3015"
            ],
            "supplier": "Schulte BV - Schein Orthopädie Service KG",
            "modelname": "Rocco"
        }
    },
    {
        "modelnumber": "4637",
        "colornumber": "na",
        "brand": "LuCro",
        "lastgroup": "lucro-heren-urbanic",
        "filename": "",
        "category": "m",
        "modelname": "Rocco",
        "attributes": {
            "closings": [
                "72589120-2085-4a19-bd1e-899c5a098a5f"
            ],
            "colorname": "zwart",
            "height": [
                "574c17d0-682d-40a5-a982-17ffae7b3015"
            ],
            "supplier": "Schulte BV - Schein Orthopädie Service KG",
            "modelname": "Rocco"
        }
    },
    {
        "modelnumber": "4638",
        "colornumber": "na",
        "brand": "LuCro",
        "lastgroup": "lucro-heren-urbanic",
        "filename": "",
        "category": "m",
        "modelname": "Rocco",
        "attributes": {
            "closings": [
                "72589120-2085-4a19-bd1e-899c5a098a5f"
            ],
            "colorname": "blauw",
            "height": [
                "574c17d0-682d-40a5-a982-17ffae7b3015"
            ],
            "supplier": "Schulte BV - Schein Orthopädie Service KG",
            "modelname": "Rocco"
        }
    },
    {
        "modelnumber": "4639",
        "colornumber": "na",
        "brand": "LuCro",
        "lastgroup": "lucro-heren-urbanic",
        "filename": "",
        "category": "m",
        "modelname": "Rocco",
        "attributes": {
            "closings": [
                "72589120-2085-4a19-bd1e-899c5a098a5f"
            ],
            "colorname": "grijs",
            "height": [
                "574c17d0-682d-40a5-a982-17ffae7b3015"
            ],
            "supplier": "Schulte BV - Schein Orthopädie Service KG",
            "modelname": "Rocco"
        }
    },
    {
        "modelnumber": "4642",
        "colornumber": "na",
        "brand": "LuCro",
        "lastgroup": "lucro-heren-urbanic",
        "filename": "",
        "category": "m",
        "modelname": "Hank",
        "attributes": {
            "closings": [
                "72589120-2085-4a19-bd1e-899c5a098a5f"
            ],
            "colorname": "zwart",
            "height": [
                "574c17d0-682d-40a5-a982-17ffae7b3015"
            ],
            "supplier": "Schulte BV - Schein Orthopädie Service KG",
            "modelname": "Hank"
        }
    },
    {
        "modelnumber": "4647",
        "colornumber": "na",
        "brand": "LuCro",
        "lastgroup": "lucro-heren-urbanic",
        "filename": "",
        "category": "m",
        "modelname": "Hank",
        "attributes": {
            "closings": [
                "72589120-2085-4a19-bd1e-899c5a098a5f"
            ],
            "colorname": "grijs",
            "height": [
                "574c17d0-682d-40a5-a982-17ffae7b3015"
            ],
            "supplier": "Schulte BV - Schein Orthopädie Service KG",
            "modelname": "Hank"
        }
    },
    {
        "modelnumber": "4649",
        "colornumber": "na",
        "brand": "LuCro",
        "lastgroup": "lucro-heren-urbanic",
        "filename": "",
        "category": "m",
        "modelname": "Hank",
        "attributes": {
            "closings": [
                "72589120-2085-4a19-bd1e-899c5a098a5f"
            ],
            "colorname": "grijs",
            "height": [
                "574c17d0-682d-40a5-a982-17ffae7b3015"
            ],
            "supplier": "Schulte BV - Schein Orthopädie Service KG",
            "modelname": "Hank"
        }
    },
    {
        "modelnumber": "4655",
        "colornumber": "na",
        "brand": "LuCro",
        "lastgroup": "lucro-heren-urbanic",
        "filename": "",
        "category": "m",
        "modelname": "Mark",
        "attributes": {
            "closings": [
                "06cfde34-87b0-428d-8e4b-1b2834194552"
            ],
            "colorname": "cognac",
            "height": [
                "574c17d0-682d-40a5-a982-17ffae7b3015"
            ],
            "supplier": "Schulte BV - Schein Orthopädie Service KG",
            "modelname": "Mark"
        }
    },
    {
        "modelnumber": "4657",
        "colornumber": "na",
        "brand": "LuCro",
        "lastgroup": "lucro-heren-urbanic",
        "filename": "",
        "category": "m",
        "modelname": "Mark",
        "attributes": {
            "closings": [
                "06cfde34-87b0-428d-8e4b-1b2834194552"
            ],
            "colorname": "zwart",
            "height": [
                "574c17d0-682d-40a5-a982-17ffae7b3015"
            ],
            "supplier": "Schulte BV - Schein Orthopädie Service KG",
            "modelname": "Mark"
        }
    },
    {
        "modelnumber": "4659",
        "colornumber": "na",
        "brand": "LuCro",
        "lastgroup": "lucro-heren-urbanic",
        "filename": "",
        "category": "m",
        "modelname": "Mark",
        "attributes": {
            "closings": [
                "06cfde34-87b0-428d-8e4b-1b2834194552"
            ],
            "colorname": "zwart",
            "height": [
                "574c17d0-682d-40a5-a982-17ffae7b3015"
            ],
            "supplier": "Schulte BV - Schein Orthopädie Service KG",
            "modelname": "Mark"
        }
    },
    {
        "modelnumber": "4663",
        "colornumber": "na",
        "brand": "LuCro",
        "lastgroup": "lucro-heren-urbanic",
        "filename": "",
        "category": "m",
        "modelname": "Hauke",
        "attributes": {
            "closings": [
                "72589120-2085-4a19-bd1e-899c5a098a5f"
            ],
            "colorname": "bruin",
            "height": [
                "574c17d0-682d-40a5-a982-17ffae7b3015"
            ],
            "supplier": "Schulte BV - Schein Orthopädie Service KG",
            "modelname": "Hauke"
        }
    },
    {
        "modelnumber": "4665",
        "colornumber": "na",
        "brand": "LuCro",
        "lastgroup": "lucro-heren-urbanic",
        "filename": "",
        "category": "m",
        "modelname": "Hauke",
        "attributes": {
            "closings": [
                "72589120-2085-4a19-bd1e-899c5a098a5f"
            ],
            "colorname": "bruin",
            "height": [
                "574c17d0-682d-40a5-a982-17ffae7b3015"
            ],
            "supplier": "Schulte BV - Schein Orthopädie Service KG",
            "modelname": "Hauke"
        }
    },
    {
        "modelnumber": "4667",
        "colornumber": "na",
        "brand": "LuCro",
        "lastgroup": "lucro-heren-urbanic",
        "filename": "",
        "category": "m",
        "modelname": "Hauke",
        "attributes": {
            "closings": [
                "72589120-2085-4a19-bd1e-899c5a098a5f"
            ],
            "colorname": "zwart",
            "height": [
                "574c17d0-682d-40a5-a982-17ffae7b3015"
            ],
            "supplier": "Schulte BV - Schein Orthopädie Service KG",
            "modelname": "Hauke"
        }
    },
    {
        "modelnumber": "4669",
        "colornumber": "na",
        "brand": "LuCro",
        "lastgroup": "lucro-heren-urbanic",
        "filename": "",
        "category": "m",
        "modelname": "Hauke",
        "attributes": {
            "closings": [
                "72589120-2085-4a19-bd1e-899c5a098a5f"
            ],
            "colorname": "grijs",
            "height": [
                "574c17d0-682d-40a5-a982-17ffae7b3015"
            ],
            "supplier": "Schulte BV - Schein Orthopädie Service KG",
            "modelname": "Hauke"
        }
    },
    {
        "modelnumber": "4670",
        "colornumber": "na",
        "brand": "LuCro",
        "lastgroup": "lucro-heren-urbanic",
        "filename": "",
        "category": "m",
        "modelname": "Marvin",
        "attributes": {
            "closings": [
                "72589120-2085-4a19-bd1e-899c5a098a5f"
            ],
            "colorname": "zwart",
            "height": [
                "574c17d0-682d-40a5-a982-17ffae7b3015"
            ],
            "supplier": "Schulte BV - Schein Orthopädie Service KG",
            "modelname": "Marvin"
        }
    },
    {
        "modelnumber": "4675",
        "colornumber": "na",
        "brand": "LuCro",
        "lastgroup": "lucro-heren-urbanic",
        "filename": "",
        "category": "m",
        "modelname": "Marvin",
        "attributes": {
            "closings": [
                "72589120-2085-4a19-bd1e-899c5a098a5f"
            ],
            "colorname": "cognac",
            "height": [
                "574c17d0-682d-40a5-a982-17ffae7b3015"
            ],
            "supplier": "Schulte BV - Schein Orthopädie Service KG",
            "modelname": "Marvin"
        }
    },
    {
        "modelnumber": "4678",
        "colornumber": "na",
        "brand": "LuCro",
        "lastgroup": "lucro-heren-urbanic",
        "filename": "",
        "category": "m",
        "modelname": "Marvin",
        "attributes": {
            "closings": [
                "72589120-2085-4a19-bd1e-899c5a098a5f"
            ],
            "colorname": "blauw",
            "height": [
                "574c17d0-682d-40a5-a982-17ffae7b3015"
            ],
            "supplier": "Schulte BV - Schein Orthopädie Service KG",
            "modelname": "Marvin"
        }
    },
    {
        "modelnumber": "4679",
        "colornumber": "na",
        "brand": "LuCro",
        "lastgroup": "lucro-heren-urbanic",
        "filename": "",
        "category": "m",
        "modelname": "Marvin",
        "attributes": {
            "closings": [
                "72589120-2085-4a19-bd1e-899c5a098a5f"
            ],
            "colorname": "grijs",
            "height": [
                "574c17d0-682d-40a5-a982-17ffae7b3015"
            ],
            "supplier": "Schulte BV - Schein Orthopädie Service KG",
            "modelname": "Marvin"
        }
    },
    {
        "modelnumber": "4683",
        "colornumber": "na",
        "brand": "LuCro",
        "lastgroup": "lucro-heren-urbanic",
        "filename": "",
        "category": "m",
        "modelname": "Lukas",
        "attributes": {
            "closings": [
                "72589120-2085-4a19-bd1e-899c5a098a5f"
            ],
            "colorname": "bruin",
            "height": [
                "574c17d0-682d-40a5-a982-17ffae7b3015"
            ],
            "supplier": "Schulte BV - Schein Orthopädie Service KG",
            "modelname": "Lukas"
        }
    },
    {
        "modelnumber": "4685",
        "colornumber": "na",
        "brand": "LuCro",
        "lastgroup": "lucro-heren-urbanic",
        "filename": "",
        "category": "m",
        "modelname": "Lukas",
        "attributes": {
            "closings": [
                "72589120-2085-4a19-bd1e-899c5a098a5f"
            ],
            "colorname": "beige",
            "height": [
                "574c17d0-682d-40a5-a982-17ffae7b3015"
            ],
            "supplier": "Schulte BV - Schein Orthopädie Service KG",
            "modelname": "Lukas"
        }
    },
    {
        "modelnumber": "4690",
        "colornumber": "na",
        "brand": "LuCro",
        "lastgroup": "lucro-heren-urbanic",
        "filename": "",
        "category": "m",
        "modelname": "Lennard",
        "attributes": {
            "closings": [
                "72589120-2085-4a19-bd1e-899c5a098a5f"
            ],
            "colorname": "zwart",
            "height": [
                "574c17d0-682d-40a5-a982-17ffae7b3015"
            ],
            "supplier": "Schulte BV - Schein Orthopädie Service KG",
            "modelname": "Lennard"
        }
    },
    {
        "modelnumber": "4692",
        "colornumber": "na",
        "brand": "LuCro",
        "lastgroup": "lucro-heren-urbanic",
        "filename": "",
        "category": "m",
        "modelname": "Lennard",
        "attributes": {
            "closings": [
                "72589120-2085-4a19-bd1e-899c5a098a5f"
            ],
            "colorname": "grijs",
            "height": [
                "574c17d0-682d-40a5-a982-17ffae7b3015"
            ],
            "supplier": "Schulte BV - Schein Orthopädie Service KG",
            "modelname": "Lennard"
        }
    },
    {
        "modelnumber": "4695",
        "colornumber": "na",
        "brand": "LuCro",
        "lastgroup": "lucro-heren-urbanic",
        "filename": "",
        "category": "m",
        "modelname": "Lennard",
        "attributes": {
            "closings": [
                "72589120-2085-4a19-bd1e-899c5a098a5f"
            ],
            "colorname": "bruin",
            "height": [
                "574c17d0-682d-40a5-a982-17ffae7b3015"
            ],
            "supplier": "Schulte BV - Schein Orthopädie Service KG",
            "modelname": "Lennard"
        }
    },
    {
        "modelnumber": "4698",
        "colornumber": "na",
        "brand": "LuCro",
        "lastgroup": "lucro-heren-urbanic",
        "filename": "",
        "category": "m",
        "modelname": "Lennard",
        "attributes": {
            "closings": [
                "72589120-2085-4a19-bd1e-899c5a098a5f"
            ],
            "colorname": "blauw",
            "height": [
                "574c17d0-682d-40a5-a982-17ffae7b3015"
            ],
            "supplier": "Schulte BV - Schein Orthopädie Service KG",
            "modelname": "Lennard"
        }
    },
    {
        "modelnumber": "4700",
        "colornumber": "na",
        "brand": "LuCro",
        "lastgroup": "lucro-dames-urbanic",
        "filename": "",
        "category": "f",
        "modelname": "Alexis",
        "attributes": {
            "closings": [
                "06cfde34-87b0-428d-8e4b-1b2834194552"
            ],
            "colorname": "zwart",
            "height": [
                "574c17d0-682d-40a5-a982-17ffae7b3015"
            ],
            "supplier": "Schulte BV - Schein Orthopädie Service KG",
            "modelname": "Alexis"
        }
    },
    {
        "modelnumber": "4701",
        "colornumber": "na",
        "brand": "LuCro",
        "lastgroup": "lucro-dames-urbanic",
        "filename": "",
        "category": "f",
        "modelname": "Alexis",
        "attributes": {
            "closings": [
                "06cfde34-87b0-428d-8e4b-1b2834194552"
            ],
            "colorname": "wit",
            "height": [
                "574c17d0-682d-40a5-a982-17ffae7b3015"
            ],
            "supplier": "Schulte BV - Schein Orthopädie Service KG",
            "modelname": "Alexis"
        }
    },
    {
        "modelnumber": "4702",
        "colornumber": "na",
        "brand": "LuCro",
        "lastgroup": "lucro-dames-urbanic",
        "filename": "",
        "category": "f",
        "modelname": "Alexis",
        "attributes": {
            "closings": [
                "06cfde34-87b0-428d-8e4b-1b2834194552"
            ],
            "colorname": "grijs",
            "height": [
                "574c17d0-682d-40a5-a982-17ffae7b3015"
            ],
            "supplier": "Schulte BV - Schein Orthopädie Service KG",
            "modelname": "Alexis"
        }
    },
    {
        "modelnumber": "4705",
        "colornumber": "na",
        "brand": "LuCro",
        "lastgroup": "lucro-dames-urbanic",
        "filename": "",
        "category": "f",
        "modelname": "Alexis",
        "attributes": {
            "closings": [
                "06cfde34-87b0-428d-8e4b-1b2834194552"
            ],
            "colorname": "wit",
            "height": [
                "574c17d0-682d-40a5-a982-17ffae7b3015"
            ],
            "supplier": "Schulte BV - Schein Orthopädie Service KG",
            "modelname": "Alexis"
        }
    },
    {
        "modelnumber": "4707",
        "colornumber": "na",
        "brand": "LuCro",
        "lastgroup": "lucro-dames-urbanic",
        "filename": "",
        "category": "f",
        "modelname": "Alexis",
        "attributes": {
            "closings": [
                "06cfde34-87b0-428d-8e4b-1b2834194552"
            ],
            "colorname": "groen",
            "height": [
                "574c17d0-682d-40a5-a982-17ffae7b3015"
            ],
            "supplier": "Schulte BV - Schein Orthopädie Service KG",
            "modelname": "Alexis"
        }
    },
    {
        "modelnumber": "4711",
        "colornumber": "na",
        "brand": "LuCro",
        "lastgroup": "lucro-dames-urbanic",
        "filename": "",
        "category": "f",
        "modelname": "Megan",
        "attributes": {
            "closings": [
                "72589120-2085-4a19-bd1e-899c5a098a5f"
            ],
            "colorname": "wit",
            "height": [
                "574c17d0-682d-40a5-a982-17ffae7b3015"
            ],
            "supplier": "Schulte BV - Schein Orthopädie Service KG",
            "modelname": "Megan"
        }
    },
    {
        "modelnumber": "4712",
        "colornumber": "na",
        "brand": "LuCro",
        "lastgroup": "lucro-dames-urbanic",
        "filename": "",
        "category": "f",
        "modelname": "Megan",
        "attributes": {
            "closings": [
                "72589120-2085-4a19-bd1e-899c5a098a5f"
            ],
            "colorname": "blauw",
            "height": [
                "574c17d0-682d-40a5-a982-17ffae7b3015"
            ],
            "supplier": "Schulte BV - Schein Orthopädie Service KG",
            "modelname": "Megan"
        }
    },
    {
        "modelnumber": "4714",
        "colornumber": "na",
        "brand": "LuCro",
        "lastgroup": "lucro-dames-urbanic",
        "filename": "",
        "category": "f",
        "modelname": "Megan",
        "attributes": {
            "closings": [
                "72589120-2085-4a19-bd1e-899c5a098a5f"
            ],
            "colorname": "wit",
            "height": [
                "574c17d0-682d-40a5-a982-17ffae7b3015"
            ],
            "supplier": "Schulte BV - Schein Orthopädie Service KG",
            "modelname": "Megan"
        }
    },
    {
        "modelnumber": "4716",
        "colornumber": "na",
        "brand": "LuCro",
        "lastgroup": "lucro-dames-urbanic",
        "filename": "",
        "category": "f",
        "modelname": "Megan",
        "attributes": {
            "closings": [
                "72589120-2085-4a19-bd1e-899c5a098a5f"
            ],
            "colorname": "rose",
            "height": [
                "574c17d0-682d-40a5-a982-17ffae7b3015"
            ],
            "supplier": "Schulte BV - Schein Orthopädie Service KG",
            "modelname": "Megan"
        }
    },
    {
        "modelnumber": "4717",
        "colornumber": "na",
        "brand": "LuCro",
        "lastgroup": "lucro-dames-urbanic",
        "filename": "",
        "category": "f",
        "modelname": "Megan",
        "attributes": {
            "closings": [
                "72589120-2085-4a19-bd1e-899c5a098a5f"
            ],
            "colorname": "zwart",
            "height": [
                "574c17d0-682d-40a5-a982-17ffae7b3015"
            ],
            "supplier": "Schulte BV - Schein Orthopädie Service KG",
            "modelname": "Megan"
        }
    },
    {
        "modelnumber": "4718",
        "colornumber": "na",
        "brand": "LuCro",
        "lastgroup": "lucro-dames-urbanic",
        "filename": "",
        "category": "f",
        "modelname": "Megan",
        "attributes": {
            "closings": [
                "72589120-2085-4a19-bd1e-899c5a098a5f"
            ],
            "colorname": "blauw",
            "height": [
                "574c17d0-682d-40a5-a982-17ffae7b3015"
            ],
            "supplier": "Schulte BV - Schein Orthopädie Service KG",
            "modelname": "Megan"
        }
    },
    {
        "modelnumber": "4719",
        "colornumber": "na",
        "brand": "LuCro",
        "lastgroup": "lucro-dames-urbanic",
        "filename": "",
        "category": "f",
        "modelname": "Megan",
        "attributes": {
            "closings": [
                "72589120-2085-4a19-bd1e-899c5a098a5f"
            ],
            "colorname": "grijs",
            "height": [
                "574c17d0-682d-40a5-a982-17ffae7b3015"
            ],
            "supplier": "Schulte BV - Schein Orthopädie Service KG",
            "modelname": "Megan"
        }
    },
    {
        "modelnumber": "4731",
        "colornumber": "na",
        "brand": "LuCro",
        "lastgroup": "lucro-dames-urbanic",
        "filename": "",
        "category": "f",
        "modelname": "Violet",
        "attributes": {
            "closings": [
                "0b535454-074a-45f0-be42-5af1abff277d"
            ],
            "colorname": "wit",
            "height": [
                "574c17d0-682d-40a5-a982-17ffae7b3015"
            ],
            "supplier": "Schulte BV - Schein Orthopädie Service KG",
            "modelname": "Violet"
        }
    },
    {
        "modelnumber": "4732",
        "colornumber": "na",
        "brand": "LuCro",
        "lastgroup": "lucro-dames-urbanic",
        "filename": "",
        "category": "f",
        "modelname": "Violet",
        "attributes": {
            "closings": [
                "0b535454-074a-45f0-be42-5af1abff277d"
            ],
            "colorname": "blauw",
            "height": [
                "574c17d0-682d-40a5-a982-17ffae7b3015"
            ],
            "supplier": "Schulte BV - Schein Orthopädie Service KG",
            "modelname": "Violet"
        }
    },
    {
        "modelnumber": "4734",
        "colornumber": "na",
        "brand": "LuCro",
        "lastgroup": "lucro-dames-urbanic",
        "filename": "",
        "category": "f",
        "modelname": "Violet",
        "attributes": {
            "closings": [
                "0b535454-074a-45f0-be42-5af1abff277d"
            ],
            "colorname": "wit",
            "height": [
                "574c17d0-682d-40a5-a982-17ffae7b3015"
            ],
            "supplier": "Schulte BV - Schein Orthopädie Service KG",
            "modelname": "Violet"
        }
    },
    {
        "modelnumber": "4736",
        "colornumber": "na",
        "brand": "LuCro",
        "lastgroup": "lucro-dames-urbanic",
        "filename": "",
        "category": "f",
        "modelname": "Violet",
        "attributes": {
            "closings": [
                "0b535454-074a-45f0-be42-5af1abff277d"
            ],
            "colorname": "rose",
            "height": [
                "574c17d0-682d-40a5-a982-17ffae7b3015"
            ],
            "supplier": "Schulte BV - Schein Orthopädie Service KG",
            "modelname": "Violet"
        }
    },
    {
        "modelnumber": "4737",
        "colornumber": "na",
        "brand": "LuCro",
        "lastgroup": "lucro-dames-urbanic",
        "filename": "",
        "category": "f",
        "modelname": "Violet",
        "attributes": {
            "closings": [
                "0b535454-074a-45f0-be42-5af1abff277d"
            ],
            "colorname": "zwart",
            "height": [
                "574c17d0-682d-40a5-a982-17ffae7b3015"
            ],
            "supplier": "Schulte BV - Schein Orthopädie Service KG",
            "modelname": "Violet"
        }
    },
    {
        "modelnumber": "4738",
        "colornumber": "na",
        "brand": "LuCro",
        "lastgroup": "lucro-dames-urbanic",
        "filename": "",
        "category": "f",
        "modelname": "Violet",
        "attributes": {
            "closings": [
                "0b535454-074a-45f0-be42-5af1abff277d"
            ],
            "colorname": "blauw",
            "height": [
                "574c17d0-682d-40a5-a982-17ffae7b3015"
            ],
            "supplier": "Schulte BV - Schein Orthopädie Service KG",
            "modelname": "Violet"
        }
    },
    {
        "modelnumber": "4739",
        "colornumber": "na",
        "brand": "LuCro",
        "lastgroup": "lucro-dames-urbanic",
        "filename": "",
        "category": "f",
        "modelname": "Violet",
        "attributes": {
            "closings": [
                "0b535454-074a-45f0-be42-5af1abff277d"
            ],
            "colorname": "silver",
            "height": [
                "574c17d0-682d-40a5-a982-17ffae7b3015"
            ],
            "supplier": "Schulte BV - Schein Orthopädie Service KG",
            "modelname": "Violet"
        }
    },
    {
        "modelnumber": "4741",
        "colornumber": "na",
        "brand": "LuCro",
        "lastgroup": "lucro-dames-urbanic",
        "filename": "",
        "category": "f",
        "modelname": "Amber",
        "attributes": {
            "closings": [
                "0b535454-074a-45f0-be42-5af1abff277d"
            ],
            "colorname": "rose",
            "height": [
                "574c17d0-682d-40a5-a982-17ffae7b3015"
            ],
            "supplier": "Schulte BV - Schein Orthopädie Service KG",
            "modelname": "Amber"
        }
    },
    {
        "modelnumber": "4742",
        "colornumber": "na",
        "brand": "LuCro",
        "lastgroup": "lucro-dames-urbanic",
        "filename": "",
        "category": "f",
        "modelname": "Amber",
        "attributes": {
            "closings": [
                "0b535454-074a-45f0-be42-5af1abff277d"
            ],
            "colorname": "grijs",
            "height": [
                "574c17d0-682d-40a5-a982-17ffae7b3015"
            ],
            "supplier": "Schulte BV - Schein Orthopädie Service KG",
            "modelname": "Amber"
        }
    },
    {
        "modelnumber": "4748",
        "colornumber": "na",
        "brand": "LuCro",
        "lastgroup": "lucro-dames-urbanic",
        "filename": "",
        "category": "f",
        "modelname": "Amber",
        "attributes": {
            "closings": [
                "0b535454-074a-45f0-be42-5af1abff277d"
            ],
            "colorname": "blauw",
            "height": [
                "574c17d0-682d-40a5-a982-17ffae7b3015"
            ],
            "supplier": "Schulte BV - Schein Orthopädie Service KG",
            "modelname": "Amber"
        }
    },
    {
        "modelnumber": "4749",
        "colornumber": "na",
        "brand": "LuCro",
        "lastgroup": "lucro-dames-urbanic",
        "filename": "",
        "category": "f",
        "modelname": "Amber",
        "attributes": {
            "closings": [
                "0b535454-074a-45f0-be42-5af1abff277d"
            ],
            "colorname": "grijs",
            "height": [
                "574c17d0-682d-40a5-a982-17ffae7b3015"
            ],
            "supplier": "Schulte BV - Schein Orthopädie Service KG",
            "modelname": "Amber"
        }
    },
    {
        "modelnumber": "4770",
        "colornumber": "na",
        "brand": "LuCro",
        "lastgroup": "lucro-dames-urbanic",
        "filename": "",
        "category": "f",
        "modelname": "Julia",
        "attributes": {
            "closings": [
                "0b535454-074a-45f0-be42-5af1abff277d"
            ],
            "colorname": "zwart",
            "height": [
                "574c17d0-682d-40a5-a982-17ffae7b3015"
            ],
            "supplier": "Schulte BV - Schein Orthopädie Service KG",
            "modelname": "Julia"
        }
    },
    {
        "modelnumber": "4771",
        "colornumber": "na",
        "brand": "LuCro",
        "lastgroup": "lucro-dames-urbanic",
        "filename": "",
        "category": "f",
        "modelname": "Julia",
        "attributes": {
            "closings": [
                "0b535454-074a-45f0-be42-5af1abff277d"
            ],
            "colorname": "wit",
            "height": [
                "574c17d0-682d-40a5-a982-17ffae7b3015"
            ],
            "supplier": "Schulte BV - Schein Orthopädie Service KG",
            "modelname": "Julia"
        }
    },
    {
        "modelnumber": "4772",
        "colornumber": "na",
        "brand": "LuCro",
        "lastgroup": "lucro-dames-urbanic",
        "filename": "",
        "category": "f",
        "modelname": "Julia",
        "attributes": {
            "closings": [
                "0b535454-074a-45f0-be42-5af1abff277d"
            ],
            "colorname": "grijs",
            "height": [
                "574c17d0-682d-40a5-a982-17ffae7b3015"
            ],
            "supplier": "Schulte BV - Schein Orthopädie Service KG",
            "modelname": "Julia"
        }
    },
    {
        "modelnumber": "4777",
        "colornumber": "na",
        "brand": "LuCro",
        "lastgroup": "lucro-dames-urbanic",
        "filename": "",
        "category": "f",
        "modelname": "Julia",
        "attributes": {
            "closings": [
                "0b535454-074a-45f0-be42-5af1abff277d"
            ],
            "colorname": "zwart",
            "height": [
                "574c17d0-682d-40a5-a982-17ffae7b3015"
            ],
            "supplier": "Schulte BV - Schein Orthopädie Service KG",
            "modelname": "Julia"
        }
    },
    {
        "modelnumber": "4779",
        "colornumber": "na",
        "brand": "LuCro",
        "lastgroup": "lucro-dames-urbanic",
        "filename": "",
        "category": "f",
        "modelname": "Julia",
        "attributes": {
            "closings": [
                "0b535454-074a-45f0-be42-5af1abff277d"
            ],
            "colorname": "grijs",
            "height": [
                "574c17d0-682d-40a5-a982-17ffae7b3015"
            ],
            "supplier": "Schulte BV - Schein Orthopädie Service KG",
            "modelname": "Julia"
        }
    },
    {
        "modelnumber": "21020",
        "colornumber": "na",
        "brand": "LuCro",
        "lastgroup": "lucro-heren-classic",
        "filename": "",
        "category": "m",
        "modelname": "Thorsten",
        "attributes": {
            "closings": [
                "06cfde34-87b0-428d-8e4b-1b2834194552"
            ],
            "colorname": "zwart",
            "height": [
                "574c17d0-682d-40a5-a982-17ffae7b3015"
            ],
            "supplier": "Schulte BV - Schein Orthopädie Service KG",
            "modelname": "Thorsten"
        }
    },
    {
        "modelnumber": "21022",
        "colornumber": "na",
        "brand": "LuCro",
        "lastgroup": "lucro-heren-classic",
        "filename": "",
        "category": "m",
        "modelname": "Thorsten",
        "attributes": {
            "closings": [
                "06cfde34-87b0-428d-8e4b-1b2834194552"
            ],
            "colorname": "bruin",
            "height": [
                "574c17d0-682d-40a5-a982-17ffae7b3015"
            ],
            "supplier": "Schulte BV - Schein Orthopädie Service KG",
            "modelname": "Thorsten"
        }
    },
    {
        "modelnumber": "21028",
        "colornumber": "na",
        "brand": "LuCro",
        "lastgroup": "lucro-heren-classic",
        "filename": "",
        "category": "m",
        "modelname": "Thorsten",
        "attributes": {
            "closings": [
                "06cfde34-87b0-428d-8e4b-1b2834194552"
            ],
            "colorname": "blauw",
            "height": [
                "574c17d0-682d-40a5-a982-17ffae7b3015"
            ],
            "supplier": "Schulte BV - Schein Orthopädie Service KG",
            "modelname": "Thorsten"
        }
    },
    {
        "modelnumber": "21030",
        "colornumber": "na",
        "brand": "LuCro",
        "lastgroup": "lucro-heren-classic",
        "filename": "",
        "category": "m",
        "modelname": "Björn",
        "attributes": {
            "closings": [
                "72589120-2085-4a19-bd1e-899c5a098a5f"
            ],
            "colorname": "zwart",
            "height": [
                "574c17d0-682d-40a5-a982-17ffae7b3015"
            ],
            "supplier": "Schulte BV - Schein Orthopädie Service KG",
            "modelname": "Björn"
        }
    },
    {
        "modelnumber": "21040",
        "colornumber": "na",
        "brand": "LuCro",
        "lastgroup": "lucro-heren-classic",
        "filename": "",
        "category": "m",
        "modelname": "Lars",
        "attributes": {
            "closings": [
                "72589120-2085-4a19-bd1e-899c5a098a5f"
            ],
            "colorname": "zwart",
            "height": [
                "574c17d0-682d-40a5-a982-17ffae7b3015"
            ],
            "supplier": "Schulte BV - Schein Orthopädie Service KG",
            "modelname": "Lars"
        }
    },
    {
        "modelnumber": "21047",
        "colornumber": "na",
        "brand": "LuCro",
        "lastgroup": "lucro-heren-classic",
        "filename": "",
        "category": "m",
        "modelname": "Lars",
        "attributes": {
            "closings": [
                "72589120-2085-4a19-bd1e-899c5a098a5f"
            ],
            "colorname": "zwart",
            "height": [
                "574c17d0-682d-40a5-a982-17ffae7b3015"
            ],
            "supplier": "Schulte BV - Schein Orthopädie Service KG",
            "modelname": "Lars"
        }
    },
    {
        "modelnumber": "21050",
        "colornumber": "na",
        "brand": "LuCro",
        "lastgroup": "lucro-heren-classic",
        "filename": "",
        "category": "m",
        "modelname": "Sven",
        "attributes": {
            "closings": [
                "06cfde34-87b0-428d-8e4b-1b2834194552"
            ],
            "colorname": "zwart",
            "height": [
                "574c17d0-682d-40a5-a982-17ffae7b3015"
            ],
            "supplier": "Schulte BV - Schein Orthopädie Service KG",
            "modelname": "Sven"
        }
    },
    {
        "modelnumber": "21053",
        "colornumber": "na",
        "brand": "LuCro",
        "lastgroup": "lucro-heren-classic",
        "filename": "",
        "category": "m",
        "modelname": "Sven",
        "attributes": {
            "closings": [
                "06cfde34-87b0-428d-8e4b-1b2834194552"
            ],
            "colorname": "bruin",
            "height": [
                "574c17d0-682d-40a5-a982-17ffae7b3015"
            ],
            "supplier": "Schulte BV - Schein Orthopädie Service KG",
            "modelname": "Sven"
        }
    },
    {
        "modelnumber": "21061",
        "colornumber": "na",
        "brand": "LuCro",
        "lastgroup": "lucro-heren-classic",
        "filename": "",
        "category": "m",
        "modelname": "Fritz",
        "attributes": {
            "closings": [
                "06cfde34-87b0-428d-8e4b-1b2834194552"
            ],
            "colorname": "grijs",
            "height": [
                "574c17d0-682d-40a5-a982-17ffae7b3015"
            ],
            "supplier": "Schulte BV - Schein Orthopädie Service KG",
            "modelname": "Fritz"
        }
    },
    {
        "modelnumber": "21062",
        "colornumber": "na",
        "brand": "LuCro",
        "lastgroup": "lucro-heren-classic",
        "filename": "",
        "category": "m",
        "modelname": "Bruno",
        "attributes": {
            "closings": [
                "06cfde34-87b0-428d-8e4b-1b2834194552"
            ],
            "colorname": "grijs",
            "height": [
                "574c17d0-682d-40a5-a982-17ffae7b3015"
            ],
            "supplier": "Schulte BV - Schein Orthopädie Service KG",
            "modelname": "Bruno"
        }
    },
    {
        "modelnumber": "21090",
        "colornumber": "na",
        "brand": "LuCro",
        "lastgroup": "lucro-heren-classic",
        "filename": "",
        "category": "m",
        "modelname": "Oliver",
        "attributes": {
            "closings": [
                "06cfde34-87b0-428d-8e4b-1b2834194552"
            ],
            "colorname": "zwart",
            "height": [
                "574c17d0-682d-40a5-a982-17ffae7b3015"
            ],
            "supplier": "Schulte BV - Schein Orthopädie Service KG",
            "modelname": "Oliver"
        }
    },
    {
        "modelnumber": "21092",
        "colornumber": "na",
        "brand": "LuCro",
        "lastgroup": "lucro-heren-classic",
        "filename": "",
        "category": "m",
        "modelname": "Oliver",
        "attributes": {
            "closings": [
                "06cfde34-87b0-428d-8e4b-1b2834194552"
            ],
            "colorname": "bruin",
            "height": [
                "574c17d0-682d-40a5-a982-17ffae7b3015"
            ],
            "supplier": "Schulte BV - Schein Orthopädie Service KG",
            "modelname": "Oliver"
        }
    },
    {
        "modelnumber": "21093",
        "colornumber": "na",
        "brand": "LuCro",
        "lastgroup": "lucro-heren-classic",
        "filename": "",
        "category": "m",
        "modelname": "Oliver",
        "attributes": {
            "closings": [
                "06cfde34-87b0-428d-8e4b-1b2834194552"
            ],
            "colorname": "TAUPE",
            "height": [
                "574c17d0-682d-40a5-a982-17ffae7b3015"
            ],
            "supplier": "Schulte BV - Schein Orthopädie Service KG",
            "modelname": "Oliver"
        }
    },
    {
        "modelnumber": "21095",
        "colornumber": "na",
        "brand": "LuCro",
        "lastgroup": "lucro-heren-classic",
        "filename": "",
        "category": "m",
        "modelname": "Oliver",
        "attributes": {
            "closings": [
                "06cfde34-87b0-428d-8e4b-1b2834194552"
            ],
            "colorname": "cognac",
            "height": [
                "574c17d0-682d-40a5-a982-17ffae7b3015"
            ],
            "supplier": "Schulte BV - Schein Orthopädie Service KG",
            "modelname": "Oliver"
        }
    },
    {
        "modelnumber": "21096",
        "colornumber": "na",
        "brand": "LuCro",
        "lastgroup": "lucro-heren-classic",
        "filename": "",
        "category": "m",
        "modelname": "Oliver",
        "attributes": {
            "closings": [
                "06cfde34-87b0-428d-8e4b-1b2834194552"
            ],
            "colorname": "bruin",
            "height": [
                "574c17d0-682d-40a5-a982-17ffae7b3015"
            ],
            "supplier": "Schulte BV - Schein Orthopädie Service KG",
            "modelname": "Oliver"
        }
    },
    {
        "modelnumber": "21097",
        "colornumber": "na",
        "brand": "LuCro",
        "lastgroup": "lucro-heren-classic",
        "filename": "",
        "category": "m",
        "modelname": "Oliver",
        "attributes": {
            "closings": [
                "06cfde34-87b0-428d-8e4b-1b2834194552"
            ],
            "colorname": "beige",
            "height": [
                "574c17d0-682d-40a5-a982-17ffae7b3015"
            ],
            "supplier": "Schulte BV - Schein Orthopädie Service KG",
            "modelname": "Oliver"
        }
    },
    {
        "modelnumber": "21141",
        "colornumber": "na",
        "brand": "LuCro",
        "lastgroup": "lucro-heren-classic",
        "filename": "",
        "category": "m",
        "modelname": "Luca",
        "attributes": {
            "closings": [
                "06cfde34-87b0-428d-8e4b-1b2834194552"
            ],
            "colorname": "grijs",
            "height": [
                "574c17d0-682d-40a5-a982-17ffae7b3015"
            ],
            "supplier": "Schulte BV - Schein Orthopädie Service KG",
            "modelname": "Luca"
        }
    },
    {
        "modelnumber": "21172",
        "colornumber": "na",
        "brand": "LuCro",
        "lastgroup": "lucro-heren-classic",
        "filename": "",
        "category": "m",
        "modelname": "Marcus",
        "attributes": {
            "closings": [
                "06cfde34-87b0-428d-8e4b-1b2834194552"
            ],
            "colorname": "zwart",
            "height": [
                "574c17d0-682d-40a5-a982-17ffae7b3015"
            ],
            "supplier": "Schulte BV - Schein Orthopädie Service KG",
            "modelname": "Marcus"
        }
    },
    {
        "modelnumber": "21173",
        "colornumber": "na",
        "brand": "LuCro",
        "lastgroup": "lucro-heren-classic",
        "filename": "",
        "category": "m",
        "modelname": "Marcus",
        "attributes": {
            "closings": [
                "06cfde34-87b0-428d-8e4b-1b2834194552"
            ],
            "colorname": "bruin",
            "height": [
                "574c17d0-682d-40a5-a982-17ffae7b3015"
            ],
            "supplier": "Schulte BV - Schein Orthopädie Service KG",
            "modelname": "Marcus"
        }
    },
    {
        "modelnumber": "21180",
        "colornumber": "na",
        "brand": "LuCro",
        "lastgroup": "lucro-heren-classic",
        "filename": "",
        "category": "m",
        "modelname": "Philip",
        "attributes": {
            "closings": [
                "72589120-2085-4a19-bd1e-899c5a098a5f"
            ],
            "colorname": "zwart",
            "height": [
                "574c17d0-682d-40a5-a982-17ffae7b3015"
            ],
            "supplier": "Schulte BV - Schein Orthopädie Service KG",
            "modelname": "Philip"
        }
    },
    {
        "modelnumber": "21185",
        "colornumber": "na",
        "brand": "LuCro",
        "lastgroup": "lucro-heren-classic",
        "filename": "",
        "category": "m",
        "modelname": "Philip",
        "attributes": {
            "closings": [
                "72589120-2085-4a19-bd1e-899c5a098a5f"
            ],
            "colorname": "cognac",
            "height": [
                "574c17d0-682d-40a5-a982-17ffae7b3015"
            ],
            "supplier": "Schulte BV - Schein Orthopädie Service KG",
            "modelname": "Philip"
        }
    },
    {
        "modelnumber": "21188",
        "colornumber": "na",
        "brand": "LuCro",
        "lastgroup": "lucro-heren-classic",
        "filename": "",
        "category": "m",
        "modelname": "Philip",
        "attributes": {
            "closings": [
                "72589120-2085-4a19-bd1e-899c5a098a5f"
            ],
            "colorname": "blauw",
            "height": [
                "574c17d0-682d-40a5-a982-17ffae7b3015"
            ],
            "supplier": "Schulte BV - Schein Orthopädie Service KG",
            "modelname": "Philip"
        }
    },
    {
        "modelnumber": "21190",
        "colornumber": "na",
        "brand": "LuCro",
        "lastgroup": "lucro-heren-classic",
        "filename": "",
        "category": "m",
        "modelname": "Achim",
        "attributes": {
            "closings": [
                "06cfde34-87b0-428d-8e4b-1b2834194552"
            ],
            "colorname": "zwart",
            "height": [
                "574c17d0-682d-40a5-a982-17ffae7b3015"
            ],
            "supplier": "Schulte BV - Schein Orthopädie Service KG",
            "modelname": "Achim"
        }
    },
    {
        "modelnumber": "21192",
        "colornumber": "na",
        "brand": "LuCro",
        "lastgroup": "lucro-heren-classic",
        "filename": "",
        "category": "m",
        "modelname": "Achim",
        "attributes": {
            "closings": [
                "06cfde34-87b0-428d-8e4b-1b2834194552"
            ],
            "colorname": "cognac",
            "height": [
                "574c17d0-682d-40a5-a982-17ffae7b3015"
            ],
            "supplier": "Schulte BV - Schein Orthopädie Service KG",
            "modelname": "Achim"
        }
    },
    {
        "modelnumber": "21202",
        "colornumber": "na",
        "brand": "LuCro",
        "lastgroup": "lucro-heren-classic",
        "filename": "",
        "category": "m",
        "modelname": "Florian",
        "attributes": {
            "closings": [
                "na"
            ],
            "colorname": "grijs",
            "height": [
                "574c17d0-682d-40a5-a982-17ffae7b3015"
            ],
            "supplier": "Schulte BV - Schein Orthopädie Service KG",
            "modelname": "Florian"
        }
    },
    {
        "modelnumber": "21204",
        "colornumber": "na",
        "brand": "LuCro",
        "lastgroup": "lucro-heren-classic",
        "filename": "",
        "category": "m",
        "modelname": "Falko",
        "attributes": {
            "closings": [
                "9922761e-670e-4f11-9332-dde591065a9b"
            ],
            "colorname": "grijs",
            "height": [
                "574c17d0-682d-40a5-a982-17ffae7b3015"
            ],
            "supplier": "Schulte BV - Schein Orthopädie Service KG",
            "modelname": "Falko"
        }
    },
    {
        "modelnumber": "21205",
        "colornumber": "na",
        "brand": "LuCro",
        "lastgroup": "lucro-heren-classic",
        "filename": "",
        "category": "m",
        "modelname": "Peter",
        "attributes": {
            "closings": [
                "9922761e-670e-4f11-9332-dde591065a9b"
            ],
            "colorname": "zwart",
            "height": [
                "574c17d0-682d-40a5-a982-17ffae7b3015"
            ],
            "supplier": "Schulte BV - Schein Orthopädie Service KG",
            "modelname": "Peter"
        }
    },
    {
        "modelnumber": "21206",
        "colornumber": "na",
        "brand": "LuCro",
        "lastgroup": "lucro-heren-classic",
        "filename": "",
        "category": "m",
        "modelname": "Peter",
        "attributes": {
            "closings": [
                "9922761e-670e-4f11-9332-dde591065a9b"
            ],
            "colorname": "bruin",
            "height": [
                "574c17d0-682d-40a5-a982-17ffae7b3015"
            ],
            "supplier": "Schulte BV - Schein Orthopädie Service KG",
            "modelname": "Peter"
        }
    },
    {
        "modelnumber": "21221",
        "colornumber": "na",
        "brand": "LuCro",
        "lastgroup": "lucro-heren-classic",
        "filename": "",
        "category": "m",
        "modelname": "Clemens",
        "attributes": {
            "closings": [
                "06cfde34-87b0-428d-8e4b-1b2834194552"
            ],
            "colorname": "grijs",
            "height": [
                "574c17d0-682d-40a5-a982-17ffae7b3015"
            ],
            "supplier": "Schulte BV - Schein Orthopädie Service KG",
            "modelname": "Clemens"
        }
    },
    {
        "modelnumber": "21232",
        "colornumber": "na",
        "brand": "LuCro",
        "lastgroup": "lucro-heren-classic",
        "filename": "",
        "category": "m",
        "modelname": "Heinrich",
        "attributes": {
            "closings": [
                "06cfde34-87b0-428d-8e4b-1b2834194552"
            ],
            "colorname": "grijs",
            "height": [
                "574c17d0-682d-40a5-a982-17ffae7b3015"
            ],
            "supplier": "Schulte BV - Schein Orthopädie Service KG",
            "modelname": "Heinrich"
        }
    },
    {
        "modelnumber": "21240",
        "colornumber": "na",
        "brand": "LuCro",
        "lastgroup": "lucro-heren-classic",
        "filename": "",
        "category": "m",
        "modelname": "Paul",
        "attributes": {
            "closings": [
                "06cfde34-87b0-428d-8e4b-1b2834194552"
            ],
            "colorname": "zwart",
            "height": [
                "574c17d0-682d-40a5-a982-17ffae7b3015"
            ],
            "supplier": "Schulte BV - Schein Orthopädie Service KG",
            "modelname": "Paul"
        }
    },
    {
        "modelnumber": "21260",
        "colornumber": "na",
        "brand": "LuCro",
        "lastgroup": "lucro-heren-classic",
        "filename": "",
        "category": "m",
        "modelname": "Jens",
        "attributes": {
            "closings": [
                "06cfde34-87b0-428d-8e4b-1b2834194552"
            ],
            "colorname": "zwart",
            "height": [
                "b591a1ea-c12b-4ab7-a414-9ed5e45de1dc"
            ],
            "supplier": "Schulte BV - Schein Orthopädie Service KG",
            "modelname": "Jens"
        }
    },
    {
        "modelnumber": "21270",
        "colornumber": "na",
        "brand": "LuCro",
        "lastgroup": "lucro-heren-classic",
        "filename": "",
        "category": "m",
        "modelname": "Eric",
        "attributes": {
            "closings": [
                "06cfde34-87b0-428d-8e4b-1b2834194552"
            ],
            "colorname": "zwart",
            "height": [
                "574c17d0-682d-40a5-a982-17ffae7b3015"
            ],
            "supplier": "Schulte BV - Schein Orthopädie Service KG",
            "modelname": "Eric"
        }
    },
    {
        "modelnumber": "21273",
        "colornumber": "na",
        "brand": "LuCro",
        "lastgroup": "lucro-heren-classic",
        "filename": "",
        "category": "m",
        "modelname": "Eric",
        "attributes": {
            "closings": [
                "06cfde34-87b0-428d-8e4b-1b2834194552"
            ],
            "colorname": "bruin",
            "height": [
                "574c17d0-682d-40a5-a982-17ffae7b3015"
            ],
            "supplier": "Schulte BV - Schein Orthopädie Service KG",
            "modelname": "Eric"
        }
    },
    {
        "modelnumber": "21276",
        "colornumber": "na",
        "brand": "LuCro",
        "lastgroup": "lucro-heren-classic",
        "filename": "",
        "category": "m",
        "modelname": "Eric",
        "attributes": {
            "closings": [
                "06cfde34-87b0-428d-8e4b-1b2834194552"
            ],
            "colorname": "cognac",
            "height": [
                "574c17d0-682d-40a5-a982-17ffae7b3015"
            ],
            "supplier": "Schulte BV - Schein Orthopädie Service KG",
            "modelname": "Eric"
        }
    },
    {
        "modelnumber": "21280",
        "colornumber": "na",
        "brand": "LuCro",
        "lastgroup": "lucro-heren-classic",
        "filename": "",
        "category": "m",
        "modelname": "William",
        "attributes": {
            "closings": [
                "72589120-2085-4a19-bd1e-899c5a098a5f"
            ],
            "colorname": "zwart",
            "height": [
                "574c17d0-682d-40a5-a982-17ffae7b3015"
            ],
            "supplier": "Schulte BV - Schein Orthopädie Service KG",
            "modelname": "William"
        }
    },
    {
        "modelnumber": "21284",
        "colornumber": "na",
        "brand": "LuCro",
        "lastgroup": "lucro-heren-classic",
        "filename": "",
        "category": "m",
        "modelname": "William",
        "attributes": {
            "closings": [
                "72589120-2085-4a19-bd1e-899c5a098a5f"
            ],
            "colorname": "blauw",
            "height": [
                "574c17d0-682d-40a5-a982-17ffae7b3015"
            ],
            "supplier": "Schulte BV - Schein Orthopädie Service KG",
            "modelname": "William"
        }
    },
    {
        "modelnumber": "21310",
        "colornumber": "na",
        "brand": "LuCro",
        "lastgroup": "lucro-heren-classic",
        "filename": "",
        "category": "m",
        "modelname": "Kurt",
        "attributes": {
            "closings": [
                "72589120-2085-4a19-bd1e-899c5a098a5f"
            ],
            "colorname": "zwart",
            "height": [
                "b591a1ea-c12b-4ab7-a414-9ed5e45de1dc"
            ],
            "supplier": "Schulte BV - Schein Orthopädie Service KG",
            "modelname": "Kurt"
        }
    },
    {
        "modelnumber": "21312",
        "colornumber": "na",
        "brand": "LuCro",
        "lastgroup": "lucro-heren-classic",
        "filename": "",
        "category": "m",
        "modelname": "Kurt",
        "attributes": {
            "closings": [
                "06cfde34-87b0-428d-8e4b-1b2834194552"
            ],
            "colorname": "beige",
            "height": [
                "b591a1ea-c12b-4ab7-a414-9ed5e45de1dc"
            ],
            "supplier": "Schulte BV - Schein Orthopädie Service KG",
            "modelname": "Kurt"
        }
    },
    {
        "modelnumber": "21313",
        "colornumber": "na",
        "brand": "LuCro",
        "lastgroup": "lucro-heren-classic",
        "filename": "",
        "category": "m",
        "modelname": "Kurt",
        "attributes": {
            "closings": [
                "72589120-2085-4a19-bd1e-899c5a098a5f"
            ],
            "colorname": "bruin",
            "height": [
                "b591a1ea-c12b-4ab7-a414-9ed5e45de1dc"
            ],
            "supplier": "Schulte BV - Schein Orthopädie Service KG",
            "modelname": "Kurt"
        }
    },
    {
        "modelnumber": "21318",
        "colornumber": "na",
        "brand": "LuCro",
        "lastgroup": "lucro-heren-classic",
        "filename": "",
        "category": "m",
        "modelname": "Kurt",
        "attributes": {
            "closings": [
                "06cfde34-87b0-428d-8e4b-1b2834194552"
            ],
            "colorname": "blauw",
            "height": [
                "b591a1ea-c12b-4ab7-a414-9ed5e45de1dc"
            ],
            "supplier": "Schulte BV - Schein Orthopädie Service KG",
            "modelname": "Kurt"
        }
    },
    {
        "modelnumber": "21320",
        "colornumber": "na",
        "brand": "LuCro",
        "lastgroup": "lucro-heren-classic",
        "filename": "",
        "category": "m",
        "modelname": "Frank",
        "attributes": {
            "closings": [
                "06cfde34-87b0-428d-8e4b-1b2834194552"
            ],
            "colorname": "zwart",
            "height": [
                "b591a1ea-c12b-4ab7-a414-9ed5e45de1dc"
            ],
            "supplier": "Schulte BV - Schein Orthopädie Service KG",
            "modelname": "Frank"
        }
    },
    {
        "modelnumber": "21330",
        "colornumber": "na",
        "brand": "LuCro",
        "lastgroup": "lucro-heren-classic",
        "filename": "",
        "category": "m",
        "modelname": "Volker",
        "attributes": {
            "closings": [
                "06cfde34-87b0-428d-8e4b-1b2834194552"
            ],
            "colorname": "zwart",
            "height": [
                "574c17d0-682d-40a5-a982-17ffae7b3015"
            ],
            "supplier": "Schulte BV - Schein Orthopädie Service KG",
            "modelname": "Volker"
        }
    },
    {
        "modelnumber": "21332",
        "colornumber": "na",
        "brand": "LuCro",
        "lastgroup": "lucro-heren-classic",
        "filename": "",
        "category": "m",
        "modelname": "Volker",
        "attributes": {
            "closings": [
                "06cfde34-87b0-428d-8e4b-1b2834194552"
            ],
            "colorname": "bruin",
            "height": [
                "574c17d0-682d-40a5-a982-17ffae7b3015"
            ],
            "supplier": "Schulte BV - Schein Orthopädie Service KG",
            "modelname": "Volker"
        }
    },
    {
        "modelnumber": "21333",
        "colornumber": "na",
        "brand": "LuCro",
        "lastgroup": "lucro-heren-classic",
        "filename": "",
        "category": "m",
        "modelname": "Volker",
        "attributes": {
            "closings": [
                "06cfde34-87b0-428d-8e4b-1b2834194552"
            ],
            "colorname": "bruin",
            "height": [
                "574c17d0-682d-40a5-a982-17ffae7b3015"
            ],
            "supplier": "Schulte BV - Schein Orthopädie Service KG",
            "modelname": "Volker"
        }
    },
    {
        "modelnumber": "21339",
        "colornumber": "na",
        "brand": "LuCro",
        "lastgroup": "lucro-heren-classic",
        "filename": "",
        "category": "m",
        "modelname": "Volker",
        "attributes": {
            "closings": [
                "06cfde34-87b0-428d-8e4b-1b2834194552"
            ],
            "colorname": "grijs",
            "height": [
                "574c17d0-682d-40a5-a982-17ffae7b3015"
            ],
            "supplier": "Schulte BV - Schein Orthopädie Service KG",
            "modelname": "Volker"
        }
    },
    {
        "modelnumber": "21360",
        "colornumber": "na",
        "brand": "LuCro",
        "lastgroup": "lucro-heren-classic",
        "filename": "",
        "category": "m",
        "modelname": "Harald",
        "attributes": {
            "closings": [
                "06cfde34-87b0-428d-8e4b-1b2834194552"
            ],
            "colorname": "zwart",
            "height": [
                "574c17d0-682d-40a5-a982-17ffae7b3015"
            ],
            "supplier": "Schulte BV - Schein Orthopädie Service KG",
            "modelname": "Harald"
        }
    },
    {
        "modelnumber": "21365",
        "colornumber": "na",
        "brand": "LuCro",
        "lastgroup": "lucro-heren-classic",
        "filename": "",
        "category": "m",
        "modelname": "Harald",
        "attributes": {
            "closings": [
                "06cfde34-87b0-428d-8e4b-1b2834194552"
            ],
            "colorname": "bruin",
            "height": [
                "574c17d0-682d-40a5-a982-17ffae7b3015"
            ],
            "supplier": "Schulte BV - Schein Orthopädie Service KG",
            "modelname": "Harald"
        }
    },
    {
        "modelnumber": "21370",
        "colornumber": "na",
        "brand": "LuCro",
        "lastgroup": "lucro-heren-classic",
        "filename": "",
        "category": "m",
        "modelname": "Walter",
        "attributes": {
            "closings": [
                "06cfde34-87b0-428d-8e4b-1b2834194552"
            ],
            "colorname": "zwart",
            "height": [
                "574c17d0-682d-40a5-a982-17ffae7b3015"
            ],
            "supplier": "Schulte BV - Schein Orthopädie Service KG",
            "modelname": "Walter"
        }
    },
    {
        "modelnumber": "21373",
        "colornumber": "na",
        "brand": "LuCro",
        "lastgroup": "lucro-heren-classic",
        "filename": "",
        "category": "m",
        "modelname": "Walter",
        "attributes": {
            "closings": [
                "06cfde34-87b0-428d-8e4b-1b2834194552"
            ],
            "colorname": "bruin",
            "height": [
                "574c17d0-682d-40a5-a982-17ffae7b3015"
            ],
            "supplier": "Schulte BV - Schein Orthopädie Service KG",
            "modelname": "Walter"
        }
    },
    {
        "modelnumber": "21377",
        "colornumber": "na",
        "brand": "LuCro",
        "lastgroup": "lucro-heren-classic",
        "filename": "",
        "category": "m",
        "modelname": "Walter",
        "attributes": {
            "closings": [
                "06cfde34-87b0-428d-8e4b-1b2834194552"
            ],
            "colorname": "zwart",
            "height": [
                "574c17d0-682d-40a5-a982-17ffae7b3015"
            ],
            "supplier": "Schulte BV - Schein Orthopädie Service KG",
            "modelname": "Walter"
        }
    },
    {
        "modelnumber": "21440",
        "colornumber": "na",
        "brand": "LuCro",
        "lastgroup": "lucro-heren-classic",
        "filename": "",
        "category": "m",
        "modelname": "Wolfgang",
        "attributes": {
            "closings": [
                "06cfde34-87b0-428d-8e4b-1b2834194552"
            ],
            "colorname": "zwart",
            "height": [
                "574c17d0-682d-40a5-a982-17ffae7b3015"
            ],
            "supplier": "Schulte BV - Schein Orthopädie Service KG",
            "modelname": "Wolfgang"
        }
    },
    {
        "modelnumber": "21443",
        "colornumber": "na",
        "brand": "LuCro",
        "lastgroup": "lucro-heren-classic",
        "filename": "",
        "category": "m",
        "modelname": "Wolfgang",
        "attributes": {
            "closings": [
                "06cfde34-87b0-428d-8e4b-1b2834194552"
            ],
            "colorname": "cognac",
            "height": [
                "574c17d0-682d-40a5-a982-17ffae7b3015"
            ],
            "supplier": "Schulte BV - Schein Orthopädie Service KG",
            "modelname": "Wolfgang"
        }
    },
    {
        "modelnumber": "22040",
        "colornumber": "na",
        "brand": "LuCro",
        "lastgroup": "lucro-dames-classic",
        "filename": "",
        "category": "f",
        "modelname": "Sylvia",
        "attributes": {
            "closings": [
                "06cfde34-87b0-428d-8e4b-1b2834194552"
            ],
            "colorname": "zwart",
            "height": [
                "574c17d0-682d-40a5-a982-17ffae7b3015"
            ],
            "supplier": "Schulte BV - Schein Orthopädie Service KG",
            "modelname": "Sylvia"
        }
    },
    {
        "modelnumber": "22043",
        "colornumber": "na",
        "brand": "LuCro",
        "lastgroup": "lucro-dames-classic",
        "filename": "",
        "category": "f",
        "modelname": "Sylvia",
        "attributes": {
            "closings": [
                "06cfde34-87b0-428d-8e4b-1b2834194552"
            ],
            "colorname": "rood",
            "height": [
                "574c17d0-682d-40a5-a982-17ffae7b3015"
            ],
            "supplier": "Schulte BV - Schein Orthopädie Service KG",
            "modelname": "Sylvia"
        }
    },
    {
        "modelnumber": "22044",
        "colornumber": "na",
        "brand": "LuCro",
        "lastgroup": "lucro-dames-classic",
        "filename": "",
        "category": "f",
        "modelname": "Sylvia",
        "attributes": {
            "closings": [
                "06cfde34-87b0-428d-8e4b-1b2834194552"
            ],
            "colorname": "blauw",
            "height": [
                "574c17d0-682d-40a5-a982-17ffae7b3015"
            ],
            "supplier": "Schulte BV - Schein Orthopädie Service KG",
            "modelname": "Sylvia"
        }
    },
    {
        "modelnumber": "22047",
        "colornumber": "na",
        "brand": "LuCro",
        "lastgroup": "lucro-dames-classic",
        "filename": "",
        "category": "f",
        "modelname": "Sylvia",
        "attributes": {
            "closings": [
                "06cfde34-87b0-428d-8e4b-1b2834194552"
            ],
            "colorname": "zwart",
            "height": [
                "574c17d0-682d-40a5-a982-17ffae7b3015"
            ],
            "supplier": "Schulte BV - Schein Orthopädie Service KG",
            "modelname": "Sylvia"
        }
    },
    {
        "modelnumber": "22049",
        "colornumber": "na",
        "brand": "LuCro",
        "lastgroup": "lucro-dames-classic",
        "filename": "",
        "category": "f",
        "modelname": "Sylvia",
        "attributes": {
            "closings": [
                "06cfde34-87b0-428d-8e4b-1b2834194552"
            ],
            "colorname": "grijs",
            "height": [
                "574c17d0-682d-40a5-a982-17ffae7b3015"
            ],
            "supplier": "Schulte BV - Schein Orthopädie Service KG",
            "modelname": "Sylvia"
        }
    },
    {
        "modelnumber": "22062",
        "colornumber": "na",
        "brand": "LuCro",
        "lastgroup": "lucro-dames-classic",
        "filename": "",
        "category": "f",
        "modelname": "Marisa",
        "attributes": {
            "closings": [
                "06cfde34-87b0-428d-8e4b-1b2834194552"
            ],
            "colorname": "beige",
            "height": [
                "574c17d0-682d-40a5-a982-17ffae7b3015"
            ],
            "supplier": "Schulte BV - Schein Orthopädie Service KG",
            "modelname": "Marisa"
        }
    },
    {
        "modelnumber": "22063",
        "colornumber": "na",
        "brand": "LuCro",
        "lastgroup": "lucro-dames-classic",
        "filename": "",
        "category": "f",
        "modelname": "Marisa",
        "attributes": {
            "closings": [
                "06cfde34-87b0-428d-8e4b-1b2834194552"
            ],
            "colorname": "bruin",
            "height": [
                "574c17d0-682d-40a5-a982-17ffae7b3015"
            ],
            "supplier": "Schulte BV - Schein Orthopädie Service KG",
            "modelname": "Marisa"
        }
    },
    {
        "modelnumber": "22064",
        "colornumber": "na",
        "brand": "LuCro",
        "lastgroup": "lucro-dames-classic",
        "filename": "",
        "category": "f",
        "modelname": "Marisa",
        "attributes": {
            "closings": [
                "06cfde34-87b0-428d-8e4b-1b2834194552"
            ],
            "colorname": "blauw",
            "height": [
                "574c17d0-682d-40a5-a982-17ffae7b3015"
            ],
            "supplier": "Schulte BV - Schein Orthopädie Service KG",
            "modelname": "Marisa"
        }
    },
    {
        "modelnumber": "22065",
        "colornumber": "na",
        "brand": "LuCro",
        "lastgroup": "lucro-dames-classic",
        "filename": "",
        "category": "f",
        "modelname": "Marisa",
        "attributes": {
            "closings": [
                "06cfde34-87b0-428d-8e4b-1b2834194552"
            ],
            "colorname": "beige",
            "height": [
                "574c17d0-682d-40a5-a982-17ffae7b3015"
            ],
            "supplier": "Schulte BV - Schein Orthopädie Service KG",
            "modelname": "Marisa"
        }
    },
    {
        "modelnumber": "22067",
        "colornumber": "na",
        "brand": "LuCro",
        "lastgroup": "lucro-dames-classic",
        "filename": "",
        "category": "f",
        "modelname": "Marisa",
        "attributes": {
            "closings": [
                "06cfde34-87b0-428d-8e4b-1b2834194552"
            ],
            "colorname": "rood",
            "height": [
                "574c17d0-682d-40a5-a982-17ffae7b3015"
            ],
            "supplier": "Schulte BV - Schein Orthopädie Service KG",
            "modelname": "Marisa"
        }
    },
    {
        "modelnumber": "22141",
        "colornumber": "na",
        "brand": "LuCro",
        "lastgroup": "lucro-dames-classic",
        "filename": "",
        "category": "f",
        "modelname": "Hanni",
        "attributes": {
            "closings": [
                "06cfde34-87b0-428d-8e4b-1b2834194552"
            ],
            "colorname": "beige",
            "height": [
                "574c17d0-682d-40a5-a982-17ffae7b3015"
            ],
            "supplier": "Schulte BV - Schein Orthopädie Service KG",
            "modelname": "Hanni"
        }
    },
    {
        "modelnumber": "22149",
        "colornumber": "na",
        "brand": "LuCro",
        "lastgroup": "lucro-dames-classic",
        "filename": "",
        "category": "f",
        "modelname": "Hanni",
        "attributes": {
            "closings": [
                "06cfde34-87b0-428d-8e4b-1b2834194552"
            ],
            "colorname": "grijs",
            "height": [
                "574c17d0-682d-40a5-a982-17ffae7b3015"
            ],
            "supplier": "Schulte BV - Schein Orthopädie Service KG",
            "modelname": "Hanni"
        }
    },
    {
        "modelnumber": "22150",
        "colornumber": "na",
        "brand": "LuCro",
        "lastgroup": "lucro-dames-classic",
        "filename": "",
        "category": "f",
        "modelname": "Doris",
        "attributes": {
            "closings": [
                "06cfde34-87b0-428d-8e4b-1b2834194552"
            ],
            "colorname": "zwart",
            "height": [
                "b591a1ea-c12b-4ab7-a414-9ed5e45de1dc"
            ],
            "supplier": "Schulte BV - Schein Orthopädie Service KG",
            "modelname": "Doris"
        }
    },
    {
        "modelnumber": "22159",
        "colornumber": "na",
        "brand": "LuCro",
        "lastgroup": "lucro-dames-classic",
        "filename": "",
        "category": "f",
        "modelname": "Doris",
        "attributes": {
            "closings": [
                "06cfde34-87b0-428d-8e4b-1b2834194552"
            ],
            "colorname": "grijs",
            "height": [
                "b591a1ea-c12b-4ab7-a414-9ed5e45de1dc"
            ],
            "supplier": "Schulte BV - Schein Orthopädie Service KG",
            "modelname": "Doris"
        }
    },
    {
        "modelnumber": "22190",
        "colornumber": "na",
        "brand": "LuCro",
        "lastgroup": "lucro-dames-classic",
        "filename": "",
        "category": "f",
        "modelname": "Andrea",
        "attributes": {
            "closings": [
                "72589120-2085-4a19-bd1e-899c5a098a5f"
            ],
            "colorname": "zwart",
            "height": [
                "574c17d0-682d-40a5-a982-17ffae7b3015"
            ],
            "supplier": "Schulte BV - Schein Orthopädie Service KG",
            "modelname": "Andrea"
        }
    },
    {
        "modelnumber": "22192",
        "colornumber": "na",
        "brand": "LuCro",
        "lastgroup": "lucro-dames-classic",
        "filename": "",
        "category": "f",
        "modelname": "Andrea",
        "attributes": {
            "closings": [
                "72589120-2085-4a19-bd1e-899c5a098a5f"
            ],
            "colorname": "beige",
            "height": [
                "574c17d0-682d-40a5-a982-17ffae7b3015"
            ],
            "supplier": "Schulte BV - Schein Orthopädie Service KG",
            "modelname": "Andrea"
        }
    },
    {
        "modelnumber": "22210",
        "colornumber": "na",
        "brand": "LuCro",
        "lastgroup": "lucro-dames-classic",
        "filename": "",
        "category": "f",
        "modelname": "Lotte",
        "attributes": {
            "closings": [
                "na"
            ],
            "colorname": "grijs",
            "height": [
                "574c17d0-682d-40a5-a982-17ffae7b3015"
            ],
            "supplier": "Schulte BV - Schein Orthopädie Service KG",
            "modelname": "Lotte"
        }
    },
    {
        "modelnumber": "22212",
        "colornumber": "na",
        "brand": "LuCro",
        "lastgroup": "lucro-dames-classic",
        "filename": "",
        "category": "f",
        "modelname": "Fiona",
        "attributes": {
            "closings": [
                "9922761e-670e-4f11-9332-dde591065a9b"
            ],
            "colorname": "beige",
            "height": [
                "574c17d0-682d-40a5-a982-17ffae7b3015"
            ],
            "supplier": "Schulte BV - Schein Orthopädie Service KG",
            "modelname": "Fiona"
        }
    },
    {
        "modelnumber": "22213",
        "colornumber": "na",
        "brand": "LuCro",
        "lastgroup": "lucro-dames-classic",
        "filename": "",
        "category": "f",
        "modelname": "Fiona",
        "attributes": {
            "closings": [
                "9922761e-670e-4f11-9332-dde591065a9b"
            ],
            "colorname": "grijs",
            "height": [
                "574c17d0-682d-40a5-a982-17ffae7b3015"
            ],
            "supplier": "Schulte BV - Schein Orthopädie Service KG",
            "modelname": "Fiona"
        }
    },
    {
        "modelnumber": "22260",
        "colornumber": "na",
        "brand": "LuCro",
        "lastgroup": "lucro-dames-classic",
        "filename": "",
        "category": "f",
        "modelname": "Hildegard",
        "attributes": {
            "closings": [
                "06cfde34-87b0-428d-8e4b-1b2834194552"
            ],
            "colorname": "grijs",
            "height": [
                "574c17d0-682d-40a5-a982-17ffae7b3015"
            ],
            "supplier": "Schulte BV - Schein Orthopädie Service KG",
            "modelname": "Hildegard"
        }
    },
    {
        "modelnumber": "22262",
        "colornumber": "na",
        "brand": "LuCro",
        "lastgroup": "lucro-dames-classic",
        "filename": "",
        "category": "f",
        "modelname": "Hildegard",
        "attributes": {
            "closings": [
                "06cfde34-87b0-428d-8e4b-1b2834194552"
            ],
            "colorname": "beige",
            "height": [
                "574c17d0-682d-40a5-a982-17ffae7b3015"
            ],
            "supplier": "Schulte BV - Schein Orthopädie Service KG",
            "modelname": "Hildegard"
        }
    },
    {
        "modelnumber": "22271",
        "colornumber": "na",
        "brand": "LuCro",
        "lastgroup": "lucro-dames-classic",
        "filename": "",
        "category": "f",
        "modelname": "Clara",
        "attributes": {
            "closings": [
                "06cfde34-87b0-428d-8e4b-1b2834194552"
            ],
            "colorname": "beige",
            "height": [
                "574c17d0-682d-40a5-a982-17ffae7b3015"
            ],
            "supplier": "Schulte BV - Schein Orthopädie Service KG",
            "modelname": "Clara"
        }
    },
    {
        "modelnumber": "22279",
        "colornumber": "na",
        "brand": "LuCro",
        "lastgroup": "lucro-dames-classic",
        "filename": "",
        "category": "f",
        "modelname": "Clara",
        "attributes": {
            "closings": [
                "06cfde34-87b0-428d-8e4b-1b2834194552"
            ],
            "colorname": "grijs",
            "height": [
                "574c17d0-682d-40a5-a982-17ffae7b3015"
            ],
            "supplier": "Schulte BV - Schein Orthopädie Service KG",
            "modelname": "Clara"
        }
    },
    {
        "modelnumber": "22291",
        "colornumber": "na",
        "brand": "LuCro",
        "lastgroup": "lucro-dames-classic",
        "filename": "",
        "category": "f",
        "modelname": "Frieda",
        "attributes": {
            "closings": [
                "06cfde34-87b0-428d-8e4b-1b2834194552"
            ],
            "colorname": "beige",
            "height": [
                "574c17d0-682d-40a5-a982-17ffae7b3015"
            ],
            "supplier": "Schulte BV - Schein Orthopädie Service KG",
            "modelname": "Frieda"
        }
    },
    {
        "modelnumber": "22292",
        "colornumber": "na",
        "brand": "LuCro",
        "lastgroup": "lucro-dames-classic",
        "filename": "",
        "category": "f",
        "modelname": "Frieda",
        "attributes": {
            "closings": [
                "06cfde34-87b0-428d-8e4b-1b2834194552"
            ],
            "colorname": "grijs",
            "height": [
                "574c17d0-682d-40a5-a982-17ffae7b3015"
            ],
            "supplier": "Schulte BV - Schein Orthopädie Service KG",
            "modelname": "Frieda"
        }
    },
    {
        "modelnumber": "22300",
        "colornumber": "na",
        "brand": "LuCro",
        "lastgroup": "lucro-dames-classic",
        "filename": "",
        "category": "f",
        "modelname": "Yvonne",
        "attributes": {
            "closings": [
                "06cfde34-87b0-428d-8e4b-1b2834194552"
            ],
            "colorname": "zwart",
            "height": [
                "574c17d0-682d-40a5-a982-17ffae7b3015"
            ],
            "supplier": "Schulte BV - Schein Orthopädie Service KG",
            "modelname": "Yvonne"
        }
    },
    {
        "modelnumber": "22301",
        "colornumber": "na",
        "brand": "LuCro",
        "lastgroup": "lucro-dames-classic",
        "filename": "",
        "category": "f",
        "modelname": "Yvonne",
        "attributes": {
            "closings": [
                "06cfde34-87b0-428d-8e4b-1b2834194552"
            ],
            "colorname": "wit",
            "height": [
                "574c17d0-682d-40a5-a982-17ffae7b3015"
            ],
            "supplier": "Schulte BV - Schein Orthopädie Service KG",
            "modelname": "Yvonne"
        }
    },
    {
        "modelnumber": "22303",
        "colornumber": "na",
        "brand": "LuCro",
        "lastgroup": "lucro-dames-classic",
        "filename": "",
        "category": "f",
        "modelname": "Yvonne",
        "attributes": {
            "closings": [
                "06cfde34-87b0-428d-8e4b-1b2834194552"
            ],
            "colorname": "grijs",
            "height": [
                "574c17d0-682d-40a5-a982-17ffae7b3015"
            ],
            "supplier": "Schulte BV - Schein Orthopädie Service KG",
            "modelname": "Yvonne"
        }
    },
    {
        "modelnumber": "22305",
        "colornumber": "na",
        "brand": "LuCro",
        "lastgroup": "lucro-dames-classic",
        "filename": "",
        "category": "f",
        "modelname": "Yvonne",
        "attributes": {
            "closings": [
                "06cfde34-87b0-428d-8e4b-1b2834194552"
            ],
            "colorname": "beige",
            "height": [
                "574c17d0-682d-40a5-a982-17ffae7b3015"
            ],
            "supplier": "Schulte BV - Schein Orthopädie Service KG",
            "modelname": "Yvonne"
        }
    },
    {
        "modelnumber": "22307",
        "colornumber": "na",
        "brand": "LuCro",
        "lastgroup": "lucro-dames-classic",
        "filename": "",
        "category": "f",
        "modelname": "Yvonne",
        "attributes": {
            "closings": [
                "06cfde34-87b0-428d-8e4b-1b2834194552"
            ],
            "colorname": "zwart",
            "height": [
                "574c17d0-682d-40a5-a982-17ffae7b3015"
            ],
            "supplier": "Schulte BV - Schein Orthopädie Service KG",
            "modelname": "Yvonne"
        }
    },
    {
        "modelnumber": "22320",
        "colornumber": "na",
        "brand": "LuCro",
        "lastgroup": "lucro-dames-classic",
        "filename": "",
        "category": "f",
        "modelname": "Monika",
        "attributes": {
            "closings": [
                "06cfde34-87b0-428d-8e4b-1b2834194552"
            ],
            "colorname": "zwart",
            "height": [
                "574c17d0-682d-40a5-a982-17ffae7b3015"
            ],
            "supplier": "Schulte BV - Schein Orthopädie Service KG",
            "modelname": "Monika"
        }
    },
    {
        "modelnumber": "22321",
        "colornumber": "na",
        "brand": "LuCro",
        "lastgroup": "lucro-dames-classic",
        "filename": "",
        "category": "f",
        "modelname": "Monika",
        "attributes": {
            "closings": [
                "06cfde34-87b0-428d-8e4b-1b2834194552"
            ],
            "colorname": "wit",
            "height": [
                "574c17d0-682d-40a5-a982-17ffae7b3015"
            ],
            "supplier": "Schulte BV - Schein Orthopädie Service KG",
            "modelname": "Monika"
        }
    },
    {
        "modelnumber": "22323",
        "colornumber": "na",
        "brand": "LuCro",
        "lastgroup": "lucro-dames-classic",
        "filename": "",
        "category": "f",
        "modelname": "Monika",
        "attributes": {
            "closings": [
                "06cfde34-87b0-428d-8e4b-1b2834194552"
            ],
            "colorname": "grijs",
            "height": [
                "574c17d0-682d-40a5-a982-17ffae7b3015"
            ],
            "supplier": "Schulte BV - Schein Orthopädie Service KG",
            "modelname": "Monika"
        }
    },
    {
        "modelnumber": "22325",
        "colornumber": "na",
        "brand": "LuCro",
        "lastgroup": "lucro-dames-classic",
        "filename": "",
        "category": "f",
        "modelname": "Monika",
        "attributes": {
            "closings": [
                "06cfde34-87b0-428d-8e4b-1b2834194552"
            ],
            "colorname": "beige",
            "height": [
                "574c17d0-682d-40a5-a982-17ffae7b3015"
            ],
            "supplier": "Schulte BV - Schein Orthopädie Service KG",
            "modelname": "Monika"
        }
    },
    {
        "modelnumber": "22328",
        "colornumber": "na",
        "brand": "LuCro",
        "lastgroup": "lucro-dames-classic",
        "filename": "",
        "category": "f",
        "modelname": "Monika",
        "attributes": {
            "closings": [
                "06cfde34-87b0-428d-8e4b-1b2834194552"
            ],
            "colorname": "blauw",
            "height": [
                "574c17d0-682d-40a5-a982-17ffae7b3015"
            ],
            "supplier": "Schulte BV - Schein Orthopädie Service KG",
            "modelname": "Monika"
        }
    },
    {
        "modelnumber": "22360",
        "colornumber": "na",
        "brand": "LuCro",
        "lastgroup": "lucro-dames-classic",
        "filename": "",
        "category": "f",
        "modelname": "Ilona",
        "attributes": {
            "closings": [
                "06cfde34-87b0-428d-8e4b-1b2834194552"
            ],
            "colorname": "zwart",
            "height": [
                "574c17d0-682d-40a5-a982-17ffae7b3015"
            ],
            "supplier": "Schulte BV - Schein Orthopädie Service KG",
            "modelname": "Ilona"
        }
    },
    {
        "modelnumber": "22362",
        "colornumber": "na",
        "brand": "LuCro",
        "lastgroup": "lucro-dames-classic",
        "filename": "",
        "category": "f",
        "modelname": "Ilona",
        "attributes": {
            "closings": [
                "06cfde34-87b0-428d-8e4b-1b2834194552"
            ],
            "colorname": "zwart",
            "height": [
                "574c17d0-682d-40a5-a982-17ffae7b3015"
            ],
            "supplier": "Schulte BV - Schein Orthopädie Service KG",
            "modelname": "Ilona"
        }
    },
    {
        "modelnumber": "22363",
        "colornumber": "na",
        "brand": "LuCro",
        "lastgroup": "lucro-dames-classic",
        "filename": "",
        "category": "f",
        "modelname": "Ilona",
        "attributes": {
            "closings": [
                "06cfde34-87b0-428d-8e4b-1b2834194552"
            ],
            "colorname": "bruin",
            "height": [
                "574c17d0-682d-40a5-a982-17ffae7b3015"
            ],
            "supplier": "Schulte BV - Schein Orthopädie Service KG",
            "modelname": "Ilona"
        }
    },
    {
        "modelnumber": "22365",
        "colornumber": "na",
        "brand": "LuCro",
        "lastgroup": "lucro-dames-classic",
        "filename": "",
        "category": "f",
        "modelname": "Ilona",
        "attributes": {
            "closings": [
                "06cfde34-87b0-428d-8e4b-1b2834194552"
            ],
            "colorname": "beige",
            "height": [
                "574c17d0-682d-40a5-a982-17ffae7b3015"
            ],
            "supplier": "Schulte BV - Schein Orthopädie Service KG",
            "modelname": "Ilona"
        }
    },
    {
        "modelnumber": "22367",
        "colornumber": "na",
        "brand": "LuCro",
        "lastgroup": "lucro-dames-classic",
        "filename": "",
        "category": "f",
        "modelname": "Ilona",
        "attributes": {
            "closings": [
                "06cfde34-87b0-428d-8e4b-1b2834194552"
            ],
            "colorname": "grijs",
            "height": [
                "574c17d0-682d-40a5-a982-17ffae7b3015"
            ],
            "supplier": "Schulte BV - Schein Orthopädie Service KG",
            "modelname": "Ilona"
        }
    },
    {
        "modelnumber": "22368",
        "colornumber": "na",
        "brand": "LuCro",
        "lastgroup": "lucro-dames-classic",
        "filename": "",
        "category": "f",
        "modelname": "Ilona",
        "attributes": {
            "closings": [
                "06cfde34-87b0-428d-8e4b-1b2834194552"
            ],
            "colorname": "blauw",
            "height": [
                "574c17d0-682d-40a5-a982-17ffae7b3015"
            ],
            "supplier": "Schulte BV - Schein Orthopädie Service KG",
            "modelname": "Ilona"
        }
    },
    {
        "modelnumber": "22400",
        "colornumber": "na",
        "brand": "LuCro",
        "lastgroup": "lucro-dames-classic",
        "filename": "",
        "category": "f",
        "modelname": "Anita",
        "attributes": {
            "closings": [
                "06cfde34-87b0-428d-8e4b-1b2834194552"
            ],
            "colorname": "zwart",
            "height": [
                "574c17d0-682d-40a5-a982-17ffae7b3015"
            ],
            "supplier": "Schulte BV - Schein Orthopädie Service KG",
            "modelname": "Anita"
        }
    },
    {
        "modelnumber": "22401",
        "colornumber": "na",
        "brand": "LuCro",
        "lastgroup": "lucro-dames-classic",
        "filename": "",
        "category": "f",
        "modelname": "Anita",
        "attributes": {
            "closings": [
                "06cfde34-87b0-428d-8e4b-1b2834194552"
            ],
            "colorname": "beige",
            "height": [
                "574c17d0-682d-40a5-a982-17ffae7b3015"
            ],
            "supplier": "Schulte BV - Schein Orthopädie Service KG",
            "modelname": "Anita"
        }
    },
    {
        "modelnumber": "22403",
        "colornumber": "na",
        "brand": "LuCro",
        "lastgroup": "lucro-dames-classic",
        "filename": "",
        "category": "f",
        "modelname": "Anita",
        "attributes": {
            "closings": [
                "06cfde34-87b0-428d-8e4b-1b2834194552"
            ],
            "colorname": "bruin",
            "height": [
                "574c17d0-682d-40a5-a982-17ffae7b3015"
            ],
            "supplier": "Schulte BV - Schein Orthopädie Service KG",
            "modelname": "Anita"
        }
    },
    {
        "modelnumber": "22407",
        "colornumber": "na",
        "brand": "LuCro",
        "lastgroup": "lucro-dames-classic",
        "filename": "",
        "category": "f",
        "modelname": "Anita",
        "attributes": {
            "closings": [
                "06cfde34-87b0-428d-8e4b-1b2834194552"
            ],
            "colorname": "zwart",
            "height": [
                "574c17d0-682d-40a5-a982-17ffae7b3015"
            ],
            "supplier": "Schulte BV - Schein Orthopädie Service KG",
            "modelname": "Anita"
        }
    },
    {
        "modelnumber": "22408",
        "colornumber": "na",
        "brand": "LuCro",
        "lastgroup": "lucro-dames-classic",
        "filename": "",
        "category": "f",
        "modelname": "Anita",
        "attributes": {
            "closings": [
                "06cfde34-87b0-428d-8e4b-1b2834194552"
            ],
            "colorname": "blauw",
            "height": [
                "574c17d0-682d-40a5-a982-17ffae7b3015"
            ],
            "supplier": "Schulte BV - Schein Orthopädie Service KG",
            "modelname": "Anita"
        }
    },
    {
        "modelnumber": "22409",
        "colornumber": "na",
        "brand": "LuCro",
        "lastgroup": "lucro-dames-classic",
        "filename": "",
        "category": "f",
        "modelname": "Anita",
        "attributes": {
            "closings": [
                "06cfde34-87b0-428d-8e4b-1b2834194552"
            ],
            "colorname": "grijs",
            "height": [
                "574c17d0-682d-40a5-a982-17ffae7b3015"
            ],
            "supplier": "Schulte BV - Schein Orthopädie Service KG",
            "modelname": "Anita"
        }
    },
    {
        "modelnumber": "22423",
        "colornumber": "na",
        "brand": "LuCro",
        "lastgroup": "lucro-dames-classic",
        "filename": "",
        "category": "f",
        "modelname": "Monika",
        "attributes": {
            "closings": [
                "06cfde34-87b0-428d-8e4b-1b2834194552"
            ],
            "colorname": "bruin",
            "height": [
                "574c17d0-682d-40a5-a982-17ffae7b3015"
            ],
            "supplier": "Schulte BV - Schein Orthopädie Service KG",
            "modelname": "Monika"
        }
    },
    {
        "modelnumber": "22425",
        "colornumber": "na",
        "brand": "LuCro",
        "lastgroup": "lucro-dames-classic",
        "filename": "",
        "category": "f",
        "modelname": "Monika",
        "attributes": {
            "closings": [
                "06cfde34-87b0-428d-8e4b-1b2834194552"
            ],
            "colorname": "beige",
            "height": [
                "574c17d0-682d-40a5-a982-17ffae7b3015"
            ],
            "supplier": "Schulte BV - Schein Orthopädie Service KG",
            "modelname": "Monika"
        }
    },
    {
        "modelnumber": "22445",
        "colornumber": "na",
        "brand": "LuCro",
        "lastgroup": "lucro-dames-classic",
        "filename": "",
        "category": "f",
        "modelname": "Sylvia",
        "attributes": {
            "closings": [
                "06cfde34-87b0-428d-8e4b-1b2834194552"
            ],
            "colorname": "beige",
            "height": [
                "574c17d0-682d-40a5-a982-17ffae7b3015"
            ],
            "supplier": "Schulte BV - Schein Orthopädie Service KG",
            "modelname": "Sylvia"
        }
    },
    {
        "modelnumber": "24010",
        "colornumber": "na",
        "brand": "LuCro",
        "lastgroup": "lucro-heren-kinetic",
        "filename": "",
        "category": "m",
        "modelname": "Hans",
        "attributes": {
            "closings": [
                "06cfde34-87b0-428d-8e4b-1b2834194552"
            ],
            "colorname": "zwart",
            "height": [
                "574c17d0-682d-40a5-a982-17ffae7b3015"
            ],
            "supplier": "Schulte BV - Schein Orthopädie Service KG",
            "modelname": "Hans"
        }
    },
    {
        "modelnumber": "24013",
        "colornumber": "na",
        "brand": "LuCro",
        "lastgroup": "lucro-heren-kinetic",
        "filename": "",
        "category": "m",
        "modelname": "Hans",
        "attributes": {
            "closings": [
                "06cfde34-87b0-428d-8e4b-1b2834194552"
            ],
            "colorname": "cognac",
            "height": [
                "574c17d0-682d-40a5-a982-17ffae7b3015"
            ],
            "supplier": "Schulte BV - Schein Orthopädie Service KG",
            "modelname": "Hans"
        }
    },
    {
        "modelnumber": "24019",
        "colornumber": "na",
        "brand": "LuCro",
        "lastgroup": "lucro-heren-kinetic",
        "filename": "",
        "category": "m",
        "modelname": "Hans",
        "attributes": {
            "closings": [
                "06cfde34-87b0-428d-8e4b-1b2834194552"
            ],
            "colorname": "grijs",
            "height": [
                "574c17d0-682d-40a5-a982-17ffae7b3015"
            ],
            "supplier": "Schulte BV - Schein Orthopädie Service KG",
            "modelname": "Hans"
        }
    },
    {
        "modelnumber": "24020",
        "colornumber": "na",
        "brand": "LuCro",
        "lastgroup": "lucro-heren-kinetic",
        "filename": "",
        "category": "m",
        "modelname": "Klaus",
        "attributes": {
            "closings": [
                "06cfde34-87b0-428d-8e4b-1b2834194552"
            ],
            "colorname": "zwart",
            "height": [
                "574c17d0-682d-40a5-a982-17ffae7b3015"
            ],
            "supplier": "Schulte BV - Schein Orthopädie Service KG",
            "modelname": "Klaus"
        }
    },
    {
        "modelnumber": "24024",
        "colornumber": "na",
        "brand": "LuCro",
        "lastgroup": "lucro-heren-kinetic",
        "filename": "",
        "category": "m",
        "modelname": "Klaus",
        "attributes": {
            "closings": [
                "06cfde34-87b0-428d-8e4b-1b2834194552"
            ],
            "colorname": "bruin",
            "height": [
                "574c17d0-682d-40a5-a982-17ffae7b3015"
            ],
            "supplier": "Schulte BV - Schein Orthopädie Service KG",
            "modelname": "Klaus"
        }
    },
    {
        "modelnumber": "24025",
        "colornumber": "na",
        "brand": "LuCro",
        "lastgroup": "lucro-heren-kinetic",
        "filename": "",
        "category": "m",
        "modelname": "Klaus",
        "attributes": {
            "closings": [
                "06cfde34-87b0-428d-8e4b-1b2834194552"
            ],
            "colorname": "beige",
            "height": [
                "574c17d0-682d-40a5-a982-17ffae7b3015"
            ],
            "supplier": "Schulte BV - Schein Orthopädie Service KG",
            "modelname": "Klaus"
        }
    },
    {
        "modelnumber": "24030",
        "colornumber": "na",
        "brand": "LuCro",
        "lastgroup": "lucro-heren-kinetic",
        "filename": "",
        "category": "m",
        "modelname": "Holger",
        "attributes": {
            "closings": [
                "72589120-2085-4a19-bd1e-899c5a098a5f"
            ],
            "colorname": "zwart",
            "height": [
                "574c17d0-682d-40a5-a982-17ffae7b3015"
            ],
            "supplier": "Schulte BV - Schein Orthopädie Service KG",
            "modelname": "Holger"
        }
    },
    {
        "modelnumber": "24033",
        "colornumber": "na",
        "brand": "LuCro",
        "lastgroup": "lucro-heren-kinetic",
        "filename": "",
        "category": "m",
        "modelname": "Holger",
        "attributes": {
            "closings": [
                "72589120-2085-4a19-bd1e-899c5a098a5f"
            ],
            "colorname": "bruin",
            "height": [
                "574c17d0-682d-40a5-a982-17ffae7b3015"
            ],
            "supplier": "Schulte BV - Schein Orthopädie Service KG",
            "modelname": "Holger"
        }
    },
    {
        "modelnumber": "24037",
        "colornumber": "na",
        "brand": "LuCro",
        "lastgroup": "lucro-heren-kinetic",
        "filename": "",
        "category": "m",
        "modelname": "Holger",
        "attributes": {
            "closings": [
                "72589120-2085-4a19-bd1e-899c5a098a5f"
            ],
            "colorname": "zwart",
            "height": [
                "574c17d0-682d-40a5-a982-17ffae7b3015"
            ],
            "supplier": "Schulte BV - Schein Orthopädie Service KG",
            "modelname": "Holger"
        }
    },
    {
        "modelnumber": "24038",
        "colornumber": "na",
        "brand": "LuCro",
        "lastgroup": "lucro-heren-kinetic",
        "filename": "",
        "category": "m",
        "modelname": "Holger",
        "attributes": {
            "closings": [
                "72589120-2085-4a19-bd1e-899c5a098a5f"
            ],
            "colorname": "blauw",
            "height": [
                "574c17d0-682d-40a5-a982-17ffae7b3015"
            ],
            "supplier": "Schulte BV - Schein Orthopädie Service KG",
            "modelname": "Holger"
        }
    },
    {
        "modelnumber": "24040",
        "colornumber": "na",
        "brand": "LuCro",
        "lastgroup": "lucro-heren-kinetic",
        "filename": "",
        "category": "m",
        "modelname": "Norbert",
        "attributes": {
            "closings": [
                "72589120-2085-4a19-bd1e-899c5a098a5f"
            ],
            "colorname": "zwart",
            "height": [
                "574c17d0-682d-40a5-a982-17ffae7b3015"
            ],
            "supplier": "Schulte BV - Schein Orthopädie Service KG",
            "modelname": "Norbert"
        }
    },
    {
        "modelnumber": "24042",
        "colornumber": "na",
        "brand": "LuCro",
        "lastgroup": "lucro-heren-kinetic",
        "filename": "",
        "category": "m",
        "modelname": "Norbert",
        "attributes": {
            "closings": [
                "72589120-2085-4a19-bd1e-899c5a098a5f"
            ],
            "colorname": "zwart",
            "height": [
                "574c17d0-682d-40a5-a982-17ffae7b3015"
            ],
            "supplier": "Schulte BV - Schein Orthopädie Service KG",
            "modelname": "Norbert"
        }
    },
    {
        "modelnumber": "24043",
        "colornumber": "na",
        "brand": "LuCro",
        "lastgroup": "lucro-heren-kinetic",
        "filename": "",
        "category": "m",
        "modelname": "Norbert",
        "attributes": {
            "closings": [
                "72589120-2085-4a19-bd1e-899c5a098a5f"
            ],
            "colorname": "bruin",
            "height": [
                "574c17d0-682d-40a5-a982-17ffae7b3015"
            ],
            "supplier": "Schulte BV - Schein Orthopädie Service KG",
            "modelname": "Norbert"
        }
    },
    {
        "modelnumber": "24048",
        "colornumber": "na",
        "brand": "LuCro",
        "lastgroup": "lucro-heren-kinetic",
        "filename": "",
        "category": "m",
        "modelname": "Norbert",
        "attributes": {
            "closings": [
                "72589120-2085-4a19-bd1e-899c5a098a5f"
            ],
            "colorname": "blauw",
            "height": [
                "574c17d0-682d-40a5-a982-17ffae7b3015"
            ],
            "supplier": "Schulte BV - Schein Orthopädie Service KG",
            "modelname": "Norbert"
        }
    },
    {
        "modelnumber": "24050",
        "colornumber": "na",
        "brand": "LuCro",
        "lastgroup": "lucro-heren-kinetic",
        "filename": "",
        "category": "m",
        "modelname": "Rolf",
        "attributes": {
            "closings": [
                "06cfde34-87b0-428d-8e4b-1b2834194552"
            ],
            "colorname": "zwart",
            "height": [
                "574c17d0-682d-40a5-a982-17ffae7b3015"
            ],
            "supplier": "Schulte BV - Schein Orthopädie Service KG",
            "modelname": "Rolf"
        }
    },
    {
        "modelnumber": "24052",
        "colornumber": "na",
        "brand": "LuCro",
        "lastgroup": "lucro-heren-kinetic",
        "filename": "",
        "category": "m",
        "modelname": "Rolf",
        "attributes": {
            "closings": [
                "06cfde34-87b0-428d-8e4b-1b2834194552"
            ],
            "colorname": "zwart",
            "height": [
                "574c17d0-682d-40a5-a982-17ffae7b3015"
            ],
            "supplier": "Schulte BV - Schein Orthopädie Service KG",
            "modelname": "Rolf"
        }
    },
    {
        "modelnumber": "24053",
        "colornumber": "na",
        "brand": "LuCro",
        "lastgroup": "lucro-heren-kinetic",
        "filename": "",
        "category": "m",
        "modelname": "Rolf",
        "attributes": {
            "closings": [
                "06cfde34-87b0-428d-8e4b-1b2834194552"
            ],
            "colorname": "bruin",
            "height": [
                "574c17d0-682d-40a5-a982-17ffae7b3015"
            ],
            "supplier": "Schulte BV - Schein Orthopädie Service KG",
            "modelname": "Rolf"
        }
    },
    {
        "modelnumber": "24058",
        "colornumber": "na",
        "brand": "LuCro",
        "lastgroup": "lucro-heren-kinetic",
        "filename": "",
        "category": "m",
        "modelname": "Rolf",
        "attributes": {
            "closings": [
                "06cfde34-87b0-428d-8e4b-1b2834194552"
            ],
            "colorname": "blauw",
            "height": [
                "574c17d0-682d-40a5-a982-17ffae7b3015"
            ],
            "supplier": "Schulte BV - Schein Orthopädie Service KG",
            "modelname": "Rolf"
        }
    },
    {
        "modelnumber": "24060",
        "colornumber": "na",
        "brand": "LuCro",
        "lastgroup": "lucro-heren-kinetic",
        "filename": "",
        "category": "m",
        "modelname": "Rüdiger",
        "attributes": {
            "closings": [
                "72589120-2085-4a19-bd1e-899c5a098a5f"
            ],
            "colorname": "zwart",
            "height": [
                "b591a1ea-c12b-4ab7-a414-9ed5e45de1dc"
            ],
            "supplier": "Schulte BV - Schein Orthopädie Service KG",
            "modelname": "Rüdiger"
        }
    },
    {
        "modelnumber": "24063",
        "colornumber": "na",
        "brand": "LuCro",
        "lastgroup": "lucro-heren-kinetic",
        "filename": "",
        "category": "m",
        "modelname": "Rüdiger",
        "attributes": {
            "closings": [
                "72589120-2085-4a19-bd1e-899c5a098a5f"
            ],
            "colorname": "bruin",
            "height": [
                "b591a1ea-c12b-4ab7-a414-9ed5e45de1dc"
            ],
            "supplier": "Schulte BV - Schein Orthopädie Service KG",
            "modelname": "Rüdiger"
        }
    },
    {
        "modelnumber": "24072",
        "colornumber": "na",
        "brand": "LuCro",
        "lastgroup": "lucro-heren-kinetic",
        "filename": "",
        "category": "m",
        "modelname": "Andreas",
        "attributes": {
            "closings": [
                "06cfde34-87b0-428d-8e4b-1b2834194552"
            ],
            "colorname": "zwart",
            "height": [
                "574c17d0-682d-40a5-a982-17ffae7b3015"
            ],
            "supplier": "Schulte BV - Schein Orthopädie Service KG",
            "modelname": "Andreas"
        }
    },
    {
        "modelnumber": "24073",
        "colornumber": "na",
        "brand": "LuCro",
        "lastgroup": "lucro-heren-kinetic",
        "filename": "",
        "category": "m",
        "modelname": "Andreas",
        "attributes": {
            "closings": [
                "06cfde34-87b0-428d-8e4b-1b2834194552"
            ],
            "colorname": "bruin",
            "height": [
                "574c17d0-682d-40a5-a982-17ffae7b3015"
            ],
            "supplier": "Schulte BV - Schein Orthopädie Service KG",
            "modelname": "Andreas"
        }
    },
    {
        "modelnumber": "24085",
        "colornumber": "na",
        "brand": "LuCro",
        "lastgroup": "lucro-heren-kinetic",
        "filename": "",
        "category": "m",
        "modelname": "Stefan",
        "attributes": {
            "closings": [
                "72589120-2085-4a19-bd1e-899c5a098a5f"
            ],
            "colorname": "TAUPE",
            "height": [
                "574c17d0-682d-40a5-a982-17ffae7b3015"
            ],
            "supplier": "Schulte BV - Schein Orthopädie Service KG",
            "modelname": "Stefan"
        }
    },
    {
        "modelnumber": "24088",
        "colornumber": "na",
        "brand": "LuCro",
        "lastgroup": "lucro-heren-kinetic",
        "filename": "",
        "category": "m",
        "modelname": "Stefan",
        "attributes": {
            "closings": [
                "72589120-2085-4a19-bd1e-899c5a098a5f"
            ],
            "colorname": "blauw",
            "height": [
                "574c17d0-682d-40a5-a982-17ffae7b3015"
            ],
            "supplier": "Schulte BV - Schein Orthopädie Service KG",
            "modelname": "Stefan"
        }
    },
    {
        "modelnumber": "24092",
        "colornumber": "na",
        "brand": "LuCro",
        "lastgroup": "lucro-heren-kinetic",
        "filename": "",
        "category": "m",
        "modelname": "Gerhard",
        "attributes": {
            "closings": [
                "06cfde34-87b0-428d-8e4b-1b2834194552"
            ],
            "colorname": "zwart",
            "height": [
                "574c17d0-682d-40a5-a982-17ffae7b3015"
            ],
            "supplier": "Schulte BV - Schein Orthopädie Service KG",
            "modelname": "Gerhard"
        }
    },
    {
        "modelnumber": "24099",
        "colornumber": "na",
        "brand": "LuCro",
        "lastgroup": "lucro-heren-kinetic",
        "filename": "",
        "category": "m",
        "modelname": "Gerhard",
        "attributes": {
            "closings": [
                "06cfde34-87b0-428d-8e4b-1b2834194552"
            ],
            "colorname": "grijs",
            "height": [
                "574c17d0-682d-40a5-a982-17ffae7b3015"
            ],
            "supplier": "Schulte BV - Schein Orthopädie Service KG",
            "modelname": "Gerhard"
        }
    },
    {
        "modelnumber": "24102",
        "colornumber": "na",
        "brand": "LuCro",
        "lastgroup": "lucro-heren-kinetic",
        "filename": "",
        "category": "m",
        "modelname": "Mathias",
        "attributes": {
            "closings": [
                "06cfde34-87b0-428d-8e4b-1b2834194552"
            ],
            "colorname": "zwart",
            "height": [
                "b591a1ea-c12b-4ab7-a414-9ed5e45de1dc"
            ],
            "supplier": "Schulte BV - Schein Orthopädie Service KG",
            "modelname": "Mathias"
        }
    },
    {
        "modelnumber": "24108",
        "colornumber": "na",
        "brand": "LuCro",
        "lastgroup": "lucro-heren-kinetic",
        "filename": "",
        "category": "m",
        "modelname": "Mathias",
        "attributes": {
            "closings": [
                "06cfde34-87b0-428d-8e4b-1b2834194552"
            ],
            "colorname": "blauw",
            "height": [
                "b591a1ea-c12b-4ab7-a414-9ed5e45de1dc"
            ],
            "supplier": "Schulte BV - Schein Orthopädie Service KG",
            "modelname": "Mathias"
        }
    },
    {
        "modelnumber": "25010",
        "colornumber": "na",
        "brand": "LuCro",
        "lastgroup": "lucro-dames-kinetic",
        "filename": "",
        "category": "f",
        "modelname": "Angelika",
        "attributes": {
            "closings": [
                "06cfde34-87b0-428d-8e4b-1b2834194552"
            ],
            "colorname": "zwart",
            "height": [
                "b591a1ea-c12b-4ab7-a414-9ed5e45de1dc"
            ],
            "supplier": "Schulte BV - Schein Orthopädie Service KG",
            "modelname": "Angelika"
        }
    },
    {
        "modelnumber": "25011",
        "colornumber": "na",
        "brand": "LuCro",
        "lastgroup": "lucro-dames-kinetic",
        "filename": "",
        "category": "f",
        "modelname": "Angelika",
        "attributes": {
            "closings": [
                "06cfde34-87b0-428d-8e4b-1b2834194552"
            ],
            "colorname": "ice",
            "height": [
                "b591a1ea-c12b-4ab7-a414-9ed5e45de1dc"
            ],
            "supplier": "Schulte BV - Schein Orthopädie Service KG",
            "modelname": "Angelika"
        }
    },
    {
        "modelnumber": "25012",
        "colornumber": "na",
        "brand": "LuCro",
        "lastgroup": "lucro-dames-kinetic",
        "filename": "",
        "category": "f",
        "modelname": "Angelika",
        "attributes": {
            "closings": [
                "06cfde34-87b0-428d-8e4b-1b2834194552"
            ],
            "colorname": "TAUPE",
            "height": [
                "b591a1ea-c12b-4ab7-a414-9ed5e45de1dc"
            ],
            "supplier": "Schulte BV - Schein Orthopädie Service KG",
            "modelname": "Angelika"
        }
    },
    {
        "modelnumber": "25020",
        "colornumber": "na",
        "brand": "LuCro",
        "lastgroup": "lucro-dames-kinetic",
        "filename": "",
        "category": "f",
        "modelname": "Brigitte",
        "attributes": {
            "closings": [
                "06cfde34-87b0-428d-8e4b-1b2834194552"
            ],
            "colorname": "zwart",
            "height": [
                "574c17d0-682d-40a5-a982-17ffae7b3015"
            ],
            "supplier": "Schulte BV - Schein Orthopädie Service KG",
            "modelname": "Brigitte"
        }
    },
    {
        "modelnumber": "25023",
        "colornumber": "na",
        "brand": "LuCro",
        "lastgroup": "lucro-dames-kinetic",
        "filename": "",
        "category": "f",
        "modelname": "Brigitte",
        "attributes": {
            "closings": [
                "06cfde34-87b0-428d-8e4b-1b2834194552"
            ],
            "colorname": "bruin",
            "height": [
                "574c17d0-682d-40a5-a982-17ffae7b3015"
            ],
            "supplier": "Schulte BV - Schein Orthopädie Service KG",
            "modelname": "Brigitte"
        }
    },
    {
        "modelnumber": "25027",
        "colornumber": "na",
        "brand": "LuCro",
        "lastgroup": "lucro-dames-kinetic",
        "filename": "",
        "category": "f",
        "modelname": "Brigitte",
        "attributes": {
            "closings": [
                "06cfde34-87b0-428d-8e4b-1b2834194552"
            ],
            "colorname": "grijs",
            "height": [
                "574c17d0-682d-40a5-a982-17ffae7b3015"
            ],
            "supplier": "Schulte BV - Schein Orthopädie Service KG",
            "modelname": "Brigitte"
        }
    },
    {
        "modelnumber": "25030",
        "colornumber": "na",
        "brand": "LuCro",
        "lastgroup": "lucro-dames-kinetic",
        "filename": "",
        "category": "f",
        "modelname": "Gabriele",
        "attributes": {
            "closings": [
                "72589120-2085-4a19-bd1e-899c5a098a5f"
            ],
            "colorname": "zwart",
            "height": [
                "574c17d0-682d-40a5-a982-17ffae7b3015"
            ],
            "supplier": "Schulte BV - Schein Orthopädie Service KG",
            "modelname": "Gabriele"
        }
    },
    {
        "modelnumber": "25050",
        "colornumber": "na",
        "brand": "LuCro",
        "lastgroup": "lucro-dames-kinetic",
        "filename": "",
        "category": "f",
        "modelname": "Ingrid",
        "attributes": {
            "closings": [
                "06cfde34-87b0-428d-8e4b-1b2834194552"
            ],
            "colorname": "zwart",
            "height": [
                "574c17d0-682d-40a5-a982-17ffae7b3015"
            ],
            "supplier": "Schulte BV - Schein Orthopädie Service KG",
            "modelname": "Ingrid"
        }
    },
    {
        "modelnumber": "25052",
        "colornumber": "na",
        "brand": "LuCro",
        "lastgroup": "lucro-dames-kinetic",
        "filename": "",
        "category": "f",
        "modelname": "Ingrid",
        "attributes": {
            "closings": [
                "06cfde34-87b0-428d-8e4b-1b2834194552"
            ],
            "colorname": "TAUPE",
            "height": [
                "574c17d0-682d-40a5-a982-17ffae7b3015"
            ],
            "supplier": "Schulte BV - Schein Orthopädie Service KG",
            "modelname": "Ingrid"
        }
    },
    {
        "modelnumber": "25053",
        "colornumber": "na",
        "brand": "LuCro",
        "lastgroup": "lucro-dames-kinetic",
        "filename": "",
        "category": "f",
        "modelname": "Ingrid",
        "attributes": {
            "closings": [
                "06cfde34-87b0-428d-8e4b-1b2834194552"
            ],
            "colorname": "rood",
            "height": [
                "574c17d0-682d-40a5-a982-17ffae7b3015"
            ],
            "supplier": "Schulte BV - Schein Orthopädie Service KG",
            "modelname": "Ingrid"
        }
    },
    {
        "modelnumber": "25058",
        "colornumber": "na",
        "brand": "LuCro",
        "lastgroup": "lucro-dames-kinetic",
        "filename": "",
        "category": "f",
        "modelname": "Ingrid",
        "attributes": {
            "closings": [
                "06cfde34-87b0-428d-8e4b-1b2834194552"
            ],
            "colorname": "blauw",
            "height": [
                "574c17d0-682d-40a5-a982-17ffae7b3015"
            ],
            "supplier": "Schulte BV - Schein Orthopädie Service KG",
            "modelname": "Ingrid"
        }
    },
    {
        "modelnumber": "25059",
        "colornumber": "na",
        "brand": "LuCro",
        "lastgroup": "lucro-dames-kinetic",
        "filename": "",
        "category": "f",
        "modelname": "Ingrid",
        "attributes": {
            "closings": [
                "06cfde34-87b0-428d-8e4b-1b2834194552"
            ],
            "colorname": "grijs",
            "height": [
                "574c17d0-682d-40a5-a982-17ffae7b3015"
            ],
            "supplier": "Schulte BV - Schein Orthopädie Service KG",
            "modelname": "Ingrid"
        }
    },
    {
        "modelnumber": "25070",
        "colornumber": "na",
        "brand": "LuCro",
        "lastgroup": "lucro-dames-kinetic",
        "filename": "",
        "category": "f",
        "modelname": "Sabine",
        "attributes": {
            "closings": [
                "06cfde34-87b0-428d-8e4b-1b2834194552"
            ],
            "colorname": "zwart",
            "height": [
                "574c17d0-682d-40a5-a982-17ffae7b3015"
            ],
            "supplier": "Schulte BV - Schein Orthopädie Service KG",
            "modelname": "Sabine"
        }
    },
    {
        "modelnumber": "25072",
        "colornumber": "na",
        "brand": "LuCro",
        "lastgroup": "lucro-dames-kinetic",
        "filename": "",
        "category": "f",
        "modelname": "Sabine",
        "attributes": {
            "closings": [
                "06cfde34-87b0-428d-8e4b-1b2834194552"
            ],
            "colorname": "TAUPE",
            "height": [
                "574c17d0-682d-40a5-a982-17ffae7b3015"
            ],
            "supplier": "Schulte BV - Schein Orthopädie Service KG",
            "modelname": "Sabine"
        }
    },
    {
        "modelnumber": "25077",
        "colornumber": "na",
        "brand": "LuCro",
        "lastgroup": "lucro-dames-kinetic",
        "filename": "",
        "category": "f",
        "modelname": "Sabine",
        "attributes": {
            "closings": [
                "06cfde34-87b0-428d-8e4b-1b2834194552"
            ],
            "colorname": "zwart",
            "height": [
                "574c17d0-682d-40a5-a982-17ffae7b3015"
            ],
            "supplier": "Schulte BV - Schein Orthopädie Service KG",
            "modelname": "Sabine"
        }
    },
    {
        "modelnumber": "25110",
        "colornumber": "na",
        "brand": "LuCro",
        "lastgroup": "lucro-dames-kinetic",
        "filename": "",
        "category": "f",
        "modelname": "Elke",
        "attributes": {
            "closings": [
                "06cfde34-87b0-428d-8e4b-1b2834194552"
            ],
            "colorname": "zwart",
            "height": [
                "574c17d0-682d-40a5-a982-17ffae7b3015"
            ],
            "supplier": "Schulte BV - Schein Orthopädie Service KG",
            "modelname": "Elke"
        }
    },
    {
        "modelnumber": "25112",
        "colornumber": "na",
        "brand": "LuCro",
        "lastgroup": "lucro-dames-kinetic",
        "filename": "",
        "category": "f",
        "modelname": "Elke",
        "attributes": {
            "closings": [
                "06cfde34-87b0-428d-8e4b-1b2834194552"
            ],
            "colorname": "TAUPE",
            "height": [
                "574c17d0-682d-40a5-a982-17ffae7b3015"
            ],
            "supplier": "Schulte BV - Schein Orthopädie Service KG",
            "modelname": "Elke"
        }
    },
    {
        "modelnumber": "25350",
        "colornumber": "na",
        "brand": "LuCro",
        "lastgroup": "lucro-dames-kinetic",
        "filename": "",
        "category": "f",
        "modelname": "Kerstin",
        "attributes": {
            "closings": [
                "06cfde34-87b0-428d-8e4b-1b2834194552"
            ],
            "colorname": "zwart",
            "height": [
                "574c17d0-682d-40a5-a982-17ffae7b3015"
            ],
            "supplier": "Schulte BV - Schein Orthopädie Service KG",
            "modelname": "Kerstin"
        }
    },
    {
        "modelnumber": "25351",
        "colornumber": "na",
        "brand": "LuCro",
        "lastgroup": "lucro-dames-kinetic",
        "filename": "",
        "category": "f",
        "modelname": "Kerstin",
        "attributes": {
            "closings": [
                "06cfde34-87b0-428d-8e4b-1b2834194552"
            ],
            "colorname": "beige",
            "height": [
                "574c17d0-682d-40a5-a982-17ffae7b3015"
            ],
            "supplier": "Schulte BV - Schein Orthopädie Service KG",
            "modelname": "Kerstin"
        }
    },
    {
        "modelnumber": "25353",
        "colornumber": "na",
        "brand": "LuCro",
        "lastgroup": "lucro-dames-kinetic",
        "filename": "",
        "category": "f",
        "modelname": "Kerstin",
        "attributes": {
            "closings": [
                "06cfde34-87b0-428d-8e4b-1b2834194552"
            ],
            "colorname": "bruin",
            "height": [
                "574c17d0-682d-40a5-a982-17ffae7b3015"
            ],
            "supplier": "Schulte BV - Schein Orthopädie Service KG",
            "modelname": "Kerstin"
        }
    },
    {
        "modelnumber": "25375",
        "colornumber": "na",
        "brand": "LuCro",
        "lastgroup": "lucro-dames-kinetic",
        "filename": "",
        "category": "f",
        "modelname": "Sabine",
        "attributes": {
            "closings": [
                "06cfde34-87b0-428d-8e4b-1b2834194552"
            ],
            "colorname": "beige",
            "height": [
                "574c17d0-682d-40a5-a982-17ffae7b3015"
            ],
            "supplier": "Schulte BV - Schein Orthopädie Service KG",
            "modelname": "Sabine"
        }
    },
    {
        "modelnumber": "25379",
        "colornumber": "na",
        "brand": "LuCro",
        "lastgroup": "lucro-dames-kinetic",
        "filename": "",
        "category": "f",
        "modelname": "Sabine",
        "attributes": {
            "closings": [
                "06cfde34-87b0-428d-8e4b-1b2834194552"
            ],
            "colorname": "grijs",
            "height": [
                "574c17d0-682d-40a5-a982-17ffae7b3015"
            ],
            "supplier": "Schulte BV - Schein Orthopädie Service KG",
            "modelname": "Sabine"
        }
    },
    {
        "modelnumber": "26010",
        "colornumber": "na",
        "brand": "LuCro",
        "lastgroup": "lucro-heren-ergonic",
        "filename": "",
        "category": "m",
        "modelname": "Lothar",
        "attributes": {
            "closings": [
                "06cfde34-87b0-428d-8e4b-1b2834194552"
            ],
            "colorname": "zwart",
            "height": [
                "574c17d0-682d-40a5-a982-17ffae7b3015"
            ],
            "supplier": "Schulte BV - Schein Orthopädie Service KG",
            "modelname": "Lothar"
        }
    },
    {
        "modelnumber": "26019",
        "colornumber": "na",
        "brand": "LuCro",
        "lastgroup": "lucro-heren-ergonic",
        "filename": "",
        "category": "m",
        "modelname": "Lothar",
        "attributes": {
            "closings": [
                "06cfde34-87b0-428d-8e4b-1b2834194552"
            ],
            "colorname": "grijs",
            "height": [
                "574c17d0-682d-40a5-a982-17ffae7b3015"
            ],
            "supplier": "Schulte BV - Schein Orthopädie Service KG",
            "modelname": "Lothar"
        }
    },
    {
        "modelnumber": "26020",
        "colornumber": "na",
        "brand": "LuCro",
        "lastgroup": "lucro-heren-ergonic",
        "filename": "",
        "category": "m",
        "modelname": "Jürgen",
        "attributes": {
            "closings": [
                "06cfde34-87b0-428d-8e4b-1b2834194552"
            ],
            "colorname": "zwart",
            "height": [
                "574c17d0-682d-40a5-a982-17ffae7b3015"
            ],
            "supplier": "Schulte BV - Schein Orthopädie Service KG",
            "modelname": "Jürgen"
        }
    },
    {
        "modelnumber": "26023",
        "colornumber": "na",
        "brand": "LuCro",
        "lastgroup": "lucro-heren-ergonic",
        "filename": "",
        "category": "m",
        "modelname": "Jürgen",
        "attributes": {
            "closings": [
                "06cfde34-87b0-428d-8e4b-1b2834194552"
            ],
            "colorname": "bruin",
            "height": [
                "574c17d0-682d-40a5-a982-17ffae7b3015"
            ],
            "supplier": "Schulte BV - Schein Orthopädie Service KG",
            "modelname": "Jürgen"
        }
    },
    {
        "modelnumber": "26032",
        "colornumber": "na",
        "brand": "LuCro",
        "lastgroup": "lucro-heren-ergonic",
        "filename": "",
        "category": "m",
        "modelname": "Manfred",
        "attributes": {
            "closings": [
                "72589120-2085-4a19-bd1e-899c5a098a5f"
            ],
            "colorname": "zwart",
            "height": [
                "574c17d0-682d-40a5-a982-17ffae7b3015"
            ],
            "supplier": "Schulte BV - Schein Orthopädie Service KG",
            "modelname": "Manfred"
        }
    },
    {
        "modelnumber": "26033",
        "colornumber": "na",
        "brand": "LuCro",
        "lastgroup": "lucro-heren-ergonic",
        "filename": "",
        "category": "m",
        "modelname": "Manfred",
        "attributes": {
            "closings": [
                "72589120-2085-4a19-bd1e-899c5a098a5f"
            ],
            "colorname": "bruin",
            "height": [
                "574c17d0-682d-40a5-a982-17ffae7b3015"
            ],
            "supplier": "Schulte BV - Schein Orthopädie Service KG",
            "modelname": "Manfred"
        }
    },
    {
        "modelnumber": "26040",
        "colornumber": "na",
        "brand": "LuCro",
        "lastgroup": "lucro-heren-ergonic",
        "filename": "",
        "category": "m",
        "modelname": "Karl",
        "attributes": {
            "closings": [
                "06cfde34-87b0-428d-8e4b-1b2834194552"
            ],
            "colorname": "zwart",
            "height": [
                "574c17d0-682d-40a5-a982-17ffae7b3015"
            ],
            "supplier": "Schulte BV - Schein Orthopädie Service KG",
            "modelname": "Karl"
        }
    },
    {
        "modelnumber": "26042",
        "colornumber": "na",
        "brand": "LuCro",
        "lastgroup": "lucro-heren-ergonic",
        "filename": "",
        "category": "m",
        "modelname": "Karl",
        "attributes": {
            "closings": [
                "06cfde34-87b0-428d-8e4b-1b2834194552"
            ],
            "colorname": "zwart",
            "height": [
                "574c17d0-682d-40a5-a982-17ffae7b3015"
            ],
            "supplier": "Schulte BV - Schein Orthopädie Service KG",
            "modelname": "Karl"
        }
    },
    {
        "modelnumber": "26043",
        "colornumber": "na",
        "brand": "LuCro",
        "lastgroup": "lucro-heren-ergonic",
        "filename": "",
        "category": "m",
        "modelname": "Karl",
        "attributes": {
            "closings": [
                "06cfde34-87b0-428d-8e4b-1b2834194552"
            ],
            "colorname": "bruin",
            "height": [
                "574c17d0-682d-40a5-a982-17ffae7b3015"
            ],
            "supplier": "Schulte BV - Schein Orthopädie Service KG",
            "modelname": "Karl"
        }
    },
    {
        "modelnumber": "26048",
        "colornumber": "na",
        "brand": "LuCro",
        "lastgroup": "lucro-heren-ergonic",
        "filename": "",
        "category": "m",
        "modelname": "Karl",
        "attributes": {
            "closings": [
                "06cfde34-87b0-428d-8e4b-1b2834194552"
            ],
            "colorname": "blauw",
            "height": [
                "574c17d0-682d-40a5-a982-17ffae7b3015"
            ],
            "supplier": "Schulte BV - Schein Orthopädie Service KG",
            "modelname": "Karl"
        }
    },
    {
        "modelnumber": "26050",
        "colornumber": "na",
        "brand": "LuCro",
        "lastgroup": "lucro-heren-ergonic",
        "filename": "",
        "category": "m",
        "modelname": "Heinz",
        "attributes": {
            "closings": [
                "72589120-2085-4a19-bd1e-899c5a098a5f"
            ],
            "colorname": "zwart",
            "height": [
                "574c17d0-682d-40a5-a982-17ffae7b3015"
            ],
            "supplier": "Schulte BV - Schein Orthopädie Service KG",
            "modelname": "Heinz"
        }
    },
    {
        "modelnumber": "26060",
        "colornumber": "na",
        "brand": "LuCro",
        "lastgroup": "lucro-heren-ergonic",
        "filename": "",
        "category": "m",
        "modelname": "Detlef",
        "attributes": {
            "closings": [
                "06cfde34-87b0-428d-8e4b-1b2834194552"
            ],
            "colorname": "zwart",
            "height": [
                "b591a1ea-c12b-4ab7-a414-9ed5e45de1dc"
            ],
            "supplier": "Schulte BV - Schein Orthopädie Service KG",
            "modelname": "Detlef"
        }
    },
    {
        "modelnumber": "26065",
        "colornumber": "na",
        "brand": "LuCro",
        "lastgroup": "lucro-heren-ergonic",
        "filename": "",
        "category": "m",
        "modelname": "Detlef",
        "attributes": {
            "closings": [
                "06cfde34-87b0-428d-8e4b-1b2834194552"
            ],
            "colorname": "bruin",
            "height": [
                "b591a1ea-c12b-4ab7-a414-9ed5e45de1dc"
            ],
            "supplier": "Schulte BV - Schein Orthopädie Service KG",
            "modelname": "Detlef"
        }
    },
    {
        "modelnumber": "26070",
        "colornumber": "na",
        "brand": "LuCro",
        "lastgroup": "lucro-heren-ergonic",
        "filename": "",
        "category": "m",
        "modelname": "Reiner",
        "attributes": {
            "closings": [
                "06cfde34-87b0-428d-8e4b-1b2834194552"
            ],
            "colorname": "zwart",
            "height": [
                "574c17d0-682d-40a5-a982-17ffae7b3015"
            ],
            "supplier": "Schulte BV - Schein Orthopädie Service KG",
            "modelname": "Reiner"
        }
    },
    {
        "modelnumber": "27010",
        "colornumber": "na",
        "brand": "LuCro",
        "lastgroup": "lucro-dames-ergonic",
        "filename": "",
        "category": "f",
        "modelname": "Gisela",
        "attributes": {
            "closings": [
                "06cfde34-87b0-428d-8e4b-1b2834194552"
            ],
            "colorname": "zwart",
            "height": [
                "574c17d0-682d-40a5-a982-17ffae7b3015"
            ],
            "supplier": "Schulte BV - Schein Orthopädie Service KG",
            "modelname": "Gisela"
        }
    },
    {
        "modelnumber": "27011",
        "colornumber": "na",
        "brand": "LuCro",
        "lastgroup": "lucro-dames-ergonic",
        "filename": "",
        "category": "f",
        "modelname": "Gisela",
        "attributes": {
            "closings": [
                "06cfde34-87b0-428d-8e4b-1b2834194552"
            ],
            "colorname": "ice",
            "height": [
                "574c17d0-682d-40a5-a982-17ffae7b3015"
            ],
            "supplier": "Schulte BV - Schein Orthopädie Service KG",
            "modelname": "Gisela"
        }
    },
    {
        "modelnumber": "27015",
        "colornumber": "na",
        "brand": "LuCro",
        "lastgroup": "lucro-dames-ergonic",
        "filename": "",
        "category": "f",
        "modelname": "Gisela",
        "attributes": {
            "closings": [
                "06cfde34-87b0-428d-8e4b-1b2834194552"
            ],
            "colorname": "beige",
            "height": [
                "574c17d0-682d-40a5-a982-17ffae7b3015"
            ],
            "supplier": "Schulte BV - Schein Orthopädie Service KG",
            "modelname": "Gisela"
        }
    },
    {
        "modelnumber": "27018",
        "colornumber": "na",
        "brand": "LuCro",
        "lastgroup": "lucro-dames-ergonic",
        "filename": "",
        "category": "f",
        "modelname": "Gisela",
        "attributes": {
            "closings": [
                "06cfde34-87b0-428d-8e4b-1b2834194552"
            ],
            "colorname": "blauw",
            "height": [
                "574c17d0-682d-40a5-a982-17ffae7b3015"
            ],
            "supplier": "Schulte BV - Schein Orthopädie Service KG",
            "modelname": "Gisela"
        }
    },
    {
        "modelnumber": "27022",
        "colornumber": "na",
        "brand": "LuCro",
        "lastgroup": "lucro-dames-ergonic",
        "filename": "",
        "category": "f",
        "modelname": "Helga",
        "attributes": {
            "closings": [
                "06cfde34-87b0-428d-8e4b-1b2834194552"
            ],
            "colorname": "zwart",
            "height": [
                "574c17d0-682d-40a5-a982-17ffae7b3015"
            ],
            "supplier": "Schulte BV - Schein Orthopädie Service KG",
            "modelname": "Helga"
        }
    },
    {
        "modelnumber": "27030",
        "colornumber": "na",
        "brand": "LuCro",
        "lastgroup": "lucro-dames-ergonic",
        "filename": "",
        "category": "f",
        "modelname": "Hannelore",
        "attributes": {
            "closings": [
                "72589120-2085-4a19-bd1e-899c5a098a5f"
            ],
            "colorname": "zwart",
            "height": [
                "574c17d0-682d-40a5-a982-17ffae7b3015"
            ],
            "supplier": "Schulte BV - Schein Orthopädie Service KG",
            "modelname": "Hannelore"
        }
    },
    {
        "modelnumber": "27042",
        "colornumber": "na",
        "brand": "LuCro",
        "lastgroup": "lucro-dames-ergonic",
        "filename": "",
        "category": "f",
        "modelname": "Bärbel",
        "attributes": {
            "closings": [
                "06cfde34-87b0-428d-8e4b-1b2834194552"
            ],
            "colorname": "zwart",
            "height": [
                "574c17d0-682d-40a5-a982-17ffae7b3015"
            ],
            "supplier": "Schulte BV - Schein Orthopädie Service KG",
            "modelname": "Bärbel"
        }
    },
    {
        "modelnumber": "27044",
        "colornumber": "na",
        "brand": "LuCro",
        "lastgroup": "lucro-dames-ergonic",
        "filename": "",
        "category": "f",
        "modelname": "Bärbel",
        "attributes": {
            "closings": [
                "06cfde34-87b0-428d-8e4b-1b2834194552"
            ],
            "colorname": "beige",
            "height": [
                "574c17d0-682d-40a5-a982-17ffae7b3015"
            ],
            "supplier": "Schulte BV - Schein Orthopädie Service KG",
            "modelname": "Bärbel"
        }
    },
    {
        "modelnumber": "27047",
        "colornumber": "na",
        "brand": "LuCro",
        "lastgroup": "lucro-dames-ergonic",
        "filename": "",
        "category": "f",
        "modelname": "Bärbel",
        "attributes": {
            "closings": [
                "06cfde34-87b0-428d-8e4b-1b2834194552"
            ],
            "colorname": "grijs",
            "height": [
                "574c17d0-682d-40a5-a982-17ffae7b3015"
            ],
            "supplier": "Schulte BV - Schein Orthopädie Service KG",
            "modelname": "Bärbel"
        }
    },
    {
        "modelnumber": "27050",
        "colornumber": "na",
        "brand": "LuCro",
        "lastgroup": "lucro-dames-ergonic",
        "filename": "",
        "category": "f",
        "modelname": "Carola",
        "attributes": {
            "closings": [
                "06cfde34-87b0-428d-8e4b-1b2834194552"
            ],
            "colorname": "zwart",
            "height": [
                "b591a1ea-c12b-4ab7-a414-9ed5e45de1dc"
            ],
            "supplier": "Schulte BV - Schein Orthopädie Service KG",
            "modelname": "Carola"
        }
    },
    {
        "modelnumber": "27060",
        "colornumber": "na",
        "brand": "LuCro",
        "lastgroup": "lucro-dames-ergonic",
        "filename": "",
        "category": "f",
        "modelname": "Marlies",
        "attributes": {
            "closings": [
                "06cfde34-87b0-428d-8e4b-1b2834194552"
            ],
            "colorname": "zwart",
            "height": [
                "574c17d0-682d-40a5-a982-17ffae7b3015"
            ],
            "supplier": "Schulte BV - Schein Orthopädie Service KG",
            "modelname": "Marlies"
        }
    },
    {
        "modelnumber": "27063",
        "colornumber": "na",
        "brand": "LuCro",
        "lastgroup": "lucro-dames-ergonic",
        "filename": "",
        "category": "f",
        "modelname": "Marlies",
        "attributes": {
            "closings": [
                "06cfde34-87b0-428d-8e4b-1b2834194552"
            ],
            "colorname": "TAUPE",
            "height": [
                "574c17d0-682d-40a5-a982-17ffae7b3015"
            ],
            "supplier": "Schulte BV - Schein Orthopädie Service KG",
            "modelname": "Marlies"
        }
    },
    {
        "modelnumber": "27070",
        "colornumber": "na",
        "brand": "LuCro",
        "lastgroup": "lucro-dames-ergonic",
        "filename": "",
        "category": "f",
        "modelname": "Marianne",
        "attributes": {
            "closings": [
                "06cfde34-87b0-428d-8e4b-1b2834194552"
            ],
            "colorname": "zwart",
            "height": [
                "574c17d0-682d-40a5-a982-17ffae7b3015"
            ],
            "supplier": "Schulte BV - Schein Orthopädie Service KG",
            "modelname": "Marianne"
        }
    },
    {
        "modelnumber": "27073",
        "colornumber": "na",
        "brand": "LuCro",
        "lastgroup": "lucro-dames-ergonic",
        "filename": "",
        "category": "f",
        "modelname": "Marianne",
        "attributes": {
            "closings": [
                "06cfde34-87b0-428d-8e4b-1b2834194552"
            ],
            "colorname": "TAUPE",
            "height": [
                "574c17d0-682d-40a5-a982-17ffae7b3015"
            ],
            "supplier": "Schulte BV - Schein Orthopädie Service KG",
            "modelname": "Marianne"
        }
    },
    {
        "modelnumber": "27420",
        "colornumber": "na",
        "brand": "LuCro",
        "lastgroup": "lucro-dames-ergonic",
        "filename": "",
        "category": "f",
        "modelname": "Cornelia",
        "attributes": {
            "closings": [
                "06cfde34-87b0-428d-8e4b-1b2834194552"
            ],
            "colorname": "zwart",
            "height": [
                "574c17d0-682d-40a5-a982-17ffae7b3015"
            ],
            "supplier": "Schulte BV - Schein Orthopädie Service KG",
            "modelname": "Cornelia"
        }
    },
    {
        "modelnumber": "27425",
        "colornumber": "na",
        "brand": "LuCro",
        "lastgroup": "lucro-dames-ergonic",
        "filename": "",
        "category": "f",
        "modelname": "Cornelia",
        "attributes": {
            "closings": [
                "06cfde34-87b0-428d-8e4b-1b2834194552"
            ],
            "colorname": "beige",
            "height": [
                "574c17d0-682d-40a5-a982-17ffae7b3015"
            ],
            "supplier": "Schulte BV - Schein Orthopädie Service KG",
            "modelname": "Cornelia"
        }
    },
    {
        "modelnumber": "27429",
        "colornumber": "na",
        "brand": "LuCro",
        "lastgroup": "lucro-dames-ergonic",
        "filename": "",
        "category": "f",
        "modelname": "Cornelia",
        "attributes": {
            "closings": [
                "06cfde34-87b0-428d-8e4b-1b2834194552"
            ],
            "colorname": "grijs",
            "height": [
                "574c17d0-682d-40a5-a982-17ffae7b3015"
            ],
            "supplier": "Schulte BV - Schein Orthopädie Service KG",
            "modelname": "Cornelia"
        }
    },
    {
        "modelnumber": "28000",
        "colornumber": "na",
        "brand": "LuCro",
        "lastgroup": "lucro-heren-sportic",
        "filename": "",
        "category": "m",
        "modelname": "Mario",
        "attributes": {
            "closings": [
                "06cfde34-87b0-428d-8e4b-1b2834194552"
            ],
            "colorname": "zwart",
            "height": [
                "574c17d0-682d-40a5-a982-17ffae7b3015"
            ],
            "supplier": "Schulte BV - Schein Orthopädie Service KG",
            "modelname": "Mario"
        }
    },
    {
        "modelnumber": "28004",
        "colornumber": "na",
        "brand": "LuCro",
        "lastgroup": "lucro-heren-sportic",
        "filename": "",
        "category": "m",
        "modelname": "Mario",
        "attributes": {
            "closings": [
                "06cfde34-87b0-428d-8e4b-1b2834194552"
            ],
            "colorname": "bruin",
            "height": [
                "574c17d0-682d-40a5-a982-17ffae7b3015"
            ],
            "supplier": "Schulte BV - Schein Orthopädie Service KG",
            "modelname": "Mario"
        }
    },
    {
        "modelnumber": "28005",
        "colornumber": "na",
        "brand": "LuCro",
        "lastgroup": "lucro-heren-sportic",
        "filename": "",
        "category": "m",
        "modelname": "Mario",
        "attributes": {
            "closings": [
                "06cfde34-87b0-428d-8e4b-1b2834194552"
            ],
            "colorname": "blauw",
            "height": [
                "574c17d0-682d-40a5-a982-17ffae7b3015"
            ],
            "supplier": "Schulte BV - Schein Orthopädie Service KG",
            "modelname": "Mario"
        }
    },
    {
        "modelnumber": "28008",
        "colornumber": "na",
        "brand": "LuCro",
        "lastgroup": "lucro-heren-sportic",
        "filename": "",
        "category": "m",
        "modelname": "Mario",
        "attributes": {
            "closings": [
                "06cfde34-87b0-428d-8e4b-1b2834194552"
            ],
            "colorname": "blauw",
            "height": [
                "574c17d0-682d-40a5-a982-17ffae7b3015"
            ],
            "supplier": "Schulte BV - Schein Orthopädie Service KG",
            "modelname": "Mario"
        }
    },
    {
        "modelnumber": "28010",
        "colornumber": "na",
        "brand": "LuCro",
        "lastgroup": "lucro-heren-sportic",
        "filename": "",
        "category": "m",
        "modelname": "Peco",
        "attributes": {
            "closings": [
                "72589120-2085-4a19-bd1e-899c5a098a5f"
            ],
            "colorname": "zwart",
            "height": [
                "574c17d0-682d-40a5-a982-17ffae7b3015"
            ],
            "supplier": "Schulte BV - Schein Orthopädie Service KG",
            "modelname": "Peco"
        }
    },
    {
        "modelnumber": "28012",
        "colornumber": "na",
        "brand": "LuCro",
        "lastgroup": "lucro-heren-sportic",
        "filename": "",
        "category": "m",
        "modelname": "Peco",
        "attributes": {
            "closings": [
                "72589120-2085-4a19-bd1e-899c5a098a5f"
            ],
            "colorname": "zwart",
            "height": [
                "574c17d0-682d-40a5-a982-17ffae7b3015"
            ],
            "supplier": "Schulte BV - Schein Orthopädie Service KG",
            "modelname": "Peco"
        }
    },
    {
        "modelnumber": "28015",
        "colornumber": "na",
        "brand": "LuCro",
        "lastgroup": "lucro-heren-sportic",
        "filename": "",
        "category": "m",
        "modelname": "Peco",
        "attributes": {
            "closings": [
                "72589120-2085-4a19-bd1e-899c5a098a5f"
            ],
            "colorname": "bruin",
            "height": [
                "574c17d0-682d-40a5-a982-17ffae7b3015"
            ],
            "supplier": "Schulte BV - Schein Orthopädie Service KG",
            "modelname": "Peco"
        }
    },
    {
        "modelnumber": "28016",
        "colornumber": "na",
        "brand": "LuCro",
        "lastgroup": "lucro-heren-sportic",
        "filename": "",
        "category": "m",
        "modelname": "Peco",
        "attributes": {
            "closings": [
                "72589120-2085-4a19-bd1e-899c5a098a5f"
            ],
            "colorname": "bruin",
            "height": [
                "574c17d0-682d-40a5-a982-17ffae7b3015"
            ],
            "supplier": "Schulte BV - Schein Orthopädie Service KG",
            "modelname": "Peco"
        }
    },
    {
        "modelnumber": "28017",
        "colornumber": "na",
        "brand": "LuCro",
        "lastgroup": "lucro-heren-sportic",
        "filename": "",
        "category": "m",
        "modelname": "Peco",
        "attributes": {
            "closings": [
                "72589120-2085-4a19-bd1e-899c5a098a5f"
            ],
            "colorname": "blauw",
            "height": [
                "574c17d0-682d-40a5-a982-17ffae7b3015"
            ],
            "supplier": "Schulte BV - Schein Orthopädie Service KG",
            "modelname": "Peco"
        }
    },
    {
        "modelnumber": "28018",
        "colornumber": "na",
        "brand": "LuCro",
        "lastgroup": "lucro-heren-sportic",
        "filename": "",
        "category": "m",
        "modelname": "Peco",
        "attributes": {
            "closings": [
                "72589120-2085-4a19-bd1e-899c5a098a5f"
            ],
            "colorname": "blauw",
            "height": [
                "574c17d0-682d-40a5-a982-17ffae7b3015"
            ],
            "supplier": "Schulte BV - Schein Orthopädie Service KG",
            "modelname": "Peco"
        }
    },
    {
        "modelnumber": "28019",
        "colornumber": "na",
        "brand": "LuCro",
        "lastgroup": "lucro-heren-sportic",
        "filename": "",
        "category": "m",
        "modelname": "Peco",
        "attributes": {
            "closings": [
                "72589120-2085-4a19-bd1e-899c5a098a5f"
            ],
            "colorname": "grijs",
            "height": [
                "574c17d0-682d-40a5-a982-17ffae7b3015"
            ],
            "supplier": "Schulte BV - Schein Orthopädie Service KG",
            "modelname": "Peco"
        }
    },
    {
        "modelnumber": "28020",
        "colornumber": "na",
        "brand": "LuCro",
        "lastgroup": "lucro-heren-sportic",
        "filename": "",
        "category": "m",
        "modelname": "Manuel",
        "attributes": {
            "closings": [
                "72589120-2085-4a19-bd1e-899c5a098a5f"
            ],
            "colorname": "zwart",
            "height": [
                "574c17d0-682d-40a5-a982-17ffae7b3015"
            ],
            "supplier": "Schulte BV - Schein Orthopädie Service KG",
            "modelname": "Manuel"
        }
    },
    {
        "modelnumber": "28021",
        "colornumber": "na",
        "brand": "LuCro",
        "lastgroup": "lucro-heren-sportic",
        "filename": "",
        "category": "m",
        "modelname": "Manuel",
        "attributes": {
            "closings": [
                "72589120-2085-4a19-bd1e-899c5a098a5f"
            ],
            "colorname": "bruin",
            "height": [
                "574c17d0-682d-40a5-a982-17ffae7b3015"
            ],
            "supplier": "Schulte BV - Schein Orthopädie Service KG",
            "modelname": "Manuel"
        }
    },
    {
        "modelnumber": "28022",
        "colornumber": "na",
        "brand": "LuCro",
        "lastgroup": "lucro-heren-sportic",
        "filename": "",
        "category": "m",
        "modelname": "Manuel",
        "attributes": {
            "closings": [
                "72589120-2085-4a19-bd1e-899c5a098a5f"
            ],
            "colorname": "blauw",
            "height": [
                "574c17d0-682d-40a5-a982-17ffae7b3015"
            ],
            "supplier": "Schulte BV - Schein Orthopädie Service KG",
            "modelname": "Manuel"
        }
    },
    {
        "modelnumber": "28023",
        "colornumber": "na",
        "brand": "LuCro",
        "lastgroup": "lucro-heren-sportic",
        "filename": "",
        "category": "m",
        "modelname": "Manuel",
        "attributes": {
            "closings": [
                "72589120-2085-4a19-bd1e-899c5a098a5f"
            ],
            "colorname": "blauw",
            "height": [
                "574c17d0-682d-40a5-a982-17ffae7b3015"
            ],
            "supplier": "Schulte BV - Schein Orthopädie Service KG",
            "modelname": "Manuel"
        }
    },
    {
        "modelnumber": "28024",
        "colornumber": "na",
        "brand": "LuCro",
        "lastgroup": "lucro-heren-sportic",
        "filename": "",
        "category": "m",
        "modelname": "Manuel",
        "attributes": {
            "closings": [
                "72589120-2085-4a19-bd1e-899c5a098a5f"
            ],
            "colorname": "bruin",
            "height": [
                "574c17d0-682d-40a5-a982-17ffae7b3015"
            ],
            "supplier": "Schulte BV - Schein Orthopädie Service KG",
            "modelname": "Manuel"
        }
    },
    {
        "modelnumber": "28028",
        "colornumber": "na",
        "brand": "LuCro",
        "lastgroup": "lucro-heren-sportic",
        "filename": "",
        "category": "m",
        "modelname": "Manuel",
        "attributes": {
            "closings": [
                "72589120-2085-4a19-bd1e-899c5a098a5f"
            ],
            "colorname": "blauw",
            "height": [
                "574c17d0-682d-40a5-a982-17ffae7b3015"
            ],
            "supplier": "Schulte BV - Schein Orthopädie Service KG",
            "modelname": "Manuel"
        }
    },
    {
        "modelnumber": "28029",
        "colornumber": "na",
        "brand": "LuCro",
        "lastgroup": "lucro-heren-sportic",
        "filename": "",
        "category": "m",
        "modelname": "Manuel",
        "attributes": {
            "closings": [
                "72589120-2085-4a19-bd1e-899c5a098a5f"
            ],
            "colorname": "grijs",
            "height": [
                "574c17d0-682d-40a5-a982-17ffae7b3015"
            ],
            "supplier": "Schulte BV - Schein Orthopädie Service KG",
            "modelname": "Manuel"
        }
    },
    {
        "modelnumber": "28032",
        "colornumber": "na",
        "brand": "LuCro",
        "lastgroup": "lucro-heren-sportic",
        "filename": "",
        "category": "m",
        "modelname": "Steffen",
        "attributes": {
            "closings": [
                "06cfde34-87b0-428d-8e4b-1b2834194552"
            ],
            "colorname": "zwart",
            "height": [
                "574c17d0-682d-40a5-a982-17ffae7b3015"
            ],
            "supplier": "Schulte BV - Schein Orthopädie Service KG",
            "modelname": "Steffen"
        }
    },
    {
        "modelnumber": "28033",
        "colornumber": "na",
        "brand": "LuCro",
        "lastgroup": "lucro-heren-sportic",
        "filename": "",
        "category": "m",
        "modelname": "Steffen",
        "attributes": {
            "closings": [
                "06cfde34-87b0-428d-8e4b-1b2834194552"
            ],
            "colorname": "wit",
            "height": [
                "574c17d0-682d-40a5-a982-17ffae7b3015"
            ],
            "supplier": "Schulte BV - Schein Orthopädie Service KG",
            "modelname": "Steffen"
        }
    },
    {
        "modelnumber": "28034",
        "colornumber": "na",
        "brand": "LuCro",
        "lastgroup": "lucro-heren-sportic",
        "filename": "",
        "category": "m",
        "modelname": "Steffen",
        "attributes": {
            "closings": [
                "06cfde34-87b0-428d-8e4b-1b2834194552"
            ],
            "colorname": "bruin",
            "height": [
                "574c17d0-682d-40a5-a982-17ffae7b3015"
            ],
            "supplier": "Schulte BV - Schein Orthopädie Service KG",
            "modelname": "Steffen"
        }
    },
    {
        "modelnumber": "28035",
        "colornumber": "na",
        "brand": "LuCro",
        "lastgroup": "lucro-heren-sportic",
        "filename": "",
        "category": "m",
        "modelname": "Steffen",
        "attributes": {
            "closings": [
                "06cfde34-87b0-428d-8e4b-1b2834194552"
            ],
            "colorname": "zwart",
            "height": [
                "574c17d0-682d-40a5-a982-17ffae7b3015"
            ],
            "supplier": "Schulte BV - Schein Orthopädie Service KG",
            "modelname": "Steffen"
        }
    },
    {
        "modelnumber": "28036",
        "colornumber": "na",
        "brand": "LuCro",
        "lastgroup": "lucro-heren-sportic",
        "filename": "",
        "category": "m",
        "modelname": "Steffen",
        "attributes": {
            "closings": [
                "06cfde34-87b0-428d-8e4b-1b2834194552"
            ],
            "colorname": "zwart",
            "height": [
                "574c17d0-682d-40a5-a982-17ffae7b3015"
            ],
            "supplier": "Schulte BV - Schein Orthopädie Service KG",
            "modelname": "Steffen"
        }
    },
    {
        "modelnumber": "28038",
        "colornumber": "na",
        "brand": "LuCro",
        "lastgroup": "lucro-heren-sportic",
        "filename": "",
        "category": "m",
        "modelname": "Steffen",
        "attributes": {
            "closings": [
                "06cfde34-87b0-428d-8e4b-1b2834194552"
            ],
            "colorname": "grijs",
            "height": [
                "574c17d0-682d-40a5-a982-17ffae7b3015"
            ],
            "supplier": "Schulte BV - Schein Orthopädie Service KG",
            "modelname": "Steffen"
        }
    },
    {
        "modelnumber": "28040",
        "colornumber": "na",
        "brand": "LuCro",
        "lastgroup": "lucro-heren-sportic",
        "filename": "",
        "category": "m",
        "modelname": "Jochen",
        "attributes": {
            "closings": [
                "72589120-2085-4a19-bd1e-899c5a098a5f"
            ],
            "colorname": "zwart",
            "height": [
                "b591a1ea-c12b-4ab7-a414-9ed5e45de1dc"
            ],
            "supplier": "Schulte BV - Schein Orthopädie Service KG",
            "modelname": "Jochen"
        }
    },
    {
        "modelnumber": "28042",
        "colornumber": "na",
        "brand": "LuCro",
        "lastgroup": "lucro-heren-sportic",
        "filename": "",
        "category": "m",
        "modelname": "Jochen",
        "attributes": {
            "closings": [
                "72589120-2085-4a19-bd1e-899c5a098a5f"
            ],
            "colorname": "bruin",
            "height": [
                "b591a1ea-c12b-4ab7-a414-9ed5e45de1dc"
            ],
            "supplier": "Schulte BV - Schein Orthopädie Service KG",
            "modelname": "Jochen"
        }
    },
    {
        "modelnumber": "28048",
        "colornumber": "na",
        "brand": "LuCro",
        "lastgroup": "lucro-heren-sportic",
        "filename": "",
        "category": "m",
        "modelname": "Jochen",
        "attributes": {
            "closings": [
                "72589120-2085-4a19-bd1e-899c5a098a5f"
            ],
            "colorname": "blauw",
            "height": [
                "b591a1ea-c12b-4ab7-a414-9ed5e45de1dc"
            ],
            "supplier": "Schulte BV - Schein Orthopädie Service KG",
            "modelname": "Jochen"
        }
    },
    {
        "modelnumber": "28049",
        "colornumber": "na",
        "brand": "LuCro",
        "lastgroup": "lucro-heren-sportic",
        "filename": "",
        "category": "m",
        "modelname": "Jochen",
        "attributes": {
            "closings": [
                "72589120-2085-4a19-bd1e-899c5a098a5f"
            ],
            "colorname": "grijs",
            "height": [
                "b591a1ea-c12b-4ab7-a414-9ed5e45de1dc"
            ],
            "supplier": "Schulte BV - Schein Orthopädie Service KG",
            "modelname": "Jochen"
        }
    },
    {
        "modelnumber": "30010",
        "colornumber": "na",
        "brand": "LuCro",
        "lastgroup": "lucro-dames-sportic",
        "filename": "",
        "category": "f",
        "modelname": "Anke",
        "attributes": {
            "closings": [
                "06cfde34-87b0-428d-8e4b-1b2834194552"
            ],
            "colorname": "zwart",
            "height": [
                "574c17d0-682d-40a5-a982-17ffae7b3015"
            ],
            "supplier": "Schulte BV - Schein Orthopädie Service KG",
            "modelname": "Anke"
        }
    },
    {
        "modelnumber": "30013",
        "colornumber": "na",
        "brand": "LuCro",
        "lastgroup": "lucro-dames-sportic",
        "filename": "",
        "category": "f",
        "modelname": "Anke",
        "attributes": {
            "closings": [
                "06cfde34-87b0-428d-8e4b-1b2834194552"
            ],
            "colorname": "grijs",
            "height": [
                "574c17d0-682d-40a5-a982-17ffae7b3015"
            ],
            "supplier": "Schulte BV - Schein Orthopädie Service KG",
            "modelname": "Anke"
        }
    },
    {
        "modelnumber": "30014",
        "colornumber": "na",
        "brand": "LuCro",
        "lastgroup": "lucro-dames-sportic",
        "filename": "",
        "category": "f",
        "modelname": "Anke",
        "attributes": {
            "closings": [
                "06cfde34-87b0-428d-8e4b-1b2834194552"
            ],
            "colorname": "zwart",
            "height": [
                "574c17d0-682d-40a5-a982-17ffae7b3015"
            ],
            "supplier": "Schulte BV - Schein Orthopädie Service KG",
            "modelname": "Anke"
        }
    },
    {
        "modelnumber": "30015",
        "colornumber": "na",
        "brand": "LuCro",
        "lastgroup": "lucro-dames-sportic",
        "filename": "",
        "category": "f",
        "modelname": "Anke",
        "attributes": {
            "closings": [
                "06cfde34-87b0-428d-8e4b-1b2834194552"
            ],
            "colorname": "beige",
            "height": [
                "574c17d0-682d-40a5-a982-17ffae7b3015"
            ],
            "supplier": "Schulte BV - Schein Orthopädie Service KG",
            "modelname": "Anke"
        }
    },
    {
        "modelnumber": "30018",
        "colornumber": "na",
        "brand": "LuCro",
        "lastgroup": "lucro-dames-sportic",
        "filename": "",
        "category": "f",
        "modelname": "Anke",
        "attributes": {
            "closings": [
                "06cfde34-87b0-428d-8e4b-1b2834194552"
            ],
            "colorname": "blauw",
            "height": [
                "574c17d0-682d-40a5-a982-17ffae7b3015"
            ],
            "supplier": "Schulte BV - Schein Orthopädie Service KG",
            "modelname": "Anke"
        }
    },
    {
        "modelnumber": "30023",
        "colornumber": "na",
        "brand": "LuCro",
        "lastgroup": "lucro-dames-sportic",
        "filename": "",
        "category": "f",
        "modelname": "Maren",
        "attributes": {
            "closings": [
                "72589120-2085-4a19-bd1e-899c5a098a5f"
            ],
            "colorname": "zwart",
            "height": [
                "574c17d0-682d-40a5-a982-17ffae7b3015"
            ],
            "supplier": "Schulte BV - Schein Orthopädie Service KG",
            "modelname": "Maren"
        }
    },
    {
        "modelnumber": "30024",
        "colornumber": "na",
        "brand": "LuCro",
        "lastgroup": "lucro-dames-sportic",
        "filename": "",
        "category": "f",
        "modelname": "Maren",
        "attributes": {
            "closings": [
                "72589120-2085-4a19-bd1e-899c5a098a5f"
            ],
            "colorname": "bruin",
            "height": [
                "574c17d0-682d-40a5-a982-17ffae7b3015"
            ],
            "supplier": "Schulte BV - Schein Orthopädie Service KG",
            "modelname": "Maren"
        }
    },
    {
        "modelnumber": "30025",
        "colornumber": "na",
        "brand": "LuCro",
        "lastgroup": "lucro-dames-sportic",
        "filename": "",
        "category": "f",
        "modelname": "Maren",
        "attributes": {
            "closings": [
                "72589120-2085-4a19-bd1e-899c5a098a5f"
            ],
            "colorname": "beige",
            "height": [
                "574c17d0-682d-40a5-a982-17ffae7b3015"
            ],
            "supplier": "Schulte BV - Schein Orthopädie Service KG",
            "modelname": "Maren"
        }
    },
    {
        "modelnumber": "30027",
        "colornumber": "na",
        "brand": "LuCro",
        "lastgroup": "lucro-dames-sportic",
        "filename": "",
        "category": "f",
        "modelname": "Maren",
        "attributes": {
            "closings": [
                "72589120-2085-4a19-bd1e-899c5a098a5f"
            ],
            "colorname": "grijs",
            "height": [
                "574c17d0-682d-40a5-a982-17ffae7b3015"
            ],
            "supplier": "Schulte BV - Schein Orthopädie Service KG",
            "modelname": "Maren"
        }
    },
    {
        "modelnumber": "30028",
        "colornumber": "na",
        "brand": "LuCro",
        "lastgroup": "lucro-dames-sportic",
        "filename": "",
        "category": "f",
        "modelname": "Maren",
        "attributes": {
            "closings": [
                "72589120-2085-4a19-bd1e-899c5a098a5f"
            ],
            "colorname": "blauw",
            "height": [
                "574c17d0-682d-40a5-a982-17ffae7b3015"
            ],
            "supplier": "Schulte BV - Schein Orthopädie Service KG",
            "modelname": "Maren"
        }
    },
    {
        "modelnumber": "30030",
        "colornumber": "na",
        "brand": "LuCro",
        "lastgroup": "lucro-dames-sportic",
        "filename": "",
        "category": "f",
        "modelname": "Nina",
        "attributes": {
            "closings": [
                "72589120-2085-4a19-bd1e-899c5a098a5f"
            ],
            "colorname": "zwart",
            "height": [
                "574c17d0-682d-40a5-a982-17ffae7b3015"
            ],
            "supplier": "Schulte BV - Schein Orthopädie Service KG",
            "modelname": "Nina"
        }
    },
    {
        "modelnumber": "30031",
        "colornumber": "na",
        "brand": "LuCro",
        "lastgroup": "lucro-dames-sportic",
        "filename": "",
        "category": "f",
        "modelname": "Nina",
        "attributes": {
            "closings": [
                "72589120-2085-4a19-bd1e-899c5a098a5f"
            ],
            "colorname": "zwart",
            "height": [
                "574c17d0-682d-40a5-a982-17ffae7b3015"
            ],
            "supplier": "Schulte BV - Schein Orthopädie Service KG",
            "modelname": "Nina"
        }
    },
    {
        "modelnumber": "30033",
        "colornumber": "na",
        "brand": "LuCro",
        "lastgroup": "lucro-dames-sportic",
        "filename": "",
        "category": "f",
        "modelname": "Nina",
        "attributes": {
            "closings": [
                "72589120-2085-4a19-bd1e-899c5a098a5f"
            ],
            "colorname": "bruin",
            "height": [
                "574c17d0-682d-40a5-a982-17ffae7b3015"
            ],
            "supplier": "Schulte BV - Schein Orthopädie Service KG",
            "modelname": "Nina"
        }
    },
    {
        "modelnumber": "30035",
        "colornumber": "na",
        "brand": "LuCro",
        "lastgroup": "lucro-dames-sportic",
        "filename": "",
        "category": "f",
        "modelname": "Nina",
        "attributes": {
            "closings": [
                "72589120-2085-4a19-bd1e-899c5a098a5f"
            ],
            "colorname": "zwart",
            "height": [
                "574c17d0-682d-40a5-a982-17ffae7b3015"
            ],
            "supplier": "Schulte BV - Schein Orthopädie Service KG",
            "modelname": "Nina"
        }
    },
    {
        "modelnumber": "30036",
        "colornumber": "na",
        "brand": "LuCro",
        "lastgroup": "lucro-dames-sportic",
        "filename": "",
        "category": "f",
        "modelname": "Nina",
        "attributes": {
            "closings": [
                "72589120-2085-4a19-bd1e-899c5a098a5f"
            ],
            "colorname": "beige",
            "height": [
                "574c17d0-682d-40a5-a982-17ffae7b3015"
            ],
            "supplier": "Schulte BV - Schein Orthopädie Service KG",
            "modelname": "Nina"
        }
    },
    {
        "modelnumber": "30037",
        "colornumber": "na",
        "brand": "LuCro",
        "lastgroup": "lucro-dames-sportic",
        "filename": "",
        "category": "f",
        "modelname": "Nina",
        "attributes": {
            "closings": [
                "72589120-2085-4a19-bd1e-899c5a098a5f"
            ],
            "colorname": "blauw",
            "height": [
                "574c17d0-682d-40a5-a982-17ffae7b3015"
            ],
            "supplier": "Schulte BV - Schein Orthopädie Service KG",
            "modelname": "Nina"
        }
    },
    {
        "modelnumber": "30038",
        "colornumber": "na",
        "brand": "LuCro",
        "lastgroup": "lucro-dames-sportic",
        "filename": "",
        "category": "f",
        "modelname": "Nina",
        "attributes": {
            "closings": [
                "72589120-2085-4a19-bd1e-899c5a098a5f"
            ],
            "colorname": "blauw",
            "height": [
                "574c17d0-682d-40a5-a982-17ffae7b3015"
            ],
            "supplier": "Schulte BV - Schein Orthopädie Service KG",
            "modelname": "Nina"
        }
    },
    {
        "modelnumber": "30039",
        "colornumber": "na",
        "brand": "LuCro",
        "lastgroup": "lucro-dames-sportic",
        "filename": "",
        "category": "f",
        "modelname": "Nina",
        "attributes": {
            "closings": [
                "72589120-2085-4a19-bd1e-899c5a098a5f"
            ],
            "colorname": "grijs",
            "height": [
                "574c17d0-682d-40a5-a982-17ffae7b3015"
            ],
            "supplier": "Schulte BV - Schein Orthopädie Service KG",
            "modelname": "Nina"
        }
    },
    {
        "modelnumber": "30041",
        "colornumber": "na",
        "brand": "LuCro",
        "lastgroup": "lucro-dames-sportic",
        "filename": "",
        "category": "f",
        "modelname": "Maren",
        "attributes": {
            "closings": [
                "72589120-2085-4a19-bd1e-899c5a098a5f"
            ],
            "colorname": "wit",
            "height": [
                "574c17d0-682d-40a5-a982-17ffae7b3015"
            ],
            "supplier": "Schulte BV - Schein Orthopädie Service KG",
            "modelname": "Maren"
        }
    },
    {
        "modelnumber": "30045",
        "colornumber": "na",
        "brand": "LuCro",
        "lastgroup": "lucro-dames-sportic",
        "filename": "",
        "category": "f",
        "modelname": "Maren",
        "attributes": {
            "closings": [
                "72589120-2085-4a19-bd1e-899c5a098a5f"
            ],
            "colorname": "beige",
            "height": [
                "574c17d0-682d-40a5-a982-17ffae7b3015"
            ],
            "supplier": "Schulte BV - Schein Orthopädie Service KG",
            "modelname": "Maren"
        }
    },
    {
        "modelnumber": "30050",
        "colornumber": "na",
        "brand": "LuCro",
        "lastgroup": "lucro-dames-sportic",
        "filename": "",
        "category": "f",
        "modelname": "Bettina",
        "attributes": {
            "closings": [
                "06cfde34-87b0-428d-8e4b-1b2834194552"
            ],
            "colorname": "zwart",
            "height": [
                "574c17d0-682d-40a5-a982-17ffae7b3015"
            ],
            "supplier": "Schulte BV - Schein Orthopädie Service KG",
            "modelname": "Bettina"
        }
    },
    {
        "modelnumber": "30053",
        "colornumber": "na",
        "brand": "LuCro",
        "lastgroup": "lucro-dames-sportic",
        "filename": "",
        "category": "f",
        "modelname": "Bettina",
        "attributes": {
            "closings": [
                "06cfde34-87b0-428d-8e4b-1b2834194552"
            ],
            "colorname": "beige",
            "height": [
                "574c17d0-682d-40a5-a982-17ffae7b3015"
            ],
            "supplier": "Schulte BV - Schein Orthopädie Service KG",
            "modelname": "Bettina"
        }
    },
    {
        "modelnumber": "30054",
        "colornumber": "na",
        "brand": "LuCro",
        "lastgroup": "lucro-dames-sportic",
        "filename": "",
        "category": "f",
        "modelname": "Bettina",
        "attributes": {
            "closings": [
                "06cfde34-87b0-428d-8e4b-1b2834194552"
            ],
            "colorname": "grijs",
            "height": [
                "574c17d0-682d-40a5-a982-17ffae7b3015"
            ],
            "supplier": "Schulte BV - Schein Orthopädie Service KG",
            "modelname": "Bettina"
        }
    },
    {
        "modelnumber": "30055",
        "colornumber": "na",
        "brand": "LuCro",
        "lastgroup": "lucro-dames-sportic",
        "filename": "",
        "category": "f",
        "modelname": "Bettina",
        "attributes": {
            "closings": [
                "06cfde34-87b0-428d-8e4b-1b2834194552"
            ],
            "colorname": "grijs",
            "height": [
                "574c17d0-682d-40a5-a982-17ffae7b3015"
            ],
            "supplier": "Schulte BV - Schein Orthopädie Service KG",
            "modelname": "Bettina"
        }
    },
    {
        "modelnumber": "30060",
        "colornumber": "na",
        "brand": "LuCro",
        "lastgroup": "lucro-dames-sportic",
        "filename": "",
        "category": "f",
        "modelname": "Bianka",
        "attributes": {
            "closings": [
                "72589120-2085-4a19-bd1e-899c5a098a5f"
            ],
            "colorname": "zwart",
            "height": [
                "b591a1ea-c12b-4ab7-a414-9ed5e45de1dc"
            ],
            "supplier": "Schulte BV - Schein Orthopädie Service KG",
            "modelname": "Bianka"
        }
    },
    {
        "modelnumber": "30062",
        "colornumber": "na",
        "brand": "LuCro",
        "lastgroup": "lucro-dames-sportic",
        "filename": "",
        "category": "f",
        "modelname": "Bianka",
        "attributes": {
            "closings": [
                "72589120-2085-4a19-bd1e-899c5a098a5f"
            ],
            "colorname": "grijs",
            "height": [
                "b591a1ea-c12b-4ab7-a414-9ed5e45de1dc"
            ],
            "supplier": "Schulte BV - Schein Orthopädie Service KG",
            "modelname": "Bianka"
        }
    },
    {
        "modelnumber": "30065",
        "colornumber": "na",
        "brand": "LuCro",
        "lastgroup": "lucro-dames-sportic",
        "filename": "",
        "category": "f",
        "modelname": "Bianka",
        "attributes": {
            "closings": [
                "72589120-2085-4a19-bd1e-899c5a098a5f"
            ],
            "colorname": "zwart",
            "height": [
                "b591a1ea-c12b-4ab7-a414-9ed5e45de1dc"
            ],
            "supplier": "Schulte BV - Schein Orthopädie Service KG",
            "modelname": "Bianka"
        }
    },
    {
        "modelnumber": "30068",
        "colornumber": "na",
        "brand": "LuCro",
        "lastgroup": "lucro-dames-sportic",
        "filename": "",
        "category": "f",
        "modelname": "Bianka",
        "attributes": {
            "closings": [
                "72589120-2085-4a19-bd1e-899c5a098a5f"
            ],
            "colorname": "blauw",
            "height": [
                "b591a1ea-c12b-4ab7-a414-9ed5e45de1dc"
            ],
            "supplier": "Schulte BV - Schein Orthopädie Service KG",
            "modelname": "Bianka"
        }
    }
]
const files = [
    {
        "filename": "Classic-Achim-21190-a.jpg"
    },
    {
        "filename": "Classic-Achim-21192-a.jpg"
    },
    {
        "filename": "Classic-Andrea-22190-a.jpg"
    },
    {
        "filename": "Classic-Andrea-22192-a.jpg"
    },
    {
        "filename": "Classic-Anita-22400-a.jpg"
    },
    {
        "filename": "Classic-Anita-22401-a.jpg"
    },
    {
        "filename": "Classic-Anita-22403-a.jpg"
    },
    {
        "filename": "Classic-Anita-22407-a.jpg"
    },
    {
        "filename": "Classic-Anita-22408-a.jpg"
    },
    {
        "filename": "Classic-Anita-22409-a.jpg"
    },
    {
        "filename": "Classic-Bjoern-21030-a.jpg"
    },
    {
        "filename": "Classic-Bruno-21062-a.jpg"
    },
    {
        "filename": "Classic-Clara_22271-a.jpg"
    },
    {
        "filename": "Classic-Clara-22279-a.jpg"
    },
    {
        "filename": "Classic-Clemens-21221-a.jpg"
    },
    {
        "filename": "Classic-Doris-22150-a.jpg"
    },
    {
        "filename": "Classic-Doris-22159-a.jpg"
    },
    {
        "filename": "Classic-Eric-21270-a.jpg"
    },
    {
        "filename": "Classic-Eric-21273-a.jpg"
    },
    {
        "filename": "Classic-Eric-21276-a.jpg"
    },
    {
        "filename": "Classic-Falko-21204-a.jpg"
    },
    {
        "filename": "Classic-Fiona-22212-a.jpg"
    },
    {
        "filename": "Classic-Fiona-22213-a.jpg"
    },
    {
        "filename": "Classic-Florian-21202-a.jpg"
    },
    {
        "filename": "Classic-Frank-21320-a.jpg"
    },
    {
        "filename": "Classic-Frieda-22291-a.jpg"
    },
    {
        "filename": "Classic-Frieda-22292-a.jpg"
    },
    {
        "filename": "Classic-Fritz-21061-a.jpg"
    },
    {
        "filename": "Classic-Hanni-22141-a.jpg"
    },
    {
        "filename": "Classic-Hanni-22149-a.jpg"
    },
    {
        "filename": "Classic-Harald-21360-a.jpg"
    },
    {
        "filename": "Classic-Harald-21365-a.jpg"
    },
    {
        "filename": "Classic-Heinrich-21232-a.jpg"
    },
    {
        "filename": "Classic-Hildegard-22260-a.jpg"
    },
    {
        "filename": "Classic-Hildegard-22262-a.jpg"
    },
    {
        "filename": "Classic-Ilona-22360-a.jpg"
    },
    {
        "filename": "Classic-Ilona-22362-a.jpg"
    },
    {
        "filename": "Classic-Ilona-22363-a.jpg"
    },
    {
        "filename": "Classic-Ilona-22365-a.jpg"
    },
    {
        "filename": "Classic-Ilona-22367-a.jpg"
    },
    {
        "filename": "Classic-Ilona-22368-a.jpg"
    },
    {
        "filename": "Classic-Jens-21260-a.jpg"
    },
    {
        "filename": "Classic-Jens-21269-a.jpg"
    },
    {
        "filename": "Classic-Kurt-21310-a.jpg"
    },
    {
        "filename": "Classic-Kurt-21312-a.jpg"
    },
    {
        "filename": "Classic-Kurt-21313-a.jpg"
    },
    {
        "filename": "Classic-Kurt-21318-a.jpg"
    },
    {
        "filename": "Classic-Lars-21040-a.jpg"
    },
    {
        "filename": "Classic-Lars-21047-a.jpg"
    },
    {
        "filename": "Classic-Lotte_22210-a.jpg"
    },
    {
        "filename": "Classic-Luca-21141-a.jpg"
    },
    {
        "filename": "Classic-Marcus-21172-a.jpg"
    },
    {
        "filename": "Classic-Marcus-21173-a.jpg"
    },
    {
        "filename": "Classic-Marisa-22062-a.jpg"
    },
    {
        "filename": "Classic-Marisa-22063-a.jpg"
    },
    {
        "filename": "Classic-Marisa-22064-a.jpg"
    },
    {
        "filename": "Classic-Marisa-22065-a.jpg"
    },
    {
        "filename": "Classic-Marisa-22066-a.jpg"
    },
    {
        "filename": "Classic-Marisa-22067-a.jpg"
    },
    {
        "filename": "Classic-Monika-22320-a.jpg"
    },
    {
        "filename": "Classic-Monika-22321-a.jpg"
    },
    {
        "filename": "Classic-Monika-22323-a.jpg"
    },
    {
        "filename": "Classic-Monika-22325-a.jpg"
    },
    {
        "filename": "Classic-Monika-22328-a.jpg"
    },
    {
        "filename": "Classic-Monika-22423-a.jpg"
    },
    {
        "filename": "Classic-Monika-22425-a.jpg"
    },
    {
        "filename": "Classic-Oliver-21090-a.jpg"
    },
    {
        "filename": "Classic-Oliver-21092-a.jpg"
    },
    {
        "filename": "Classic-Oliver-21093-a.jpg"
    },
    {
        "filename": "Classic-Oliver-21095-a.jpg"
    },
    {
        "filename": "Classic-Oliver-21096-a.jpg"
    },
    {
        "filename": "Classic-Oliver-21097-a.jpg"
    },
    {
        "filename": "Classic-Paul-21240-a.jpg"
    },
    {
        "filename": "Classic-Peter-21203-a.jpg"
    },
    {
        "filename": "Classic-Peter-21205-a.jpg"
    },
    {
        "filename": "Classic-Peter-21206-a.jpg"
    },
    {
        "filename": "Classic-Phillip-21180-a.jpg"
    },
    {
        "filename": "Classic-Phillip-21185-a.jpg"
    },
    {
        "filename": "Classic-Phillip-21188-a.jpg"
    },
    {
        "filename": "Classic-Sven-21050-a.jpg"
    },
    {
        "filename": "Classic-Sven-21053-a.jpg"
    },
    {
        "filename": "Classic-Syliva-22040-a.jpg"
    },
    {
        "filename": "Classic-Syliva-22044-a.jpg"
    },
    {
        "filename": "Classic-Syliva-22046-a.jpg"
    },
    {
        "filename": "Classic-Syliva-22047-a.jpg"
    },
    {
        "filename": "Classic-Syliva-22049-a.jpg"
    },
    {
        "filename": "Classic-Syliva-22445-a.jpg"
    },
    {
        "filename": "Classic-Sylvia-22043-a.jpg"
    },
    {
        "filename": "Classic-Thorsten-21020-a.jpg"
    },
    {
        "filename": "Classic-Thorsten-21022-a.jpg"
    },
    {
        "filename": "Classic-Thorsten-21028-a.jpg"
    },
    {
        "filename": "Classic-Volker-21330-a.jpg"
    },
    {
        "filename": "Classic-Volker-21332-a.jpg"
    },
    {
        "filename": "Classic-Volker-21333-a.jpg"
    },
    {
        "filename": "Classic-Volker-21339-a.jpg"
    },
    {
        "filename": "Classic-Walter-21370-a.jpg"
    },
    {
        "filename": "Classic-Walter-21373-a.jpg"
    },
    {
        "filename": "Classic-Walter-21377-a.jpg"
    },
    {
        "filename": "Classic-William-21280-a.jpg"
    },
    {
        "filename": "Classic-William-21284-a.jpg"
    },
    {
        "filename": "Classic-Wolfgang-21440-a.jpg"
    },
    {
        "filename": "Classic-Wolfgang-21443-a.jpg"
    },
    {
        "filename": "Classic-Yvonne-22300-a.jpg"
    },
    {
        "filename": "Classic-Yvonne-22301-a.jpg"
    },
    {
        "filename": "Classic-Yvonne-22303-a.jpg"
    },
    {
        "filename": "Classic-Yvonne-22307-a.jpg"
    },
    {
        "filename": "Ergonic-Baerbel-27042-a.jpg"
    },
    {
        "filename": "Ergonic-Baerbel-27044-a.jpg"
    },
    {
        "filename": "Ergonic-Baerbel-27047-a.jpg"
    },
    {
        "filename": "Ergonic-Carola-27050-a.jpg"
    },
    {
        "filename": "Ergonic-Cornelia-27420-a.jpg"
    },
    {
        "filename": "Ergonic-Cornelia-27425-a.jpg"
    },
    {
        "filename": "Ergonic-Cornelia-27429-a.jpg"
    },
    {
        "filename": "Ergonic-Detlef-26060-a.jpg"
    },
    {
        "filename": "Ergonic-Detlef-26065-a.jpg"
    },
    {
        "filename": "Ergonic-Gisela-27010-a.jpg"
    },
    {
        "filename": "Ergonic-Gisela-27011-a.jpg"
    },
    {
        "filename": "Ergonic-Gisela-27015-a.jpg"
    },
    {
        "filename": "Ergonic-Gisela-27018-a.jpg"
    },
    {
        "filename": "Ergonic-Hannelore-27030-a.jpg"
    },
    {
        "filename": "Ergonic-Heinz-26050-a.jpg"
    },
    {
        "filename": "Ergonic-Helga-27022-a.jpg"
    },
    {
        "filename": "Ergonic-Juergen-26020-a.jpg"
    },
    {
        "filename": "Ergonic-Juergen-26023-a.jpg"
    },
    {
        "filename": "Ergonic-Karl-26040-a.jpg"
    },
    {
        "filename": "Ergonic-Karl-26042-a.jpg"
    },
    {
        "filename": "Ergonic-Karl-26043-a.jpg"
    },
    {
        "filename": "Ergonic-Karl-26048-a.jpg"
    },
    {
        "filename": "Ergonic-Lothar-26010-a.jpg"
    },
    {
        "filename": "Ergonic-Lothar-26019-a.jpg"
    },
    {
        "filename": "Ergonic-Manfred-26032-a.jpg"
    },
    {
        "filename": "Ergonic-Manfred-26033-a.jpg"
    },
    {
        "filename": "Ergonic-Marianne-27070-a.jpg"
    },
    {
        "filename": "Ergonic-Marianne-27073-a.jpg"
    },
    {
        "filename": "Ergonic-Marlies-27060-a.jpg"
    },
    {
        "filename": "Ergonic-Marlies-27063-a.jpg"
    },
    {
        "filename": "Ergonic-Reiner-26070-a.jpg"
    },
    {
        "filename": "Kinetic-Andreas-24072-a.jpg"
    },
    {
        "filename": "Kinetic-Andreas-24073-a.jpg"
    },
    {
        "filename": "Kinetic-Angelika-25010-a.jpg"
    },
    {
        "filename": "Kinetic-Angelika-25011-a.jpg"
    },
    {
        "filename": "Kinetic-Angelika-25012-a.jpg"
    },
    {
        "filename": "Kinetic-Brigitte-25020-a.jpg"
    },
    {
        "filename": "Kinetic-Brigitte-25023-a.jpg"
    },
    {
        "filename": "Kinetic-Brigitte-25027-a.jpg"
    },
    {
        "filename": "Kinetic-Brigitte-25029-a.jpg"
    },
    {
        "filename": "Kinetic-Elke-25110-a.jpg"
    },
    {
        "filename": "Kinetic-Elke-25112-a.jpg"
    },
    {
        "filename": "Kinetic-Gabriele-25030-a.jpg"
    },
    {
        "filename": "Kinetic-Gerhard-24092-a.jpg"
    },
    {
        "filename": "Kinetic-Gerhard-24099-a.jpg"
    },
    {
        "filename": "Kinetic-Hans-24010-a.jpg"
    },
    {
        "filename": "Kinetic-Hans-24013-a.jpg"
    },
    {
        "filename": "Kinetic-Hans-24019-a.jpg"
    },
    {
        "filename": "Kinetic-Holger-24030-a.jpg"
    },
    {
        "filename": "Kinetic-Holger-24033-a.jpg"
    },
    {
        "filename": "Kinetic-Holger-24037-a.jpg"
    },
    {
        "filename": "Kinetic-Holger-24038-a.jpg"
    },
    {
        "filename": "Kinetic-Ingrid-25050-a.jpg"
    },
    {
        "filename": "Kinetic-Ingrid-25052-a.jpg"
    },
    {
        "filename": "Kinetic-Ingrid-25053-a.jpg"
    },
    {
        "filename": "Kinetic-Ingrid-25058-a.jpg"
    },
    {
        "filename": "Kinetic-Ingrid-25059-a.jpg"
    },
    {
        "filename": "Kinetic-Kerstin-25350-a.jpg"
    },
    {
        "filename": "Kinetic-Kerstin-25351-a.jpg"
    },
    {
        "filename": "Kinetic-Kerstin-25353-a.jpg"
    },
    {
        "filename": "Kinetic-Klaus-24020-a.jpg"
    },
    {
        "filename": "Kinetic-Klaus-24024-a.jpg"
    },
    {
        "filename": "Kinetic-Klaus-24025-a.jpg"
    },
    {
        "filename": "Kinetic-Mathias-24102-a.jpg"
    },
    {
        "filename": "Kinetic-Mathias-24108-a.jpg"
    },
    {
        "filename": "Kinetic-Norbert-24040-a.jpg"
    },
    {
        "filename": "Kinetic-Norbert-24042-a.jpg"
    },
    {
        "filename": "Kinetic-Norbert-24043-a.jpg"
    },
    {
        "filename": "Kinetic-Norbert-24048-a.jpg"
    },
    {
        "filename": "Kinetic-Rolf-24050-a.jpg"
    },
    {
        "filename": "Kinetic-Rolf-24052-a.jpg"
    },
    {
        "filename": "Kinetic-Rolf-24053-a.jpg"
    },
    {
        "filename": "Kinetic-Rolf-24058-a.jpg"
    },
    {
        "filename": "Kinetic-Ruediger-24060-a.jpg"
    },
    {
        "filename": "Kinetic-Ruediger-24063-a.jpg"
    },
    {
        "filename": "Kinetic-Sabine-25070-a.jpg"
    },
    {
        "filename": "Kinetic-Sabine-25072-a.jpg"
    },
    {
        "filename": "Kinetic-Sabine-25077-a.jpg"
    },
    {
        "filename": "Kinetic-Sabine-25375-a.jpg"
    },
    {
        "filename": "Kinetic-Sabine-25379-a.jpg"
    },
    {
        "filename": "Kinetic-Stefan-24085-a.jpg"
    },
    {
        "filename": "Kinetic-Stefan-24088-a.jpg"
    },
    {
        "filename": "Sportic-Anke-30010-a.jpg"
    },
    {
        "filename": "Sportic-Anke-30013-a.jpg"
    },
    {
        "filename": "Sportic-Anke-30014-a.jpg"
    },
    {
        "filename": "Sportic-Anke-30015-a.jpg"
    },
    {
        "filename": "Sportic-Anke-30018-a.jpg"
    },
    {
        "filename": "Sportic-Anke-300101-a.jpg"
    },
    {
        "filename": "Sportic-Bettina-30050-a.jpg"
    },
    {
        "filename": "Sportic-Bettina-30053-a.jpg"
    },
    {
        "filename": "Sportic-Bettina-30054-a.jpg"
    },
    {
        "filename": "Sportic-Bettina-30055-a.jpg"
    },
    {
        "filename": "Sportic-Bianka-30060-a.jpg"
    },
    {
        "filename": "Sportic-Bianka-30062-a.jpg"
    },
    {
        "filename": "Sportic-Bianka-30065-a.jpg"
    },
    {
        "filename": "Sportic-Bianka-30068-a.jpg"
    },
    {
        "filename": "Sportic-Jochen-28040-a.jpg"
    },
    {
        "filename": "Sportic-Jochen-28042-a.jpg"
    },
    {
        "filename": "Sportic-Jochen-28048-a.jpg"
    },
    {
        "filename": "Sportic-Jochen-28049-a.jpg"
    },
    {
        "filename": "Sportic-Manuel-28020-a.jpg"
    },
    {
        "filename": "Sportic-Manuel-28021-a.jpg"
    },
    {
        "filename": "Sportic-Manuel-28022-a.jpg"
    },
    {
        "filename": "Sportic-Manuel-28023-a.jpg"
    },
    {
        "filename": "Sportic-Manuel-28024-a.jpg"
    },
    {
        "filename": "Sportic-Manuel-28028-a.jpg"
    },
    {
        "filename": "Sportic-Manuel-28029-a.jpg"
    },
    {
        "filename": "Sportic-Maren-30023-a.jpg"
    },
    {
        "filename": "Sportic-Maren-30024-a.jpg"
    },
    {
        "filename": "Sportic-Maren-30025-a.jpg"
    },
    {
        "filename": "Sportic-Maren-30027-a.jpg"
    },
    {
        "filename": "Sportic-Maren-30028-a.jpg"
    },
    {
        "filename": "Sportic-Maren-30041-a.jpg"
    },
    {
        "filename": "Sportic-Maren-30045-a.jpg"
    },
    {
        "filename": "Sportic-Mario-28000-a.jpg"
    },
    {
        "filename": "Sportic-Mario-28004-a.jpg"
    },
    {
        "filename": "Sportic-Mario-28005-a.jpg"
    },
    {
        "filename": "Sportic-Mario-28008-a.jpg"
    },
    {
        "filename": "Sportic-Nina-30030-a.jpg"
    },
    {
        "filename": "Sportic-Nina-30031-a.jpg"
    },
    {
        "filename": "Sportic-Nina-30033-a.jpg"
    },
    {
        "filename": "Sportic-Nina-30035-a.jpg"
    },
    {
        "filename": "Sportic-Nina-30036-a.jpg"
    },
    {
        "filename": "Sportic-Nina-30037-a.jpg"
    },
    {
        "filename": "Sportic-Nina-30038-a.jpg"
    },
    {
        "filename": "Sportic-Nina-30039-a.jpg"
    },
    {
        "filename": "Sportic-Peco-28010-a.jpg"
    },
    {
        "filename": "Sportic-Peco-28012-a.jpg"
    },
    {
        "filename": "Sportic-Peco-28015-a.jpg"
    },
    {
        "filename": "Sportic-Peco-28016-a.jpg"
    },
    {
        "filename": "Sportic-Peco-28017-a.jpg"
    },
    {
        "filename": "Sportic-Peco-28018-a.jpg"
    },
    {
        "filename": "Sportic-Peco-28019-a.jpg"
    },
    {
        "filename": "Sportic-Steffen-28032-a.jpg"
    },
    {
        "filename": "Sportic-Steffen-28033-a.jpg"
    },
    {
        "filename": "Sportic-Steffen-28034-a.jpg"
    },
    {
        "filename": "Sportic-Steffen-28035-a.jpg"
    },
    {
        "filename": "Sportic-Steffen-28036-a.jpg"
    },
    {
        "filename": "Sportic-Steffen-28038-a.jpg"
    }
]
const prices = [
    {
        "modelnumber": "4601",
        "price": "€ 121,00"
    },
    {
        "modelnumber": "4603",
        "price": "€ 121,00"
    },
    {
        "modelnumber": "4608",
        "price": "€ 121,00"
    },
    {
        "modelnumber": "4617",
        "price": "€ 121,00"
    },
    {
        "modelnumber": "4618",
        "price": "€ 121,00"
    },
    {
        "modelnumber": "4619",
        "price": "€ 121,00"
    },
    {
        "modelnumber": "4620",
        "price": "€ 125,00"
    },
    {
        "modelnumber": "4622",
        "price": "€ 121,00"
    },
    {
        "modelnumber": "4628",
        "price": "€ 121,00"
    },
    {
        "modelnumber": "4629",
        "price": "€ 121,00"
    },
    {
        "modelnumber": "4632",
        "price": "€ 121,00"
    },
    {
        "modelnumber": "4637",
        "price": "€ 121,00"
    },
    {
        "modelnumber": "4638",
        "price": "€ 121,00"
    },
    {
        "modelnumber": "4639",
        "price": "€ 121,00"
    },
    {
        "modelnumber": "4642",
        "price": "€ 121,00"
    },
    {
        "modelnumber": "4647",
        "price": "€ 121,00"
    },
    {
        "modelnumber": "4649",
        "price": "€ 121,00"
    },
    {
        "modelnumber": "4655",
        "price": "€ 121,00"
    },
    {
        "modelnumber": "4657",
        "price": "€ 121,00"
    },
    {
        "modelnumber": "4659",
        "price": "€ 121,00"
    },
    {
        "modelnumber": "4663",
        "price": "€ 121,00"
    },
    {
        "modelnumber": "4665",
        "price": "€ 121,00"
    },
    {
        "modelnumber": "4667",
        "price": "€ 121,00"
    },
    {
        "modelnumber": "4669",
        "price": "€ 121,00"
    },
    {
        "modelnumber": "4670",
        "price": "€ 121,00"
    },
    {
        "modelnumber": "4675",
        "price": "€ 121,00"
    },
    {
        "modelnumber": "4678",
        "price": "€ 121,00"
    },
    {
        "modelnumber": "4679",
        "price": "€ 121,00"
    },
    {
        "modelnumber": "4683",
        "price": "€ 121,00"
    },
    {
        "modelnumber": "4685",
        "price": "€ 121,00"
    },
    {
        "modelnumber": "4690",
        "price": "€ 121,00"
    },
    {
        "modelnumber": "4692",
        "price": "€ 121,00"
    },
    {
        "modelnumber": "4695",
        "price": "€ 121,00"
    },
    {
        "modelnumber": "4698",
        "price": "€ 121,00"
    },
    {
        "modelnumber": "4700",
        "price": "€ 121,00"
    },
    {
        "modelnumber": "4701",
        "price": "€ 121,00"
    },
    {
        "modelnumber": "4702",
        "price": "€ 121,00"
    },
    {
        "modelnumber": "4705",
        "price": "€ 121,00"
    },
    {
        "modelnumber": "4707",
        "price": "€ 121,00"
    },
    {
        "modelnumber": "4711",
        "price": "€ 121,00"
    },
    {
        "modelnumber": "4712",
        "price": "€ 121,00"
    },
    {
        "modelnumber": "4714",
        "price": "€ 121,00"
    },
    {
        "modelnumber": "4716",
        "price": "€ 121,00"
    },
    {
        "modelnumber": "4717",
        "price": "€ 121,00"
    },
    {
        "modelnumber": "4718",
        "price": "€ 121,00"
    },
    {
        "modelnumber": "4719",
        "price": "€ 121,00"
    },
    {
        "modelnumber": "4731",
        "price": "€ 121,00"
    },
    {
        "modelnumber": "4732",
        "price": "€ 121,00"
    },
    {
        "modelnumber": "4734",
        "price": "€ 121,00"
    },
    {
        "modelnumber": "4736",
        "price": "€ 121,00"
    },
    {
        "modelnumber": "4737",
        "price": "€ 121,00"
    },
    {
        "modelnumber": "4738",
        "price": "€ 121,00"
    },
    {
        "modelnumber": "4739",
        "price": "€ 121,00"
    },
    {
        "modelnumber": "4741",
        "price": "€ 121,00"
    },
    {
        "modelnumber": "4742",
        "price": "€ 121,00"
    },
    {
        "modelnumber": "4748",
        "price": "€ 121,00"
    },
    {
        "modelnumber": "4749",
        "price": "€ 121,00"
    },
    {
        "modelnumber": "4770",
        "price": "€ 121,00"
    },
    {
        "modelnumber": "4771",
        "price": "€ 121,00"
    },
    {
        "modelnumber": "4772",
        "price": "€ 121,00"
    },
    {
        "modelnumber": "4777",
        "price": "€ 121,00"
    },
    {
        "modelnumber": "4779",
        "price": "€ 121,00"
    },
    {
        "modelnumber": "21020",
        "price": "€ 150,00"
    },
    {
        "modelnumber": "21022",
        "price": "€ 150,00"
    },
    {
        "modelnumber": "21028",
        "price": "€ 150,00"
    },
    {
        "modelnumber": "21030",
        "price": "€ 141,00"
    },
    {
        "modelnumber": "21040",
        "price": "€ 150,00"
    },
    {
        "modelnumber": "21047",
        "price": "€ 150,00"
    },
    {
        "modelnumber": "21050",
        "price": "€ 159,00"
    },
    {
        "modelnumber": "21053",
        "price": "€ 159,00"
    },
    {
        "modelnumber": "21061",
        "price": "€ 93,00"
    },
    {
        "modelnumber": "21062",
        "price": "€ 93,00"
    },
    {
        "modelnumber": "21090",
        "price": "€ 145,50"
    },
    {
        "modelnumber": "21092",
        "price": "€ 151,50"
    },
    {
        "modelnumber": "21093",
        "price": "€ 145,50"
    },
    {
        "modelnumber": "21095",
        "price": "€ 145,50"
    },
    {
        "modelnumber": "21096",
        "price": "€ 145,50"
    },
    {
        "modelnumber": "21097",
        "price": "€ 145,50"
    },
    {
        "modelnumber": "21141",
        "price": "€ 93,00"
    },
    {
        "modelnumber": "21172",
        "price": "€ 150,00"
    },
    {
        "modelnumber": "21173",
        "price": "€ 156,00"
    },
    {
        "modelnumber": "21180",
        "price": "€ 159,00"
    },
    {
        "modelnumber": "21185",
        "price": "€ 159,00"
    },
    {
        "modelnumber": "21188",
        "price": "€ 159,00"
    },
    {
        "modelnumber": "21190",
        "price": "€ 136,50"
    },
    {
        "modelnumber": "21192",
        "price": "€ 136,50"
    },
    {
        "modelnumber": "21202",
        "price": "€ 93,00"
    },
    {
        "modelnumber": "21204",
        "price": "€ 93,00"
    },
    {
        "modelnumber": "21205",
        "price": "€ 119,50"
    },
    {
        "modelnumber": "21206",
        "price": "€ 119,50"
    },
    {
        "modelnumber": "21221",
        "price": "€ 93,00"
    },
    {
        "modelnumber": "21232",
        "price": "€ 93,00"
    },
    {
        "modelnumber": "21240",
        "price": "€ 147,50"
    },
    {
        "modelnumber": "21260",
        "price": "€ 179,50"
    },
    {
        "modelnumber": "21270",
        "price": "€ 119,50"
    },
    {
        "modelnumber": "21273",
        "price": "€ 119,50"
    },
    {
        "modelnumber": "21276",
        "price": "€ 119,50"
    },
    {
        "modelnumber": "21280",
        "price": "€ 159,00"
    },
    {
        "modelnumber": "21284",
        "price": "€ 159,00"
    },
    {
        "modelnumber": "21310",
        "price": "€ 171,50"
    },
    {
        "modelnumber": "21312",
        "price": "€ 165,00"
    },
    {
        "modelnumber": "21313",
        "price": "€ 165,00"
    },
    {
        "modelnumber": "21318",
        "price": "€ 165,00"
    },
    {
        "modelnumber": "21320",
        "price": "€ 170,50"
    },
    {
        "modelnumber": "21330",
        "price": "€ 159,00"
    },
    {
        "modelnumber": "21332",
        "price": "€ 165,00"
    },
    {
        "modelnumber": "21333",
        "price": "€ 159,00"
    },
    {
        "modelnumber": "21339",
        "price": "€ 159,00"
    },
    {
        "modelnumber": "21360",
        "price": "€ 159,00"
    },
    {
        "modelnumber": "21365",
        "price": "€ 159,00"
    },
    {
        "modelnumber": "21370",
        "price": "€ 165,00"
    },
    {
        "modelnumber": "21373",
        "price": "€ 165,00"
    },
    {
        "modelnumber": "21377",
        "price": "€ 159,00"
    },
    {
        "modelnumber": "21440",
        "price": "€ 147,50"
    },
    {
        "modelnumber": "21443",
        "price": "€ 147,50"
    },
    {
        "modelnumber": "22040",
        "price": "€ 146,50"
    },
    {
        "modelnumber": "22043",
        "price": "€ 146,50"
    },
    {
        "modelnumber": "22044",
        "price": "€ 146,50"
    },
    {
        "modelnumber": "22047",
        "price": "€ 146,50"
    },
    {
        "modelnumber": "22049",
        "price": "€ 146,50"
    },
    {
        "modelnumber": "22062",
        "price": "€ 142,00"
    },
    {
        "modelnumber": "22063",
        "price": "€ 142,00"
    },
    {
        "modelnumber": "22064",
        "price": "€ 142,00"
    },
    {
        "modelnumber": "22065",
        "price": "€ 142,00"
    },
    {
        "modelnumber": "22067",
        "price": "€ 142,00"
    },
    {
        "modelnumber": "22141",
        "price": "€ 93,00"
    },
    {
        "modelnumber": "22149",
        "price": "€ 93,00"
    },
    {
        "modelnumber": "22150",
        "price": "€ 165,00"
    },
    {
        "modelnumber": "22159",
        "price": "€ 165,00"
    },
    {
        "modelnumber": "22190",
        "price": "€ 146,50"
    },
    {
        "modelnumber": "22192",
        "price": "€ 146,50"
    },
    {
        "modelnumber": "22210",
        "price": "€ 93,00"
    },
    {
        "modelnumber": "22212",
        "price": "€ 93,00"
    },
    {
        "modelnumber": "22213",
        "price": "€ 93,00"
    },
    {
        "modelnumber": "22260",
        "price": "€ 93,00"
    },
    {
        "modelnumber": "22262",
        "price": "€ 93,00"
    },
    {
        "modelnumber": "22271",
        "price": "€ 93,00"
    },
    {
        "modelnumber": "22279",
        "price": "€ 93,00"
    },
    {
        "modelnumber": "22291",
        "price": "€ 93,00"
    },
    {
        "modelnumber": "22292",
        "price": "€ 93,00"
    },
    {
        "modelnumber": "22300",
        "price": "€ 150,00"
    },
    {
        "modelnumber": "22301",
        "price": "€ 150,00"
    },
    {
        "modelnumber": "22303",
        "price": "€ 150,00"
    },
    {
        "modelnumber": "22305",
        "price": "€ 150,00"
    },
    {
        "modelnumber": "22307",
        "price": "€ 150,00"
    },
    {
        "modelnumber": "22320",
        "price": "€ 119,50"
    },
    {
        "modelnumber": "22321",
        "price": "€ 119,50"
    },
    {
        "modelnumber": "22323",
        "price": "€ 119,50"
    },
    {
        "modelnumber": "22325",
        "price": "€ 119,50"
    },
    {
        "modelnumber": "22328",
        "price": "€ 119,50"
    },
    {
        "modelnumber": "22360",
        "price": "€ 150,00"
    },
    {
        "modelnumber": "22362",
        "price": "€ 156,00"
    },
    {
        "modelnumber": "22363",
        "price": "€ 156,00"
    },
    {
        "modelnumber": "22365",
        "price": "€ 150,00"
    },
    {
        "modelnumber": "22367",
        "price": "€ 150,00"
    },
    {
        "modelnumber": "22368",
        "price": "€ 150,00"
    },
    {
        "modelnumber": "22400",
        "price": "€ 136,50"
    },
    {
        "modelnumber": "22401",
        "price": "€ 136,50"
    },
    {
        "modelnumber": "22403",
        "price": "€ 136,50"
    },
    {
        "modelnumber": "22407",
        "price": "€ 136,50"
    },
    {
        "modelnumber": "22408",
        "price": "€ 136,50"
    },
    {
        "modelnumber": "22409",
        "price": "€ 136,50"
    },
    {
        "modelnumber": "22423",
        "price": "€ 119,50"
    },
    {
        "modelnumber": "22425",
        "price": "€ 119,50"
    },
    {
        "modelnumber": "22445",
        "price": "€ 146,50"
    },
    {
        "modelnumber": "24010",
        "price": "€ 119,50"
    },
    {
        "modelnumber": "24013",
        "price": "€ 119,50"
    },
    {
        "modelnumber": "24019",
        "price": "€ 119,50"
    },
    {
        "modelnumber": "24020",
        "price": "€ 145,50"
    },
    {
        "modelnumber": "24024",
        "price": "€ 151,50"
    },
    {
        "modelnumber": "24025",
        "price": "€ 145,50"
    },
    {
        "modelnumber": "24030",
        "price": "€ 152,50"
    },
    {
        "modelnumber": "24033",
        "price": "€ 152,50"
    },
    {
        "modelnumber": "24037",
        "price": "€ 152,50"
    },
    {
        "modelnumber": "24038",
        "price": "€ 152,50"
    },
    {
        "modelnumber": "24040",
        "price": "€ 152,50"
    },
    {
        "modelnumber": "24042",
        "price": "€ 159,00"
    },
    {
        "modelnumber": "24043",
        "price": "€ 152,50"
    },
    {
        "modelnumber": "24048",
        "price": "€ 152,50"
    },
    {
        "modelnumber": "24050",
        "price": "€ 158,00"
    },
    {
        "modelnumber": "24052",
        "price": "€ 164,00"
    },
    {
        "modelnumber": "24053",
        "price": "€ 158,00"
    },
    {
        "modelnumber": "24058",
        "price": "€ 158,00"
    },
    {
        "modelnumber": "24060",
        "price": "€ 179,50"
    },
    {
        "modelnumber": "24063",
        "price": "€ 179,50"
    },
    {
        "modelnumber": "24072",
        "price": "€ 164,00"
    },
    {
        "modelnumber": "24073",
        "price": "€ 158,00"
    },
    {
        "modelnumber": "24085",
        "price": "€ 152,50"
    },
    {
        "modelnumber": "24088",
        "price": "€ 152,50"
    },
    {
        "modelnumber": "24092",
        "price": "€ 164,00"
    },
    {
        "modelnumber": "24099",
        "price": "€ 158,00"
    },
    {
        "modelnumber": "24102",
        "price": "€ 179,50"
    },
    {
        "modelnumber": "24108",
        "price": "€ 179,50"
    },
    {
        "modelnumber": "25010",
        "price": "€ 165,00"
    },
    {
        "modelnumber": "25011",
        "price": "€ 165,00"
    },
    {
        "modelnumber": "25012",
        "price": "€ 165,00"
    },
    {
        "modelnumber": "25020",
        "price": "€ 149,50"
    },
    {
        "modelnumber": "25023",
        "price": "€ 149,50"
    },
    {
        "modelnumber": "25027",
        "price": "€ 149,50"
    },
    {
        "modelnumber": "25030",
        "price": "€ 146,50"
    },
    {
        "modelnumber": "25050",
        "price": "€ 119,50"
    },
    {
        "modelnumber": "25052",
        "price": "€ 119,50"
    },
    {
        "modelnumber": "25053",
        "price": "€ 119,50"
    },
    {
        "modelnumber": "25058",
        "price": "€ 119,50"
    },
    {
        "modelnumber": "25059",
        "price": "€ 119,50"
    },
    {
        "modelnumber": "25070",
        "price": "€ 150,00"
    },
    {
        "modelnumber": "25072",
        "price": "€ 150,00"
    },
    {
        "modelnumber": "25077",
        "price": "€ 150,00"
    },
    {
        "modelnumber": "25110",
        "price": "€ 150,00"
    },
    {
        "modelnumber": "25112",
        "price": "€ 150,00"
    },
    {
        "modelnumber": "25350",
        "price": "€ 119,50"
    },
    {
        "modelnumber": "25351",
        "price": "€ 119,50"
    },
    {
        "modelnumber": "25353",
        "price": "€ 119,50"
    },
    {
        "modelnumber": "25375",
        "price": "€ 150,00"
    },
    {
        "modelnumber": "25379",
        "price": "€ 150,00"
    },
    {
        "modelnumber": "26010",
        "price": "€ 145,50"
    },
    {
        "modelnumber": "26019",
        "price": "€ 145,50"
    },
    {
        "modelnumber": "26020",
        "price": "€ 159,00"
    },
    {
        "modelnumber": "26023",
        "price": "€ 159,00"
    },
    {
        "modelnumber": "26032",
        "price": "€ 159,00"
    },
    {
        "modelnumber": "26033",
        "price": "€ 159,00"
    },
    {
        "modelnumber": "26040",
        "price": "€ 155,00"
    },
    {
        "modelnumber": "26042",
        "price": "€ 161,50"
    },
    {
        "modelnumber": "26043",
        "price": "€ 161,50"
    },
    {
        "modelnumber": "26048",
        "price": "€ 155,00"
    },
    {
        "modelnumber": "26050",
        "price": "€ 155,00"
    },
    {
        "modelnumber": "26060",
        "price": "€ 165,00"
    },
    {
        "modelnumber": "26065",
        "price": "€ 165,00"
    },
    {
        "modelnumber": "26070",
        "price": "€ 155,00"
    },
    {
        "modelnumber": "27010",
        "price": "€ 142,00"
    },
    {
        "modelnumber": "27011",
        "price": "€ 142,00"
    },
    {
        "modelnumber": "27015",
        "price": "€ 142,00"
    },
    {
        "modelnumber": "27018",
        "price": "€ 142,00"
    },
    {
        "modelnumber": "27022",
        "price": "€ 156,00"
    },
    {
        "modelnumber": "27030",
        "price": "€ 146,50"
    },
    {
        "modelnumber": "27042",
        "price": "€ 156,00"
    },
    {
        "modelnumber": "27044",
        "price": "€ 150,00"
    },
    {
        "modelnumber": "27047",
        "price": "€ 150,00"
    },
    {
        "modelnumber": "27050",
        "price": "€ 165,00"
    },
    {
        "modelnumber": "27060",
        "price": "€ 150,00"
    },
    {
        "modelnumber": "27063",
        "price": "€ 150,00"
    },
    {
        "modelnumber": "27070",
        "price": "€ 150,00"
    },
    {
        "modelnumber": "27073",
        "price": "€ 150,00"
    },
    {
        "modelnumber": "27420",
        "price": "€ 119,50"
    },
    {
        "modelnumber": "27425",
        "price": "€ 119,50"
    },
    {
        "modelnumber": "27429",
        "price": "€ 119,50"
    },
    {
        "modelnumber": "28000",
        "price": "€ 157,00"
    },
    {
        "modelnumber": "28004",
        "price": "€ 157,00"
    },
    {
        "modelnumber": "28005",
        "price": "€ 157,00"
    },
    {
        "modelnumber": "28008",
        "price": "€ 153,50"
    },
    {
        "modelnumber": "28010",
        "price": "€ 153,50"
    },
    {
        "modelnumber": "28012",
        "price": "€ 153,50"
    },
    {
        "modelnumber": "28015",
        "price": "€ 153,50"
    },
    {
        "modelnumber": "28016",
        "price": "€ 160,00"
    },
    {
        "modelnumber": "28017",
        "price": "€ 153,50"
    },
    {
        "modelnumber": "28018",
        "price": "€ 150,50"
    },
    {
        "modelnumber": "28019",
        "price": "€ 150,50"
    },
    {
        "modelnumber": "28020",
        "price": "€ 153,50"
    },
    {
        "modelnumber": "28021",
        "price": "€ 153,50"
    },
    {
        "modelnumber": "28022",
        "price": "€ 160,00"
    },
    {
        "modelnumber": "28023",
        "price": "€ 153,50"
    },
    {
        "modelnumber": "28024",
        "price": "€ 160,00"
    },
    {
        "modelnumber": "28028",
        "price": "€ 150,50"
    },
    {
        "modelnumber": "28029",
        "price": "€ 153,50"
    },
    {
        "modelnumber": "28032",
        "price": "€ 157,00"
    },
    {
        "modelnumber": "28033",
        "price": "€ 157,00"
    },
    {
        "modelnumber": "28034",
        "price": "€ 153,50"
    },
    {
        "modelnumber": "28035",
        "price": "€ 153,50"
    },
    {
        "modelnumber": "28036",
        "price": "€ 153,50"
    },
    {
        "modelnumber": "28038",
        "price": "€ 157,00"
    },
    {
        "modelnumber": "28040",
        "price": "€ 165,00"
    },
    {
        "modelnumber": "28042",
        "price": "€ 165,00"
    },
    {
        "modelnumber": "28048",
        "price": "€ 165,00"
    },
    {
        "modelnumber": "28049",
        "price": "€ 165,00"
    },
    {
        "modelnumber": "30010",
        "price": "€ 154,50"
    },
    {
        "modelnumber": "30013",
        "price": "€ 154,50"
    },
    {
        "modelnumber": "30014",
        "price": "€ 154,50"
    },
    {
        "modelnumber": "30015",
        "price": "€ 154,50"
    },
    {
        "modelnumber": "30018",
        "price": "€ 154,50"
    },
    {
        "modelnumber": "30023",
        "price": "€ 147,00"
    },
    {
        "modelnumber": "30024",
        "price": "€ 147,00"
    },
    {
        "modelnumber": "30025",
        "price": "€ 150,00"
    },
    {
        "modelnumber": "30027",
        "price": "€ 150,00"
    },
    {
        "modelnumber": "30028",
        "price": "€ 150,00"
    },
    {
        "modelnumber": "30030",
        "price": "€ 147,00"
    },
    {
        "modelnumber": "30031",
        "price": "€ 147,00"
    },
    {
        "modelnumber": "30033",
        "price": "€ 150,00"
    },
    {
        "modelnumber": "30035",
        "price": "€ 150,00"
    },
    {
        "modelnumber": "30036",
        "price": "€ 150,00"
    },
    {
        "modelnumber": "30037",
        "price": "€ 147,00"
    },
    {
        "modelnumber": "30038",
        "price": "€ 150,00"
    },
    {
        "modelnumber": "30039",
        "price": "€ 150,00"
    },
    {
        "modelnumber": "30041",
        "price": "€ 150,00"
    },
    {
        "modelnumber": "30045",
        "price": "€ 150,00"
    },
    {
        "modelnumber": "30050",
        "price": "€ 159,00"
    },
    {
        "modelnumber": "30053",
        "price": "€ 152,50"
    },
    {
        "modelnumber": "30054",
        "price": "€ 152,50"
    },
    {
        "modelnumber": "30055",
        "price": "€ 152,50"
    },
    {
        "modelnumber": "30060",
        "price": "€ 165,00"
    },
    {
        "modelnumber": "30062",
        "price": "€ 165,00"
    },
    {
        "modelnumber": "30065",
        "price": "€ 165,00"
    },
    {
        "modelnumber": "30068",
        "price": "€ 159,00"
    }
]
export const insert = async () => {
    let lucro = await DataStore.query(DevFittrModel, post => post.brand("eq", 'LuCro'));
    console.log(lucro);
    const deleted = await DataStore.delete(DevFittrModel, post => post.brand("eq", 'LuCro'));
     lucro = await DataStore.query(DevFittrModel, post => post.brand("eq", 'LuCro'));
    console.log(lucro);
    for (let i = 0; i < models.length; i++) {
        const model = models[i];
        for (let i = 0; i < files.length; i++) {
            const filename = files[i].filename;
            if (filename.includes(model.modelnumber)) {
                model.filename = filename;
            }
        }
        const lasts = [];
        console.log(model.lastgroup);
        if (model.lastgroup.includes('classic')) {
            lasts.push('S-classic');
            lasts.push('M-classic');
            lasts.push('W-classic');
            lasts.push('plus10-classic');
            for (let i = 0; i < prices.length; i++) {
                const price = prices[i];
                if (price.modelnumber === model.modelnumber) {
                    const prices = [];
                    prices.push({'lasttype':'S-classic','price': parseInt(price.price.replace('€',''))});
                    prices.push({'lasttype':'M-classic','price': parseInt(price.price.replace('€',''))});
                    prices.push({'lasttype':'W-classic','price': parseInt(price.price.replace('€',''))});
                    prices.push({'lasttype':'plus10-classic','price': parseInt(price.price.replace('€','')) + 5});
                    model.prices = JSON.stringify(prices);
                }
            }
        } else if (model.lastgroup.includes('urbanic')) {
            lasts.push('S-urbanic');
            lasts.push('M-urbanic');
            lasts.push('W-urbanic');
            lasts.push('plus10-urbanic');
            for (let i = 0; i < prices.length; i++) {
                const price = prices[i];
                if (price.modelnumber === model.modelnumber) {
                    const prices = [];
                    prices.push({'lasttype':'S-urbanic','price': parseInt(price.price.replace('€',''))});
                    prices.push({'lasttype':'M-urbanic','price': parseInt(price.price.replace('€',''))});
                    prices.push({'lasttype':'W-urbanic','price': parseInt(price.price.replace('€',''))});
                    prices.push({'lasttype':'plus10-urbanic','price': parseInt(price.price.replace('€','')) + 5});
                    model.prices = JSON.stringify(prices);
                }
            }
        } else if (model.lastgroup.includes('kinetic')) {
            lasts.push('S-kinetic');
            lasts.push('M-kinetic');
            lasts.push('W-kinetic');
            lasts.push('plus10-kinetic');
            for (let i = 0; i < prices.length; i++) {
                const price = prices[i];
                if (price.modelnumber === model.modelnumber) {
                    const prices = [];
                    prices.push({'lasttype':'S-kinetic','price': parseInt(price.price.replace('€',''))});
                    prices.push({'lasttype':'M-kinetic','price': parseInt(price.price.replace('€',''))});
                    prices.push({'lasttype':'W-kinetic','price': parseInt(price.price.replace('€',''))});
                    prices.push({'lasttype':'plus10-kinetic','price': parseInt(price.price.replace('€','')) + 5});
                    model.prices = JSON.stringify(prices);
                }
            }
        } else if (model.lastgroup.includes('sportic')) {
            lasts.push('S-sportic');
            lasts.push('M-sportic');
            lasts.push('W-sportic');
            lasts.push('plus10-sportic');
            for (let i = 0; i < prices.length; i++) {
                const price = prices[i];
                if (price.modelnumber === model.modelnumber) {
                    const prices = [];
                    prices.push({'lasttype':'S-sportic','price': parseInt(price.price.replace('€',''))});
                    prices.push({'lasttype':'M-sportic','price': parseInt(price.price.replace('€',''))});
                    prices.push({'lasttype':'W-sportic','price': parseInt(price.price.replace('€',''))});
                    prices.push({'lasttype':'plus10-sportic','price': parseInt(price.price.replace('€','')) + 5});
                    model.prices = JSON.stringify(prices);
                }
            }
        } else if (model.lastgroup.includes('ergonic')) {
            lasts.push('S-ergonic');
            lasts.push('M-ergonic');
            lasts.push('W-ergonic');
            lasts.push('plus10-ergonic');
            for (let i = 0; i < prices.length; i++) {
                const price = prices[i];
                if (price.modelnumber === model.modelnumber) {
                    const prices = [];
                    prices.push({'lasttype':'S-ergonic','price': parseInt(price.price.replace('€',''))});
                    prices.push({'lasttype':'M-ergonic','price': parseInt(price.price.replace('€',''))});
                    prices.push({'lasttype':'W-ergonic','price': parseInt(price.price.replace('€',''))});
                    prices.push({'lasttype':'plus10-ergonic','price': parseInt(price.price.replace('€','')) + 5});
                    model.prices = prices;
                }
            }
        }
        model.lasttypes = "[" + lasts.toString() + "]";
        model.category = [model.category];
        model.attributes.specs = [];
        model.attributes.styles = [];
        model.attributes.colors = [];
        if (model.category.includes('m')) {
            model.sizerangeMin = 40;
            model.sizerangeMax = 50;
        } else if (model.category.includes('f')) {
            model.sizerangeMin = 36;
            model.sizerangeMax = 43;
        }
        console.log(model);
        const saveResult = await DataStore.save(
            new DevFittrModel(model)
        );
        console.log(saveResult);
    }
}