<script setup>
import {
  IonModal,
  IonButton,
  IonCard,
  IonToolbar,
  IonButtons,
  IonItem,
  IonGrid,
  IonRow,
  IonCol,
  IonHeader,
  IonContent,
  IonSpinner,
  IonTitle,
  IonLabel,
  IonFooter,
  IonAvatar,
  IonAccordion,
  IonAccordionGroup,
  IonList,
  IonSearchbar,
  useIonRouter,
  alertController,
  loadingController
} from '@ionic/vue'
import {useRoute} from "vue-router";
import {useGlobalStore} from "@/store/global";
import {morph, useQuasar} from "quasar";
import {onBeforeUpdate, ref} from "vue";
import {API, DataStore, Predicates, SortDirection} from "aws-amplify";
import {
  FootcareApplicatie,
  FootcareClosingItem,
  FootcareOutsole,
  FootcareUpperleather,
  FootcareWelt
} from "@/models";

import {useShare} from "@vueuse/core/index";

const store = useGlobalStore();
const route = useRoute();
const router = useIonRouter();
const $q = useQuasar();

const props = defineProps({
  isOpenRef: Boolean,
  setFavorite: Function,
  setOpen: Function,
  innerWidth: Number
})

const thumbRef = ref([]);
const indexZoomed = ref(void 0);
const materials = ref();
const selectedMaterial = ref();
const zoomed = ref(false);

const slide = ref('');
slide.value = store.activeModel.modelID;

async function orderModel() {
  props.setOpen(false);
  await router.push(`/osb/order/${store.activeModel.category}`);
}

function zoomImage(index) {
  const indexZoomedState = indexZoomed.value
  let cancel = void 0
  indexZoomed.value = void 0
  if (index !== void 0 && index !== indexZoomedState) {
    cancel = morph({
      from: thumbRef.value[index].$el,
      onToggle: () => {
        indexZoomed.value = index
      },
      duration: 500,
      onEnd: end => {
        if (end === 'from' && indexZoomed.value === index) {
          indexZoomed.value = void 0
        }
      }
    })
  }
  if (
      indexZoomedState !== void 0 &&
      (cancel === void 0 || cancel() === false)
  ) {
    morph({
      from: thumbRef.value[indexZoomedState].$el,
      waitFor: 300,
      duration: 300
    })
  }
}

const getMaterials = async (event) => {
  if (event === 'leathers') {
    selectedMaterial.value = 'leathers';
    materials.value = await DataStore.query(FootcareUpperleather, Predicates.ALL, {
      sort: s => s.item(SortDirection.ASCENDING)
    });
  } else if (event === 'outsoles') {
    selectedMaterial.value = 'outsoles';
    materials.value = await DataStore.query(FootcareOutsole, Predicates.ALL, {
      sort: s => s.item(SortDirection.ASCENDING)
    });
  } else if (event === 'welts') {
    selectedMaterial.value = 'welts';
    materials.value = await DataStore.query(FootcareWelt, Predicates.ALL, {
      sort: s => s.item(SortDirection.ASCENDING)
    });
  } else if (event === 'applicaties') {
    selectedMaterial.value = 'applicaties';
    materials.value = await DataStore.query(FootcareApplicatie, Predicates.ALL, {
      sort: s => s.item(SortDirection.ASCENDING)
    });
  } else if (event === 'closings') {
    selectedMaterial.value = 'closings';
    materials.value = await DataStore.query(FootcareClosingItem, Predicates.ALL, {
      sort: s => s.item(SortDirection.ASCENDING)
    });
  }
}

const searchMaterials = async (event) => {
  if (event !== '' && event !== undefined && event !== null) {
    if (selectedMaterial.value === 'leathers') {
      materials.value = await DataStore.query(FootcareUpperleather, c => c.item('contains', event), {
        sort: s => s.item(SortDirection.ASCENDING)
      });
    } else if (selectedMaterial.value === 'outsoles') {
      materials.value = await DataStore.query(FootcareOutsole, c => c.item('contains', event), {
        sort: s => s.item(SortDirection.ASCENDING)
      });
    } else if (selectedMaterial.value === 'welts') {
      materials.value = await DataStore.query(FootcareWelt, c => c.item('contains', event), {
        sort: s => s.item(SortDirection.ASCENDING)
      });
    } else if (selectedMaterial.value === 'applicaties') {
      materials.value = await DataStore.query(FootcareApplicatie, c => c.item('contains', event), {
        sort: s => s.item(SortDirection.ASCENDING)
      });
    }
  } else {
    if (selectedMaterial.value === 'leathers') {
      materials.value = await DataStore.query(FootcareUpperleather, Predicates.ALL, {
        sort: s => s.item(SortDirection.ASCENDING)
      });
    } else if (selectedMaterial.value === 'outsoles') {
      materials.value = await DataStore.query(FootcareOutsole, Predicates.ALL, {
        sort: s => s.item(SortDirection.ASCENDING)
      });
    } else if (selectedMaterial.value === 'welts') {
      materials.value = await DataStore.query(FootcareWelt, Predicates.ALL, {
        sort: s => s.item(SortDirection.ASCENDING)
      });
    } else if (selectedMaterial.value === 'applicaties') {
      materials.value = await DataStore.query(FootcareApplicatie, Predicates.ALL, {
        sort: s => s.item(SortDirection.ASCENDING)
      });
    }
  }
}

const shareModel = async () => {
  const alert = await alertController
      .create({
        cssClass: 'my-custom-class',
        header: 'Naar welk email adres mogen we het model verzenden?',
        subHeader: 'Uw device ondersteund het delen van bestanden alleen delen via direct-mail. ' +
            'In onderstaand formulier kunt u het email adres van de ontvanger invoeren en optioneel hier een persoonlijke boodschap bijvoegen. ',
        message: null,
        translucent: true,
        inputs: [
          {
            name: 'mailto',
            id: 'mailto',
            type: 'email',
            label: 'Email',
            placeholder: 'ont@vanger.nl',
            attributes: {
              inputmode: 'email'
            },
          },
          {
            name: 'message',
            id: 'message',
            type: 'textarea',
            label: 'Persoonlijke boodschap',
            value: 'Schoenmodel doorgestuurd via Fittr!',
          },
        ],
        buttons: [
          {
            text: 'Cancel',
            role: 'cancel',
            cssClass: 'secondary',
            handler: () => {
              console.log('Confirm Cancel')
            },
          },
          {
            text: 'Verzenden!',
            handler: async (input) => {
              const loading = await loadingController
                  .create({
                    cssClass: 'my-custom-class',
                    message: 'Verzenden...',
                  });
              await loading.present();
              const apiName = "shareModel"; // replace this with your api name.
              const path = "/" + "share"; //replace this with the path you have configured on your API
              const myInit = {
                body: {
                  mailto: [input.mailto],
                  models: JSON.stringify([store.activeModel]),
                  message: input.message,
                }, // replace this with attributes you need
              };
              await API.post(apiName, path, myInit)
                  .then(response => {
                    console.log(response);
                    setTimeout(async function () {
                      await loading.dismiss();
                      $q.notify({
                        color: 'green-5',
                        position: 'center',
                        textColor: 'white',
                        icon: 'fal fa-cat-space',
                        message: 'Cool, het model is verzonden!'
                      })
                    }, 1500);
                  })
                  .catch(error => {
                    console.log(error.response);
                  });
            },
          },
        ],
      });
  await alert.present();
}

async function shareAllModels() {
  const urls = ref([]);
  store.activeModels.forEach(model => urls.value.push('https://d1mxpim4y30517.cloudfront.net/modelImagesFittr/' + model.filename));

  const alert = await alertController
      .create({
        cssClass: 'my-custom-class',
        header: 'Naar welk email adres mogen we het model verzenden?',
        subHeader: 'Uw device ondersteund het delen van bestanden alleen delen via direct-mail. ' +
            'In onderstaand formulier kunt u het email adres van de ontvanger invoeren en optioneel hier een persoonlijke boodschap bijvoegen. ',
        message: null,
        translucent: true,
        inputs: [
          {
            name: 'mailto',
            id: 'mailto',
            type: 'email',
            label: 'Email',
            placeholder: 'ont@vanger.nl',
            attributes: {
              inputmode: 'email'
            },
          },
          {
            name: 'message',
            id: 'message',
            type: 'textarea',
            label: 'Persoonlijke boodschap',
            value: 'Schoenmodellen doorgestuurd via Fittr!',
          },
        ],
        buttons: [
          {
            text: 'Cancel',
            role: 'cancel',
            cssClass: 'secondary',
            handler: () => {
              console.log('Confirm Cancel')
            },
          },
          {
            text: 'Verzenden!',
            handler: async (input) => {

              const loading = await loadingController
                  .create({
                    cssClass: 'my-custom-class',
                    message: 'Verzenden...',
                  });
              await loading.present();
              const apiName = "shareModel"; // replace this with your api name.
              const path = "/" + "share"; //replace this with the path you have configured on your API
              const myInit = {
                body: {
                  mailto: [input.mailto],
                  models: JSON.stringify(store.activeModels),
                  message: input.message,
                }, // replace this with attributes you need
              };
              await API.post(apiName, path, myInit)
                  .then(response => {
                    console.log(response);
                    setTimeout(async function () {
                      await loading.dismiss();
                      $q.notify({
                        color: 'green-5',
                        position: 'center',
                        textColor: 'white',
                        icon: 'fal fa-cat-space',
                        message: 'Cool, het model is verzonden!'
                      })
                    }, 1500);
                  })
                  .catch(error => {
                    console.log(error.response);
                  });
            },
          },
        ],
      });
  await alert.present();
}

async function updateActiveModel(id) {

  store.activeModel = store.initialisedModels.find(model => model.modelID === id);

  store.model = store.activeModel;

}

// Make sure to reset the dynamic refs before each update.
onBeforeUpdate(() => {
  thumbRef.value = [];
  slide.value = store.activeModel.modelID;
});

</script>

<template>
  <ion-modal
      :breakpoints="[0.895,1]"
      :class="[route.path.includes('osa') ? 'modal-with-sidebar' : 'modal-without-sidebar']"
      :initial-breakpoint="0.895"
      :handle="true"
      :canDismiss="true"
      :is-open="props.isOpenRef"
      :backdropDismiss="false"
      @didDismiss="props.setOpen(false)">
    <ion-grid style="padding:0;margin:0;width:100%;height: 100%">
      <ion-row style="padding:0;margin:0;width:100%;height: 100%">
        <ion-col style="border-right:solid 1px var(--ion-color-medium);padding:0;margin:0;width:100%;height: 100%"
                 :size-sm="route.path.includes('osa') ? 12 : 12" :size-md="route.path.includes('osa') ? 8 : 12">
          <ion-header>
            <ion-toolbar>
              <ion-buttons slot="start">

                <ion-button color="tertiary" fill="clear" @click="shareModel()">
                  <i class="fa-light fa-share-from-square"
                     style="font-size: 22px;"></i>
                </ion-button>
                <ion-button color="tertiary" fill="clear" @click="shareAllModels()">
                  <i class="fa-light fa-share-all"
                     style="font-size: 22px;"></i>
                </ion-button>
                <ion-button v-if="store.favoritesOpen === true"
                            @click="props.setFavorite(store.activeModel.modelID)"
                            color="tertiary"
                            fill="clear"
                            style="margin-left: 10px">
                  <i class="fa-light fa-trash-alt"
                     style="font-size: 22px;"></i>
                </ion-button>
              </ion-buttons>
              <ion-buttons v-if="route.path.includes('osb') || props.innerWidth < 768"
                           slot="end">
                <ion-button color="tertiary"
                            @click="props.setOpen(false)">
                  Close
                </ion-button>
              </ion-buttons>
            </ion-toolbar>
          </ion-header>

          <ion-content
              style="--background:white;--gallery-box-width: 100%!important;max-width: 100%!important;max-height: 100%!important;width:100%;height: 100%;padding:5px">
            <q-carousel
                v-model="slide"
                :draggable="false"
                animated
                :arrows="store.activeModels.length > 1"
                control-color="grey"
                infinite
                style="width:100%;height: 80%;background: transparent"
                swipeable
                transition-next="slide-left"
                transition-prev="slide-right"
                @transition="updateActiveModel(slide)">

              <template v-for="item in store.activeModels" :key="'slide_' + item?.modelID">

                <q-carousel-slide :img-src="store.imagePath + item.filename"
                                  :name="item.modelID" style="background-image:none!important">
                  <v-zoomer
                      :ref="() => {
                            return 'zoomer_' + item.modelID
                          }"
                      :max-scale="3"
                      :zooming-elastic="true">
                    <q-img
                        :draggable="false"
                        class="rounded-borders"
                        @error="$event.target.src = 'https://d1mxpim4y30517.cloudfront.net/modelImagesFittr/' + item.filename"
                        :src="store.imagePath + item.filename"
                        style="object-fit: contain; width: 87.5%; height: 87.5%;margin-left: 6.250%;"
                    >
                      <template v-slot:loading>
                        <ion-spinner>
                        </ion-spinner>
                      </template>
                    </q-img>
                  </v-zoomer>
                </q-carousel-slide>
              </template>
            </q-carousel>

            <ion-card
                style="overflow-x:auto;box-shadow: rgba(0, 0, 0, 0.25) 0 0 16px;position:absolute;bottom:0;height: 34%;left:0;margin:0;width: 100%;">
              <ion-header>
                <ion-toolbar
                    style="--min-height: unset;padding-top:0;padding-bottom:0;--margin-top: 0;--margin-bottom: 0;display:flex;align-items:center;width: 100%;">
                  <ion-buttons slot="start"
                               style="--padding-top:3px;--padding-bottom:3px;--margin-top: 0;--margin-bottom: 0">
                    <!--                        <ion-button translucent style="&#45;&#45;background:var(&#45;&#45;ion-color-tertiary)" fill="solid"-->
                    <!--                                    @click="$refs['zoomer_' + slide].zoomIn()">-->
                    <!--                          <i-->
                    <!--                              class="fa-light fa-magnifying-glass-plus"-->
                    <!--                              style="padding-left:6px;padding-right:6px"></i></ion-button>-->
                    <!--                        <ion-button style="&#45;&#45;background:var(&#45;&#45;ion-color-tertiary);margin-left:10px;" fill="solid"-->
                    <!--                                    @click="$refs['zoomer_' + slide].zoomOut()"><i-->
                    <!--                            class="fa-light fa-magnifying-glass-minus"-->
                    <!--                            style="padding-left:6px;padding-right:6px"></i></ion-button>-->
                  </ion-buttons>
                  <ion-title>
                    <ion-label style="padding-top:5px;padding-bottom:5px;">
                      <div class="text-h5 text-weight-bold"
                           style="color:var(--ion-color-primary)">
                        {{
                          store.activeModel.colornumber !== 'na' ? store.activeModel.modelnumber + " - " + store.activeModel.colornumber : store.activeModel.modelnumber
                        }}
                      </div>
                    </ion-label>
                  </ion-title>
                  <q-space></q-space>
                  <ion-button v-if="route.path.includes('osb') && store.signedIn" color="tertiary" fill="solid"
                              shape="rounded"
                              slot="end"
                              @click="orderModel()"
                              style="height: 2.2em;--padding-start: 15px;--padding-end: 15px;margin-right:16px;">
                    <i class="fa-light fa-truck-fast" style="font-size:16px;margin-right: 5px"></i>
                    <ion-label style="font-size: 16px"> | Order</ion-label>
                  </ion-button>
                  <!--              </ion-buttons>-->
                </ion-toolbar>
              </ion-header>

              <q-toolbar
                  style="overflow-x:auto;justify-content:center;display: flex;flex-direction: row;flex-wrap: nowrap;width:100%;">
                <template v-for="item in store.activeModels" :key="item.modelID">
                  <ion-item tappable @click="slide = item.modelID"
                            :style="slide === item.modelID ? 'border-radius:5px;--inner-border-width:2px;' : 'border-radius:0;--inner-border-width:0;--border-color:transparent;border-bottom:1px solid light-grey'"
                            style="margin:6px;--padding-start: 1px;--padding-end: 1px;--inner-padding-end:2px;--inner-padding-start:2px;">
                    <q-img width="55px"
                           :ratio="1"
                           :src="store.imagePath + 'medium_' + item.filename"
                           alt="No Image">
                      <template v-slot:loading>
                        <ion-spinner>
                        </ion-spinner>
                      </template>
                    </q-img>
                  </ion-item>
                </template>
              </q-toolbar>
              <ion-footer
                  style="width: 100%;bottom:0;height:100%;padding-top: 0;padding-bottom: 0;margin-top: 0;margin-bottom:0;">
                <ion-toolbar style="padding-top: 0;padding-bottom: 0;margin-top: 0;margin-bottom:0;">
                  <ion-item
                      style="--background:transparent;--padding-top: 0px;--padding-bottom: 0;margin-top:0;margin-bottom: 0">
                    <ion-label
                        style="padding-top: 0;padding-bottom: 0;margin-top: 0;margin-bottom:0;font-size: 1.1em"
                        v-if="route.path.includes('osa') && store.signedIn">
                      Opmerkingen
                    </ion-label>
                  </ion-item>
                  <ion-item lines="none"
                            style="--background:transparent;--padding-top: 0;--padding-bottom: 0;">
                    <ion-label style="margin-top: 0;margin-bottom:0;" mode="ios"
                               v-if="route.path.includes('osa') && store.signedIn">
                      {{ store.activeModel?.attributes.notes || 'Geen' }}
                    </ion-label>
                  </ion-item>
                </ion-toolbar>
              </ion-footer>
            </ion-card>
          </ion-content>
        </ion-col>
        <ion-col class="ion-hide-sm-down" style="padding:0;margin:0;width:100%;height: 100%;" :size-sm="route.path.includes('osa') ? 12 : 0"
                 :size-md="route.path.includes('osa') ? 4 : 0">
          <ion-content color="light">
            <ion-toolbar style="position: absolute;top:0;left:0;--background:var(--ion-color-light)">
              <ion-buttons slot="end">
                <ion-button style="margin-right:16px" color="tertiary" @click="props.setOpen(false)">Close</ion-button>
              </ion-buttons>
            </ion-toolbar>

            <ion-accordion-group expand="inset" @ionChange="getMaterials($event.target.value)"
                                 style="position: relative;top:60px;left:-8px;width:calc(100% - 16px);background:var(--ion-color-light)">

              <ion-accordion value="leathers">
                <ion-item slot="header" button style="color:var(--ion-color-secondary);">
                  <ion-avatar slot="start" style="display: flex;justify-content: center;align-items: center;">
                    <i class="fa-solid fa-cow" style="font-size: 20px"></i>
                  </ion-avatar>
                  <ion-label>Overleder</ion-label>
                </ion-item>

                <ion-list slot="content" :inset="true">
                  <ion-toolbar style="--background:white">
                    <ion-searchbar inputmode="search"
                                   @ionChange="searchMaterials($event.target.value)"/>
                  </ion-toolbar>
                  <ion-item v-for="(item,index) in materials" :key="'leather_'+ item.id"
                            style="position:unset;z-index:0;--width:100%;--max-width: 100%;--min-width:100%;"
                            :style="index === indexZoomed ? 'z-index:7000;' : void 0">
                    <ion-avatar
                        style="border-radius:5px;margin:8px;position:unset;max-width: 160px;min-width:110px;height: auto"
                        :ref="el => { thumbRef[index] = el }"
                        :class="index === indexZoomed ? 'z-top' : void 0"
                        :style="index === indexZoomed ? 'width: 100%; max-width: 100%;' : void 0"
                        class="cursor-pointer"
                        tappable
                        @click="zoomImage(index)">
                      <q-img style="border-radius:5px;min-width:110px;height: auto"
                             :ratio="1"
                             :style="index === indexZoomed ? 'z-index:7000;' : void 0"
                             :src="store.imagePath + item.filename"
                             alt="No Image">
                        <template v-slot:loading>
                          <ion-spinner>
                          </ion-spinner>
                        </template>
                      </q-img>
                    </ion-avatar>
                    <ion-label style="padding-left: 16px">
                      {{ item.item }}
                    </ion-label>
                  </ion-item>
                </ion-list>
              </ion-accordion>

              <ion-accordion value="applicaties">
                <ion-item slot="header" button style="color:var(--ion-color-secondary);">
                  <ion-avatar slot="start" style="display: flex;justify-content: center;align-items: center;">
                    <i class="fa-solid fa-poo" style="font-size: 20px"></i>
                  </ion-avatar>
                  <ion-label>Applicaties</ion-label>
                </ion-item>
                <ion-list slot="content" :inset="true">
                  <ion-toolbar style="--background:white">
                    <ion-searchbar inputmode="search"
                                   @ionChange="searchMaterials($event.target.value)"/>
                  </ion-toolbar>
                  <ion-item v-for="(item,index) in materials" :key="'applicaties_'+ item.id"
                            style="position:unset;z-index:0;--width:100%;--max-width: 100%;--min-width:100%;"
                            :style="index === indexZoomed ? 'z-index:7000;' : void 0">
                    <ion-avatar
                        style="border-radius:5px;margin:8px;position:unset;max-width: 160px;min-width:110px;height: auto"
                        :ref="el => { thumbRef[index] = el }"
                        :class="index === indexZoomed ? 'z-top' : void 0"
                        :style="index === indexZoomed ? 'width: 100%; max-width: 100%;' : void 0"
                        class="cursor-pointer"
                        tappable
                        @click="zoomImage(index)">
                      <q-img style="border-radius:5px;min-width:110px;height: auto"
                             :ratio="1"
                             :style="index === indexZoomed ? 'z-index:7000;' : void 0"
                             :src="store.imagePath + item.filename"
                             alt="No Image">
                        <template v-slot:loading>
                          <ion-spinner>
                          </ion-spinner>
                        </template>
                      </q-img>
                    </ion-avatar>
                    <ion-label style="padding-left: 16px">
                      {{ item.item }}
                    </ion-label>
                  </ion-item>
                </ion-list>
              </ion-accordion>

              <ion-accordion value="outsoles">
                <ion-item slot="header" button style="color:var(--ion-color-secondary);">
                  <ion-avatar slot="start" style="display: flex;justify-content: center;align-items: center;">
                    <i class="fa-solid fa-shoe-prints" style="font-size: 20px"></i>
                  </ion-avatar>
                  <ion-label>Loopzolen</ion-label>
                </ion-item>
                <ion-list slot="content" :inset="true">
                  <ion-toolbar style="--background:white">
                    <ion-searchbar inputmode="search"
                                   @ionChange="searchMaterials($event.target.value)"/>
                  </ion-toolbar>
                  <ion-item v-for="(item,index) in materials" :key="'outsoles_'+ item.id"
                            style="position:unset;z-index:0;--width:100%;--max-width: 100%;--min-width:100%;"
                            :style="index === indexZoomed ? 'z-index:7000;' : void 0">
                    <ion-avatar
                        style="border-radius:5px;margin:8px;position:unset;max-width: 160px;min-width:110px;height: auto"
                        :ref="el => { thumbRef[index] = el }"
                        :class="index === indexZoomed ? 'z-top' : void 0"
                        :style="index === indexZoomed ? 'width: 100%; max-width: 100%;' : void 0"
                        class="cursor-pointer"
                        tappable
                        @click="zoomImage(index)">
                      <q-img style="border-radius:5px;min-width:110px;height: auto"
                             :ratio="1"
                             :style="index === indexZoomed ? 'z-index:7000;' : void 0"
                             :src="store.imagePath + item.filename"
                             alt="No Image">
                        <template v-slot:loading>
                          <ion-spinner>
                          </ion-spinner>
                        </template>
                      </q-img>
                    </ion-avatar>
                    <ion-label style="padding-left: 16px">
                      {{ item.item }}
                    </ion-label>
                  </ion-item>
                </ion-list>
              </ion-accordion>

              <ion-accordion value="welts">
                <ion-item slot="header" button style="color:var(--ion-color-secondary);">
                  <ion-avatar slot="start" style="display: flex;justify-content: center;align-items: center;">
                    <i class="fa-solid fa-ribbon" style="font-size: 20px"></i>
                  </ion-avatar>
                  <ion-label>Randen</ion-label>
                </ion-item>
                <ion-list slot="content" :inset="true">
                  <ion-toolbar style="--background:white">
                    <ion-searchbar inputmode="search"
                                   @ionChange="searchMaterials($event.target.value)"/>
                  </ion-toolbar>
                  <ion-item v-for="(item,index) in materials" :key="'welts_'+ item.id"
                            style="position:unset;z-index:0;--width:100%;--max-width: 100%;--min-width:100%;"
                            :style="index === indexZoomed ? 'z-index:7000;' : void 0">
                    <ion-avatar
                        style="border-radius:5px;margin:8px;position:unset;max-width: 160px;min-width:110px;height: auto"
                        :ref="el => { thumbRef[index] = el }"
                        :class="index === indexZoomed ? 'z-top' : void 0"
                        :style="index === indexZoomed ? 'width: 100%; max-width: 100%;' : void 0"
                        class="cursor-pointer"
                        tappable
                        @click="zoomImage(index)">
                      <q-img style="border-radius:5px;min-width:110px;height: auto"
                             :ratio="1"
                             :style="index === indexZoomed ? 'z-index:7000;' : void 0"
                             :src="store.imagePath + item.filename"
                             alt="No Image">
                        <template v-slot:loading>
                          <ion-spinner>
                          </ion-spinner>
                        </template>
                      </q-img>
                    </ion-avatar>
                    <ion-label style="padding-left: 16px">
                      {{ item.item }}
                    </ion-label>
                  </ion-item>
                </ion-list>
              </ion-accordion>

              <ion-accordion value="closings">
                <ion-item slot="header" button style="color:var(--ion-color-secondary);">
                  <ion-avatar slot="start" style="display: flex;justify-content: center;align-items: center;">
                    <i class="fa-solid fa-american-sign-language-interpreting" style="font-size: 20px"></i>
                  </ion-avatar>
                  <ion-label>Sluitingen</ion-label>
                </ion-item>
                <ion-list slot="content" :inset="true">
                  <ion-toolbar style="--background:white">
                    <ion-searchbar inputmode="search"
                                   @ionChange="searchMaterials($event.target.value)"/>
                  </ion-toolbar>
                  <ion-item v-for="(item,index) in materials" :key="'welts_'+ item.id"
                            style="position:unset;z-index:0;--width:100%;--max-width: 100%;--min-width:100%;"
                            :style="index === indexZoomed ? 'z-index:7000;' : void 0">
                    <ion-avatar
                        style="border-radius:5px;margin:8px;position:unset;max-width: 160px;min-width:110px;height: auto"
                        :ref="el => { thumbRef[index] = el }"
                        :class="index === indexZoomed ? 'z-top' : void 0"
                        :style="index === indexZoomed ? 'width: 100%; max-width: 100%;' : void 0"
                        class="cursor-pointer"
                        tappable
                        @click="zoomImage(index)">
                      <q-img style="border-radius:5px;min-width:110px;height: auto"
                             :ratio="1"
                             :style="index === indexZoomed ? 'z-index:7000;' : void 0"
                             :src="store.imagePath + item.filename"
                             alt="No Image">
                        <template v-slot:loading>
                          <ion-spinner>
                          </ion-spinner>
                        </template>
                      </q-img>
                    </ion-avatar>
                    <ion-label style="padding-left: 16px">
                      {{ item.item }}
                    </ion-label>
                  </ion-item>
                </ion-list>
              </ion-accordion>

            </ion-accordion-group>

          </ion-content>
        </ion-col>
      </ion-row>
    </ion-grid>
  </ion-modal>
</template>
<style>
/*.my-custom-class .alert-wrapper {*/
/*  --min-width: 30%;*/
/*  --max-width: 50%;*/
/*}*/
.my-custom-class h2 {
  line-height: unset;
}

.q-carousel .q-carousel__navigation--bottom {
  bottom: 13.5%;
  position: fixed;
  z-index: 1000;
}

.q-carousel .q-carousel__navigation-inner img {
  height: 55px !important;
  z-index: 1000 !important;
  width: 55px;
}

.q-carousel img.q-carousel__thumbnail {
  padding: 0;
  margin: 0;
  width: 55px;
  border-radius: 0;
  opacity: 1;
  /*border-right: 1px solid rgba(155, 155, 155, 0.5);*/
  /*border-left: 1px solid rgba(255, 255, 255, 0.5);*/
}

.q-carousel img.q-carousel__thumbnail.q-carousel__thumbnail--active {
  border-top: 1px solid var(--q-secondary);
  border-bottom: 1px solid var(--q-secondary);
  border-left: 1px solid var(--q-secondary);
  border-right: 1px solid var(--q-secondary);
  border-radius: 5px;
  opacity: 0.65;
}

.q-carousel__slide {
  background-image: none !important;
  padding-left: 30px !important;
  padding-right: 30px !important;
}

/*.q-table__middle {*/
/*	min-height: 0 !important;*/
/*	height: 0 !important;*/
/*	margin-bottom: 0 !important;*/
/*}*/
</style>

<style scoped>

ion-modal {
  --background: white;
  --max-height: 100%;
  --height: 100%;
}

.modal-with-sidebar {
  --width: 95%;
  --min-width: 95%;
}

ion-accordion {
  width: 100%;
  margin: 0 16px 0 0;
}

ion-accordion.accordion-expanding,
ion-accordion.accordion-expanded {
  width: calc(100% + 16px);
  margin: 8px 8px 8px -8px;
}

@media (min-width: 1150px) {
  .modal-with-sidebar {
    --width: 80%;
    --min-width: 80%;
  }

  /*.modal-without-sidebar {*/
  /*  --width: '';*/
  /*  --min-width: '';*/
  /*}*/
}

/*###############################################################*/
@media (min-width: 1281px) {
  .modal-with-sidebar {
    --width: 65%;
    --min-width: 65%;
  }

  /*.modal-without-sidebar {*/
  /*  --width: '';*/
  /*  --min-width: '';*/
  /*}*/
}
</style>